import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EmtrService} from '../emtr.service';
import {emtrConstant} from '../constants/emtrConstants';
import {Subscription} from 'rxjs';
import {BulkUploadComponent, SharedService} from '@ems/shared';
import {Router} from '@angular/router';

@Component({
  selector: 'tr-rules-bulk-upload',
  templateUrl: './rules-bulk-upload.component.html'
})
export class RulesBulkUploadComponent implements OnInit, OnDestroy {

  emtrConstant = emtrConstant;
  fileTemplate = 'RULE';
  library = 'RULE';
  orgId: any;
  uploadFilePoint: any;
  navigateTo = '/ems/emtr/rules/rules-list';
  nameEntered = '';
  public currentOrgSubscription: Subscription;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(BulkUploadComponent) bulkUploadComponent: BulkUploadComponent;
  isFileUploaded = false;
  showError = false;
  confirmMessage: any;
  downloadTemplateLabel: any;
  hasEmpSummaryAccess = true;
  downloadTempGutTrackValue = 'adobe-dl_rulesupload_template|fireonce';
  chooseFileGutTrackValue = 'adobe-lc_rulesupload_choosefile|fireonce';
  uploadBtnGutTack = 'rulesupload_upload';
  cancelBtnGutTack = 'rulesupload_cancel';

  constructor(private e: EmtrService, private router: Router, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst) {
  }

  ngOnInit() {
    this.currentOrgSubscription = this.e.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        if (clientInfo.businessUnit && clientInfo.businessUnit === 'RBS') {
          this.router.navigate([this.navigateTo]);
        }
        this.orgId = clientInfo.orgId;
      }
      if (this.orgId) {
        this.hasEmpSummaryAccess = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.employeeSummary.uuid, '', '');
        const postURL = '/rules';
        this.uploadFilePoint = postURL + '?name=RuleUpload&orgId=' + this.orgId;
        if (this.hasEmpSummaryAccess && !this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '')) {
          this.router.navigate([this.navigateTo]);
        }
      }
    });
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.downloadTemplateLabel = this.emtrConstant.bulkUpload.downloadText;
  }

  ngOnDestroy(): void {
    this.sharedService.isConfirmationRequiredClientChange = false;
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
  }

  getEmittedFileVal(data) {
    this.showError = data.showError;
    this.isFileUploaded = data.isFileUploaded;
    this.sharedService.isConfirmationRequiredClientChange = this.isFileUploaded;
  }

  uploadFile() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue(this.uploadBtnGutTack);
    this.isFileUploaded = false;
    this.bulkUploadComponent.uploadFile();
  }

  cancelFileUpload() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue(this.cancelBtnGutTack);
    this.confirmMessage.message = this.emtrConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate([this.navigateTo]);
  }

  canDeactivate(event, observer) {
    if (this.confirmMessage) {
      this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.emtrConstant.rulesConstant.confirmationMessage.menuClickMsg;
    }
    if (this.isFileUploaded) {
      this.sharedService.isLoadPage = true;
      this.confirmationMessage.cancelBtnRejectGutTack = this.cancelBtnGutTack;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }
}
