import {Injectable} from '@angular/core';
import {
  instrmntGroupFormDataModel,
  InstrumentGroupFormDataModel,
  InstrumentGrpStep1
} from './instrument-group-form-data.model';
import {Router} from '@angular/router';
import {WorkflowService} from '@ems/shared';


@Injectable({
  providedIn: 'root'
})
export class InstrumentGroupFormDataService {

  public formData = instrmntGroupFormDataModel;

  constructor(private workflowService: WorkflowService, private router: Router) {
  }

  setStep1(data: any, valid: boolean) {
    /* Update the the instrument group Step 1 data only when the the instrument group Step 1 Form had been validated successfully */
    this.formData.name = data.groupName;
    this.formData.orgId = data.orgId;
    this.formData.description = data.description;
    this.formData.businessUnit = data.businessUnit;
    /* Validate Personal Step in Workflow */
    this.workflowService.validateStep('step1', 'instrumentGrps', valid);
  }

  getInstrumentGrpStep1(): InstrumentGrpStep1 {
    const step1: InstrumentGrpStep1 = {
      name: this.formData.name,
      groupDesc: this.formData.description,
      businessUnit: this.formData.businessUnit
    };
    return step1;
  }

  setStep2(data: any, valid: boolean) {
    /* Update the the instrument group Step 2 data only when the the instrument group Step 2 Form had been validated successfully */
    this.formData.securityInfoList = data.securityInfoList;
    /* Validate Personal Step in Workflow */
    this.workflowService.validateStep('step2', 'instrumentGrps', valid);
  }

  getFormData(): InstrumentGroupFormDataModel {
    /* Return the entire Form Data */
    return this.formData;
  }

  resetForm() {
    this.formData.clear();
    this.workflowService.resetSteps('instrumentGrps');
  }

  setFormData(formData) {
    this.formData.setData(formData);
  }

  setMode(mode) {
    this.formData.mode = mode;
  }

  validateAllSteps() {
    this.workflowService.validateAllSteps('instrumentGrps');
  }

  setStep3(data: any, valid: boolean) {
    /*Validate Personal Step in Workflow*/
    this.workflowService.validateStep('step3', 'instrumentGrps', valid);
  }
}
