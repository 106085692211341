<div class="content-area" [ngClass]="(businessUnit !== getSharedConstant.rbsBUText && (noRUAUEntForDBS || noRUAUEntForSPS) || showUGForClientAcc) || !sharedService.isInternalApp() ? 'sdps-p-left_large sdps-p-right_large sdps-p-bottom_large': 'sdps-p-around_large'">

  <div *ngIf="sharedService.errorMessage | async" [ngClass]="{'sdps-p-top_large' : businessUnit !== getSharedConstant.rbsBUText}">
    <ems-service-error *ngIf="sharedService.errorMessage | async"></ems-service-error>
  </div>

  <div class="sdps-grid-container no-margin display-chart">
    <div class="sdps-m-right_small border-color sdps-p-horizontal_none" *ngIf="showPanel"
         [ngClass]="(showPanel.hasEmtr && showPanel.hasAddlink) ? 'col-6' : showPanel.hasAddlink ? 'col' : 'sdps-hide'">
      <div class="addlink-section">
        <app-workbench-panel-header [headerTxt]="shellConstant.workBenchChart.addLink.addlinkTxt"
                                    (refreshState)="fetchAddlinkData('addLink')" [lastUpdatedTime]="addlinkData.addlinkTimestamp"
                                    [lastUpdatedTxt]="shellConstant.workBenchChart.lastRefreshedTxt"></app-workbench-panel-header>
        <div class="sdps-row sdps-p-bottom_medium sdps-is-relative">
          <app-workbench-loader *ngIf="loader && loadSection === 'addLink'"></app-workbench-loader>
          <div class="sdps-p-left_none sdps-p-right_xx-small col-4" *ngIf="showPanel.hasAddlink">
            <div class="container-title sdps-p-horizontal_none">
              <span class="sdps-p-left_x-small" [innerHTML]="shellConstant.workBenchChart.quickLinkTxt"></span>
            </div>
            <div class="sdps-p-vertical_x-small sdps-p-left_x-small">
              <ul class="quick-link-list">
                <li class="sdps-p-bottom_x-small" *ngFor="let link of addlinkQuickLinks">
                  <a class="cursor">
                    <div (click)="quickLinkNavigation(link.link, link.gutTrack)"> {{link.name}}
                      <span class="sch-chevron-right link-right-icon"></span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="sdps-p-horizontal_none workbench-border-left full-width">
            <div class="container-title sdps-p-horizontal_none">
              <span class="sdps-p-left_x-small" [innerHtml]="shellConstant.workBenchChart.addLink.requestStatusTxt"></span>
            </div>
            <div class="sdps-p-vertical_x-small sdps-p-left_x-small sdps-p-right_x-small background-color-table">
              <div class="sdps-row" *ngIf="businessUnit !== getSharedConstant.rbsBUText && !this.accessOnlyForRbs && (this.accessWithAuthorizedAgentDBS || this.accessWithAuthorizedAgentSPS)">
                <div class="col-6 request-status-hold sdps-p-vertical_medium sdps-p-horizontal_none" (click)="redirectToRequestList(shellConstant.workBenchChart.addLink.requestStatus.onHold)">
                  <div class="count-block sdps-text-center">
                    <div>
                      <div>
                        <span class="sdps-display-inline-block  sdps-font-weight-bold display-status-text-all"
                              [innerHTML]="shellConstant.workBenchChart.addLink.requestStatus.onHold"></span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span class="sdps-font-weight-bold sdps-display-inline-block display-count sdps-p-top_xx-small" [innerHTML]="addlinkData.onHoldCount"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 sdps-p-horizontal_none request-status-validation sdps-p-vertical_medium sdps-m-left_xx-small" (click)="redirectToRequestList(shellConstant.workBenchChart.addLink.requestStatus.validationMismatch)">
                  <div class="count-block sdps-text-center">
                    <div>
                      <div>
                        <span class="sdps-display-inline-block  sdps-font-weight-bold display-status-text-all"
                              [innerHTML]="shellConstant.workBenchChart.addLink.requestStatus.validationMismatch"></span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span class="sdps-font-weight-bold sdps-display-inline-block display-count sdps-p-top_xx-small"
                              [innerHTML]="addlinkData.validationMismatchCount"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- DBS Action Needed is hidden from external users please don't ulter this section -->
              <div class="sdps-row sdps-p-top_x-small" *ngIf="businessUnit !== getSharedConstant.rbsBUText && !this.accessOnlyForRbs && (this.accessWithAuthorizedAgentDBS || this.accessWithAuthorizedAgentSPS)">
                <div class="col-6 sdps-p-horizontal_none request-status-dbs sdps-p-vertical_medium"
                 [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid, '', '') || !sharedService.isInternalApp()}"
                  (click)="redirectToRequestList(shellConstant.workBenchChart.addLink.requestStatus.DBSActionNeeded)">
                  <div class="count-block sdps-text-center">
                    <div>
                      <div>
                        <span class="sdps-display-inline-block  sdps-font-weight-bold display-status-text-all"
                              [innerHTML]="shellConstant.workBenchChart.addLink.requestStatus.DBSActionNeeded"></span>
                      </div>
                    </div>
                    <div>
                      <div>
                         <span class="sdps-font-weight-bold sdps-display-inline-block display-count sdps-p-top_xx-small"
                               [innerHTML]="addlinkData.dbsActionNeededCount"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 sdps-p-horizontal_none request-status-indexing sdps-p-vertical_medium sdps-m-left_xx-small"
                  [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid, '', '') || !sharedService.isInternalApp()}"
                  (click)="redirectToRequestList(shellConstant.workBenchChart.addLink.requestStatus.requireIndexing)">
                  <div class="count-block sdps-text-center">
                    <div>
                      <div>
                        <span class="sdps-display-inline-block  sdps-font-weight-bold display-status-text-all"
                              [innerHTML]="shellConstant.workBenchChart.addLink.requestStatus.requireIndexing"></span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span class="sdps-font-weight-bold sdps-display-inline-block display-count sdps-p-top_xx-small"
                              [innerHTML]="addlinkData.requireIndexing"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sdps-row sdps-p-top_x-small">
                <div class="col-6 sdps-p-horizontal_none request-status-hold sdps-p-vertical_medium"
                     [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid, '', '') || !sharedService.isInternalApp()}"
                     (click)="redirectToRequestList(shellConstant.workBenchChart.addLink.requestStatus.clientCentralLinks)">
                  <div class="count-block sdps-text-center">
                    <div>
                      <div>
                        <span class="sdps-display-inline-block  sdps-font-weight-bold display-status-text-all"
                              [innerHTML]="shellConstant.workBenchChart.addLink.requestStatus.clientCentralLinks"></span>
                      </div>
                    </div>
                    <div>
                      <div>
                         <span class="sdps-font-weight-bold sdps-display-inline-block display-count sdps-p-top_xx-small"
                               [innerHTML]="addlinkData.clientCentralLinks"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-6 sdps-p-horizontal_none request-status-validation sdps-p-vertical_medium sdps-m-left_xx-small"
                  [ngClass]="{'sdps-hide': !sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid, '', '')|| !sharedService.isInternalApp()}"
                  (click)="redirectToRequestList(shellConstant.workBenchChart.addLink.requestStatus.clientCentralDelinks)">
                  <div class="count-block sdps-text-center">
                    <div>
                      <div>
                        <span class="sdps-display-inline-block  sdps-font-weight-bold display-status-text-all"
                              [innerHTML]="shellConstant.workBenchChart.addLink.requestStatus.clientCentralDelinks"></span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <span class="sdps-font-weight-bold sdps-display-inline-block display-count sdps-p-top_xx-small"
                              [innerHTML]="addlinkData.clientCentralDelinks"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sdps-row">

          <div class="sdps-p-horizontal_none workbench-border-left col-6 sdps-hide">
            <div class="container-title sdps-p-horizontal_none">
              <span class="sdps-p-left_x-small" [innerHtml]="shellConstant.workBenchChart.addLink.accNotLinkedTXT"></span>
            </div>
            <div class="sdps-p-vertical_x-small sdps-p-left_x-small sdps-p-right_medium">
              <ems-donut-chart #child [labelVal]="shellConstant.workBenchChart.addLink.bulkUploadChart"
                               [arrayVal]="workbenchData.bulkUploadData"
                               [restrictBackgroundColorCode]="shellConstant.workBenchChart.bulkUploadBgColor"></ems-donut-chart>
            </div>

          </div>

        </div>
        <ng-container *ngIf="businessUnit !== getSharedConstant.rbsBUText">
        <div class="sdps-row chart-dropdown sdps-p-vertical_x-small" *ngIf="orgId && showPanel.hasAddlink">
          <div class="col sdps-p-left_none">
            <div class="sdps-row sdps-p-vertical_medium sdps-p-horizontal_large master-account">
              <div class="col-1">
                <span class="sdps-p-right_medium sdps-display-inline-block master-acc-count sdps-font-weight-bold"
                      [innerHTML]="masterAccCount"></span>
              </div>
              <div class="col-9">
                <span class="sdps-display-inline-block sdps-font-weight-bold sdps-checkbox__input "
                      [innerHTML]="shellConstant.workBenchChart.addLink.masterAcc.masterAccTxt"></span>
              </div>
            </div>

          </div>
        </div>
        </ng-container>

        <!-- Authorized Agent List Section -->
        <div
          *ngIf="orgId && showAAPanel && showPanel.hasAddlink">
          <app-workbench-panel-header (refreshState)="fetchAuthorizedAgents('authorizedAgents'); restAAToggleAndTable()"
                                      [lastUpdatedTime]="authorizedAgentData.agentTimestamp"
                                      [headerTxt]="shellConstant.workBenchChart.addLink.authorizedAgents"
                                      [lastUpdatedTxt]="shellConstant.workBenchChart.lastRefreshedTxt"></app-workbench-panel-header>
          <form [formGroup]="authorizedAgentForm" class="reportListMenu">
          <div class="sdps-row sdps-is-relative">
            <app-workbench-loader *ngIf="loader && loadSection === 'authorizedAgents'"></app-workbench-loader>
            <p-table #authAgentTable [columns]="authAgentTableColumn" [value]="authAgentList" [paginator]="authAgentList && authAgentList.length>15"
                     [rows]="noOfRecords" [totalRecords]="authAgentList && authAgentList.length" [(first)]="pageNumResetToFirst"
                     class="common-data-table auth-agent-table-layout-fixed" alwaysShowPaginator="true"
                     sortField="authAgentLastNm">
              <ng-template pTemplate="header" let-columns>
                <!-- Header -->
                <tr class="filter-header-input">
                  <th class="table-checkbox-selection filter-icon">
                    <a (click)="toggleFunction()" class="cursor">
                      <em class="pi pi-filter"></em>
                    </a>
                  </th>
                  <ng-container *ngFor="let col of columns">
                    <th *ngIf="col.field !== 'action'" [pSortableColumn]="col.field"
                        [pSortableColumnDisabled]="col.field === 'action'" (click)="isSortingChanged = true">
                      {{ col.header }}
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                  </ng-container>
                </tr>

                <!-- Filter input fields -->
                <tr *ngIf="toggle" class="filtered-row-input">
                  <th></th>
                  <th *ngFor="let col of columns" class="sdps-p-left_small">
                    <input pInputText *ngIf="col.field !== 'action'" [formControlName]="col.field" class="w-100-per"
                           type="text" name="filter" (keydown.enter)="applyFilter()" onkeydown="return (event.keyCode !== 13);">

                    <div *ngIf="col.field === 'action'" class="sdps-p-right_none">
                      <button class="buttons button-filter"
                              [innerHTML]="shellConstant.uploadListPage.uploadsListFilter.clearButton"
                              [disabled]="!authorizedAgentForm.dirty && !isSortingChanged"
                              (click)="isSortingChanged || isFilterApplied ? authAgentTable.reset() : '';resetFilterAndSortApplied()"></button>
                      <button class="buttons button-save button-filter"
                              (click)="applyFilter()"
                              [innerHTML]="shellConstant.uploadListPage.uploadsListFilter.applyButton"></button>
                    </div>
                  </th>
                </tr>
              </ng-template>

              <!-- Empty Message section -->
              <ng-template pTemplate="emptymessage" let-columns>
                <tr *ngIf="authAgentList && authAgentList.length === 0">
                  <td [attr.colspan]="columns.length + 1" class="sdps-text-center"
                      [innerHTML]="shellConstant.manageUserPage.noRecords"></td>
                </tr>
              </ng-template>

              <!-- Table body section -->
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td></td>
                  <td *ngFor="let col of columns">
                    {{rowData[col.field]}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          </form>
        </div>
      </div>
    </div>

    <div class="border-color sdps-p-horizontal_none" *ngIf="showPanel"
         [ngClass]="(showPanel.hasEmtr && showPanel.hasAddlink) ? 'col-6' : showPanel.hasEmtr ? 'col' : 'sdps-hide'">
      <div class="rules-section">
        <app-workbench-panel-header [headerTxt]="shellConstant.workBenchChart.rules.rulesTxt"
                                    [lastUpdatedTxt]="shellConstant.workBenchChart.lastUpdatedTxt"></app-workbench-panel-header>
        <div class="sdps-row sdps-p-bottom_x-small">
          <div class="sdps-p-left_none sdps-p-right_xx-small col-4" *ngIf="showPanel.hasEmtr">
            <div class="container-title sdps-p-horizontal_none">
              <span class="sdps-p-left_x-small" [innerHTML]="shellConstant.workBenchChart.quickLinkTxt"></span>
            </div>
            <div class="sdps-p-vertical_x-small sdps-p-left_x-small">
              <ul class="quick-link-list">
                <li class="sdps-p-bottom_x-small" *ngFor="let link of tradeRulesLinks" [ngClass]="{'sdps-hide' : !showPanel.hasEmtr}">
                  <a class="cursor">
                    <div (click)="quickLinkNavigation(link.link, link.gutTrack)"> {{link.name}}
                      <span class="sch-chevron-right link-right-icon"></span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="sdps-p-horizontal_none workbench-border-left sdps-modal--open col-8" *ngIf="showPanel.hasEmtr">
            <div class="container-title sdps-p-horizontal_none">
              <span class="sdps-p-left_x-small" [innerHTML]="shellConstant.workBenchChart.rules.emtrTxt"></span>
            </div>
            <div class="sdps-p-top_x-small sdps-p-bottom_xx-small sdps-p-horizontal_x-small image-block column-image-block">
              <div class="info-container sdps-is-relative sdps-p-vertical_none">
                <div class="coming-soon-image sdps-is-absolute sdps-backdrop--open">
                </div>
                <div class="coming-soon-txt sdps-is-absolute">
                  <span class="sdps-display-inline-block sdps-p-vertical_medium sdps-p-horizontal_small" [innerHtml]="shellConstant.workBenchChart.comingSoonTxt"></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sdps-row" *ngIf="showPanel.hasEmtr">
          <div class="container-title col sdps-p-horizontal_none">
            <span [innerHTML]="shellConstant.workBenchChart.rules.restrictionTxt" class="sdps-p-left_x-small"></span>
          </div>
        </div>
        <div class="sdps-row  chart-dropdown sdps-p-vertical_x-small" *ngIf="showPanel.hasEmtr">
          <!-- Start Date Section -->
          <div class="col-6 border-right">
            <span class="date-title" [innerHtml]="shellConstant.workBenchChart.rules.startDateTxt"></span>
            <div class="chart-holder">
              <span *ngIf="!validateResponse(restrictStartDtValues)">{{shellConstant.workBenchChart.rules.noOfRulesStartDateTxt}}&nbsp;
                <p-dropdown [options]="startDateArr" [(ngModel)]="selectedStartDate"
                            (onChange)="getDropdownVal(selectedStartDate, 'startDate', 'restriction')"
                            class="sdps-display-inline-block"></p-dropdown>
                {{shellConstant.workBenchChart.rules.fromTodayTxt}}
              </span>

              <sdps-notification type="information" class="sdps-m-top_none" *ngIf="validateResponse(restrictStartDtValues)">
                <span class="sdps-notification__text" [innerHtml]="shellConstant.workBenchChart.rules.noDataTxt"></span>
              </sdps-notification>

              <div class="sdps-pull-left" *ngIf="!validateResponse(restrictStartDtValues)">
                <ems-donut-chart #child [labelVal]="restrictStartDtLabel" [chartTitle]="2"
                                 [arrayVal]="restrictStartDtValues"
                                 [restrictBackgroundColorCode]="shellConstant.workBenchChart.restrictionBgColorStartDate"></ems-donut-chart>
              </div>
            </div>
          </div>
          <!-- End Date Section -->
          <div class="col-6">
            <span class="date-title" [innerHtml]="shellConstant.workBenchChart.rules.endDateTxt"></span>
            <div class="chart-holder">

                <span *ngIf="!validateResponse(restrictEndDtValues)">{{shellConstant.workBenchChart.rules.noOfRulesEndDateTxt}}&nbsp;
                  <p-dropdown [options]="endDateArr" [(ngModel)]="selectedEndDate"
                              (onChange)="getDropdownVal(selectedEndDate, 'endDate', 'restriction')"
                              class="sdps-display-inline-block"></p-dropdown>
                  {{shellConstant.workBenchChart.rules.fromTodayTxt}}
                </span>

              <sdps-notification type="information" class="sdps-m-top_none" *ngIf="validateResponse(restrictEndDtValues)">
                <span class="sdps-notification__text" [innerHtml]="shellConstant.workBenchChart.rules.noDataTxt"></span>
              </sdps-notification>

              <div class="sdps-pull-left" *ngIf="!validateResponse(restrictEndDtValues)">
                <ems-donut-chart #child [labelVal]="restrictEndDtLabel" [chartTitle]="2"
                                 [arrayVal]="restrictEndDtValues"
                                 [restrictBackgroundColorCode]="shellConstant.workBenchChart.restrictionBgColorEndDate"></ems-donut-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="sdps-row" *ngIf="showPanel.hasEmtr">
          <div class="container-title col sdps-p-horizontal_none">
            <span [innerHTML]="shellConstant.workBenchChart.rules.overrideTxt" class="sdps-p-left_x-small"></span>
          </div>
        </div>
        <div class="sdps-row  chart-dropdown sdps-p-vertical_x-small" *ngIf="showPanel.hasEmtr">
          <!-- Start Date Section -->
          <div class="col-6 border-right">
            <span class="date-title" [innerHtml]="shellConstant.workBenchChart.rules.startDateTxt"></span>
            <div class="chart-holder">
              <span *ngIf="!validateResponse(overrideStartDtValues)">{{shellConstant.workBenchChart.rules.noOfRulesStartDateTxt}}&nbsp;
                <p-dropdown [options]="startDateArr" [(ngModel)]="selectedOverrideStartDate"
                            (onChange)="getDropdownVal(selectedOverrideStartDate, 'startDate', 'override')"
                            class="sdps-display-inline-block"></p-dropdown>
                {{shellConstant.workBenchChart.rules.fromTodayTxt}}</span>
              <sdps-notification type="information" class="sdps-m-top_none" *ngIf="validateResponse(overrideStartDtValues)">
                <span class="sdps-notification__text" [innerHtml]="shellConstant.workBenchChart.rules.noDataTxt"></span>
              </sdps-notification>
              <div class="sdps-pull-left" *ngIf="!validateResponse(overrideStartDtValues)">
                <ems-donut-chart #child [labelVal]="overrideStartDtLabel" [chartTitle]="2"
                                 [arrayVal]="overrideStartDtValues"
                                 [restrictBackgroundColorCode]="shellConstant.workBenchChart.overrideBgColorStartDate"></ems-donut-chart>
              </div>
            </div>
          </div>
          <!-- End Date Section -->
          <div class="col-6">
            <div>
              <span class="date-title" [innerHtml]="shellConstant.workBenchChart.rules.endDateTxt"></span>
              <span *ngIf="!validateResponse(overrideEndDtValues)">{{shellConstant.workBenchChart.rules.noOfRulesEndDateTxt}}&nbsp;
                <p-dropdown [options]="overrideEndDateArr" [(ngModel)]="selectedOverrideEndDate"
                            (onChange)="getDropdownVal(selectedOverrideEndDate, 'endDate', 'override')"
                            class="sdps-display-inline-block"></p-dropdown>
                {{shellConstant.workBenchChart.rules.fromTodayTxt}}</span>
              <sdps-notification type="information" class="sdps-m-top_none" *ngIf="validateResponse(overrideEndDtValues)">
                <span class="sdps-notification__text" [innerHtml]="shellConstant.workBenchChart.rules.noDataTxt"></span>
              </sdps-notification>
              <div class="sdps-pull-left" *ngIf="!validateResponse(overrideEndDtValues)">
                <ems-donut-chart #child [labelVal]="overrideEndDtLabel" [chartTitle]="2"
                                 [arrayVal]="overrideEndDtValues"
                                 [restrictBackgroundColorCode]="shellConstant.workBenchChart.overrideBgColorEndDate"></ems-donut-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
