export interface EmployeeGroupAssignInfo {
  empIds?: any[];
  id?: number;
  orgId?: string;
  subAccntNumbers?: any;
  employeeAccounts?: any[];
  newSelectedRecords?: any;
  removedExistingSelected?: any;
  assignEmpIds?: any[];
  assignSubAcctNumbers?: any[];
  unAssignEmpIds?: any[];
  unAssignSubAcctNumbers?: any[];
  subAccntNewlySelected?: any[];
  removedExistingEmpId?: any[];
  clear();
}

export const empGrpAssignInfo: EmployeeGroupAssignInfo = {
  empIds: [],
  id: 0,
  orgId: '',
  subAccntNumbers: [],
  employeeAccounts: [],
  newSelectedRecords: [],
  removedExistingSelected: [],
  assignEmpIds: [],
  assignSubAcctNumbers: [],
  unAssignEmpIds: [],
  unAssignSubAcctNumbers: [],
  subAccntNewlySelected: [],
  removedExistingEmpId: [],
  clear() {
    this.empIds = [];
    this.id = '';
    this.orgId = '';
    this.selectedEmpCount = {};
    this.subAccntNumbers = [];
    this.employeeAccounts = [];
    this.newSelectedRecords = [];
    this.removedExistingSelected = [];
    this.assignEmpIds = [];
    this.assignSubAcctNumbers = [];
    this.unAssignEmpIds = [];
    this.unAssignSubAcctNumbers = [];
    this.subAccntNewlySelected =  [];
    this.removedExistingEmpId = [];
  }
};

export interface EmpGroupFormDataModel {
  name?: string;
  orgId?: string;
  description?: string;
  id?: any;
  type?: string;
  mode?: string;
  planId?: string;
  currentEditedName?: string;
  selectedEmpCount?: any;

  employeeGroupAssignInfo?: EmployeeGroupAssignInfo;

  clear();

  setData(data);
}

export const empGroupFormDataModel: EmpGroupFormDataModel = {
  name: '',
  orgId: '',
  description: '',
  id: '',
  type: 'EGP',
  mode: '',
  planId: '',
  selectedEmpCount: '',
  employeeGroupAssignInfo: empGrpAssignInfo,

  clear() {
    this.name = '';
    this.description = '';
    this.id = '';
    this.orgId = '';
    this.mode = '';
    this.planId = '';
    this.selectedEmpCount = '';
    this.type = 'EGP';
    this.employeeGroupAssignInfo = empGrpAssignInfo;
    this.employeeGroupAssignInfo.clear();
  },

  setData(data) {
    this.name = data.name || '';
    this.currentEditedName = data.name || '';
    this.description = data.description || '';
    this.employeeGroupAssignInfo = data.employeeGroupAssignInfo || {};
    this.id = data.id || '';
    this.orgId = data.orgId || '';
    this.planId = data.planId || '';
    this.selectedEmpCount = data.selectedEmpCount || '';
  }
};

export class EmpGrpStep1 {
  name = '';
  groupDesc = '';
}

export class EmpGrpStep2 {
  employeeAccounts: any[] = [];
  newSelectedRecords: any = [];
  removedExistingSelected: any = [];
  assignEmpIds: any = [];
  assignSubAcctNumbers: any = [];
  unAssignEmpIds: any = [];
  unAssignSubAcctNumbers: any = [];
  empIds: any[] = [];
  subAccntNumbers: any[] = [];
  subAccntNewlySelected: any = [];
  removedExistingEmpId: any = [];
}
