export interface UserTableHead {
    field?: string;
    header?: string;
    enum?: string;
}
export const userTableHeader: UserTableHead[] = [
    {field: 'firstName', header: 'First Name', enum: 'textbox'},
    {field: 'lastName', header: 'Last Name', enum: 'textbox'},
    {field: 'emailAddresses', header: 'Email', enum: 'textbox'},
    {field: 'userType', header: 'User Type',  enum: 'dropdown'},
    {field: 'roles', header: 'Roles', enum: 'multiselect'},
    {field: 'status', header: 'Status', enum: 'dropdown'},
    {field: 'businessUnit', header: 'Business Unit', enum: 'dropdown'},
    {field: 'updatedDate', header: 'Last Updated', enum: 'calendar'},
    { field: 'actions', header: 'Action', enum: '' }
];
