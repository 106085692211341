import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as temp from 'moment';
const moment = temp['default'];
import {Subject, Subscription} from 'rxjs';
import { emtrConstant } from '../../../constants/emtrConstants';
import { RuleService } from '../../../services/rule-service/rule.service';
import { FormDataService, SharedService, SharedConstant} from '@ems/shared';
import { SecurityTypeComponent } from '../../security-type/security-type.component';
import { EmtrService } from '../../../emtr.service';

@Component({
  selector: 'tr-rules-step2-select-attributes',
  templateUrl: './rules-step2.component.html'
})
export class RulesStep2Component implements OnInit, OnDestroy {

  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('child', { static: true }) childSecurityType: SecurityTypeComponent;

  currentOrgSubscription: Subscription;
  isInValidFromDate = false;
  isPastFromDate = false;
  isInValidToDate = false;
  isPastToDate = false;
  errorMsg = [];
  dateErrorMsg = '';
  indSecsDuplicateErrMsg = '';
  componentName = [];

  attributesForm: UntypedFormGroup = this.fb.group({
    dateTo: this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      timeMeridian: ['', Validators.required]
    }),
    dateFrom: this.fb.group({
      date: ['', Validators.required],
      time: ['', Validators.required],
      timeMeridian: ['', Validators.required]
    }),
    datepickerDisabled: false,
    restrictBuys: '',
    restrictSells: '',
    restrictShortSells: '',
    validateRestrictType: ['', Validators.required],
    description: '',
    notes: '',
    changeRequestId: '',
    indSecs: [[], Validators.required],
    instGrps: [[], Validators.required],
    fndFmlyInfo: [[], Validators.required],
    appliesTo: ['', Validators.required],
    autoCompleteData: [],
    chooseSecurityType: [{}, Validators.required],
    assetGroups: []
  });
  indSecsDuplicateErr: any = '';
  dateError: any = '';
  attributesFormData: any;
  appliesType: any = {};
  multiselectSelectedData: any;
  multiselectSelectedInstGrpData: any;
  multiLevelUpdatedList: any = {};
  multiLevelSecurityType: any;
  showIndividualSecurity = false;
  dateSelectionType: any = 'date';
  message: string;
  isClicked = true;
  defaultSaveSuccess: any;
  securityData = [];
  selectedSecurityType = false;
  attributeData: any;
  localObserver = new Subject<boolean>();
  confirmMessage: any;
  fromDateDisabled: any = false;
  is10b5Client = false;
  selectedInstrumentGroups = false;
  selectedIndividualSecurity = false;
  warningMsg = '';
  presetRuleId: any;
  assetGroups: any;
  selectedDropDownVal: any;
  presetRuleKey: any;
  businessUnit: any;
  formObjToCompare: any;
  allSecCategories = [];
  setMicAGFlag = false;
  viewIG: any;
  showSecurityOrPreset: any = '';
  presetRuleWarnMsg: any;
  displayRadioButtonsForRestSecType: any;
  assetGroupsInput = [];
  presetRuleRadioBtnLabel: any = '';
  customVal = false;

  /* eslint-disable-next-line max-len */
  constructor(private fb: UntypedFormBuilder, private router: Router, public formDataService: FormDataService, private r: ActivatedRoute, private ruleService: RuleService, @Inject('commonConstants') public commonConstants, public sharedService: SharedService, @Inject('entitlementConst') public entitlementConst, public emtrService: EmtrService) {
    this.attributeData = { ...this.formDataService.getFormData() };
    this.attributesFormData = this.attributeData;
    this.currentOrgSubscription = this.sharedService.clientInformation.subscribe(clientInfo => {
      this.businessUnit = clientInfo && clientInfo.businessUnit;
    });
    this.presetRuleRadioBtnLabel = this.businessUnit === this.getSharedConstant.rbsBUText ? 'Preset Rules' : 'Asset Groups';
    this.presetRuleWarnMsg = this.businessUnit === this.getSharedConstant.rbsBUText ? this.getSharedConstant.RBS.warningMsgForPresetRulesOrSecurities : this.getSharedConstant.DBS.warningMsgForPresetRulesOrSecurities;
    this.viewIG = (this.attributeData.mode === 'EDIT') && this.businessUnit === 'DBS' && (this.attributesFormData.instrumentGroups && this.attributesFormData.instrumentGroups.length > 0);
    this.displayRadioButtonsForRestSecType = (this.businessUnit === this.getSharedConstant.dbsBUText || this.businessUnit === this.getSharedConstant.rbsBUText) && this.attributesFormData.rulesCategory === this.getConstant.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt;
    /*This is to compare old seccategories with new seccategories*/
    this.allSecCategories.push(this.attributesFormData.secCatgs1);
    this.allSecCategories.push(this.attributesFormData.secCatgs2);
    this.allSecCategories.push(this.attributesFormData.secCatgs3);
    /* Selected Type is Security-Type removed appliesTo and AutoComplete*/
    if (this.attributesFormData.assetGroups && this.attributesFormData.assetGroups.length > 0) {
      this.showSecurityOrPreset = 'presetRules';
      if (this.businessUnit !== this.getSharedConstant.spsBUText && this.attributesFormData.rulesCategory !== this.getConstant.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText) {
      this.displayWarningMsg();
      }
    } else if ((this.attributesFormData.secCatgs1 && this.attributesFormData.secCatgs1.length > 0) || (this.attributesFormData.secCatgs2 && this.attributesFormData.secCatgs2.length > 0) || (this.attributesFormData.secCatgs3 && this.attributesFormData.secCatgs3.length > 0)) {
      this.showSecurityOrPreset = 'secLevel';
      if (this.businessUnit !== this.getSharedConstant.spsBUText && this.attributesFormData.rulesCategory !== this.getConstant.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText) {
      this.displayWarningMsg();
      }
    }
    this.assetGroupsInput = this.attributesFormData.assetGroups;
    this.populateData();
  }

  ngOnInit() {
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.removeValidatorsForFundFamily();
    this.is10b5Client = this.sharedService.get10b5ClientDetails();
  }

  removeValidatorsForFundFamily() {
    if (this.attributesFormData && this.attributesFormData.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
      const appliesToTxt = 'appliesTo';
      this.attributesForm.controls[appliesToTxt].clearValidators();
      this.attributesForm.controls[appliesToTxt].updateValueAndValidity();
      const indSecsTxt = 'indSecs';
      this.attributesForm.controls[indSecsTxt].clearValidators();
      this.attributesForm.controls[indSecsTxt].updateValueAndValidity();
    } else {
      const fundFamilyInfoTxt = 'fndFmlyInfo';
      this.attributesForm.controls[fundFamilyInfoTxt].clearValidators();
      this.attributesForm.controls[fundFamilyInfoTxt].updateValueAndValidity();
    }
    const inst = 'instGrps';
    if (!this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '') || !(this.attributesFormData.ruleType === 'INDSEC' && this.attributesFormData.rulesCategory === this.getConstant.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt)) {
      if (this.attributesForm.controls[inst]) {
        this.attributesForm.controls[inst].clearValidators();
        this.attributesForm.updateValueAndValidity();
      }
    }
  }

  calendarValidation(value) {
    if (value.dateLabel === this.getConstant.rulesConstant.startDate) {
      this.isInValidFromDate = value.isInValidDate;
      this.isPastFromDate = value.isPastDate;
    } else if (value.dateLabel === this.getConstant.rulesConstant.endDate) {
      this.isInValidToDate = value.isInValidDate;
      this.isPastToDate = value.isPastDate;
    }

    /*To show 'Please enter a valid date MM/DD/YYYY' error msg*/
    if (this.isInValidFromDate || this.isInValidToDate) {
      if (this.errorMsg.indexOf(this.getConstant.inValidDateMessage) === -1) {
        this.errorMsg.push(this.getConstant.inValidDateMessage);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.errorMsg.indexOf(this.getConstant.inValidDateMessage) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.inValidDateMessage), 1);
      }
    }

    /*To show 'From Date should be Current or Future Date' error msg*/
    if (this.isPastFromDate) {
      if (this.errorMsg.indexOf(this.getConstant.pastFromDateMessage) === -1) {
        this.errorMsg.push(this.getConstant.pastFromDateMessage);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.errorMsg.indexOf(this.getConstant.pastFromDateMessage) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.pastFromDateMessage), 1);
      }
    }

    /*To show 'To Date should be Current or Future Date' error msg*/
    if (this.isPastToDate) {
      if (this.errorMsg.indexOf(this.getConstant.pastToDateMessage) === -1) {
        this.errorMsg.push(this.getConstant.pastToDateMessage);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.errorMsg.indexOf(this.getConstant.pastToDateMessage) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.pastToDateMessage), 1);
      }
    }

    /*Hide the error block when errorMsg is empty*/
    if (this.errorMsg.length === 0) {
      this.ruleService.showSchError.emit(false);
    }

    this.ruleService.schErrorMsg.emit(this.errorMsg);
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.isClicked) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  populateData() {
    if (this.attributesFormData.ruleType === 'SECTYP') {
      this.showIndividualSecurity = true;
      this.attributesForm.removeControl('indSecs');
      this.attributesForm.removeControl('instGrps');
      this.attributesForm.removeControl('appliesTo');
    } else {
      this.attributesForm.removeControl('chooseSecurityType');
      this.showIndividualSecurity = false;
    }
    this.attributesFormData.startDt = (this.attributesFormData.startDt) ? this.attributesFormData.startDt : '';

    this.attributesFormData.endDt = (this.attributesFormData.endDt) ? this.attributesFormData.endDt : '';

    this.attributesFormData.indSecs = (this.attributesFormData.indSecs) ? this.attributesFormData.indSecs : [];
    this.attributesFormData.instrumentGroups = (this.attributesFormData.instrumentGroups) ? this.attributesFormData.instrumentGroups : [];

    this.attributesFormData.indSecs.length > 0 ? this.selectedIndividualSecurity = true : this.selectedIndividualSecurity = false;
    this.attributesFormData.instrumentGroups.length > 0 ? this.selectedInstrumentGroups = true : this.selectedInstrumentGroups = false;
    this.attributesFormData.fndFmlyInfo = (this.attributesFormData.fndFmlyInfo.length > 0) ? this.attributesFormData.fndFmlyInfo : [];

    this.attributesForm.patchValue({
      datepickerDisabled: (this.attributesFormData.endDt) ? true : '',
      dateTo: {
        date: (this.attributesFormData.endDt) ? new Date(this.attributesFormData.endDt) : null,
        time: (this.attributesFormData.endDt) ? moment(this.attributesFormData.endDt).format('hh:mm') : null,
        timeMeridian: (this.attributesFormData.endDt) ? moment(this.attributesFormData.endDt).format('a').toUpperCase() : null
      },
      dateFrom: {
        date: (this.attributesFormData.startDt) ? new Date(this.attributesFormData.startDt) : '',
        time: (this.attributesFormData.startDt) ? moment(this.attributesFormData.startDt).format('hh:mm') : '',
        timeMeridian: (this.attributesFormData.startDt) ? moment(this.attributesFormData.startDt).format('a').toUpperCase() : ''
      },
      description: this.attributesFormData.desc,
      notes: this.attributesFormData.note,
      changeRequestId: this.attributesFormData.changeRequestId,
      restrictSells: (this.attributesFormData.rstrctSell === 'Y'),
      restrictBuys: (this.attributesFormData.rstrctBuy === 'Y'),
      restrictShortSells: (this.attributesFormData.rstrctShortSell === 'Y'),
      indSecs: this.attributesFormData.indSecs,
      instGrps: this.attributesFormData.instrumentGroups,
      fndFmlyInfo: this.attributesFormData.fndFmlyInfo,
      appliesTo: this.attributesFormData.appliesTo,
      chooseSecurityType: this.updateSecurityValues(this.attributesFormData.chooseSecurityType),
      validateRestrictType: (this.attributesFormData.rstrctSell === 'Y' || this.attributesFormData.rstrctBuy === 'Y'
        || this.attributesFormData.rstrctShortSell === 'Y') ? true : '',
      assetGroups: this.attributesFormData.assetGroups ? this.attributesFormData.assetGroups : ''
    });
    this.multiselectSelectedData = (this.attributesFormData.securityType) ? this.attributesFormData.securityType : [];
    this.appliesType.individualSecurities = this.attributesFormData.appliesTo === 'BTH' || this.attributesFormData.appliesTo === 'IND';
    this.appliesType.underlyingOptions = this.attributesFormData.appliesTo === 'BTH' || this.attributesFormData.appliesTo === 'OPT';
    this.attributesFormData.rulesCategory = (this.attributesFormData.override === 'Y') ? 'Override' : this.attributesFormData.rulesCategory;
    /* eslint-disable-next-line max-len */
    if (this.attributesFormData.mode && this.attributesFormData.ruleType === 'SECTYP' && !Object.keys(this.multiLevelUpdatedList).length) {
      this.populateSecurityTypeData();
    }
    /* disable date for status ACTIVE and EXPIRED */
    if (this.attributesFormData.status === 'ACTIVE') {
      this.attributesForm.get('dateFrom').disable({ onlySelf: true });
      this.fromDateDisabled = true;
    } else if (this.attributesFormData.status === 'EXPIRED') {
      this.fromDateDisabled = true;
      this.attributesForm.get('dateFrom').disable({ onlySelf: true });
      const that = this;
      setTimeout(() => {
        that.attributesForm.get('dateTo').disable({ onlySelf: true });
      }, 10);
      this.attributesForm.get('datepickerDisabled').disable({ onlySelf: true });
    }
    this.formObjToCompare = this.attributesForm.getRawValue();
    delete this.formObjToCompare.chooseSecurityType;
  }

  updateAppliesto(appliesTo) {
    const appliesToValidate = (appliesTo && appliesTo.individualSecurities && appliesTo.underlyingOptions) ? 'BTH' :
      (appliesTo && appliesTo.individualSecurities) ? 'IND' : (appliesTo && appliesTo.underlyingOptions) ? 'OPT' : '';
    this.attributesForm.patchValue({ appliesTo: appliesToValidate });
    this.attributesForm.markAsDirty();
  }

  updateIndividualSecurities(securities) {
    if (this.attributesFormData && this.attributesFormData.ruleType !== this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
      this.attributesForm.patchValue({ indSecs: securities });
      /* Setting the Form to Dirty if Autocomplete Record is selected */
      this.attributesForm.markAsDirty();
      if (this.attributesForm.get('indSecs').value && this.attributesForm.get('indSecs').value.length > 0) {
        this.selectedIndividualSecurity = true;
      } else {
        this.selectedIndividualSecurity = false;
      }
    } else {
      this.attributesForm.patchValue({ fndFmlyInfo: securities });
      /* Setting the Form to Dirty if Autocomplete Record is selected */
      this.attributesForm.markAsDirty();
    }
  }

  deleteFormValues() {
    const ruleObj: any = { ...this.formDataService.getFormData() };
    if (this.attributesFormData.ruleType !== 'SECTYP') {
      delete ruleObj.secCatgs1;
      delete ruleObj.secCatgs2;
      delete ruleObj.secCatgs3;
      delete ruleObj.presetRuleId;
      delete ruleObj.presetRuleKey;
      delete ruleObj.assetGroups;
    }
    return ruleObj;
  }

  compareFormDataValue(ruleObj) {
    /*This is to avoid circular JSOn error caused by chooseSecurityType. Using secCats to check for any changes in securities*/
    const latestFormObj = this.attributesForm.getRawValue();
    delete latestFormObj.chooseSecurityType;
    /*Comparing sec categaries to make put call if any differences*/
    const compareSecCategories = this.compareSecCategories(latestFormObj, ruleObj);

    if (latestFormObj.assetGroups && latestFormObj.assetGroups.length > 0) {
      latestFormObj.assetGroups.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      latestFormObj.assetGroups.forEach(latAttrbts => {
        latAttrbts.attributes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      });
    }
    if (this.formObjToCompare.assetGroups && this.formObjToCompare.assetGroups.length > 0) {
      this.formObjToCompare.assetGroups.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.formObjToCompare.assetGroups.forEach(formAttrbts => {
        formAttrbts.attributes.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      });
    }

    return (JSON.stringify(latestFormObj) !== JSON.stringify(this.formObjToCompare) || compareSecCategories);
  }

  goToNextStep() {
    if (!this.dateError) {
      this.isClicked = false;
      this.defaultSaveSuccess = true;
      this.saveData(true);
      const ruleObj = this.deleteFormValues();
      delete ruleObj.chooseSecurityType;
      delete ruleObj.oldRusleIdForCopy;
      delete ruleObj.isEmpGrpModified;

      if (!this.is10b5Client) {
        delete ruleObj.planId;
        delete ruleObj.ruleSubCategory;
        delete ruleObj.isSavedAsDraft;
      }

      if (this.attributesFormData.ruleType === this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
        ruleObj.fndFmly = this.emtrService.formFundFamily(ruleObj.fndFmlyInfo);
        delete ruleObj.appliesTo;
        delete ruleObj.indSecs;
        delete ruleObj.instrumentGroups;
        delete ruleObj.rstrctShortSell;
        delete ruleObj.fndFmlyInfo;
      } else {
        delete ruleObj.fndFmlyInfo;
        delete ruleObj.fndFmly;
      }
      /* Setting Action in Request Payload only for Copy/New Rule */
      if (ruleObj.mode === 'Copy') {
        ruleObj.action = 'COPY';
        ruleObj.ruleAssignInfo = {};
      } else if (ruleObj.mode === '') {
        ruleObj.action = 'NEW';
      }

      const differenceInformVal = this.compareFormDataValue(ruleObj);
      if ((this.ruleService.checkIfChangesMadeInStep1 || this.ruleService.checkIfChangesMadeInStep2 ) || differenceInformVal) {
        if (this.businessUnit === this.getSharedConstant.rbsBUText) {
          delete ruleObj.name;
        }
        if (this.attributesFormData.globalRule) {
          // First half condition is for Draft status rule where in no assignment has happened and second half is to check if Business Unit is selected
          // checking the length of employeeAccounts in second half condition to avoid a scenario if group is created with the name as Business Unit
          if ((ruleObj.employeeGroups.length === 0 && ruleObj.employeeAccounts.length === 0) || (this.attributesFormData.employeeGroups.length === 1 && this.attributesFormData.employeeGroups[0].name === 'Business Unit' && this.attributesFormData.employeeAccounts.length === 0)) {
            ruleObj.global = this.getConstant.rulesConstant.globalObj;
          } else {
            ruleObj.global = this.getConstant.rulesConstant.semiGlobalObj;
          }
          delete ruleObj.orgId;
        } else {
          delete ruleObj.global;
        }
        delete ruleObj.globalRule;
        delete ruleObj.micAGSelectedFlag;
        delete ruleObj.employeeAccounts;

        ruleObj.custom = this.customVal;

        const localObj = this.attributesForm.getRawValue();
        if (localObj.assetGroups && localObj.assetGroups.length > 0) {
          delete ruleObj.secCatgs1;
          delete ruleObj.secCatgs2;
          delete ruleObj.secCatgs3;
        }

        this.formDataService.formData.showToast = true;
        this.ruleService.checkIfChangesMadeInStep1 = false;
        this.ruleService.checkIfChangesMadeInStep2 = false;
        this.ruleService.saveRule(ruleObj).subscribe((res) => {
          /* Clearing Error Message after clicking Save & Continue in Step2 */
          this.ruleService.showSchError.emit(false);
          this.ruleService.schErrorMsg.emit([]);
          this.defaultSaveSuccess = false;

          /* Setting startDt/endDt/status in Edit scenario and Active Status for Production Bug #16815 */
          if (this.formDataService.formData.mode === this.getConstant.rulesConstant.editCapsTxt && res.data.status === this.getConstant.rulesConstant.ruleActiveStatus) {
            this.formDataService.formData.startDt = (res.data && res.data.startDt) ? res.data.startDt : '';
            this.formDataService.formData.endDt = (res.data && res.data.endDt) ? res.data.endDt : '';
            this.formDataService.formData.status = (res.data && res.data.status) ? res.data.status : '';
          }
          /* appending copy when new copy rule is created and removing it when rule engine has given new name */
          if (this.businessUnit === this.getSharedConstant.rbsBUText && this.attributeData.mode === 'Copy' && this.ruleService.oldRBSRuleNameForCopyFlow === '') {
            this.ruleService.originalRBSRuleNameForCopyFlow = res.data.name;
            res.data.name = res.data.name + this.getConstant.rulesConstant.ruleGlobalConst.copySuffixTxt;
            this.ruleService.oldRBSRuleNameForCopyFlow = res.data.name;
          } else if (this.businessUnit === this.getSharedConstant.rbsBUText && (this.attributeData.mode === 'Copy' || this.attributeData.mode === '') && this.ruleService.oldRBSRuleNameForCopyFlow !==  res.data.name) {
            this.ruleService.originalRBSRuleNameForCopyFlow = res.data.name;
            this.ruleService.oldRBSRuleNameForCopyFlow = res.data.name;
          }
          this.formDataService.formData.expiredRuleStatus = res.data.status;
          this.formDataService.formData.employeeGroups = (res.data.employeeGroups && res.data.employeeGroups.length > 0) ? res.data.employeeGroups : this.formDataService.formData.employeeGroups;
          this.formDataService.formData.isSavedAsDraft = true;
          this.formDataService.setId((ruleObj.id) ? ruleObj.id : res.data.id);
          this.formDataService.setRuleName(res.data.name ? res.data.name : ruleObj.data);
          if (this.formDataService.formData.mode === '') {
            /* Save & Continue button click GUTtracking value for adobe analytics - Only in create flow */
            this.sharedService.gutTracking('adobe-lc_rule_step2continue');
          }
          this.router.navigate(['../step3'], {relativeTo: this.r});
        });
      } else {
        this.formDataService.formData.showToast = false;
        if (this.formDataService.formData.mode === '') {
          /* Save & Continue button click GUTtracking value for adobe analytics - Only in create flow */
          this.sharedService.gutTracking('adobe-lc_rule_step2continue');
        }
        this.router.navigate(['../step3'], {relativeTo: this.r});
      }
    }
  }

  /* Handling the Save from confirm-message clicking 'Yes' */
  goToPreviousStep() {
    if (this.errorMsg.length === 0 || (this.errorMsg.length === 1 && this.errorMsg[0] === this.getConstant.rulesConstant.autoComplete.noRecords)) {
      this.saveData(false);
      const ruleObj = this.deleteFormValues();
      this.isClicked = false;
      /* This is to identify changes made in step 2 and came back to step1. If this is not put, new changes are not identified hence to put call is made*/
      this.ruleService.checkIfChangesMadeInStep2 = this.compareFormDataValue(ruleObj);
      this.ruleService.previousStep = true;
      this.navigateTo('../step1', '');
    }
  }

  compareSecCategories(latestFormObj, ruleObj) {
    const latestSecCategoriesVal = [];
    let comapareSecCategories = false;
    if (this.attributesFormData.ruleType === 'SECTYP') {
      latestSecCategoriesVal.push(ruleObj.secCatgs1);
      latestSecCategoriesVal.push(ruleObj.secCatgs2);
      latestSecCategoriesVal.push(ruleObj.secCatgs3);
      if (latestSecCategoriesVal.length > 0) {
        latestSecCategoriesVal.forEach(latSecCat => {
          latSecCat.sort((a, b) => (a.secTypNm > b.secTypNm) ? 1 : ((b.secTypNm > a.secTypNm) ? -1 : 0));
        });
      }
      if (this.allSecCategories.length > 0) {
        this.allSecCategories.forEach(allSecCat => {
          allSecCat.sort((a, b) => (a.secTypNm > b.secTypNm) ? 1 : ((b.secTypNm > a.secTypNm) ? -1 : 0));
        });
      }
      if (JSON.stringify(latestSecCategoriesVal) !== JSON.stringify(this.allSecCategories)) {
        return comapareSecCategories = true;
      }
    }
  }

  saveData(validateStep) {
    const formObj = this.attributesForm.value;
    const fromDate = this.attributesForm.get('dateFrom').value;
    const toDate = this.attributesForm.get('dateTo').value;
    /* eslint-disable-next-line max-len */
    formObj.startDt = (fromDate.date) ? (moment.tz(moment(new Date(moment(fromDate.date).format('YYYY/MM/DD') + ' ' + fromDate.time + ' ' + fromDate.timeMeridian).getTime()).format('YYYY MM DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS')) : '';

    /* eslint-disable-next-line max-len */
    formObj.endDt = (toDate.date) ? moment.tz(moment(new Date(moment(toDate.date).format('YYYY/MM/DD') + ' ' + toDate.time + ' ' + toDate.timeMeridian).getTime()).format('YYYY MM DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS') : '';
    formObj.restrictBuys = (formObj.restrictBuys && formObj.restrictBuys === true) ? 'Y' : 'N';

    formObj.restrictSells = (formObj.restrictSells && formObj.restrictSells === true) ? 'Y' : 'N';
    formObj.restrictShortSells = (this.attributesForm.get('restrictShortSells').value === true) ? 'Y' : 'N';
    /*When operation net expense ration dropdown has value*/
    if (this.selectedDropDownVal) {
      let obj: any = {};
      obj = {
        name: this.getConstant.ruleSteps.step2.operatingNetExpenseRatio,
        code: this.selectedDropDownVal.code,
        value: this.selectedDropDownVal.value,
      };
      if (this.assetGroups.some(val => val.name === this.getConstant.ruleSteps.step2.mutualFunds)) {
        this.assetGroups.forEach(data => {
          if (data.name === this.getConstant.ruleSteps.step2.mutualFunds) {
            const IsExitint = data.attributes.filter( assets => assets.code === obj.code );
            if ( IsExitint.length === 0 ) {
               data.attributes.push(obj);
            }
          }
        });
      } else {
        this.assetGroups.push({
          name: this.getConstant.ruleSteps.step2.mutualFunds,
          code: this.getConstant.ruleSteps.step2,
          attributes: [obj],
          sscLevel: [this.getConstant.ruleSteps.step2.mutualFundsSscLevel],
        });
      }
    }
    /* Selected Type is Security-Type saving selected Securities in formObj*/
    if (this.attributesFormData.ruleType === 'SECTYP') {
      this.formingJson(this.multiLevelUpdatedList);
    }
    formObj.secCatgs1 = this.attributesFormData.secCatgs1;
    formObj.secCatgs2 = this.attributesFormData.secCatgs2;
    formObj.secCatgs3 = this.attributesFormData.secCatgs3;
    formObj.rulesCategory = this.attributeData.rulesCategory;
    formObj.multiselectSelectedData = this.multiselectSelectedData;
    formObj.multiselectSelectedInstGrpData = this.multiselectSelectedInstGrpData;

    formObj.presetRuleId = this.presetRuleId;
    formObj.presetRuleKey = this.presetRuleKey;
    formObj.assetGroups = this.assetGroups;
    formObj.micAGSelectedFlag = this.setMicAGFlag;

    if (this.attributesFormData.ruleType !== this.getConstant.rulesConstant.autoComplete.FNDISUTxt) {
      formObj.fndFmlyInfo = [];
      formObj.fndFmly = [];
      this.attributesForm.patchValue({
        fndFmlyInfo: []
      });
    } else {
      this.attributesForm.patchValue({
        indSecs: []
      });
    }
    if (this.attributesFormData.ruleType !== 'SECTYP') {
      formObj.indSecs = this.attributesForm.get('indSecs').value;
      formObj.instrumentGroups = this.attributesForm.get('instGrps').value;
      formObj.fndFmlyInfo = this.attributesForm.get('fndFmlyInfo').value;
      formObj.fndFmly = this.emtrService.formFundFamily(this.attributesForm.get('fndFmlyInfo').value);
    }

    if (this.attributesFormData.globalRule) {
      const ruleObj: any = { ...this.formDataService.getFormData() };
      // First half condition is for Draft status rule where in no assignment has happened and second half is to check if Business Unit is selected
      // checking the length of employeeAccounts in second half condition to avoid a scenario if group is created with the name as Business Unit
      if ((ruleObj.employeeGroups.length === 0 && ruleObj.employeeAccounts.length === 0) || (this.attributesFormData.employeeGroups.length === 1 && this.attributesFormData.employeeGroups[0].name === 'Business Unit' && this.attributesFormData.employeeAccounts.length === 0)) {
        formObj.global = this.getConstant.rulesConstant.globalObj;
      } else {
        formObj.global = this.getConstant.rulesConstant.semiGlobalObj;
      }
    }
    this.formDataService.setStep2(formObj, validateStep);
  }

  restrictTypeChanged() {
    const formObj = this.attributesForm.value;
    const restrictShortSells = this.attributesForm.get('restrictShortSells').value;
    this.attributesForm.get('validateRestrictType').setValue((formObj.restrictBuys || formObj.restrictSells || restrictShortSells) ?
      true : '');
  }

  navigateTo(path, message) {
    this.confirmMessage.message = message;
    this.router.navigate([path], { relativeTo: this.r });
  }

  displayErrorAutocomplete(errorMsg) {
    if (errorMsg.msg === this.getConstant.rulesConstant.autoComplete.duplicateRecord || errorMsg.msg === this.getConstant.rulesConstant.autoComplete.instrumentGroupDuplicateRecord) {
      /*To show the duplicate record information msg*/
      this.ruleService.toastType.emit('info');
      this.ruleService.toastMsg.emit(errorMsg.msg);
    } else {
      this.indSecsDuplicateErr = errorMsg.msg;
      this.ruleService.toastType.emit('');
      this.ruleService.toastMsg.emit(errorMsg.msg);
    }

    /*To show 'No Records Found' error msg*/
    if (this.indSecsDuplicateErr) {
      this.indSecsDuplicateErrMsg = this.indSecsDuplicateErr;
      /*Push the component name in array when error is generated and it is not already present in the array*/
      if (this.componentName.indexOf(errorMsg.component) === -1) {
        this.componentName.push(errorMsg.component);
      }
      if (this.errorMsg.indexOf(this.indSecsDuplicateErr) === -1) {
        this.errorMsg.push(this.indSecsDuplicateErr);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      /*Splice the component name out of the array when search field is cleared*/
      if (this.componentName.indexOf(errorMsg.component) !== -1) {
        this.componentName.splice(this.componentName.indexOf(errorMsg.component), 1);
      }
      if (this.indSecsDuplicateErrMsg && this.componentName.length === 0) {
        if (this.errorMsg.indexOf(this.indSecsDuplicateErrMsg) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.indSecsDuplicateErrMsg), 1);
          this.componentName = [];
        }
      }
    }

    /*Hide the error block when errorMsg is empty*/
    if (this.errorMsg.length === 0) {
      this.ruleService.showSchError.emit(false);
    }

    this.ruleService.schErrorMsg.emit(this.errorMsg);
  }

  displayErrorDate(errorMsg) {
    this.dateError = errorMsg;

    /*To show date error on top of the step wizard*/
    if (this.dateError) {
      /*To remove the previously displayed date error from errorMsg and replace it with new error msg*/
      if (this.dateErrorMsg) {
        if (this.errorMsg.indexOf(this.dateErrorMsg) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.dateErrorMsg), 1);
        }
      }
      this.dateErrorMsg = this.dateError;
      if (this.errorMsg.indexOf(this.dateError) === -1) {
        this.errorMsg.push(this.dateError);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.dateErrorMsg) {
        if (this.errorMsg.indexOf(this.dateErrorMsg) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.dateErrorMsg), 1);
        }
      }
    }

    /*Hide the error block when errorMsg is empty*/
    if (this.errorMsg.length === 0) {
      this.ruleService.showSchError.emit(false);
    }

    this.ruleService.schErrorMsg.emit(this.errorMsg);
  }

  updateMultiselectValues(selecteddata) {
    this.multiselectSelectedData = selecteddata;
  }

  /* Validating selectedObj and formObj both are equal not updating the formObj*/
  isEquivalent(a, b) {
    // Create arrays of property names
    const aProps: any = Object.getOwnPropertyNames(a);
    const bProps: any = Object.getOwnPropertyNames(b);
    return aProps.length !== bProps.length;
  }

  /* Forming the Json while clicking Save&Continue in security-type*/
  formingJson(selectedValues) {
    if (!this.assetGroups || (this.assetGroups && this.assetGroups.length === 0)) {
      const wholeArray = selectedValues;
      let responseArray = this.attributesFormData.chooseSecurityType;
      if (responseArray === undefined) {
        responseArray = selectedValues;
      }
      const that = this;
      that.attributesFormData.secCatgs1 = [];
      that.attributesFormData.secCatgs2 = [];
      that.attributesFormData.secCatgs3 = [];
      Object.keys(wholeArray).forEach(key => {
        wholeArray[key].forEach(keys => {
          if (keys.data.level === 'SC1') {
            that.attributesFormData.secCatgs1.push({
              secTypNm: keys.data.desc,
              secTypCd: keys.data.code,
            });
          } else if (keys.data.level === 'SC2') {
            that.attributesFormData.secCatgs2.push({
              secTypNm: keys.data.desc,
              secTypCd: keys.data.code,
            });
          } else if (keys.data.level === 'SC3') {
            that.attributesFormData.secCatgs3.push({
              secTypNm: keys.data.desc,
              secTypCd: keys.data.code,
            });
          }
        });

      });
    } else {
      this.attributesFormData.secCatgs1 = [];
      this.attributesFormData.secCatgs2 = [];
      this.attributesFormData.secCatgs3 = [];
    }
    this.attributesFormData.secCatgs1 = this.attributesFormData.secCatgs1.reduce((unique, o1) => {
      if (!unique.some(objCat1 => objCat1.secTypCd === o1.secTypCd && objCat1.secTypNm === o1.secTypNm)) {
        unique.push(o1);
      }
      return unique;
    }, []);
    this.attributesFormData.secCatgs2 = this.attributesFormData.secCatgs2.reduce((uniqueVal, o2) => {
      if (!uniqueVal.some(objCat2 => objCat2.secTypCd === o2.secTypCd && objCat2.secTypNm === o2.secTypNm)) {
        uniqueVal.push(o2);
      }
      return uniqueVal;
    }, []);
    this.attributesFormData.secCatgs3 = this.attributesFormData.secCatgs3.reduce((uniqueValue, o3) => {
      if (!uniqueValue.some(objCat3 => objCat3.secTypCd === o3.secTypCd && objCat3.secTypNm === o3.secTypNm)) {
        uniqueValue.push(o3);
      }
      return uniqueValue;
    }, []);
  }

  /*removeDuplicateItem(data) {
    let array = [];
    data.reduce((unique, o) => {
      if (!unique.some(obj => obj.secTypCd === o.secTypCd && obj.secTypNm === o.secTypNm)) {
        unique.push(o);
      }
      array = unique;
    }, []);
    return array;
  }
*/
  /* Setting the formObj and displaying the selected Data in Page*/
  updateSecurityValues(selectedValues) {
    this.multiLevelSecurityType = Object.assign({}, selectedValues);
    const wholeArray = Object.assign({}, this.multiLevelSecurityType);
    Object.keys(wholeArray).forEach(key => {
      wholeArray[key].forEach(keys => {
        if (keys.children) {
          keys.data.selected = 'ALL';
          keys.children.forEach(record => {
            wholeArray[key] = wholeArray[key].filter(item => item.data.code !== record.data.code);
          });
        }
      });
    });
    this.multiLevelUpdatedList = wholeArray;
    this.attributesForm.patchValue({ chooseSecurityType: selectedValues });
    this.attributesForm.markAsDirty();
    this.selectedSecurityType = selectedValues;
    return Object.keys(this.multiLevelSecurityType).length > 0 ? this.multiLevelSecurityType : false;
  }

  /* Selected Security Type is 0 showing the error message*/
  selectedCapacity(data) {
    return Object.keys(data).length;
  }

  /* Performing SSC call while clicking Security Button */
  openSecurityType() {
    const isRestrictShortSellsChecked = (this.attributesForm.get('restrictBuys').value || this.attributesForm.get('restrictSells').value) ? false : this.attributesForm.get('restrictShortSells').value;
    this.ruleService.getSecurities(isRestrictShortSellsChecked).subscribe((data: any) => {
      this.securityData = data.esoLevels;
      if (this.securityData) {
        this.childSecurityType.openModal(event, this.securityData);
      }
    });
  }

  recurrsive(data, array) {
    if (data.subLevels) {
      data.subLevels.forEach((subLevel) => {
        if (subLevel.subLevels) {
          array.push({ ...subLevel });
          this.recurrsive(subLevel, array);
        } else {
          array.push({ ...subLevel });
        }
      });
      return array;
    } else {
      return data;
    }
  }

  populateSecurityTypeData() {
    if (!this.assetGroups && (this.attributesForm.get('assetGroups').value && this.attributesForm.get('assetGroups').value.length === 0)) {
      const isRestrictShortSellsChecked = (this.attributesForm.get('restrictBuys').value || this.attributesForm.get('restrictSells').value) ? false : this.attributesForm.get('restrictShortSells').value;
      this.ruleService.getSecurities(isRestrictShortSellsChecked).subscribe((data: any) => {
        const securityData = data.esoLevels;
        if (securityData) {
          const secCatgs1 = this.formatSecurities(this.formDataService.formData.secCatgs1, 'SC1');
          const secCatgs2 = this.formatSecurities(this.formDataService.formData.secCatgs2, 'SC2');
          const secCatgs3 = this.formatSecurities(this.formDataService.formData.secCatgs3, 'SC3');
          const selectedValues = secCatgs1.concat(secCatgs2).concat(secCatgs3);
          selectedValues.forEach(selectedData => {
            securityData.forEach(secData => {
              if (JSON.stringify(secData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
                if (!this.multiLevelSecurityType[secData.desc]) {
                  this.multiLevelSecurityType[secData.desc] = [];
                }
                if (selectedData.data.level === 'SC1') {
                  this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                    .concat(this.childSecurityType.formatJson(this.recurrsive({...secData}, [])))
                    .concat(this.childSecurityType.formatJson([{...secData}]));
                } else if (selectedData.data.level === 'SC2') {
                  secData.subLevels.forEach(secSubLevelData => {
                    if (JSON.stringify(secSubLevelData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
                      this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                        .concat(this.childSecurityType.formatJson(this.recurrsive({...secSubLevelData}, [])))
                        .concat(this.childSecurityType.formatJson([{...secSubLevelData}]));
                    }
                  });
                } else {
                  this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                    .concat(this.childSecurityType.formatJson([selectedData.data]));
                }
              }
            });
          });
          this.updateSecurityValues(this.multiLevelSecurityType);
        }
      });
    }
  }

  formatSecurities(securities, level) {
    return securities.map(item => {
      return {
        data: {
          desc: item.secTypNm,
          code: item.secTypCd,
          level
        }
      };
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.ruleService.schErrorMsg.emit([]);
    this.ruleService.showSchError.emit(false);
    this.ruleService.toastMsg.emit('');
    this.ruleService.toastType.emit('');
    this.ruleService.showRequestIdError.emit(false);
    this.sharedService.isModalOpened.next(false);
  }

  updateInstrumentGroups(instrumentGrps) {
    if (this.attributesFormData) {
      this.attributesForm.patchValue({ instGrps: instrumentGrps });
      /* Setting the Form to Dirty if Autocomplete Record is selected */
      this.attributesForm.markAsDirty();
      if (this.attributesForm.get('instGrps').value && this.attributesForm.get('instGrps').value.length > 0) {
        this.selectedInstrumentGroups = true;
      } else {
        this.selectedInstrumentGroups = false;
      }
    }
  }

  updateMultiselectInstGrpValues(selecteddata) {
    this.multiselectSelectedInstGrpData = selecteddata;
  }

  checkIfFormIsValid() {
    /* Validate form to enable or disable Save and Continue btn when Security Type is Selected  */
    if (this.attributesFormData.ruleType === 'SECTYP') {
        /* Enable or disable Save and Continue btn for choose Security Type List flow */
      return !(this.attributesForm.valid && ((this.selectedSecurityType && Object.keys(this.selectedSecurityType).length !== 0) || (this.attributesForm.value.assetGroups && this.attributesForm.value.assetGroups.length > 0)));
    }
    return !(this.attributesForm.get('validateRestrictType').value === true && (this.selectedIndividualSecurity || this.selectedInstrumentGroups) &&
      this.attributesForm.get('dateFrom').get('date').value && this.attributesForm.get('appliesTo').valid &&
      /* eslint-disable-next-line max-len */
      ((this.attributesForm.get('datepickerDisabled').value === false || this.attributesForm.get('datepickerDisabled').value === '') ? true : (this.attributesForm.get('dateTo').get('date').value !== '' && this.attributesForm.get('dateTo').get('date').value !== null)));
  }

  setSelectedPresetRule(event) {
    this.presetRuleId = event;
  }

  setSelectedAssetGroups(event) {
    this.assetGroups = event;
    this.attributesForm.patchValue({assetGroups: event});
  }

  /* Custom flag will be set to true for MF preset rules and fore rest all selection custom flag will be set to false */
  setCustomValue(event) {
    this.customVal = event;
  }

  setSelectedDropdownVal(event) {
    this.selectedDropDownVal = event;
  }

  showWarningMsg(event) {
    this.warningMsg = event;
    /* To show the warning msg when changes made in asset groups */
    this.ruleService.toastType.emit('warn');
    this.ruleService.toastMsg.emit(this.warningMsg);
  }

  displayWarningMsg() {
    this.ruleService.toastType.emit('warn');
    this.ruleService.toastMsg.emit(this.presetRuleWarnMsg);
  }

  clearFormOnRadioSelection() {
    this.displayWarningMsg();
    if (this.showSecurityOrPreset === 'presetRules') {
      this.assetGroupsInput = [];
      this.attributesForm.get('chooseSecurityType').reset();
      this.ruleService.presetRuleRadioSelected.next(false);
      this.updateSecurityValues(this.attributesForm.value.chooseSecurityType);
      this.attributesForm.get('chooseSecurityType').clearValidators();
      this.attributesForm.get('assetGroups').setValidators(Validators.required);
      this.attributesForm.get('assetGroups').updateValueAndValidity();
      this.attributesForm.get('chooseSecurityType').updateValueAndValidity();
    } else if (this.showSecurityOrPreset === 'secLevel') {
      /* Custom flag will be set to true for MF preset rules selection only and for Security Level Lists selection custom flag will be set to false */
      this.customVal = false;
      this.attributesFormData.presetRuleKey = this.getConstant.presetRules.customAssetGroupText;
      this.attributesFormData.presetRuleId = null;
      this.assetGroupsInput = this.assetGroups = [];
      this.selectedDropDownVal = null;
      this.presetRuleId = null;
      this.presetRuleKey = this.getConstant.presetRules.customAssetGroupText;
      this.attributesForm.get('assetGroups').reset();
      this.ruleService.presetRuleRadioSelected.next(true);
      this.attributesForm.get('assetGroups').clearValidators();
      this.attributesForm.get('chooseSecurityType').setValidators(Validators.required);
      this.attributesForm.get('chooseSecurityType').updateValueAndValidity();
      this.attributesForm.get('assetGroups').updateValueAndValidity();
    }
  }

  setSelectedPresetRuleKey(event) {
    this.presetRuleKey = event;
  }
}
