<header *ngIf="!hideUnauthorized">
  <div class="sdps-grid-container sdps-wrap">
    <div class="col-1 t-logo">
      <img src="assets/img/schwab-logo.png" alt="Charles Schwab">
    </div>
  </div>
</header>

<div class="sdps-p-around_large" *ngIf="!hideUnauthorized">
  <p class="accessDenied sdps-m-bottom_medium" [innerHTML]="shellConstant.accessDenied"></p>

  <ems-service-error></ems-service-error>

  <div class="sdps-messaging sdps-messaging--error sdps-p-vertical_medium" role="alert" tabindex="0">
      <span aria-label="Error message" class="sdps-messaging__icon sch-x-circle-2x sdps-p-top_xx-small" role="text"></span>
      <span [innerHTML]="shellConstant.accessDeniedMessage"></span>
  </div>

  <p>
    {{shellConstant.refreshMsg}}{{shellConstant.redirectMsg}}<a href="/" [innerHTML]="shellConstant.link"></a>.
  </p>
</div>

<div *ngIf="hideUnauthorized" >
  <div class="sdps-spinner-container sdps-spinner-container--page">
    <div class="sdps-spinner sdps-flex-center">
      <span class="sch sch-spinner sch-spin-stepped"></span>
    </div>
  </div>
</div>
