import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EditEmployeeModalComponent} from '../edit-employee-modal/edit-employee-modal.component';
import {SharedConstant, SharedManageEmployeesComponent, SharedService} from '@ems/shared';
import {ActivatedRoute, Router} from '@angular/router';
import {ManageEmployeeDataService} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';
import {EmtrService} from '../../emtr.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'tr-employee-list',
  templateUrl: './employee-list.component.html'
})
export class EmployeeListComponent implements OnInit, OnDestroy {

  public isModalOpen: boolean;
  public rowsDisplay: any = 15;
  public empData: any;
  getConstant = emtrConstant;
  clientName: string;
  businessUnit: string;
  empMsg: string;
  orgId: any;
  sharedConstant = SharedConstant;
  currentOrgSubscription: Subscription;

  @ViewChild(EditEmployeeModalComponent) editEmp: EditEmployeeModalComponent;
  @ViewChild(SharedManageEmployeesComponent) sharedEmp: SharedManageEmployeesComponent;
  @ViewChild('toastMessage') msgToast: any;

  constructor(public emtrService: EmtrService, private router: Router, public sharedService: SharedService, public manageEmployeeDataService: ManageEmployeeDataService,
              private r: ActivatedRoute, @Inject('entitlementConst') public entitlementConst) {
    this.getSaveEmpGrpMessage();
  }

  ngOnInit() {
    this.currentOrgSubscription = this.emtrService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.orgId = clientInfo && clientInfo.orgId;
        this.clientName = clientInfo && clientInfo.k4ClientName;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        if (this.businessUnit && this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAppAccess.employeeSummary.uuid, '', '')) {
          this.sharedService.isLoadPageOnClientChange = true;
          this.router.navigate([this.sharedConstant[this.businessUnit].toEmpParticipantLandingPage]);
        }
      }
    });
    if (this.empMsg) {
      setTimeout(() => {
        this.msgToast.pushMessage('success', this.empMsg);
      }, 1);
    }
  }

  editModalRoute(empdata: any) {
    this.isModalOpen = true;
    this.empData = empdata;
    this.editEmp.openModal(empdata);
  }

  refreshEmpData() {
    const param: any = {
      orgId: this.orgId,
      pageNumber: 1,
      pageSize: this.rowsDisplay,
      sortField: 'empId'
    };
    this.sharedEmp.updateEmployeeList(param);
  }

  getSaveEmpGrpMessage() {
    this.manageEmployeeDataService.getMessage().subscribe((data) => {
      this.empMsg = data.text ? data.text : '';
    });
  }

  ngOnDestroy(): void {
    this.sharedService.clearValidationFields();
    this.manageEmployeeDataService.clearMessage();
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
  }
}
