export class FormData {
    name = '';
    ruleType = '';
    rulesCategory = '';
    orgId = '';
    appliesTo = '';
    desc = '';
    endDt = '';
    indSecs: any[] = [];
    instrumentGroups: any[] = [];
    note = '';
    rstrctBuy = '';
    rstrctSell = '';
    rstrctShortSell = '';
    startDt = '';
    securityType: any[] = [];
    status = 'DRAFT';
    id: any = '';
    employeeAccounts: any[] = [];
    employeeGroups: any[] = [];
    showToast = false;
    expiredRuleStatus = '';
    autoCompleteData: any[] = [];
    chooseSecurityType: any = '';
    secCatgs1: any[] = [];
    secCatgs2: any[] = [];
    secCatgs3: any[] = [];
    activate = false;
    override = '';
    empIds: any[] = [];
    subAccntNumbers: any[] = [];
    justification = null;
    otherInput = '';
    mode = '';
    ruleSubCategory = '';
    planId = '';
    isSavedAsDraft: any = '';
    fndFmlyInfo: any[] = [];
    fndFmly: any[] = [];
    newSelectedRecords: any[] = [];
    removedExistingSelected: any[] = [];
    assignEmpIds: any[] = [];
    assignSubAcctNumbers: any[] = [];
    unAssignEmpIds: any[] = [];
    unAssignSubAcctNumbers: any[] = [];
    subAccntNewlySelected: any[] = [];
    removedExistingEmpId: any[] = [];
    isEmpGrpModified = '';
    oldRuleIdForCopy = '';
    presetRuleId = '';
    assetGroups: any[] = [];
    unassignedEmpGroup: any[] = [];
    presetRuleKey = '';
    globalRule = false;
    micAGSelectedFlag = false;
    unassignOrgIds: any[] = [];
    assignOrgIds: any[] = [];
    global: any = null;
    clientList: any = [];
    changeRequestId = '';
    assignBU: any[] = [];
    unAssignBU: any[] = [];

  clear() {
        this.name = '';
        this.ruleType = '';
        this.rulesCategory = '';
        this.ruleSubCategory = '';
        this.planId = '';
        this.isSavedAsDraft = '';
        this.orgId = '';
        this.appliesTo = '';
        this.desc = '';
        this.endDt = '';
        this.indSecs = [];
        this.instrumentGroups = [];
        this.note = '';
        this.rstrctBuy = '';
        this.rstrctSell = '';
        this.rstrctShortSell = '';
        this.startDt = '';
        this.securityType = [];
        this.employeeAccounts = [];
        this.employeeGroups = [];
        this.autoCompleteData = [];
        this.chooseSecurityType = '';
        this.secCatgs1 = [];
        this.secCatgs2 = [];
        this.secCatgs3 = [];
        this.activate = false;
        this.empIds = [];
        this.subAccntNumbers = [];
        this.fndFmlyInfo = [];
        this.fndFmly = [];
        this.justification  = '';
        this.otherInput = '';
        this.override = '';
        this.id = '';
        this.mode = '';
        this.status = 'DRAFT';
        this.newSelectedRecords = [];
        this.removedExistingSelected = [];
        this.assignEmpIds = [];
        this.assignSubAcctNumbers = [];
        this.unAssignEmpIds = [];
        this.unAssignSubAcctNumbers = [];
        this.subAccntNewlySelected = [];
        this.removedExistingEmpId = [];
        this.isEmpGrpModified = '';
        this.oldRuleIdForCopy = '';
        this.presetRuleId = '';
        this.assetGroups = [];
        this.unassignedEmpGroup = [];
        this.presetRuleKey = '';
        this.global = null;
        this.globalRule = false;
        this.micAGSelectedFlag = false;
        this.unassignOrgIds = [];
        this.assignOrgIds = [];
        this.clientList = [];
        this.changeRequestId = '';
        this.expiredRuleStatus = '';
    }

    setData(data) {
        this.name = data.name || '';
        this.ruleType = data.ruleType || '';
        this.rulesCategory = data.rulesCategory || '';
        this.ruleSubCategory = data.ruleSubCategory || '';
        this.planId = data.planId || '';
        this.isSavedAsDraft = data.isSavedAsDraft || '';
        this.orgId = data.orgId || '';
        this.appliesTo = data.appliesTo || null;
        this.desc = data.desc || '';
        this.changeRequestId = data.changeRequestId || '';
        this.endDt = data.endDt || '';
        this.indSecs = data.indSecsInfo || [];
        this.instrumentGroups = data.instrumentGroupsInfos || [];
        this.note = data.note || '';
        this.rstrctBuy = data.rstrctBuy || '';
        this.rstrctSell = data.rstrctSell || '';
        this.rstrctShortSell = data.rstrctShortSell || '';
        this.startDt = data.startDt || '';
        this.securityType = data.securityType || [];
        this.employeeAccounts = data.empAccounts ? data.empAccounts : data.employeeInfos || [];
        this.employeeGroups = data.employeeGroups || [];
        this.autoCompleteData = data.autoCompleteData || [];
        this.chooseSecurityType = data.chooseSecurityType || '';
        this.secCatgs1 = data.secCatgs1 || [];
        this.secCatgs2 = data.secCatgs2 || [];
        this.secCatgs3 = data.secCatgs3 || [];
        this.activate = data.activate || false;
        this.empIds = data.empIds || [];
        this.subAccntNumbers = data.subAccntNumbers || [];
        this.fndFmlyInfo = data.fndFmlyInfo || [];
        this.fndFmly = data.fndFmly || [];
        this.justification  = data.justification || null;
        this.otherInput = data.otherInput || '';
        this.override = data.override || '';
        this.id = data.id || '';
        this.status = data.status || 'DRAFT';
        this.newSelectedRecords = data.newSelectedRecords || [];
        this.removedExistingSelected = data.removedExistingSelected || [];
        this.assignEmpIds = data.assignEmpIds || [];
        this.assignSubAcctNumbers = data.assignSubAcctNumbers || [];
        this.unAssignEmpIds = data.unAssignEmpIds || [];
        this.unAssignSubAcctNumbers = data.unAssignSubAcctNumbers || [];
        this.subAccntNewlySelected = data.subAccntNewlySelected || [];
        this.removedExistingEmpId = data.removedExistingEmpId || [];
        this.presetRuleId = data.presetRuleId || '';
        this.assetGroups = data.assetGroups || [];
        this.unassignedEmpGroup = data.unassignedEmpGroup || [];
        this.presetRuleKey = data.presetRuleKey || [];
        this.global = data.global || null;
        this.globalRule = data.globalRule || false;
        this.micAGSelectedFlag = data.micAGSelectedFlag || false;
        this.unassignOrgIds = data.unassignOrgIds || [];
        this.assignOrgIds = data.assignOrgIds || [];
        this.clientList = data.clientList || data.clientProfiles || [];
        this.expiredRuleStatus = data.status;
    }
}

export class Step1 {
    name = '';
    ruleType = '';
    rulesCategory = '';
    justification = null;
    otherInput = '';
    ruleSubCategory = '';
    planId = '';
    globalRule = false;
}

export class Step2 {
    appliesTo = '';
    desc = '';
    changeRequestId = '';
    endDt = '';
    indSecs: any[] = [];
    instrumentGroups: any[] = [];
    note = '';
    rstrctBuy = '';
    rstrctSell = '';
    rstrctShortSell = '';
    startDt = '';
    securityType: any[] = [];
    autoCompleteData: any[] = [];
    chooseSecurityType: any = {};
    secCatgs1: any[] = [];
    secCatgs2: any[] = [];
    secCatgs3: any[] = [];
    isSavedAsDraft = '';
    fndFmly: any[] = [];
    fndFmlyInfo: any[] = [];
    presetRuleId: any = '';
    assetGroups: any[] = [];
    unassignedEmpGroup: any[] = [];
    presetRuleKey: any = '';
    micAGSelectedFlag = false;
}

export class Step3 {
    employeeAccounts: any[] = [];
    employeeGroups: any[] = [];
    empIds: any[] = [];
    subAccntNumbers: any[] = [];
    newSelectedRecords: any[] = [];
    removedExistingSelected: any[] = [];
    assignEmpIds: any[] = [];
    assignSubAcctNumbers: any[] = [];
    unAssignEmpIds: any[] = [];
    unAssignSubAcctNumbers: any[] = [];
    subAccntNewlySelected: any[] = [];
    removedExistingEmpId: any[] = [];
    unassignedEmpGroup: any[] = [];
    unassignOrgIds: any[] = [];
    assignOrgIds: any[] = [];
}
