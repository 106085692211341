import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, EventEmitter} from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import {ConfigUrlService, SharedService} from '@ems/shared';
import {Observable, Subject, BehaviorSubject} from 'rxjs';
import {CommonService} from './common.service';
import {AddlinkConstant} from '../../constants/addlinkConstants';

@Injectable()
export class RequestListService {

  public env: any;
  configURL = this.configService.setApplicationUrl();
  showRequestListError = new BehaviorSubject(false);
  showRequestListMessage: any;
  clearClicked = new EventEmitter<boolean>();
  filterClicked = new EventEmitter<boolean>();
  disableFilterButton = new EventEmitter<boolean>();
  disableForm = new EventEmitter<any>();
  private subject = new Subject<any>();
  requestFilterMessage = new EventEmitter<any>();
  sourceAndStatusMultiselectValues: any = new BehaviorSubject(null);
  updateRequest = new BehaviorSubject(null);
  addlinkConstants = AddlinkConstant;
  getOrgId = 0;
  orgId: any;

  constructor(@Inject('env') public e, private http: HttpClient, public configService: ConfigUrlService, private commonService: CommonService, private sharedService: SharedService) {
    this.env = e;
  }

  public getRequestList(page: number, pageSize: number, sortColumn: string, sortOrder: string, clientId: any): Observable<any> {
    if (sortColumn === 'employeeName') {
      sortColumn = 'employeeLastName';
    }
    if (sortColumn === 'client') {
      sortColumn = 'client.clientName';
    }
    const sort = sortColumn + ',' + sortOrder;
    let url = this.configURL.addlinkRequestEndPoint + '/requestlist?page=' + page + '&size=' + pageSize + '&sort=' + sort;
    if (clientId !== null && clientId !== '' && clientId !== 0) {
      url = url + '&ClientId=' + clientId;
    }
    return this.http.get(url);
  }

  public reSubmitIndexkey(indexKey: string, requestId: string): Observable<any> {
    const url = this.configURL.linkageEndPoint + '/indexKey/request';
    const body = {
      indexKey,
      requestId
    };

    return this.http.post(url, body);
  }

  public resubmitRequests(requestIds: any[]): Observable<any> {
    const url = this.configURL.linkageEndPoint + '/multiResubmit';
    return this.http.post(url, requestIds);
  }

  public takeActionRequests(requestObj): Observable<any> {
    const url = this.configURL.linkageEndPoint + '/takeAction';
    return this.http.post(url, requestObj);
  }

  public addlinkRequestPutEndPoint(requestIds, indexKeyValue, action, orgId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const reqInfo = {
      operation: action,
      index: indexKeyValue,
      requestIds
    };
    const url = this.configURL.bffAddlinkRequestEndPointV1;
    if (orgId && (orgId !== null && orgId !== 0)) {
      return this.http.put(url + '&Schwab-Client-Ids= ' + schwabClientHeader, reqInfo);
    } else {
      return this.http.put(url, reqInfo);
    }
  }

  public resubmitValidationMismatch(requestEditForm, requestId, orgId, authAgentId, masterAccount, accountNumber, sourceVal): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkRequestEndPointV1;
    let accountHolderAffiliation = requestEditForm.get('employeeToAccountHolderAffiliation').value;

    if (accountHolderAffiliation == null || accountHolderAffiliation[0] == null) {
      accountHolderAffiliation = '';
    } else {
      accountHolderAffiliation = accountHolderAffiliation[0].value;
    }

    const body = {
      action: 'LINK',
      employeeFirstName: requestEditForm.get('employeeFirstName').value.trim(),
      employeeLastName: requestEditForm.get('employeeLastName').value.trim(),
      accountOwnerFirstName: requestEditForm.get('accountHolderFirstName').value.trim(),
      accountOwnerLastName: requestEditForm.get('accountHolderLastName').value.trim(),
      accountHolderAffiliation,
      acctHolderEmpMatchCheck: requestEditForm.get('accountHolderSameAsEmployee').value,
      masterAccountNbr: masterAccount,
      acctNumber: accountNumber,
      source: sourceVal,
      requestId,
      orgId,
      authAgentId,
      resubmitRequest: true
    };
    if (this.getOrgId && this.getOrgId !== 0) {
      return this.http.post(url + '&Schwab-Client-Ids=' + schwabClientHeader, body);
    } else {
      return this.http.post(url, body);
    }
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }

  public applyFilter(page: number, pageSize: number, sortColumn: string, sortOrder: string, orgId: any, filterObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const requestListFilterInput = filterObj;
    if (sortColumn === 'employeeName') {
      sortColumn = 'employeeLastName';
    }
    if (sortColumn === 'client') {
      sortColumn = 'client.clientName';
    }
    const sort = sortColumn + ',' + sortOrder;
    const url = this.configURL.bffAddlinkRequestEndPointV1.replace('PassThrough?', `PassThrough/list?`) + '&pageNumber=' + page + '&pageSize=' + pageSize  + '&sortField=' + sort;

    if (orgId && orgId !== 0) {
      return this.http.post(url + '&Schwab-Client-Ids= ' + schwabClientHeader, requestListFilterInput);
    } else {
      return this.http.post(url, requestListFilterInput);
    }
  }

  public getSourceAndStatusValue(): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.getOrgId, schwabClientHeader);
    let url = '';
    if (this.getOrgId && this.getOrgId !== 0) {
      url = this.configURL.bffAddlinkFilterDataV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      url = this.configURL.bffAddlinkFilterDataV1;
    }
    return this.http.get(url);
  }

  public saveCommentsRequests(requestObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, requestObj.orgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkRequestRemarkEndPointV1;
    if (requestObj.orgId && requestObj.orgId !== 0) {
      return this.http.post(url + '&Schwab-Client-Ids=' + schwabClientHeader, requestObj);
    } else {
      return this.http.post(url, requestObj);
    }
  }

  public getRequestStatus(requestIds, intervalJobs) {
    this.commonService.pollRequestDetailsByRequestId(requestIds, this.getOrgId).subscribe((res) => {
      this.updateRequest.next(res.data.requestInfo);
      const status = this.sharedService.isInternalApp() ? res.data.requestInfo.internalStatus : res.data.requestInfo.status;
      if (status !== this.addlinkConstants.statusLabel.validating && status !== this.addlinkConstants.statusLabel.received) {
        clearInterval(intervalJobs[requestIds]);
      }
    }, error => {
      clearInterval(intervalJobs[requestIds]);
    });
  }
}
