<p-confirmDialog #cd key="globalTimeout" [closable]="false" autofocus="false" defaultButton="reject" class='emtr-warning-box global-timeout' *ngIf="showModal">
  <p-footer>
    <div class="sdps-p-top_small border-top-line">
      <button type="button" id="warning-accept" class="cursor button button-save sdps-p-vertical_xx-small sdps-p-horizontal_xx-small"
      pButton [label]=logOut (click)="logOutFn(); cd.accept()"></button>
      <button type="button" id="warning-reject" class="buttons button-default-cancel sdps-p-vertical_xx-small sdps-p-horizontal_xx-small"
      pButton [label]=stayLoggedIn (click)="stayLoggedInFn(); cd.reject()"></button>
    </div>
  </p-footer>
</p-confirmDialog>
