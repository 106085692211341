export interface AutoCompleteSecuritiesTableHead {
  field?: string;
  header?: string;
  order?: string;
}
export const searchSecuritiesTableHeads: AutoCompleteSecuritiesTableHead[] = [
  { field: 'tickerSymbol', header: 'Ticker Symbol', order: '1' },
  { field: 'name', header: 'Name', order: '2' },
  { field: 'cusip', header: 'CUSIP', order: '3' },
  { field: 'securityType', header: 'Security Type', order: '4' },
  { field: 'sedol', header: 'SEDOL', order: '5' },
  { field: 'isin', header: 'ISIN', order: '6' }
];
