<sdps-modal id='auth-agent-modal-id' [sdpsTitle]="title" [size]="'flex--small'">

  <div slot="modal-body">

    <sdps-notification type="error" class="sdps-m-top_none" *ngIf="authAgentForm.errors || (authAgentForm.get('authAgentFirstName').value && authAgentForm.get('authAgentFirstName').invalid)
                    || (authAgentForm.get('authAgentLastName').value && authAgentForm.get('authAgentLastName').invalid)">
      <div class="sdps-notification__text">
        <p>Please correct following error.</p>
        <ul class="sdps-navigation--horizontal__list error-list">
          <li *ngIf="authAgentForm.getError('errorMessage')" [innerHTML]="getConstant.authAgent.authAgentErrorMessage"
              class="sdps-text-l-body">
          </li>
          <li *ngIf="(authAgentForm.get('authAgentFirstName').value && authAgentForm.get('authAgentFirstName').invalid)
                    || (authAgentForm.get('authAgentLastName').value && authAgentForm.get('authAgentLastName').invalid)"
              [innerHTML]="getConstant.textFieldErrorMsg.invalidName">
          </li>
        </ul>
      </div>
    </sdps-notification>

    <form class="container" [formGroup]="authAgentForm">

      <div class="sdps-grid-fluid sdps-wrap">

        <div class="sdps-row sdps-m-vertical_large sdps-wrap">

          <div class="sdps-row">

            <div class="col-6">
              <div class="ems-input"
                   [ngClass]="{'invalidEmployee':(authAgentForm.get('authAgentFirstName').value && authAgentForm.get('authAgentFirstName').invalid)}">
                <span class="p-float-label">
                  <input type="text" formControlName="authAgentFirstName" restrictValidation emsNamePatternCheck emsCharacterLimit
                         [charMaxLimit]="commonConstants.charLimitValues.thirtyMaxLimit"
                         (emsCharacterLimitCheck)="showCharLimit = $event"  pInputText [maxlength]="30" />
                  <label>{{getConstant.authAgent.authAgentFirstName}}<em class="highlight-color">*</em></label>
                </span>
              </div>
            </div>

            <div class="col-6">
              <div class="ems-input"
                   [ngClass]="{'invalidEmployee':(authAgentForm.get('authAgentLastName').value && authAgentForm.get('authAgentLastName').invalid)}">
                <span class="p-float-label">
                  <input type="text" formControlName="authAgentLastName" restrictValidation emsNamePatternCheck emsCharacterLimit
                         [charMaxLimit]="commonConstants.charLimitValues.thirtyMaxLimit"
                         (emsCharacterLimitCheck)="showCharLimit = $event"  pInputText [maxlength]="30" />
                  <label>{{getConstant.authAgent.authAgentLastName}}<em class="highlight-color">*</em></label>
                </span>
              </div>
            </div>

          </div>

          <div class="sdps-grid-fluid"
               *ngIf="authAgentForm.get('authAgentFirstName').value && authAgentForm.get('authAgentFirstName').value.length >= showCharLimit || authAgentForm.get('authAgentLastName').value && authAgentForm.get('authAgentLastName').value.length >= showCharLimit">
            <div class="col-6">
              <p class="sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
                 *ngIf="authAgentForm.get('authAgentFirstName').value && authAgentForm.get('authAgentFirstName').value.length >= showCharLimit">
                {{getConstant.characterLimit}}
                <span class="character-limit">{{authAgentForm.get('authAgentFirstName').value.length}}/{{commonConstants.charLimitValues.thirtyMaxLimit}}</span>
              </p>
            </div>

            <div class="col-5 sdps-m-left_x-large">
              <p class="sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
                 *ngIf="authAgentForm.get('authAgentLastName').value && authAgentForm.get('authAgentLastName').value.length > showCharLimit">
                {{getConstant.characterLimit}}
                <span class="character-limit">{{authAgentForm.get('authAgentLastName').value.length}}/{{commonConstants.charLimitValues.thirtyMaxLimit}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </form>

  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="closeModal()">Cancel</button>
    <button type="button" class="buttons button-save" [disabled]="authAgentForm.invalid"
        (click)="addAuthAgent()">Save</button>
  </div>

</sdps-modal>
