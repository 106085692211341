import {Directive, ElementRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[emsK4MasterAccountPatterncheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: K4masterAccountPatterncheckDirective, multi: true}
  ]
})
export class K4masterAccountPatterncheckDirective {

  newExp: any;

  constructor(public el: ElementRef) {
  }

  validate() {
    this.newExp = new RegExp('^[1-9][0-9]*$');
    const elementVal = this.el.nativeElement.value;
    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
        emsNumberOnlyPattern: false
      };
    }
  }
}
