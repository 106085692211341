<div class="sdps-p-left_xx-large">

  <!-- Review Security Type details modal -->
  <tr-security-type #reviewSecurity [selectedValues]="multiLevelSecurityType" [reviewSecurity]="true"
                    [modalId]="'review-security-modal'"></tr-security-type>

  <!--  Preset Rules Header Section  -->
  <div class="preset-rule-tab" *ngIf="businessUnit === 'RBS'">
    <a class="sdps-font-weight-bold" (click)="togglePresetRule()">
      <em class='sdps-pull-right' [ngClass]="expandedPresetRule ? 'pi pi-caret-down' : 'pi pi-caret-right'"></em>
      {{emtrConstant.presetRules.presetRulesText}}
    </a>
  </div>

  <!--  Preset Rules Section  -->
  <div class="sdps-grid-container no-margin" *ngIf="businessUnit === 'RBS' && expandedPresetRule">
    <div class="col">
      <div class="sdps-grid-fluid sdps-wrap">
        <div class="col-6">
          <!--  Preset Rules 1st Section displaying first 5 preset rules values  -->
          <div class="emtr-radio sdps-m-around_none sdps-p-top_small" *ngFor="let item of presetRulesSet1">
            <p-radioButton class="sdps-p-left_none" name="Preset Rules" [value]=item.key [label]=item.name
                           [ngClass]="presetRuleInput === item.key ? 'sdps-font-weight-bold' : ''"
                           [(ngModel)]="presetRuleInput" (ngModelChange)="updatePresetRule()"></p-radioButton>
          </div>
        </div>
        <!--  Preset Rules 2nd Section displaying next 4 preset rules values  -->
        <div class="col-6">
          <div class="emtr-radio sdps-m-around_none sdps-p-top_small" *ngFor="let item of presetRulesSet2">
            <p-radioButton class="sdps-p-left_none" name="Preset Rules" [value]=item.key [label]=item.name
                           [ngClass]="presetRuleInput === item.key ? 'sdps-font-weight-bold' : ''"
                           [(ngModel)]="presetRuleInput" (ngModelChange)="updatePresetRule()"></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  Asset Group Header Section  -->
  <div [ngClass]="businessUnit === getSharedConstant.rbsBUText ? 'sdps-p-top_medium': ''">
    <div class="preset-rule-tab">
      <a class="sdps-font-weight-bold w-20-per display-inline-block" (click)="toggleAssetGroup()">
        {{ assetGrpLabel }}
      </a>
      <!-- Review Security Type Details -->
      <span>
        <sdps-icon class="info-icon vertical-align-middle" type="functional" name="info" size="small"></sdps-icon>
        <a class="ruleGrpLinks cursor" [title]="emtrConstant.presetRules.reviewSecTypeDetailsText"
        [innerHTML]="emtrConstant.presetRules.reviewSecTypeDetailsText" (click)="openSecurityType()"></a>
      </span>
      <em class='sdps-pull-right' (click)="toggleAssetGroup()" [ngClass]="expandedAssetGroup ? 'pi pi-caret-down' : 'pi pi-caret-right'"></em>
    </div>
  </div>

  <!--  Asset Group Table Section  -->
  <div class="sdps-grid-container no-margin" *ngIf="expandedAssetGroup">
    <div class="col">
      <div class="sdps-grid-fluid sdps-wrap">
        <div class="sdps-display-inline-block sdps-p-bottom_medium timePickerForm">
          <p-treeTable [value]="assetGroupsTreeData" [columns]="columnsLevel" [resizableColumns]="true" selectionMode="checkbox"
                       [(selection)]="selectedNodes" class="common-data-table asset-group-table" [ngClass]="{'asset-group-table-no-border' : globalRuleFlag}">
            <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
              <tr class="assetGrptr" *ngIf="isParentNode(rowNode) && !(rowNode.parent ? rowNode.parent.data.code.startsWith('g-') : rowNode.node.data.code.startsWith('g-'))">
                <td class="assetGrptd" *ngFor="let col of columns; let i = index" [ngClass]="i === 0 ? 'w-20-per' : ''">
                  <p-treeTableCheckbox class="emtr-checkbox" [value]="superParentNode" *ngIf="col.level === 'SC1'" (click)="setWarningMsg();parentNodeSelected($event)">
                  </p-treeTableCheckbox>
                  <p-treeTableCheckbox class="emtr-checkbox" [value]="childrenNode[i-1]" (click)="setWarningMsg();childNodeSelected($event)"
                                        *ngIf="col.level === 'SC2' && rowNode.parent && i-1<rowNode.parent.children.length && rowNode.parent.children[i-1].data.htmlType === 'checkbox'">
                  </p-treeTableCheckbox>
                  <span class="sdps-font-weight-bold word-break" *ngIf="col.level === 'SC1'">{{rowNode.parent && rowNode.parent.data.name ? rowNode.parent.data.name : rowNode.node.data.name}}</span>
                  <span class="sdps-m-left_large font-10 display-flex word-break line-height-nml" *ngIf="col.level === 'SC1' && (rowNode.parent && (rowNode.parent.data.name === 'Options'))">{{rowNode.parent.data.label}}</span>
                  <span
                    *ngIf="col.level === 'SC2' && rowNode.parent && i-1<rowNode.parent.children.length" class="word-break">{{childrenNode[i - 1].node.data.name}}</span>
                  <p-dropdown *ngIf="col.level === 'SC2' && rowNode.parent && i-1<rowNode.parent.children.length && rowNode.parent.children[i-1].data.htmlType === 'dropdown'" [options]="mutualFundsDropdownValue"
                              placeholder="Select" optionLabel="name" appendTo="body" [(ngModel)]="selectedAction" (onChange)="setWarningMsg();childNodeSelected($event)"
                              class="sdps-p-left_small editableTimepicker mutual-fund-asset-group-dropdown display-block expense-ratio">
                  </p-dropdown>
                </td>
              </tr>
            </ng-template>
          </p-treeTable>
        </div>
      </div>
    </div>
  </div>

  <div class="misc-asset-group-color" *ngIf="globalRuleFlag && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid,'','')">
    <form [formGroup]="miscellaneousAssetGrpForm">
      <!--  Miscellaneous Asset Group Header Section  -->
      <div class="sdps-p-top_medium">
        <div class="preset-rule-tab">
          <a class="sdps-font-weight-bold w-20-per display-inline-block" (click)="toggleMiscellaneousAssetGroup()">
            {{emtrConstant.presetRules.miscellaneousAssetGroupText}}
          </a>
          <!-- Review Security Type Details -->
          <span>
            <sdps-icon class="info-icon vertical-align-middle" type="functional" name="info" size="small" ></sdps-icon>
            <a class="ruleGrpLinks cursor" [title]="emtrConstant.presetRules.reviewSecTypeDetailsText"
               [innerHTML]="emtrConstant.presetRules.reviewSecTypeDetailsText" (click)="openSecurityType()"></a>
          </span>
             <em class='sdps-pull-right' (click)="toggleMiscellaneousAssetGroup()" [ngClass]="expandedMiscAssetGroup ? 'pi pi-caret-down' : 'pi pi-caret-right'"></em>
        </div>
      </div>

      <!--  Miscellaneous Asset Group value Section  -->
      <div class="sdps-grid-container sdps-m-top_small" *ngIf="expandedMiscAssetGroup">
        <div class="col" *ngFor="let rowNode of miscAssetGroups; let i = index;" [ngClass]="i === 0 ? 'w-20-per' : ''">
          <div class="sdps-grid-fluid sdps-wrap">
            <div class="sdps-m-top_xx-small">
              <div class="sdps-display-inline-block sdps-p-bottom_medium">
                <p-checkbox class="emtr-checkbox sdps-font-weight-bold" [formControlName]="rowNode.name"
                            *ngIf="(rowNode.name !== 'Debt')" [label]="rowNode.name" [value]="rowNode.name"
                            (click)="miscellaneousAssetGroupSelected()"></p-checkbox>
                <p-checkbox class="emtr-checkbox sdps-font-weight-bold" [formControlName]="rowNode.name"
                            *ngIf="(rowNode.name === 'Debt')" [label]="debtAssGrpName" [value]="rowNode.name"
                            (click)="miscellaneousAssetGroupSelected()"></p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
