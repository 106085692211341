import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EmpGroupFormDataService} from '../emp-group-form-data/emp-group-form-data.service';
import {ManageEmpGrpService, SharedConstant, SharedService} from '@ems/shared';
import {emtrConstant} from '../../../constants/emtrConstants';
import {EmtrService} from '../../../emtr.service';

@Component({
  selector: 'tr-step1-enter-group-details',
  templateUrl: './step1-enter-group-details.component.html'
})
export class Step1EnterGroupDetailsComponent implements OnInit {

  public draftRuleName: string;
  public draftRuleId: any;

  createEmpGrpRuleForm: UntypedFormGroup;
  getConstant = emtrConstant;
  getSharedConstant = SharedConstant;
  groupNameExists = false;
  orgId: any;
  message: string;
  cancelButton = true;
  confirmMessage: any;
  showGroupNameCharLimit: boolean;
  showGroupDescCharLimit: boolean;
  errorMsg = [];
  empGroupNameExistsErrMsg = '';
  is10b5Client = false;
  planIDTxt = 'planId';
  isEditMode = false;
  prevMessage: string;
  showInvalidError = '';
  businessUnit: any;

  @ViewChild('focusName', { static: true }) inputEl: ElementRef;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;

  constructor(private router: Router, private fb: UntypedFormBuilder,
              private manageEmpService: ManageEmpGrpService, public formDataService: EmpGroupFormDataService, private r: ActivatedRoute,
              private cd: ChangeDetectorRef, @Inject('commonConstants') public commonConstants, public emtrService: EmtrService,
              private manageEmpGrpService: ManageEmpGrpService, public sharedService: SharedService) {
    this.manageEmpService.clearMessage();
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      this.businessUnit = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.getSharedConstant.manageMasterGroups.groupTypeMGP : clientInfo.businessUnit;
      this.sharedService.businessUnit = clientInfo.businessUnit;
    });
  }

  ngOnInit() {
    let enable10b5Checkbox;
    const formData = this.formDataService.getFormData();
    if (formData.mode === 'EDIT') {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
    this.draftRuleName = formData.name;
    this.draftRuleId = formData.id;
    if (formData.planId) {
      enable10b5Checkbox = true;
    } else {
      enable10b5Checkbox = false;
    }
    this.createEmpGrpRuleForm = this.fb.group({
      groupName: [formData.name, {validators: [Validators.required]}],
      description: [formData.description],
      enable10b5Checkbox: [enable10b5Checkbox],
      planId: [formData.planId]
    });
    this.is10b5Client = this.sharedService.get10b5ClientDetails();
    this.onChanges();
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    // this.inputEl.nativeElement.focus();
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.getConstant.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.cancelButton && (this.createEmpGrpRuleForm.dirty || this.createEmpGrpRuleForm.get('groupName').value)) {
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  onChanges(): void {
    this.createEmpGrpRuleForm.get('groupName').valueChanges.subscribe((groupNameValue) => {
      this.groupNameExists = false;
      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (groupNameValue && !regExp.test(groupNameValue) && this.businessUnit !== 'RBS' && this.businessUnit !== this.getSharedConstant.manageMasterGroups.groupTypeMGP) {
        if (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName) === -1) {
          this.errorMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName);
        }
        this.emtrService.showSchError.emit(true);
      } else {
        if (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName), 1);
        }
      }

      /*To remove group name already exist error msg from the errorMsg array*/
      if (this.errorMsg.indexOf(this.empGroupNameExistsErrMsg) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.empGroupNameExistsErrMsg), 1);
      }

      /* Removing groupName existed error if new value is entered */
      if ((this.showInvalidError === 'emp-grp') && this.errorMsg.length > 0 && (this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt) !== -1) && this.is10b5Client && !this.createEmpGrpRuleForm.get('groupName').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt), 1);
        this.showInvalidError = '';
      }

      /* Removing both(groupName & planId) existed error if new value is entered  in both fields*/
      if ((this.showInvalidError === 'both') && this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId) !== -1 && this.is10b5Client && !this.createEmpGrpRuleForm.get('planId').invalid && !this.createEmpGrpRuleForm.get('groupName').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId), 1);
        this.showInvalidError = '';
      }

      /*Hide the error block when errorMsg is empty*/
      if (this.errorMsg.length === 0) {
        this.emtrService.showSchError.emit(false);
      }

      this.emtrService.schErrorMsg.emit(this.errorMsg);
    });
    this.createEmpGrpRuleForm.get('planId').valueChanges.subscribe((value) => {
      if (value && this.createEmpGrpRuleForm.get('planId').invalid && this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered) === -1) {
        this.errorMsg.push(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered);
      } else {
        if ((value === '' && this.showInvalidError !== 'emp-grp') || (this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered) !== -1 &&
          !this.createEmpGrpRuleForm.get('planId').invalid)) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.getSharedConstant.textFieldErrorMsg.invalidNumericCharacterEntered), 1);
          if (this.showInvalidError === 'planId' || this.showInvalidError === 'both') {
            const planIdTxt = 'planId';
            this.createEmpGrpRuleForm.controls[planIdTxt].setErrors({incorrect: false});
          }
        }
      }

      /* Removing groupName existed error if new value is entered */
      if (this.showInvalidError === 'planId' && this.errorMsg.length > 0 && this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdExitsTxt) !== -1 && this.is10b5Client && !this.createEmpGrpRuleForm.get('planId').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.planIdExitsTxt), 1);
        this.showInvalidError = '';
      }

      /* Removing both(groupName & planId) existed error if new value is entered  in both fields*/
      if (this.showInvalidError === 'both' && this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId) !== -1 && this.is10b5Client && !this.createEmpGrpRuleForm.get('groupName').invalid && !this.createEmpGrpRuleForm.get('planId').invalid) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.getConstant.manageEmployeeGroups.empGroup10b5Msg.bothNameAndPlanId), 1);
        this.showInvalidError = '';
      }

      this.emtrService.schErrorMsg.emit(this.errorMsg);
      if (this.errorMsg.length === 0) {
        this.emtrService.showSchError.emit(false);
      } else {
        this.emtrService.showSchError.emit(true);
      }
    });
  }

  goToNextStep() {
    this.cancelButton = false;
    const formObj = {
      groupName: this.createEmpGrpRuleForm.get('groupName').value.trim(),
      description: this.createEmpGrpRuleForm.get('description').value,
      planId: this.createEmpGrpRuleForm.get('planId').value
    };
    if (this.is10b5Client) {
      this.validatePlanId(formObj);
    } else {
      this.checkWhetherEmpGroupNameExists(formObj);
    }
  }

  checkWhetherEmpGroupNameExists(ruleObj) {
    this.orgId = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? 0 : this.manageEmpService.orgId;
    const formData = this.formDataService.getFormData();
    if (formData.mode === 'EDIT' && this.createEmpGrpRuleForm.get('groupName').value.trim() === formData.currentEditedName) {
      this.navigateTo(ruleObj);
    } else {
      const type = this.sharedService.groupType === this.getSharedConstant.manageMasterGroups.groupTypeMGP ? this.sharedService.groupType : '';
      const planIdValue = (this.createEmpGrpRuleForm.get('planId').value) ? this.createEmpGrpRuleForm.get('planId').value : '';
      this.manageEmpService.checkEmployeeGroupNameExists(ruleObj.groupName.trim(), this.orgId, planIdValue, type).subscribe(res => {
        this.sharedService.decodeUrl = true;
        if (res && res.data !== null) {
          /* eslint-disable-next-line max-len */
          this.empGroupNameExistsErrMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' "' + this.createEmpGrpRuleForm.get('groupName').value.trim() + '" ' + '</strong>' + this.sharedService.validateNamePlanIdResponse(res, this.is10b5Client, this.createEmpGrpRuleForm.get('groupName').value, planIdValue, this.getConstant, 'EmpGrp');
          this.groupNameExists = true;
          this.cancelButton = true;

          /*To show group name already exist error msg*/
          if (this.errorMsg.indexOf(this.empGroupNameExistsErrMsg) === -1) {
            this.errorMsg.push(this.empGroupNameExistsErrMsg);
          }
          this.emtrService.schErrorMsg.emit(this.errorMsg);
          this.emtrService.showSchError.emit(true);

        } else {
          this.navigateTo(ruleObj);
        }
      });
    }
  }

  validatePlanId(ruleObj) {
    let planId;
    if (this.isEditMode) {
      planId = '';
    } else {
      planId = this.createEmpGrpRuleForm.get('planId').value;
    }
    const formData = this.formDataService.getFormData();
    if (formData.mode === 'EDIT' && this.createEmpGrpRuleForm.get('groupName').value.trim() === formData.currentEditedName) {
      this.navigateTo(ruleObj);
    } else {
      this.manageEmpGrpService.validatePlanId(this.manageEmpService.orgId, this.createEmpGrpRuleForm.get('groupName').value.trim(), planId).subscribe(res => {
        this.sharedService.decodeUrl = true;
        /* eslint-disable-next-line max-len */
        const validate10b5 = this.emtrService.nameAndPlanIdValidation(this.prevMessage, this.sharedService.setResponseMessage(res, this.is10b5Client, this.createEmpGrpRuleForm.get('groupName').value.trim(), planId, this.getConstant, 'EmpGrp') , this.errorMsg, this.createEmpGrpRuleForm, this.getConstant);
        this.showInvalidError = validate10b5;
        if (validate10b5 === 'emp-grp') {
          this.empGroupNameExistsErrMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong>' + ' "' + this.createEmpGrpRuleForm.get('groupName').value.trim() + '" ' + '</strong>' + this.getConstant.manageEmployeeGroups.empGroup10b5Msg.empGrpExistTxt;
        }
        if (validate10b5 === '') {
          this.navigateTo(ruleObj);
        }
      });
    }
  }

  navigateTo(steps) {
    const ruleObj = {
      groupName: steps.groupName.trim(),
      description: steps.description,
      orgId: this.manageEmpService.orgId,
    };
    /* Adding 'planId' in form only if selected client is a '10b5' Client */
    if (this.is10b5Client) {
      ruleObj[this.planIDTxt] = this.createEmpGrpRuleForm.get('planId').value;
    }
    this.formDataService.setStep1(ruleObj, true);
    this.router.navigate(['../step2'], {relativeTo: this.r});
  }

  cancelCreateEmpGroup() {
    this.confirmMessage.message = this.getConstant.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate([this.getSharedConstant[this.businessUnit].toLandingPage]);
  }

  /* If '10b5' checkbox is checked/unchecked adding planId Validators */
  selected10b5() {
    this.sharedService.set10b5Validators(this.createEmpGrpRuleForm, this.planIDTxt);
  }

  removeLeadingZeroes() {
    const value = this.createEmpGrpRuleForm.getRawValue().planId ? this.createEmpGrpRuleForm.getRawValue().planId.toString().replace(/^0+/, '') : '';
    this.createEmpGrpRuleForm.get('planId').setValue(value);
    if (value === '0') {
      this.createEmpGrpRuleForm.get('planId').reset();
    }
    this.selected10b5();
  }
}
