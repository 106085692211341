import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {WorkflowService} from '@ems/shared';
import {FormDataService, SharedService, SharedConstant} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';
import {RuleService} from '../../services/rule-service/rule.service';

@Component({
  selector: 'tr-edit-rule',
  templateUrl: './edit-rule.component.html'
})
export class EditRuleComponent implements OnInit, OnDestroy {
  items: any;
  editRuleData: any;
  emtrConst = emtrConstant;
  schErrorMsg: string[];
  showSchError = false;
  showRequestError = false;
  toastMsg: string;
  toastType: string;
  sharedConstant = SharedConstant;
  @ViewChild('toastMessage') msgToast: any;

  constructor(private workflowService: WorkflowService, private formDataService: FormDataService, public sharedService: SharedService, public ruleService: RuleService) {
    this.sharedService.clearErrorMessage();
  }

  ngOnInit() {
    this.sharedService.disableClientSelector.next(true);
    this.editRuleData = this.formDataService.getFormData();
    this.items = this.workflowService.getWorkflowSteps('editRule');
    if (this.editRuleData.mode === this.emtrConst.rulesConstant.ruleGlobalConst.restriction) {
      this.items[1].name = this.emtrConst.ruleSteps.step2.step2Restriction;
    } else if (this.editRuleData.mode === this.emtrConst.rulesConstant.ruleGlobalConst.override) {
      this.items[1].name = this.emtrConst.ruleSteps.step2.step2Override;
    } else {
      this.items[1].name = this.emtrConst.ruleSteps.step2.step2Recurrence;
    }

    this.ruleService.schErrorMsg.subscribe(value => {
      this.schErrorMsg = value;
    });

    this.ruleService.showSchError.subscribe(value => {
      this.showSchError = value;
    });

    this.ruleService.showRequestIdError.subscribe(value => {
      this.showRequestError = value;
    });

    this.ruleService.toastType.subscribe(value => {
      this.toastType = value ? value : '';
    });

    this.ruleService.toastMsg.subscribe(value => {
      this.toastMsg = value ? value : '';
      if (this.toastMsg && this.toastMsg !== undefined) {
        setTimeout(() => {
          this.msgToast.pushMessage(this.toastType, this.toastMsg);
        }, 1);
      }
    });
  }

  ngOnDestroy() {
    this.sharedService.disableClientSelector.next(this.sharedService.ClientCount === this.sharedConstant.clientCount);
  }
}
