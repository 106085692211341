<ems-toast-message *ngIf="removeSuccessMsg" #toastMessage></ems-toast-message>
<ems-confirm-message #masGrpDeleteConfirm></ems-confirm-message>
<!-- error validation message -->
<sdps-notification type="error" class="sdps-m-top_none" *ngIf="schError.minCharacter === true || schError.invalidCharacter === true">
  <div class="sdps-notification__text">
    <p [innerText]="sharedConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="schError.minCharacter" [innerHTML]="sharedConstant.textFieldErrorMsg.minCharLimitErrorMsg"></li>
      <li *ngIf="schError.invalidCharacter" [innerHTML]="sharedConstant.textFieldErrorMsg.invalidCharacterForGrpOrRuleName"></li>
    </ul>
  </div>
</sdps-notification>

<div>
  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
      [innerHTML]="getEmtrConstants.manageMasterGroups.masterGroupText"></h2>
  <span class="sdps-m-horizontal_large sdps-display-inline-block client-selection-indicator"
        [innerHTML]="(emtrService.clientInformation | async)?.k4ClientName">
  </span>
</div>

<div class="tbl-rec-count">
  <button *ngIf="showActionItems" type="button" class="buttons button-save sdps-pull-left sdps-m-top_small sdps-m-left_small"
          [routerLink]="sharedConstant[this.sharedConstant.manageMasterGroups.groupTypeMGP].toStep1" (click)="navigateToCreateMstrGroup()">
    <em class="pi pi-plus"></em>
    {{getEmtrConstants.manageMasterGroups.masterGroupList.addNewMasterGroup}}
  </button>

  <ems-shared-master-groups [masterAcctList]="masterGroups" (paginateMasterGrpList)="paginateMasterGrpList($event)"
                            (deleteMasterGroup)="fetchRuleNamesLinkedToMasGroup($event)" [tableCountData]="tableCountData"
                            (restoreMasterGroup)="restoreMasterGroup($event)" [isStateManagementRequired]="true"
                            (sendError)="setErrorValue($event)" (masterGrpEdit)="getMasterGroupData($event)">
  </ems-shared-master-groups>

</div>
