<ems-confirm-message #confirmationMessage></ems-confirm-message>

<tr-security-type #reviewSecurity [selectedValues]="multiLevelSecurityType" [reviewSecurity]="true"
                  [modalId]="'review-security-modal'"></tr-security-type>

<div class="review-heading sdps-p-bottom_small">{{emtrConst.ruleSteps.step4.reviewRule}}</div>

<div class="review-rules">
  <form [formGroup]="reviewRuleForm" *ngIf="reviewRuleForm">
    <!-- Display Rule Name and selected Rule Type -->
    <div class="sdps-p-bottom_large select-rule-type step-border-box">
      <div class="sdps-grid-container sdps-wrap">
        <div class="col-5 sdps-p-top_large sdps-p-left_none">
          <div class="rule-type-textbox sdps-p-around_medium"
               [ngClass]="{'invalidField': (reviewRuleForm.get('reviewRuleName').value && reviewRuleForm.get('reviewRuleName').invalid), 'input-disabled': (reviewRuleData.status === 'EXPIRED' ||  !showActionItems || !showActionItemForGRule)}">
                        <span class="p-float-label">
                            <input type="text" name="ruleName" [maxlength]=100 formControlName="reviewRuleName"
                                   (ngModelChange)="editRuleNameDescriptionNotes()" emsCharacterLimit
                                   value="reviewRuleForm.get('reviewRuleName').value" emsAlphaNumericSpecialCharacterValidation
                                   [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                                   (emsCharacterLimitCheck)="showCharLimit = $event"
                                   pInputText/>
                            <label>
                              {{emtrConst.rulesConstant.ruleName}}
                              <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
                            </label>
                        </span>
          </div>
          <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
             *ngIf="reviewRuleForm.get('reviewRuleName').value.trim().length > showCharLimit">{{emtrConst.rulesConstant.characterLimit}}
            <span
              class="character-limit">{{reviewRuleForm.get('reviewRuleName').value.trim().length}}/{{commonConstants.charLimitValues.hundredMaxLimit}}</span>
          </p>
        </div>
        <div [ngClass]="(reviewRuleData.ruleSubCategory === emtrConst.rule10b5Msg.label10b5Txt) ? 'col-2' : 'col-2'">
          <div class="sdps-p-top_x-large sdps-p-left_x-large">{{emtrConst.rulesConstant.ruleType}}</div>
          <div class="sdps-p-left_x-large sdps-text-l-bold-body">
            <span>{{(rulesCategory === 'RESTRICT_LST_ENG') ? emtrConst.rulesConstant.ruleGlobalConst.restriction : (rulesCategory === 'Override') ? emtrConst.rulesConstant.ruleGlobalConst.override: emtrConst.rulesConstant.ruleGlobalConst.watchList}}</span>
          </div>
        </div>

        <div class="col-2" >
          <div  *ngIf="isPlanIdVisible">
          <div class="sdps-p-top_x-large sdps-p-left_x-large">{{emtrConst.rule10b5Msg.restrictionCategoryTxt}}</div>
          <div class="sdps-p-left_x-large sdps-text-l-bold-body">
            <span>{{emtrConst.rule10b5Msg.label10b5Txt}}</span>
          </div>
        </div>
        </div>
        <div class="col-2">
          <div  *ngIf="isPlanIdVisible">
          <div class="sdps-p-top_x-large sdps-p-left_x-large">{{emtrConst.rule10b5Msg.planIdTxt}}</div>
          <div class="sdps-p-left_x-large sdps-text-l-bold-body">
            <span>{{reviewRuleData.planId}}</span>
          </div>
        </div>
        </div>
     
        <div class="sdps-p-top_x-large sdps-pull-right col-1 sdps-p-right_none" *ngIf="reviewRuleData.status !== 'EXPIRED'">

          <button type="button" *ngIf="showActionItems && showActionItemForGRule"
                  class="sdps-pull-right sdps-button sdps-button--secondary sdps-is-selected disabled-edit-button"
                  disabled>
            <em class="pi pi-pencil" pTooltip="{{emtrConst.ruleSteps.step4.ruleTypeTooltip}}"
                tooltipPosition="left"></em>
          </button>
        </div>
      </div>
    </div>

    <!-- Display selected Justification for Override -->
    <ems-accordion [label]="emtrConst.rulesConstant.ruleGlobalConst.justification" class="disabled-button-icon"
                   [disabledButton]="true"
                   [editable]="showActionItems && showActionItemForGRule"
                   [toolTip]="true" [message]="overrideTooltip" [showEditIcon]="reviewRuleData.status"
                   *ngIf="reviewRuleData.override === 'Y'">
      <div class="emtr-radio sdps-grid-container sdps-p-left_large sdps-m-around_none sdps-p-vertical_small select-rule-type"
           *ngIf="reviewRuleData.override === 'Y'">
        <div class="sdps-grid sdps-m-around_none sdps-p-left_none" *ngFor="let justification of sharedConstant[businessUnit].overrideJustification">
          <p-radioButton class="col-2 sdps-p-top_small radio-button" name="justification"[value]=justification [label]=justification
                         formControlName="justification"
                         (ngModelChange)="editRuleNameDescriptionNotes()"></p-radioButton>
        </div>
        <span class="p-float-label rule-type-textbox sdps-p-around_small col-4 other-input"
              [ngClass]="((reviewRuleForm.get('justification').value !== 'Other' || reviewRuleData.status ==='EXPIRED' || !showActionItems || !showActionItemForGRule) ? 'input-disabled' : 'input-enabled')">
            <input type="text" name="others" maxlength="35" formControlName="otherInput"
                   (ngModelChange)="editRuleNameDescriptionNotes()" pInputText #step4_otherInput/>
          </span>
      </div>
    </ems-accordion>

    <!-- Display selected Rule Attributes -->
    <ems-accordion
      [label]="emtrConst.rulesConstant.ruleTypeLabels.ruleAttributes"
      [editable]="showActionItems && showActionItemForGRule" (editPanel)="goToPreviousStep('../step2')" [disabledButton]="!(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))"
      [ngClass]="{'disabled-button-icon' : !(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))}" [showEditIcon]="reviewRuleData.status">

      <p class="sdps-p-horizontal_xx-large no-margin"
         [innerHTML]="(reviewRuleData.ruleType === emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? emtrConst.rulesConstant.ruleTypeLabels[rulesCategory]+ ' ' + emtrConst.rulesConstant.ruleTypeLabels.ruleTypeFundFamilyTitle : emtrConst.rulesConstant.ruleTypeLabels[rulesCategory]+ ' ' + emtrConst.rulesConstant.ruleTypeLabels.ruleTypeTitle"></p>

      <tr-restrict-checkbox class="emtr-checkbox-disable" [rulesCategory]="rulesCategory" [ruleType]="reviewRuleData.ruleType"
                            [restrictForm]="reviewRuleForm.get('restrictForm')"></tr-restrict-checkbox>
    </ems-accordion>

    <!--Display rule subtype IND SEC-->
    <ems-accordion [label]="subTypeLabel" [editable]="showActionItems && showActionItemForGRule"
                   (editPanel)="goToPreviousStep('../step2')" *ngIf='reviewRuleData.ruleType !== "SECTYP"'
                   [disabledButton]="!(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))"
                   [ngClass]="{'disabled-button-icon' : !(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))}"
                   [showEditIcon]="reviewRuleData.status">
      <div *ngIf='reviewRuleData.ruleType !== emtrConst.rulesConstant.autoComplete.FNDISUTxt'>
        <div class="sdps-p-top_small sdps-p-bottom_xx-small sdps-p-horizontal_xx-large sdps-text-l-bold-body">
          <span [innerHTML]="emtrConst.rulesConstant.appliesTo"></span>
          <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
        </div>

        <!--Display Applies To-->
        <div class="sdps-p-horizontal_xx-large">
          <p-checkbox class="emtr-checkbox emtr-checkbox-disable" *ngIf="appliesTo === 'BTH' || appliesTo === 'IND'"
                      binary="true"
                      formControlName="indSecs" label="{{emtrConst.rulesConstant.individualSecurity}}"></p-checkbox>
          <span *ngIf="appliesTo === 'BTH'">&nbsp;&nbsp;</span>
          <p-checkbox *ngIf="appliesTo === 'BTH' || appliesTo === 'OPT'" class="emtr-checkbox emtr-checkbox-disable"
                      binary="true"
                      formControlName="underlyingOpts"
                      label="{{emtrConst.rulesConstant.underlyingOptions}}"></p-checkbox>
        </div>

        <!--Display Security Type -->
        <div class="sdps-p-top_small sdps-p-bottom_xx-small sdps-p-horizontal_xx-large sdps-text-l-bold-body" *ngIf="reviewRuleData.securityType.length > 0">
          {{emtrConst.rulesConstant.securityType}}
        </div>
        <div class="sdps-p-bottom_small sdps-p-horizontal_xx-large">
          <p-checkbox class="emtr-checkbox emtr-checkbox-disable sdps-p-right_medium "
                      *ngFor="let security of securityType;let i = index" [value]="security.code"
                      [(ngModel)]="securityType[i].code" [ngModelOptions]="{standalone: true}"
                      [label]="security.code" disabled></p-checkbox>
        </div>
      </div>

      <!-- Display selected Securities -->
      <ems-table-record-count [count]="indSecs && indSecs.length" [showRecords]="indSecs && indSecs.length && indSecs.length > 15"
                              (emitter)="indTable.reset();receiveDataFromChild($event, 'indSecs')"
                              [showHeaderIcons]="false"></ems-table-record-count>
      <p-table [columns]="securityTableHeader" [value]="indSecs" [paginator]="(indSecs && indSecs.length && indSecs.length > 15) ? true : false" [rows]="noOfRecordsIndSecs" #indTable
               class="common-data-table" [ngClass]="{ 'display-inline-block mb-35': indSecs && indSecs.length && indSecs.length <= 15}">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">{{col.header}}</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length" class="sdps-text-center"
                [innerHTML]="emtrConst.rulesConstant.autoComplete.noSecuritySelected"></td>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-indSec let-columns="columns">
          <tr>
            <td *ngFor="let col of columns">
              <span *ngIf="col.field === 'name'">{{indSec[col.field] | titlecase}}</span>
              <span *ngIf="col.field !== 'name'">{{indSec[col.field]}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- Display selected Instruments Groups -->
    <div
    *ngIf="reviewRuleData.ruleType === 'INDSEC' && rulesCategory !== emtrConst.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText && (this.viewIG || sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', ''))">
    <ems-table-record-count [count]="instGroup && instGroup.length" [showRecords]="instGroup && instGroup.length && instGroup.length > 15"
      (emitter)="indTable.reset();receiveDataFromChild($event, 'instGroup')" [showHeaderIcons]="false"></ems-table-record-count>
    <p-table [columns]="instGrpTableHeader" [value]="instGroup" [paginator]="(instGroup && instGroup.length && instGroup.length > 15) ? true : false" [rows]="noOfRecordsInstGroup" #indTable
      class="common-data-table" [ngClass]="{ 'mb-35': instGroup && instGroup.length && instGroup.length <= 15}">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{col.header}}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length" class="sdps-text-center"
            [innerHTML]="emtrConst.rulesConstant.autoComplete.noInstrumentGroupSelected"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-instGrp let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <span *ngIf="col.field !== 'tickerSymbols'" [title]="instGrp[col.field]">{{instGrp[col.field]}}</span>
            <span *ngIf="col.field === 'tickerSymbols'">
              <span class="ruleGrpLinks cursor" (click)="child.openModal(instGrp)"
                    [title]="emtrConst.rulesConstant.ruleGlobalConst.viewSymboleInstGrpText"
                    [innerHTML]="emtrConst.rulesConstant.ruleGlobalConst.viewSymboleInstGrpText"></span>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    </div>
      <tr-view-symbols-modal #child></tr-view-symbols-modal>
    </ems-accordion>

    <!--Display Rule subType Securtity Data-->
    <ems-accordion [label]="subTypeLabel" *ngIf='reviewRuleData.ruleType === "SECTYP"' [editable]="showActionItems && showActionItemForGRule"
                   (editPanel)="goToPreviousStep('../step2')" [disabledButton]="!(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))"
                   [ngClass]="{'disabled-button-icon' : !(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))}"
                   [showEditIcon]="reviewRuleData.status">
      <div *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')">
      <div class="security-type-holder">
        <div class="security-selected-result">
          <div class="sdps-p-top_medium sdps-datepicker__range" *ngFor='let item of multiLevelUpdatedList | keyvalue '>
            <div class="security-type-text">
              <span class="sch-check-circle security-highlight"></span>
              <span class="sdps-p-left_medium security-level-text">{{item.key}}</span>
            </div>
            <div class="whole-container">
              <div *ngFor="let first of item.value | slice:0;">
                                <span *ngIf="first.data.level === 'SC1'">Level 1 {{first.data.desc}}
                                  ({{first.data.code}})
                                    <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                                </span>
                <span *ngIf="first.data.level === 'SC2'">Level 2 {{first.data.desc}} ({{first.data.code}})
                                    <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                                </span>
                <span *ngIf="first.data.level === 'SC3'">Level 3 {{first.data.desc}} ({{first.data.code}})
                                    <span *ngIf="first.data.selected">({{first.data.selected}})</span>
                                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <!-- Review Security Type button Section - for Asset group or Miscellaneous asset grp selection -->
      <div *ngIf="displaySelectedAssetGrp && displaySelectedAssetGrp.length > 0" class="security-type-holder">
        <div class="choose-security-box" (click)="openSecurityType()">
          <div class="security-box-content pi pi-list"></div>
          <span class="sdps-p-left_x-small">{{emtrConst.presetRules.reviewSecTypeDetailsText}}</span>
        </div>
      </div>

      <!-- Preset Rules and Asset Groups Section -->
      <div
        *ngIf="reviewRuleData.rulesCategory !== emtrConst.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText && (!reviewRuleData.micAGSelectedFlag)">
        <div class="security-type-holder">
          <div class="security-selected-result">
            <div class="sdps-p-top_medium sdps-p-bottom_medium sdps-datepicker__range" *ngFor='let item of displaySelectedAssetGrp'>
              <span class="security-type-text">
                <span class="assetGroup-highlight" [ngClass]="businessUnit === 'RBS' || businessUnit === 'MGP' ? item['attributes'].length > 0 ? 'sch-minus-square-large font-21' : 'sch-check-circle' : 'sch-check-circle'"></span>
                <span class="sdps-p-left_medium security-level-text">{{item['name']}}</span>
              </span>
              <div class="whole-container display-flex">
                <div *ngFor="let first of item['attributes']">
                  <span class="sch-check-circle assetGroup-highlight sdps-p-right_medium"></span>
                  <span class="sdps-p-right_medium">{{first['name']}}</span>
                  <span class="sdps-p-right_medium" *ngIf="first['name'] === emtrConst.ruleSteps.step2.operatingNetExpenseRatio">{{first['value']}}</span>
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Miscellaneous Asset Groups Section -->
      <div
        *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.globalRuleSummary.uuid,'','') && (reviewRuleData.rulesCategory === emtrConst.rulesConstant.autoComplete.RESTRICT_LST_ENGTxt || reviewRuleData.override === 'Y') && (reviewRuleData.micAGSelectedFlag) ">
        <div class="security-type-holder">
          <div class="security-selected-result">
            <div class="sdps-p-top_medium sdps-p-bottom_medium sdps-p-right_x-large sdps-display-inline-block"
                 *ngFor='let item of displaySelectedAssetGrp'>
                <span class="sch-check-circle assetGroup-highlight sdps-p-right_medium"></span>
                <span class="misc-asset-group-color" *ngIf="item.name !== 'Debt'">{{item['name']}}</span>
                <span class="misc-asset-group-color" *ngIf="item.name === 'Debt'">{{item['attributes'][0]['name']}}</span>
            </div>
          </div>
        </div>
      </div>
    </ems-accordion>

    <!-- Display selected From and To Date -->
    <ems-accordion label={{emtrConst.rulesConstant.selectDates}} (editPanel)="goToPreviousStep('../step2')" [editable]="showActionItems && showActionItemForGRule"
                   [disabledButton]="!(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))" [ngClass]="{'disabled-button-icon' : !(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))}"
                   [showEditIcon]="reviewRuleData.status">
      <div class="display-flex emtr-radio select-date">
        <p-radioButton name="selectDate" disabled [(ngModel)]="dateSelectionType"
                       [ngModelOptions]="{standalone: true}" value="date"
                       class="sdps-p-top_small p-radio">
        </p-radioButton>
        <tr-from-to-datepicker
          [calendarForm]="reviewRuleForm.get('calendarForm')"></tr-from-to-datepicker>
      </div>
    </ems-accordion>

    <!-- Display Description and Note -->
    <div class="security-description-container">
      <ems-accordion label={{emtrConst.rulesConstant.descriptionNotesTitle}} [editable]="showActionItems && showActionItemForGRule"
                     class="disabled-button-icon"
                     [disabledButton]="true" [toolTip]="true" [message]="toolTipMessage" [showEditIcon]="reviewRuleData.status">
        <tr-description-and-notes class="description-notes" (editTextArea)="editRuleNameDescriptionNotes()"
                                  [descriptionForm]="reviewRuleForm.get('descriptionForm')" [isRuleExpired]="reviewRuleData.status"></tr-description-and-notes>
      </ems-accordion>
    </div>

    <!-- Display selected Participants/Employees, Plans/Emp Group, Master Accounts and Master Groups -->
    <ems-accordion label={{emtrConst.rulesConstant.ruleAssign}} [editable]="showActionItems && showActionItemForGRule"
                   [ngClass]="{'disabled-button-icon' : !(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))}" [disabledButton]="!(reviewRuleForm.get('reviewRuleName').value.trim()) || (disableEditIcon && !(disableBtn && disableEditIcon))"
                   (editPanel)="goToPreviousStep('../step3')" [showEditIcon]="reviewRuleData.status">

      <p-tabView class="emtr-tab" [activeIndex]="activeIndex">
        <!-- Employee Group/Plans/Master Groups tab -->
        <p-tabPanel [header]=sharedConstant[businessUnit].employeeGrpOrPlan [disabled]="disableEmployeeGroupPlan">
          <ems-table-record-count [count]="employeeGroups && employeeGroups.length" [showRecords]="employeeGroups && employeeGroups.length && employeeGroups.length > 15"
                                  (emitter)="dt.reset();receiveDataFromChild($event, 'employeeGroups')" [showHeaderIcons]="false">
          </ems-table-record-count>
          <p-table [columns]="employeeGroupTableHeader" [value]="employeeGroups" [paginator]="(employeeGroups && employeeGroups.length && employeeGroups.length > 15) ? true : false"
                   [rows]="noOfRecordsEmpGroup" [totalRecords]="employeeGroups && employeeGroups.length" #dt class="common-data-table">
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns">{{col.header}}</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
              <tr *ngIf="reviewRuleData.global && reviewRuleData.global.type === 'GLOBAL'">
                <td *ngFor="let col of columns">
                  <span *ngIf="col.field === 'name'"
                    [innerHTML]="emtrConst.ruleSteps.step3.allClientInfo.clientName"></span>
                </td>
              </tr>
              <tr *ngIf="!reviewRuleData.globalRule || (reviewRuleData.globalRule && reviewRuleData.global && reviewRuleData.global.type === this.emtrConst.rulesConstant.semiGlobalObj.type)">
                <td [attr.colspan]="columns.length" class="sdps-text-center" [innerHTML]="sharedConstant.noRecords"></td>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-employeeGroup>
              <tr>
                <td>{{employeeGroup.name}}</td>
                <td>{{employeeGroup.description}}</td>
                <td *ngIf="businessUnit !== 'RBS' && businessUnit !== 'MGP'">{{employeeGroup.noOfEmployees}}</td>
                <td>{{employeeGroup.noOfSubAccounts}}</td>
                <td>{{employeeGroup.noOfRules}}</td>
                <td [innerHTML]="(employeeGroup.updTs) ? moment(employeeGroup.updTs).format('MM/DD/YYYY') : ''"></td>
              </tr>
            </ng-template>
          </p-table>
        </p-tabPanel>
    
        <p-tabPanel  [header]=sharedConstant[businessUnit].employeeOrParticipant [disabled]="disableEmployeeParticipant" >
          <!-- Table to display Employee/Participants -->
          <div *ngIf="!reviewRuleData.globalRule">
          <ems-table-record-count [count]="" [showRecords]="totalRecords && totalRecords > 15"
            (emitter)="dtt.reset();receiveDataFromChild($event, 'verifyData')" [selectedCount]="" [empIds]=""
                                  [showHeaderIcons]="false"></ems-table-record-count>
          <p-table [value]="verifyData" [columns]="cols" [rows]="noOfRecordsEmp"
                       [totalRecords]="verifyData && verifyData.length" #dtt class="common-data-table">

            <ng-template pTemplate="header" let-columns>
              <tr>
                <th *ngFor="let col of columns"><span [innerHTML]="col.header"></span></th>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                <td [attr.colspan]="columns.length" class="sdps-text-center" [innerHTML]="sharedConstant.noRecords"></td>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td *ngFor="let col of columns; let i = index">
                  <span
                    *ngIf="i === 0 && businessUnit !== 'RBS' &&  rowData[col.field]">{{rowData[col.field]}}</span>
                  <span *ngIf="(i === 0 && businessUnit === 'RBS') || (i === 1 && businessUnit !== 'RBS')">{{rowData[col.field]}}</span>
                  <span
                    *ngIf="col.field === 'firstName' || col.field === 'lastName' || col.field === 'acctHolderFn' || col.field === 'acctHolderLn'">{{rowData[col.field]}}</span>
                  <span
                    *ngIf="col.field === 'ruleIds' || col.field === 'groupIds'">{{rowData[col.field] && rowData[col.field].length}}</span>
                  <span *ngIf="col.field === 'acctHolderName'">{{rowData['firstName']}} {{rowData['lastName']}}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
          <p-paginator #p [rows]="pageObject.pageSize" [alwaysShow]="(totalRecords && totalRecords > 15) ? true : false" [totalRecords]="totalRecords"
                       (onPageChange)="paginate($event)" class="common-pagination"></p-paginator>
          </div>

          <!-- Table to display Master Accounts -->
          <div *ngIf="reviewRuleData.globalRule">
            <ems-table-record-count [count]="" [showRecords]="totalRecords && totalRecords > 15"
              (emitter)="acct.reset();receiveDataFromChild($event, 'verifyData')"
                                    [selectedCount]="" [empIds]="" [showHeaderIcons]="false"></ems-table-record-count>
            <p-table [value]="verifyData" [columns]="cols" [rows]="noOfRecordsEmp"
                     [totalRecords]="verifyData && verifyData.length" #acct class="common-data-table">

              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns"><span [innerHTML]="col.header"></span></th>
                </tr>
              </ng-template>

              <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                  <td [attr.colspan]="columns.length" class="sdps-text-center" [innerHTML]="sharedConstant.noRecords"></td>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns" [ngClass]="col.field === 'firstName' ? 'word-break' : ''">
                    <span [innerHTML]="rowData[col.field]"></span>
                  </td>
                </tr>

              </ng-template>
            </p-table>
            <p-paginator #p [rows]="pageObject.pageSize" [alwaysShow]="(totalRecords && totalRecords > 15) ? true : false" [totalRecords]="totalRecords"
                         (onPageChange)="paginate($event)" class="common-pagination"></p-paginator>
          </div>
        </p-tabPanel>
      </p-tabView>
    </ems-accordion>
  </form>
</div>
<ems-step-wizard-buttons
  [showCancelBtn]="(!(!revertDisable && reviewRuleData.status === 'DRAFT') && !(reviewRuleData.mode === 'Copy' || reviewRuleData.mode ==='' || revertDisable))"
  [showSaveBtn]="reviewRuleData.mode === 'EDIT' && (reviewRuleData.status === 'SCHEDULED' || reviewRuleData.status === 'ACTIVE') && showActionItems && showActionItemForGRule"
  [showActivateBtn]="((revertDisable) && !(reviewRuleData.mode === 'EDIT' || reviewRuleData.status === 'SCHEDULED' || reviewRuleData.status === 'ACTIVE'|| reviewRuleData.status === 'EXPIRED') || (reviewRuleData.mode === 'EDIT' && reviewRuleData.status === 'DRAFT'&& revertDisable && showActionItems && showActionItemForGRule))"
  [showSaveActivateBtn]="(!revertDisable && !(reviewRuleData.mode === 'EDIT' || reviewRuleData.status === 'SCHEDULED' || reviewRuleData.status === 'ACTIVE') || (!revertDisable && reviewRuleData.mode === 'EDIT' && reviewRuleData.status === 'DRAFT'))"
  [showSaveCloseBtn]="(!revertDisable && !(reviewRuleData.mode === 'EDIT' || reviewRuleData.status === 'SCHEDULED' || reviewRuleData.status === 'ACTIVE') || (!revertDisable && reviewRuleData.mode === 'EDIT' && reviewRuleData.status === 'DRAFT'))"
  [showCloseBtn]="!isFieldEditted && (reviewRuleData.mode === 'Copy' || reviewRuleData.mode === '') || revertDisable"
  [showPreviousBtn]="true && showActionItems && showActionItemForGRule && reviewRuleData.status !== 'EXPIRED'" [showRevertBtn]="true && showActionItems && showActionItemForGRule && reviewRuleData.status !== 'EXPIRED'"
  [disableBtn]="disableEditIcon || disableBtn || reviewRuleForm.invalid || formObjCompare || (reviewRuleForm.get('reviewRuleName').value && reviewRuleForm.get('reviewRuleName').value.trim().length === 0)" [revertDisable]="revertDisable"
  [disablePrevBtn]="(disableBtn && !(disableBtn && disableEditIcon)) || reviewRuleForm.invalid || (reviewRuleForm.get('reviewRuleName').value && reviewRuleForm.get('reviewRuleName').value.trim().length === 0)"
  (revert)="revertChanges()" (saveAndClose)="ruleSaveAndClose()" (activate)="activateRule($event)"
  (closeBtn)="ruleCancel();navigateTo('/ems/emtr/rules/rules-list')"
  (goToPrevious)="goToPreviousStep('../step3');" (save)="ruleSave($event)"></ems-step-wizard-buttons>

