import { Inject, Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigUrlService } from '../config-url/config-url.service';
import { SelectItem } from 'primeng/api';
import { Validators } from '@angular/forms';
import { SharedService } from '../../shared.service';


@Injectable({
  providedIn: 'root'
})
export class LinkDelinkService {
  configURL = this.config.setApplicationUrl();
  authorizedAgents: any = [];
  masterAccountOptions: any[];
  SLCount: any;
  showRemarks: any = false;
  constructor(private httpService: HttpClient, public config: ConfigUrlService, public sharedService: SharedService) { }

  getAAExceptionsBUs(): Observable<any> {
    const url = this.configURL.addlinkBusinessUnitExceptionEndPointV1;
    return this.httpService.get(url);
  }

  populateAuthorizedAgents(businessUnit, clientInfo) {
    let isAAExceptBU: any = false;
    if (businessUnit && clientInfo.businessUnit && !businessUnit.includes(clientInfo.businessUnit)) {
      isAAExceptBU = true;
    } else {
      isAAExceptBU = false;
    }
    return isAAExceptBU;
  }

  getAuthAgents(clientId, result) {
    this.authorizedAgents = [];
    let authorizedAgentList = null;

    authorizedAgentList = result.dbsauthAgentlist;

    if (authorizedAgentList !== null) {
        authorizedAgentList.sort((a, b) => {
          const aLastName = a.authAgentLastNm.trim().toLowerCase();
          const bLastName = b.authAgentLastNm.trim().toLowerCase();
          if (aLastName < bLastName) {
            return -1;
          }
          if (aLastName > bLastName) {
            return 1;
          }
          return 0;
        });

        for (const authorizedAgent of authorizedAgentList) {
          if (authorizedAgent.authAgentLastNm !== '' && authorizedAgent.authAgentFirstNm !== '' && authorizedAgent.active !== 'N') {
            const item: SelectItem = {
              label: authorizedAgent.authAgentLastNm + ', ' + authorizedAgent.authAgentFirstNm,
              value: authorizedAgent.authAgentId
            };
            this.authorizedAgents.push(item);
          }
      }
    }
    return this.authorizedAgents;
  }

  public getAuthorizedAgents(orgId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkAuthAgentEndPointV1;
    if (orgId && (orgId !== null && orgId !== 0)) {
      return this.httpService.get(url + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      return this.httpService.get(url);
    }
  }

  public getLinkMasterAccounts(orgId, clientId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.clientId, clientId, schwabClientHeader);
    const url = this.configURL.bffMasterAccountEndpointV1;
    return this.httpService.get(url + '&appSource=EMTR' + '&Schwab-Client-Ids=' + schwabClientHeader);
  }

  populateMasterAccounts(linkRequestForm, clientId, linkType, countOfSL, usertype, result) {
    this.SLCount = 0;
    linkRequestForm.controls.masterAccounts.enable();
    this.masterAccountOptions = [];
    if (result !== null && result.length > 0) {
      if (linkType.toLowerCase() !== 'standard') {
        result.sort((a, b) => {
          return a.masterAccountNbr - b.masterAccountNbr;
        });
      }
      for (const data of result) {
        const item = {
          /* Adding AccountType to label ie.08765409 SL-EMTR only if it is 'SL' */
          label: data.clientLabel + ' - ' + data.masterAccountNbr,
          value: data.masterAccountNbr,
          accountType: data.accountType,
          accountSubType: data.accountSubType
        };
        if (data.accountType === 'SL' && countOfSL <= 1) {
          this.SLCount++;
        }
        if (usertype === 'External' && this.SLCount === 1 && linkType === 'Multi') {
          linkRequestForm.controls.masterAccounts.setValidators([]);
          linkRequestForm.controls.masterAccounts.setValue([item]);
        } else {
          linkRequestForm.controls.masterAccounts.setValidators([Validators.required]);
          linkRequestForm.controls.masterAccounts.setValue([]);
        }
        linkRequestForm.updateValueAndValidity();
        this.masterAccountOptions.push(item);
      }

      this.masterAccountOptions.sort((a, b) => {
        const aType = a.label.split(' ')[1];
        const bType = b.label.split(' ')[1];
        if (aType < bType) {
          return -1;
        }
        if (aType > bType) {
          return 1;
        }
        return 0;
      });
    }
    const resData = { masterAccountOptions: this.masterAccountOptions, countOfSL: this.SLCount };
    return resData;
  }

  public authAgentValidation(form) {
    form.get('authAgent').valueChanges.subscribe((result) => {
      if (result.length > 0 && result[0].label.indexOf('Exception') >= 0) {
        this.showRemarks = true;
        form.controls.remarks.setValidators(Validators.required);
        form.controls.remarks.updateValueAndValidity();
        form.controls.doNotShowRemarkToClient.setValue(true);
        form.controls.doNotShowRemarkToClient.disable();
        form.controls.doNotShowRemarkToClient.updateValueAndValidity();
      } else {
        this.showRemarks = false;
        form.controls.doNotShowRemarkToClient.setValue(false);
        form.controls.doNotShowRemarkToClient.enable();
        form.controls.doNotShowRemarkToClient.updateValueAndValidity();
        form.controls.remarks.clearValidators();
        form.controls.remarks.updateValueAndValidity();
      }
    });
    form.get('doNotShowRemarkToClient').valueChanges.subscribe((result) => {
      if (result) {
        this.showRemarks = true;
        form.controls.remarks.setValidators(Validators.required);
        form.controls.remarks.updateValueAndValidity();
      } else {
        this.showRemarks = false;
        form.controls.remarks.clearValidators();
        form.controls.remarks.updateValueAndValidity();
      }
    });
  }
}
