import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ConfigUrlService } from '@ems/shared';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private allUsers: Array<any> = [];
  public loggedInUser = new BehaviorSubject(null);
  clientOrgId: any;
  configURL = this.config.setApplicationUrl();
  constructor(private httpService: HttpClient, private config: ConfigUrlService) {
  }

  public getLoggedInUser(): any {
    return this.loggedInUser;
  }

  public setLoggedInUser(loggedInUser): void {
    this.loggedInUser = loggedInUser;
  }

  setCurrentOrg(org) {
    this.clientOrgId = org;
  }

  getTermsAndConditions(): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Accept', 'text; charset=utf-8');
    const url = this.configURL.sposEndPoint + '/files/templates/USERAGREEMENT';
    return this.httpService.get(url, { headers: httpHeaders,
      responseType: 'text'});
  }

  updateTerms(): Observable< any> {
    return this.httpService.put(this.configURL.userAgreementEndPointV1 + '/true', {observe: 'response'});
  }

  handleErrorMessage(message) {
    if (message && message.match(/mongo|password/gim)) {
      return true;
    } else {
      return false;
    }
  }
}
