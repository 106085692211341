import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DynamicEnvironment} from '@environment/dynamic-environment';
import {ShellConstant} from '@shell_components/constants/shellConstants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpService: HttpClient) {
  }

  policy: any;
  isInternalUser: any;
  clientPolicyInfo: any;
  userAuthentication = new BehaviorSubject(false);
  userActionOccuredObj: Subject<void> = new Subject();
  userPolicy: any = new BehaviorSubject(null);
  clientPolicy = new BehaviorSubject(null);
  environment = new DynamicEnvironment();
  shellConstant = ShellConstant;

  get userActionOccured(): Observable<void> {
    return this.userActionOccuredObj.asObservable();
  }

  notifyUserAction() {
    this.userActionOccuredObj.next();
  }

  logOutUser() {
    this.httpService.delete(this.environment.webAppService + '/v1/logout').subscribe(res => {
      const data = 'data';
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = encodeURI(res[data]);
    });
  }

  getUserPolicy() {
    return this.userPolicy;
  }

  setUserPolicy(policyInfo) {
    this.policy = policyInfo;
  }

  getUsername() {
      return this.policy.userName;
  }

  setClientPolicy(policyInfo) {
    this.clientPolicyInfo = policyInfo;
  }

  getClientPolicy() {
    return this.clientPolicy;
  }

  setUsertype(type) {
    this.isInternalUser = type;
  }

  getUsertype() {
    return this.isInternalUser;
  }

}
