<div class="sdps-navigation--horizontal" role="application">
  <nav aria-label="Expanded Summary panel with five nested items"
       class="sdps-navigation--horizontal__levelFour subNavigation" id="unique-secondaryNav">
    <ul aria-label="Open an Summary steps" class="sdps-navigation--horizontal__nav" role="group">
      <ng-container *ngFor="let item of rulesSubMenu;">
        <li class="sdps-navigation--horizontal__item" routerLinkActive="sdps-is-active"
            [ngClass]="!item.showLink ? 'demo-border-bottom':''">
          <a class="sdps-navigation--horizontal__link"
             *ngIf="item.showLink && this.sharedService.checkAccess(item.accessName)">
            <button class="buttons sub-navigation-button" [routerLink]="item.route" [innerHTML]="item.name"
                    (click)="sharedService.loadDataTrackValue(item.route)"></button>
          </a>
          <a class="sdps-navigation--horizontal__link"
             *ngIf="!item.showLink && this.sharedService.checkAccess(item.accessName)" [innerHTML]="item.name"
             [class.ui-disable-nav-tab]="!item.showLink ? true: ''"
             [title]="sharedConstant.accessDeniedTooltipMessage"></a>
        </li>
      </ng-container>
    </ul>
  </nav>

  <div class="content-area sdps-p-around_large">
    <ems-service-error *ngIf="!isModalOpened"></ems-service-error>
    <router-outlet></router-outlet>
  </div>
</div>
