<div [formGroup]="dateForm" *ngIf="dateForm">
  <div class="emtr-datepicker sdps-grid-container sdps-p-around_none sdps-m-around_none">
    <div class="emtr-calender" [ngClass]="{'date-errorHighlight':showError}">
      <span class="p-float-label timeTextbox">
        <p-calendar #dateEntered appendTo="body" [disabled]="disabled" (keyup)="changeValue($event)" (onClose)="onCloseMethod($event, dateEntered)"  formControlName="date" [showIcon]="true" name="emtr-date" [minDate]="minDate" [maxDate]="maxDateValue" [readonlyInput]="readonlyInput" [monthNavigator]="true" [yearNavigator]="true" yearRange="{{commonConstants.calendarYear}}" keepInvalid="true"></p-calendar>
        <label [ngClass]="{'labelDisabled': dateForm.controls.date.disabled || disabled}">{{dateLabel}}
          <span *ngIf="showAsterisk" class="sdps-text-market-red">*</span>
        </label>
      </span>
    </div>
  </div>

</div>
