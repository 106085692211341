import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {emtrConstant} from '../../../constants/emtrConstants';
import {FormDataService, SharedConstant, SharedService} from '@ems/shared';
import {RuleService} from '../../../services/rule-service/rule.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AllSecurityRecords} from '../../../models/custom-multiselect.model';
import {DefaultSecurityGridHeader} from '../../../models/search-security-modal.model';
import {FundFamilyTableHead, searchFundFamilyTableHead} from '../../../models/search-fund-Family-table-header.model';
import {RulesSelectInstrumentGroupTableHeader} from '../../../models/instrument-group-header';
import { SecurityTypeComponent } from '../../security-type/security-type.component';

@Component({
  selector: 'tr-edit-rule-step1',
  templateUrl: './edit-rule-step1.component.html'
})
export class EditRuleStep1Component implements OnInit {
  editReviewRuleForm: UntypedFormGroup = this.fb.group({
    reviewRuleName: ['', {validators: [Validators.required]}],
    restrictForm: this.fb.group({
      restrictBuys: '',
      restrictSells: '',
      restrictShortSells: ''
    }),
    indSecs: '',
    underlyingOpts: '',
    justification: '',
    otherInput: '',
    globalRule: ''
  });
  emtrConst = emtrConstant;
  sharedConstant = SharedConstant;
  editReviewRuleData: any;
  rulesCategory: any;
  justification: any;
  otherInput: any;
  override = '';
  ruleType: string;
  multiLevelSecurityType: any = {};
  multiLevelUpdatedList: any;
  subTypeLabel: string;
  appliesTo = '';
  securityType: any[] = [];
  noOfRecords: any = 15;
  noOfRecordsInstGroup: any = 15;
  fundFamilyTableHeadCols: FundFamilyTableHead[] = searchFundFamilyTableHead;
  securityTableHeader: any[] = [];
  indSecs: any;
  employee: any;
  employeeGroups: any;
  cancelButton = false;
  targetElem: any;
  localObserver: any;
  continueToNavigate = false;
  ruleNameExists = false;
  orgId = '';
  searchSecurities: any;
  confirmMessage: any;
  showCharLimit: boolean;
  draftRuleName: string;
  public draftRuleId: any;
  errorMsg = [];
  ruleNameExistsMsg = '';
  displaySelectedAssetGrp = [];
  instGrpTableHeader = RulesSelectInstrumentGroupTableHeader;
  instGroup: any;
  businessUnit: any;
  securityData = [];

  @Output() emitSelectedvalues: EventEmitter<string> = new EventEmitter<string>();
  @Input() multiselectSelectedData: any[] = [];
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('focusName') inputEl: ElementRef;
  @ViewChild('reviewSecurity', { static: true }) childSecurityType: SecurityTypeComponent;


  constructor(private fb: UntypedFormBuilder, public formDataService: FormDataService, public ruleService: RuleService, private router: Router, private r: ActivatedRoute, private cd: ChangeDetectorRef, @Inject('commonConstants') public commonConstants, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst) {
    this.editReviewRuleData = this.formDataService.getFormData();
    this.draftRuleId = this.editReviewRuleData.orgId;
    this.sharedService.clientInformation.subscribe(clientInfo => {
      this.businessUnit = clientInfo && clientInfo.businessUnit;
    });
    // this.viewIG = this.editReviewRuleData.mode === 'Override' && this.businessUnit === 'DBS' && this.editReviewRuleData.instrumentGroups.length > 0;
  }

  ngOnInit() {
    this.securityTableHeader = (this.editReviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? this.fundFamilyTableHeadCols : DefaultSecurityGridHeader;
    /*changing the ruleCategory type for different modes of edit*/
    if (this.editReviewRuleData.mode === this.emtrConst.rulesConstant.ruleGlobalConst.restriction) {
      this.editReviewRuleData.rulesCategory = 'RESTRICT_LST_ENG';
    } else if (this.editReviewRuleData.mode === this.emtrConst.rulesConstant.ruleGlobalConst.override) {
      this.editReviewRuleData.rulesCategory = 'RESTRICT_LST_ENG';
      this.editReviewRuleData.override = 'Y';
    }

    if (this.editReviewRuleData.rulesCategory === 'RESTRICT_LST_ENG' && this.editReviewRuleData.override !== 'Y') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.restriction;
    } else if (this.editReviewRuleData.rulesCategory === 'WATCH_LST_ENG') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.watchList;
    } else {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.override;
    }

    /* eslint-disable-next-line max-len */
    this.subTypeLabel = this.editReviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt && this.editReviewRuleData.rulesCategory === 'RESTRICT_LST_ENG' ? this.emtrConst.rulesConstant.autoComplete.selectFundFamiliesToRestrictTxt : this.editReviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt && this.editReviewRuleData.rulesCategory === 'Override' ? this.emtrConst.rulesConstant.autoComplete.fundFamilesOverrideTxt :this.editReviewRuleData.ruleType === 'INDSEC' && this.editReviewRuleData.rulesCategory === 'RESTRICT_LST_ENG' && this.businessUnit !== 'SPS' ? this.emtrConst.rulesConstant.ruleTypeLabels.securityInstrumentGrpOverrideText :
    this.emtrConst.rulesConstant.editRule.editStep1ReviewRule.securities.concat((this.ruleType === this.emtrConst.rulesConstant.ruleGlobalConst.restriction) ? this.emtrConst.rulesConstant.editRule.editStep1ReviewRule.securitiesRestricted :
    (this.ruleType === this.emtrConst.rulesConstant.ruleGlobalConst.override) ? this.emtrConst.rulesConstant.editRule.editStep1ReviewRule.securitiesAllowed : this.emtrConst.rulesConstant.editRule.editStep1ReviewRule.securitiesWatched);
    this.populateData();

    /* If the selected client is RBS client and the rule getting Overridden is of Restriction and Security type only then sort the asset group data */
    if ((this.ruleService.businessUnit === this.sharedConstant.rbsBUText || this.ruleService.businessUnit === this.sharedConstant.dbsBUText) && this.editReviewRuleData.rulesCategory === 'RESTRICT_LST_ENG' && this.editReviewRuleData.ruleType === 'SECTYP') {
      /* If the asset group data is already stored in the cache then call the maintaAssetGroupOrder method to sort the data based on order value */
      if (this.sharedService.assetGrpCachedData && this.sharedService.assetGrpCachedData.length > 0  && this.businessUnit === this.sharedService.assetGrpCachedData[0].bu) {
        this.displaySelectedAssetGrp = this.ruleService.maintainAssetGroupOrder(this.editReviewRuleData);
      } else {
        /* If the asset group data is not yet stored in the cache then make the asset group endpoint call to fetch the data */
        this.fetchAssetGroups();
      }
    }

    setTimeout(() => {
      if ((this.sharedService.presetRuleCachedData && this.sharedService.presetRuleCachedData.length > 0) || this.businessUnit === 'DBS') {
        this.formSecCatgsForAssetGrp();
      } else {
        this.fetchPresetRules();
      }
    }, 500);
    this.changeJustificationOpt();
    this.onChanges();
    this.confirmMessage = this.confirmationMessage.confirmMessage;
  }

  onChanges(): void {
    this.editReviewRuleForm.get('reviewRuleName').valueChanges.subscribe((ruleNameValue) => {
      this.ruleNameExists = this.continueToNavigate = false;

      /*To clear the rulename exists warning msg*/
      this.ruleService.toastMsg.emit('');
      this.sharedService.closeToast = false;

      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (ruleNameValue && !regExp.test(ruleNameValue)) {
        if (this.errorMsg.indexOf(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter) === -1) {
          this.errorMsg.push(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter);
        }
        this.ruleService.showSchError.emit(true);
      } else {
        this.errorMsg = [];
        this.ruleService.showSchError.emit(false);
      }
      this.ruleService.schErrorMsg.emit(this.errorMsg);
    });
  }

  navigateTo(steps) {
    const ruleObj = {
      ruleName: steps.ruleName.trim(),
      rulesCategory: steps.rulesCategory,
      override: steps.override,
      justification: steps.justification,
      otherInput: steps.otherInput,
      globalRule: this.isCheckboxGlobalEnabled(),
    };
    this.formDataService.editSetStep1(ruleObj, true);

    /*To clear the rulename exists warning msg*/
    this.ruleService.toastMsg.emit('');
    this.sharedService.closeToast = false;

    this.router.navigate(['/ems/emtr/rules/edit-rule/step2'], {relativeTo: this.r});
  }

  receiveDataFromChild(count, actionMenu) {
    if (actionMenu === 'indSecs') {
      this.noOfRecords = count;
      this.indSecs = [...this.indSecs];
    } else if (actionMenu === 'instGroup') {
      this.noOfRecordsInstGroup = count;
      this.instGroup = [...this.instGroup];
    }
  }

  onClose() {
    this.cd.detectChanges();
    this.inputEl.nativeElement.focus();
    this.ruleNameExists = false;
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.emtrConst.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.cancelButton === false && (this.editReviewRuleForm.dirty || this.editReviewRuleForm.get('reviewRuleName').value)) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    } else {
        return true;
    }
  }

  cancelCreateRule() {
    this.confirmMessage.message = this.emtrConst.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate(['/ems/emtr/rules/rules-list']);
  }

  checkWhetherRuleNameExists(ruleObj) {
    this.orgId = this.editReviewRuleData.orgId;
    this.ruleService.checkRuleNameExists(ruleObj.ruleName, this.orgId, '').subscribe(res => {
      this.sharedService.decodeUrl = true;
      if (res && res.data !== null) {
        this.ruleNameExistsMsg = this.emtrConst.rulesConstant.ruleNameMsg.the + '<strong> "' + this.editReviewRuleForm.get('reviewRuleName').value.trim() + '"</strong>' + this.emtrConst.rulesConstant.ruleNameMsg.ruleNameExistWarningMsg;
        this.ruleNameExists = this.continueToNavigate = true;
        this.cancelButton = false;

        /*To show the rulename exists warning msg*/
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.ruleNameExistsMsg);
      } else {
        this.navigateTo(ruleObj);
      }
    });
  }

  goToNextStep() {
    this.cancelButton = true;
    const formObj = {
      ruleName: this.editReviewRuleForm.get('reviewRuleName').value.trim(),
      rulesCategory: this.editReviewRuleData.rulesCategory,
      override: this.editReviewRuleData.override,
      justification: this.editReviewRuleForm.get('justification').value,
      otherInput: this.editReviewRuleForm.get('otherInput').value,
      globalRule: this.isCheckboxGlobalEnabled()
    };
    if ((this.draftRuleName === formObj.ruleName && this.draftRuleId) || this.continueToNavigate || this.businessUnit === 'RBS' ) {
      this.navigateTo(formObj);
    } else {
      this.checkWhetherRuleNameExists(formObj);
    }
  }

  populateData() {
    /* eslint-disable-next-line max-len */
    this.rulesCategory = (this.editReviewRuleData.override === 'Y') ? this.emtrConst.rulesConstant.ruleGlobalConst.override : this.editReviewRuleData.rulesCategory;
    this.indSecs = (this.editReviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? this.editReviewRuleData.fndFmlyInfo : this.editReviewRuleData.indSecs;
    this.instGroup = this.editReviewRuleData.instrumentGroups ;
    this.employeeGroups = this.editReviewRuleData.employeeGroups;
    this.employee = this.editReviewRuleData.employeeAccounts;
    this.ruleType = this.editReviewRuleData.ruleType;
    this.justification = this.editReviewRuleData.justification;
    this.otherInput = this.editReviewRuleData.otherInput;
    this.appliesTo = this.editReviewRuleData.appliesTo;
    this.securityType = (this.editReviewRuleData.securityType) ? this.editReviewRuleData.securityType : [];
    if (this.editReviewRuleData.ruleType !== this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) {
      this.ruleService.getAssettypes().subscribe((datas: any) => {
        if (this.securityType.length === datas.data.length) {
          this.securityType = [AllSecurityRecords];
        }
      });
    }
    this.editReviewRuleForm.patchValue({
      reviewRuleName: this.editReviewRuleData.name,
      ruleType: this.editReviewRuleData.ruleType,
      appliesTo: this.editReviewRuleData.appliesTo,
      underlyingOpts: this.editReviewRuleData.appliesTo === 'BTH' || this.editReviewRuleData.appliesTo === 'OPT',
      indSecs: this.editReviewRuleData.appliesTo === 'BTH' || this.editReviewRuleData.appliesTo === 'IND',
      justification: this.editReviewRuleData.justification,
      otherInput: this.editReviewRuleData.otherInput,
      restrictForm: {
        restrictSells: (this.editReviewRuleData.rstrctSell === 'Y') ? true : '',
        restrictBuys: (this.editReviewRuleData.rstrctBuy === 'Y') ? true : '',
        restrictShortSells: (this.editReviewRuleData.rstrctShortSell === 'Y') ? true : '',
      },
      globalRule: this.isCheckboxGlobalEnabled()
    });
    this.editReviewRuleForm.disable();
    this.editReviewRuleForm.get('reviewRuleName').enable();
    if (this.editReviewRuleData.override === 'Y') {
      this.editReviewRuleForm.get('justification').enable();
      this.editReviewRuleForm.get('otherInput').enable();
    }
    this.editReviewRuleForm.get('restrictForm').disable();
    if (this.editReviewRuleData.ruleType === 'SECTYP' && this.editReviewRuleData.assetGroups && this.editReviewRuleData.assetGroups <= 0) {
      this.fetchAndPupulateSecurityTypeList(this.formDataService.formData);
    }
  }

  updateMultiselectValues(selectedValues) {
    this.searchSecurities = selectedValues;
    this.emitSelectedvalues.emit(selectedValues);
  }

  formatJson(data) {
    return data.map(dataEle => {
      const subLevels = dataEle.subLevels;
      // delete dataEle.subLevels;
      const obj: object = {
        data: dataEle
      };
      if (subLevels) {
        /* eslint-disable @typescript-eslint/dot-notation */
        obj['children'] = this.formatJson(subLevels);
        obj['expanded'] = true;
      }
      return obj;
    });
  }

  recurrsive(data, array) {
    if (data.subLevels) {
      data.subLevels.forEach((subLevel) => {
        if (subLevel.subLevels) {
          array.push({...subLevel});
          this.recurrsive(subLevel, array);
        } else {
          array.push({...subLevel});
        }
      });
      return array;
    } else {
      return data;
    }
  }

  populateSecurityTypeData(SecurityValue) {
      const securityData = this.securityData;
      if (securityData) {
        const secCatgs1 = this.formatSecurities(SecurityValue.secCatgs1, 'SC1');
        const secCatgs2 = this.formatSecurities(SecurityValue.secCatgs2, 'SC2');
        const secCatgs3 = this.formatSecurities(SecurityValue.secCatgs3, 'SC3');
        const selectedValues = secCatgs1.concat(secCatgs2).concat(secCatgs3);
        selectedValues.forEach(selectedData => {
          securityData.forEach(secData => {
            if (JSON.stringify(secData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
              if (!this.multiLevelSecurityType[secData.desc]) {
                this.multiLevelSecurityType[secData.desc] = [];
              }
              if (selectedData.data.level === 'SC1') {
                this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                  .concat(this.formatJson(this.recurrsive({...secData}, [])))
                  .concat(this.formatJson([{...secData}]));
              } else if (selectedData.data.level === 'SC2') {
                secData.subLevels.forEach(secSubLevelData => {
                  if (JSON.stringify(secSubLevelData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
                    this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                      .concat(this.formatJson(this.recurrsive({...secSubLevelData}, [])))
                      .concat(this.formatJson([{...secSubLevelData}]));
                  }
                });
              } else {
                this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                  .concat(this.formatJson([selectedData.data]));
              }
            }
          });
        });
        this.updateSecurityValues(this.multiLevelSecurityType);
      };
  }
  isCheckboxGlobalEnabled() {
    let enableGlobalChkbox;
    if (this.editReviewRuleData.global && (this.editReviewRuleData.global.type === 'GLOBAL' || this.editReviewRuleData.global.type === 'SEMI_GLOBAL')) {
      enableGlobalChkbox = true;
    } else {
      enableGlobalChkbox = false;
    }
    return enableGlobalChkbox;
  }
  formatSecurities(securities, level) {
    return securities.map(item => {
      return {
        data: {
          desc: item.secTypNm,
          code: item.secTypCd,
          level
        }
      };
    });
  }

  updateSecurityValues(selectedValues) {
    this.multiLevelSecurityType = Object.assign({}, selectedValues);
    const wholeArray = Object.assign({}, this.multiLevelSecurityType);
    Object.keys(wholeArray).forEach((key) => {
      wholeArray[key].forEach((keys) => {
        if (keys.children) {
          keys.data.selected = 'ALL';
          keys.children.forEach((record) => {
            wholeArray[key] = wholeArray[key].filter(item => item.data.desc !== record.data.desc);
          });
        }
      });
      if (this.editReviewRuleData.assetGroups && this.editReviewRuleData.assetGroups.length > 0) {
        /* when we are getting few securities from preset and few from asset group, and if the formed secCatgs2 has all the Level2 of a specific security selected then we are making the Level1 selection for that security */
        const secCatgs = {
          secCatgs1: [],
          secCatgs2: [],
          secCatgs3: []
        }
        /* Clearing this.multiLevelSecurityType[sec.desc] so that duplicate data are not pushed so the 'Select All' checkbox on modal is selected when all securities are selected */
        this.securityData.some(sec => sec.desc == key && sec.subLevels.length === wholeArray[key].length ? (secCatgs.secCatgs1.push({secTypNm: sec.desc, secTypCd: sec.code}), this.multiLevelSecurityType[sec.desc] = [],this.populateSecurityTypeData(secCatgs)) : '')
      }
    });
    if((this.editReviewRuleData.assetGroups === undefined || (this.editReviewRuleData.assetGroups && this.editReviewRuleData.assetGroups.length <= 0))) {
      this.multiLevelUpdatedList = wholeArray;
    }
    return Object.keys(this.multiLevelSecurityType).length > 0 ? this.multiLevelSecurityType : false;
  }

  changeJustificationOpt() {
    if (this.editReviewRuleForm.get('justification').value !== 'Other') {
      this.editReviewRuleForm.get('otherInput').disable();
    }
    this.editReviewRuleForm.get('justification').valueChanges.subscribe((value) => {
      if (value === 'Other') {
        this.editReviewRuleForm.get('otherInput').enable();
      } else {
        this.editReviewRuleForm.get('otherInput').reset();
        this.editReviewRuleForm.get('otherInput').disable();
      }
    });
  }

  fetchAssetGroups() {
    /* Fetch Asset group and global asset group. Also store the fetched response data in the cache to utilise it later */
    this.ruleService.getAssetGroups().subscribe(assetGrp => {
      this.ruleService.setAssetGrpCacheData(assetGrp);
      /* Once the data is fetched and stored in cache, call the maintainAssetGroupOrder to sort the selected asset grp
      data and display the sorted data to user */
      this.displaySelectedAssetGrp = this.ruleService.maintainAssetGroupOrder(this.editReviewRuleData);
    });
  }

  fetchPresetRules() {
    /* Fetch preset rules and store the fetched response data in the cache to utilise it later */
    this.ruleService.getPresetRules().subscribe(presetData => {
      this.sharedService.presetRuleCachedData = this.ruleService.updateSecCatgsInPresentRule(presetData.data, this.sharedService.assetGrpCachedData);
      this.formSecCatgsForAssetGrp();
    });
  }

  openSecurityType() {
    if (this.securityData) {
      this.childSecurityType.openModal(event, this.securityData);
    }
  }

  fetchAndPupulateSecurityTypeList(SecurityValue) {
    let isRestrictShortSellsChecked = false;
    if ((this.editReviewRuleData.assetGroups === undefined || (this.editReviewRuleData.assetGroups && this.editReviewRuleData.assetGroups.length <= 0))) {
      isRestrictShortSellsChecked = !(this.editReviewRuleData.rstrctBuy === 'Y' || this.editReviewRuleData.rstrctSell === 'Y');
    }
    if (this.securityData && this.securityData.length === 0) {
      this.ruleService.getSecurities(isRestrictShortSellsChecked).subscribe((data: any) => {
        this.securityData = data.esoLevels;
        this.populateSecurityTypeData(SecurityValue);
      });
    } else {
      this.populateSecurityTypeData(SecurityValue);
    }
  }

  formSecCatgsForAssetGrp() {
    const secCatgs = {
      secCatgs1: [],
      secCatgs2: [],
      secCatgs3: []
    }
    if (this.editReviewRuleData.presetRuleId && this.editReviewRuleData.presetRuleId !== 1) {
      this.sharedService.presetRuleCachedData.forEach(rule => {
        /* Compare the selected preset rule with each key value iterated from the presetRules list */
        if (this.editReviewRuleData.presetRuleId === rule.id) {
          /* The selected rule will have all the required secCatgs, and to form the selected security array to display on the review security details modal call fetchAndPupulateSecurityTypeList */
          secCatgs.secCatgs1 = rule.secCatgs1 ? rule.secCatgs1 : [];
          secCatgs.secCatgs2 = rule.secCatgs2 ? rule.secCatgs2 : [];
          secCatgs.secCatgs3 = rule.secCatgs3 ? rule.secCatgs3 : [];
        }
      });
    } else {
      this.editReviewRuleData.assetGroups.forEach(assetGrp => {
        this.sharedService.assetGrpCachedData.some(grp => assetGrp.name === grp.name && grp.secCatgs1 ? grp.secCatgs1.filter(security => secCatgs.secCatgs1.push(security)) : '');
        this.sharedService.assetGrpCachedData.some(grp => assetGrp.name === grp.name && grp.secCatgs2 ? grp.secCatgs2.filter(security => secCatgs.secCatgs2.push(security)) : '');
        this.sharedService.assetGrpCachedData.some(grp => assetGrp.name === grp.name && grp.secCatgs3 ? grp.secCatgs3.filter(security => secCatgs.secCatgs3.push(security)) : '');
      });
    }
    this.fetchAndPupulateSecurityTypeList(secCatgs);
  }
}
