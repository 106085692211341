import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '@ems/shared';
import {Router} from '@angular/router';

@Component({
  selector: 'tr-manage-employee-groups',
  templateUrl: './manage-employee-groups.component.html'
})
export class ManageEmployeeGroupsComponent implements OnInit, OnDestroy {

  constructor(public sharedService: SharedService, public router: Router) {
    /* set value to parentPage during navigation */
    this.sharedService.parentPage = '';
    this.sharedService.deletedGrpOrPlan = false;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.sharedService.deletedGrpOrPlan = false;
  }
}
