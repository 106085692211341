declare var window: any;

export class DynamicEnvironment {
    public get environment() {
        return window.config.environment;
    }

    public get camUrl() {
        return window.config.camUrl;
    }

    public get webAppService() {
        return window.config.webAppService;
    }

    public get jfkURL() {
        return window.config.jfkURL;
    }

    public get domain() {
        return window.config.domain;
    }

    public get production() {
      return window.config.production;
    }
}
