<div [formGroup]="descriptionForm" class="security-description-container no-margin">
  <div class="sdps-row">
    <div class="col-6 sdps-p-left_x-small sdps-p-right_x-small sdps-p-top_x-small sdps-p-bottom_medium">
      <div class="change-requestid-box sdps-p-left_x-small sdps-p-right_x-small sdps-p-top_x-small sdps-p-bottom_medium" [ngClass]="{'invalidField': ((descriptionForm.get('changeRequestId').value && descriptionForm.get('changeRequestId').invalid)), 'input-disabled': ((descriptionForm.get('changeRequestId').disabled === true)) }">
            <span class="p-float-label">
              <input type="text" name="changeRequestId" [maxlength]=20 formControlName="changeRequestId" #focusName
                     (ngModelChange)="goToEditPage()" emsChangeRequestIdPatterncheck pInputText />
                <label>{{getConstant.rulesConstant.changeRequestId}}</label>
            </span>
      </div>
      <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="descriptionForm.get('changeRequestId').value.length > 16">{{getConstant.rulesConstant.characterLimit}}
        <span class="character-limit">{{descriptionForm.get('changeRequestId').value.length}}/{{commonConstants.charLimitValues.twentyMaxLimit}}</span>
      </p>
    </div>
  </div>
  <div class="sdps-row">
    <div class="col-6 e-input-textarea rule-description">
      <span class="p-float-label">
          <textarea class="security-description-notes sdps-p-vertical_x-small" rows="5" maxlength="300"
                    formControlName="description" pInputTextarea (ngModelChange)="goToEditPage()" emsCharacterLimit
                    [charMaxLimit]="commonConstants.charLimitValues.threeHundredMaxLimit"
                    (emsCharacterLimitCheck)="showDescCharLimit = $event" [disabled]="isRuleExpired === 'EXPIRED'"></textarea>
          <label class="description-notes-text">Description</label>
      </span>
      <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="descriptionForm.get('description').value.length > showDescCharLimit">{{getConstant.rulesConstant.characterLimit}}
        <span class="character-limit">{{descriptionForm.get('description').value.length}}/{{commonConstants.charLimitValues.threeHundredMaxLimit}}</span>
      </p>
  </div>
  <div class="col-6 e-input-textarea">
      <span class="p-float-label">
          <textarea class=" security-description-notes sdps-p-vertical_x-small" rows="5" maxlength="1000"
                    formControlName="notes" pInputTextarea (ngModelChange)="goToEditPage()" emsCharacterLimit
                    [charMaxLimit]="commonConstants.charLimitValues.thousandMaxLimit"
                    (emsCharacterLimitCheck)="showNotesCharLimit = $event"></textarea>
          <label class="description-notes-text">Notes</label>
      </span>
      <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml" *ngIf="descriptionForm.get('notes').value.length > showNotesCharLimit">{{getConstant.rulesConstant.characterLimit}}
          <span class="character-limit">{{descriptionForm.get('notes').value.length}}/{{commonConstants.charLimitValues.thousandMaxLimit}}</span>
      </p>
  </div>
  </div>
</div>
