
export const affiliations: any[] = [
    { label: 'Partner', value: 'PARTNER' },
    { label: 'POA/LPOA', value: 'POA/LPOA' },
    { label: 'Guardian', value: 'GUARDIAN' },
    { label: 'Trust/Trustee', value: 'TRUST/TRUSTEE' },
    { label: 'Other', value: 'OTHER' },
    { label: 'Beneficiary', value: 'BENEFICIARY' },
    { label: 'Custodian', value: 'CUSTODIAN' },
    { label: 'Spouse', value: 'SPOUSE' }
];
