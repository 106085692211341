import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneNumberValidationService {

  constructor() { }

  validatePhoneNumber(phoneNumber, countryCode) {
    /* Fetching only entered phoneNumber's count */
    const numb = phoneNumber ? (phoneNumber.match(/\d/g)).join('') : '';
    const length = countryCode === '1' ? 10 : 4;
    /* If entered phoneNumber's count is below 10 showing the Error Message in respective components */
    if (numb && numb.length < length) {
      return false;
    } else {
      return true;
    }
  }
}
