import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared.service';
import {SharedConstant} from '../../constants/sharedConstants';

@Component({
  selector: 'ems-service-error',
  templateUrl: './service-error.component.html'
})
export class ServiceErrorComponent {

  sharedConst = SharedConstant;

  constructor(public sharedService: SharedService) {
  }
}
