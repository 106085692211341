export interface ModalSearchSecurity {
    field?: string;
    header?: string;
}
export const DefaultSecurityGridHeader: ModalSearchSecurity[] = [
    { field: 'tickerSymbol', header: 'Ticker Symbol' },
    { field: 'name', header: 'Name' },
    { field: 'cusip', header: 'CUSIP' },
    { field: 'securityType', header: 'Security Type' },
    { field: 'sedol', header: 'SEDOL' },
    { field: 'isin', header: 'ISIN' }];

