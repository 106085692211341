import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {SharedService} from '@ems/shared';
import {IUser} from '@shell_components/model/user';
import {IRole} from '@shell_components/model/irole';
import {ConfigUrlService} from '@ems/shared';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import {of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {
  public subject = new ReplaySubject<any>();
  public showSuccessMsg: boolean;
  public successMsg: any;
  private getUsersURL: string;
  private createUserURL: string;
  private baseURL: string;
  private userInfoUrl: string;
  public roles: any[];
  private getRolesURL: string;
  public editMode: boolean;
  public getUserID: any;
  public custId: any = [];
  getUsersConstant = ShellConstant;

  configURL = this.config.setApplicationUrl();

  constructor(private http: HttpClient, public sharedService: SharedService, public config: ConfigUrlService) {
    this.baseURL = this.configURL.userMgmtEndPoint;
    this.getUsersURL = this.baseURL + '/users';
    this.createUserURL = this.baseURL;
    this.userInfoUrl = this.baseURL + '/userId/';
    this.getRolesURL = this.configURL.userMgmtRolesEndPoint;
    this.editMode = false;
  }


  getAllUsers(pageObj, custId, filterObj): Observable<any> {
    const userFilterInfo = {...filterObj};
    this.sharedService.stateValues.filterObj = userFilterInfo;
    this.sharedService.stateValues.pageObj.pageNo = pageObj.pageNo;
    this.sharedService.stateValues.pageObj.pageSize = pageObj.pageSize;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    this.sharedService.tableDropdownVal.next(pageObj.pageSize);
    delete filterObj.roles;
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    return this.http.post(this.configURL.bffUserListEndPointV1 + '&pageNumber=' + (pageObj.pageNo) + '&pageSize=' + pageObj.pageSize + sortField, userFilterInfo);
  }

  createUser(user: IUser): Observable<any> {
    return this.http.post<any>(this.configURL.bffUserEndPointV1, (user));
  }

  getUserById(userId: number): Observable<any> {
    let  endPoint = this.configURL.bffUserEndPointV1 ;
    if (userId) {
      endPoint = this.configURL.bffUserEndPointV1.replace('PassThrough?', `PassThrough/${userId}?`);
    }
    return this.http.get(endPoint);
  }

  updateUser(user: IUser): Observable<any> {
    return this.http.put<any>(this.configURL.bffUserEndPointV1, (user));
  }

  getBusinessUnits(): Observable<any> {
    return this.http.get(this.configURL.bffBusinessUnitEndpointV1 + '&type=all');
  }

  getRoles(userId, type): Observable<any> {
    let endPoint = '';
    if (userId) {
      endPoint = this.configURL.bffUserRolesEndPointV1.replace('PassThrough?', `PassThrough/${userId}?`);
    }
    if (type) {
      return this.http.get(encodeURI(endPoint + '&type=' + type));
    } else {
      return this.http.get(encodeURI(endPoint));
    }
  }

  addSuccessMsg(msg) {
    this.showSuccessMsg = true;
    this.successMsg = msg;
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: null});
  }

  deactivateManageUser(userId): Observable<any> {
    return this.http.put<any>(this.configURL.bffUserEndPointV1, userId);
  }

  getRolesIdsAndDesc(): Observable<any> {
    const endPoint = this.configURL.bffUserFilterEndPointV1;
    return this.http.get(endPoint);
  }
}
