export interface EmpTableHead {
  field?: string;
  header?: string;
}

export interface EmpTableHeader {
  DBS?: EmpTableHead[];
  SPS?: EmpTableHead[];
  RBS?: EmpTableHead[];
}

export const empGroupTableHeader: EmpTableHead[] = [
  {field: 'name', header: 'Employee Group Name'},
  {field: 'description', header: 'Group Description'},
  {field: 'noOfEmployees', header: 'No. of Employees'},
  {field: 'noOfSubAccounts', header: 'No. of Accounts'},
  {field: 'noOfRules', header: 'No. of Rules Assigned'},
  {field: 'updTs', header: 'Last Updated'},
  {field: 'actions', header: 'Action'}
];

export const empGrpOrPlanTableHeader: EmpTableHeader = {
  DBS: [
    {field: 'name', header: 'Employee Group Name'},
    {field: 'description', header: 'Group Description'},
    {field: 'noOfEmployees', header: 'No. of Employees'},
    {field: 'noOfSubAccounts', header: 'No. of Accounts'},
    {field: 'noOfRules', header: 'No. of Rules Assigned'},
    {field: 'updTs', header: 'Last Updated'},
    {field: 'actions', header: 'Action'}
  ],

  SPS: [
    {field: 'name', header: 'Employee Group Name'},
    {field: 'description', header: 'Group Description'},
    {field: 'noOfEmployees', header: 'No. of Employees'},
    {field: 'noOfSubAccounts', header: 'No. of Accounts'},
    {field: 'noOfRules', header: 'No. of Rules Assigned'},
    {field: 'updTs', header: 'Last Updated'},
    {field: 'actions', header: 'Action'}
  ],

  RBS: [
    {field: 'name', header: 'Plan Name'},
    {field: 'description', header: 'Plan Description'},
    {field: 'noOfSubAccounts', header: 'No. of Accounts'},
    {field: 'noOfRules', header: 'No. of Rules Assigned'},
    {field: 'updTs', header: 'Last Updated'},
    {field: 'actions', header: 'Action'}
  ]

};

export const empGrpOrPlanTableSubHeader: EmpTableHead[] = [
  { field: 'showRecords', header: 'Show Records'}
];

export const masterGroupHeaderTableColoumn: any[] = [
  {field: 'name', header: 'Name', enum: 'textbox'},
  {field: 'description', header: 'Description', enum: 'textbox'},
  {field: 'noOfSubAccounts', header: 'No. of Accounts', enum: 'heading'},
  {field: 'noOfRules', header: 'No. of Rules Assigned', enum: 'heading'},
  {field: 'updTs', header: 'Last Updated', enum: 'dateRange'},
  {field: 'actions', header: 'Action', enum: 'heading'}
];
