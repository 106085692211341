export interface ManageClientsListTableHeader {
  field?: string;
  header?: string;
  enum?: string;
}
export const manageClientsListTableHeaderForAllBU: ManageClientsListTableHeader[] = [
  { field: 'clientName', header: 'Client / Registration Name', enum: ' ' },
  { field: 'custId', header: 'CustID', enum: 'textbox' },
  { field: 'masterAccount', header: 'Master Account', enum: 'textbox' },
  { field: 'applications', header: 'Applications', enum: 'dropdown' },
  { field: 'linkType', header: 'Link Type', enum: 'dropdown' },
  { field: 'businessUnit', header: 'Business Unit', enum: 'dropdown' },
  { field: 'activeStatus', header: 'Status', enum: ' ' },
  { field: 'modifiedDate', header: 'Last Updated', enum: 'calendar' },
  { field: 'actions', header: 'Action', enum: ''}
];

export const manageClientsListTableHeaderForRBS: ManageClientsListTableHeader[] = [
  { field: 'clientName', header: 'Client / Registration Name', enum: ' ' },
  { field: 'custId', header: 'CustID', enum: 'textbox' },
  { field: 'masterAccount', header: 'Master Account', enum: 'textbox' },
  { field: 'applications', header: 'Applications', enum: 'dropdown' },
  { field: 'businessUnit', header: 'Business Unit', enum: 'dropdown' },
  { field: 'activeStatus', header: 'Status', enum: ' ' },
  { field: 'modifiedDate', header: 'Last Updated', enum: 'calendar' },
  { field: 'actions', header: 'Action', enum: ''}
];

export const manageClientsListTableHeaderForNonRBS: ManageClientsListTableHeader[] = [
  { field: 'clientName', header: 'Client / Registration Name', enum: ' ' },
  { field: 'custId', header: 'CustID', enum: 'textbox' },
  { field: 'applications', header: 'Applications', enum: 'dropdown' },
  { field: 'linkType', header: 'Link Type', enum: 'dropdown' },
  { field: 'businessUnit', header: 'Business Unit', enum: 'dropdown' },
  { field: 'activeStatus', header: 'Status', enum: ' ' },
  { field: 'modifiedDate', header: 'Last Updated', enum: 'calendar' },
  { field: 'actions', header: 'Action', enum: ''}
];

export const manageClientsListTableSubHeader: ManageClientsListTableHeader[] = [
  { field: 'accessType', header: 'Access Type', enum: 'dropdown' },
];
