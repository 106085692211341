import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SharedService, SharedConstant, ManageEmpGrpService } from '@ems/shared';
import { Dropdown } from 'primeng/dropdown';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmtrService } from '../../emtr.service';
import { EmtrPagination, EmtrPaginationValues } from '../../models/pagination-model';
import { RuleService } from '../../services/rule-service/rule.service';
import { RulesListComponent } from '../rules-list/rules-list.component';
import { emtrConstant } from '../../constants/emtrConstants';
import { InstrumentGroupService } from '../../services/instrument-group-service/instrument-group.service';
import { ruleListSubHeaderDateColumns } from '../../models/rule-list-table-header';

@Component({
  selector: 'tr-rule-list-sub-header',
  templateUrl: './rule-list-sub-header.component.html'
})
export class RuleListSubHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() isHidden: boolean;
  @Output() sendFilterObject: EventEmitter<any> = new EventEmitter<any>();
  @Output() setPaginator: EventEmitter<any> = new EventEmitter<any>();
  ruleListSubHeaderForm: UntypedFormGroup;
  filteredRequest: any[];
  empGroupDropDownOpts = [];
  instrumentGroupDropDownOpts = [];
  orgID: any;
  sharedConstant = SharedConstant;
  businessUnit: any;
  pageObject: EmtrPagination = EmtrPaginationValues;
  currentOrgSubscription: Subscription;
  clearOrgSubscription: Subscription;
  parent: RulesListComponent;
  filterBtnDisableForTradeSymbol = false;
  filterBtnDisableForCusip = false;
  dropdownInputValue: string;
  groupPlanSearchValue: string;
  instrumentGroupSearchValue: string;
  searchInputUpdate = new Subject<any>();
  searchInstrumentGroupUpdate = new Subject<any>();
  emtrConstant = emtrConstant;
  stateVal: any;
  instrumentGroupDropdownValue: string;
  instrumentGroupId = 0;
  isInstrumentFilterOpen = false;
  isInitialListUpdated = false;
  ruleSubHeaderColumns: any[] = ruleListSubHeaderDateColumns;

  @ViewChild('dropdown') groupDropdown: Dropdown;
  @ViewChild('instdropdown') instGroupDropdown: Dropdown;

constructor(private fb: UntypedFormBuilder, public sharedService: SharedService, public emtrService: EmtrService, private ruleService: RuleService, @Inject('entitlementConst') public entitlementConst,
            public manageEmpGrpService: ManageEmpGrpService, public instrumentGroupService: InstrumentGroupService) { }

  ngOnInit() {
    this.ruleListSubHeaderForm = this.fb.group({
      tradeSymbol: '',
      cusipSymbol: '',
      empGroupName: '',
      instrumentGroupId: '',
      view: {value: 'Client', label: 'Client'},
      showRecords: this.emtrConstant.showRecords[0]
    });

    /* Set 'Active' as default value for Show Records dropdown */
    const showRecorValue = this.emtrConstant.showRecords[0];
    this.ruleListSubHeaderForm.get('showRecords').setValue(showRecorValue);

    this.stateVal = this.sharedService.getStateVal();
    this.currentOrgSubscription = this.emtrService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.clearFilterForm();
        this.groupPlanSearchValue = '';
        this.instrumentGroupSearchValue = '';
        this.dropdownInputValue = '';
        this.orgID = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        this.pageObject.pageNo = 0;
        this.pageObject.pageSize = 15;
        this.pageObject.sortField = ['name,asc'];
        this.filterRequest();
        const param = {
          paramObj: this.pageObject,
          groupName: ''
        };
        if (this.orgID !== 0 && this.ruleService.isSubHeaderFlag === true) {
          if (this.stateVal && this.stateVal.filterObj && this.stateVal.filterObj.instrumentGroupId !== 0) {
            this.instrumentGroupDropdownValue = this.stateVal.filterObj.instrumentGroupId;
            this.instrumentGroupId = this.stateVal.filterObj.instrumentGroupId;
          }
          if (this.sharedService.selectedGroupOrPlanName || (this.stateVal && this.stateVal.filterObj && this.stateVal.filterObj.groupName !== 'All')) {
            const grpNameVal = this.sharedService.selectedGroupOrPlanName ? this.sharedService.selectedGroupOrPlanName : this.stateVal.filterObj.groupName;
            this.dropdownInputValue = this.groupPlanSearchValue = grpNameVal;
            param.groupName = this.dropdownInputValue;
            this.searchInputUpdate.next(grpNameVal);
            if (this.sharedService.selectedGroupOrPlanName) {
              this.groupNameUpdated();
              this.updateGroupOrPlanName();
            }
            this.sharedService.selectedGroupOrPlanName = '';
          }
          this.getEmployeeGroupList(param);
          this.ruleService.isSubHeaderFlag = false;
          if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
            this.getInstrumentGroupList('', false);
            this.instrumentGroupDropdownValue = this.instrumentGroupDropdownValue ? this.instrumentGroupDropdownValue : this.sharedConstant.groupNameAll;
          }
        }
      }
    });

    if (this.stateVal && this.stateVal.filterObj) {
      this.updateFormValue();
    }

    this.ruleListSubHeaderForm.get('tradeSymbol').valueChanges.subscribe(value => {
      /* special character validation */
      if (value && this.ruleListSubHeaderForm.get('tradeSymbol').invalid) {
        this.ruleService.invalidTradeSymbolField.emit(true);
      } else {
        this.ruleService.invalidTradeSymbolField.emit(false);
      }
      /* clear button disable */
      if (value && value.length > 0) {
        this.ruleService.hasValueTradeSymbolField.emit(true);
      }
    });

    this.ruleListSubHeaderForm.get('cusipSymbol').valueChanges.subscribe(value => {
      this.filterBtnDisableForCusip = this.sharedService.isValueLengthLessThan9(value);
      /* showing validation message in parent */
      if (this.filterBtnDisableForCusip) {
        this.ruleService.invalidCusipSymbolField.emit(true);
      } else {
        this.ruleService.invalidCusipSymbolField.emit(false);
      }

      if (this.ruleListSubHeaderForm.get('cusipSymbol').invalid) {
        this.ruleService.specialCharValidation.emit(true);
      } else {
        this.ruleService.specialCharValidation.emit(false);
      }

      /* clear button disable and input value not more than 9 digits. */
      if (value && value.length > 0 ) {
        this.ruleService.hasValueCusipField.emit(true);
      }
    });

    this.ruleListSubHeaderForm.get('view').valueChanges.subscribe(value => {
      if (value) {
        this.ruleService.hasValueViewField.emit(true);
        this.disableOrEnableShowRecord(value.value);
      }
    });

    this.ruleListSubHeaderForm.get('showRecords').valueChanges.subscribe(value => {
      if (value) {
        this.ruleService.hasValueViewField.emit(true);
      }
    });

    this.ruleService.filterClickedSubHeader.subscribe(value => {
      if (value === true) {
        this.updateGroupOrPlanName();
      }
    });

    /* To clear the ruleListSubHeaderForm when clearAll button is clicked */
    this.ruleService.clearClicked
      .subscribe(value => {
      if (value === true) {
        if (this.groupPlanSearchValue) {
          this.groupPlanSearchValue = '';
          this.searchInputUpdate.next('');
        }
        if (this.instrumentGroupSearchValue) {
          this.instrumentGroupSearchValue = '';
          this.searchInstrumentGroupUpdate.next('');
        }
        this.dropdownInputValue = '';
        this.instrumentGroupDropdownValue = '';
        this.ruleService.hasGroupPlanValue.emit(false);
        this.ruleService.hasValueViewField.emit(false);
        this.ruleService.hasValueCusipField.emit(false);
        this.ruleService.hasValueTradeSymbolField.emit(false);
        this.ruleService.hasInstrumentGroupValue.emit(false);
        this.clearFilterForm();
      }
    });

    this.searchInputUpdate
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())
    .subscribe((results) =>  {
      const param = {
        paramObj: this.pageObject,
        groupName: results ? results : ''
        };
      if (this.orgID !== undefined && this.orgID !== 0) {
        this.getEmployeeGroupList(param);
      }
    });
    this.searchInstrumentGroupUpdate
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .subscribe((results) =>  {
        if (this.orgID && this.isInstrumentFilterOpen) {
          this.getInstrumentGroupList(results, true);
        }
      });
    this.clearOrgSubscription = this.sharedService.resetInstrumentGroupValue
      .subscribe((result) => {
      if (result === 'reset') {
        this.instrumentGroupId = 0;
        if (this.isInitialListUpdated) {
          this.getInstrumentGroupList('reset', false);
        }
      }
    });
  }
  hideOptions() {
    this.isInstrumentFilterOpen = false;
  }

  enterFilterValue(): void {
    this.ruleListSubHeaderForm.get('empGroupName').setValue(this.dropdownInputValue ? this.dropdownInputValue : 'All');
    this.ruleListSubHeaderForm.get('instrumentGroupId').setValue(this.instrumentGroupId ? this.instrumentGroupId : 0);
    const localUpdatedFormValues = this.ruleListSubHeaderForm.getRawValue();
    this.setPaginator.emit(true);
    this.sendFilterObject.emit({
      ruleListSubHeaderForm: localUpdatedFormValues
    });
  }

  filterRequest(): void {
    this.filteredRequest = [];
    /* Rule-sub-header for SPS and DBS without IGA access should not contain View and Instrument Group fields */
    if (this.businessUnit === this.sharedConstant.spsBUText || (this.businessUnit === this.sharedConstant.dbsBUText && !this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', ''))) {
      this.filteredRequest = this.ruleSubHeaderColumns.filter(item => (item.field !== 'view' && item.field !== 'instrumentGroup'));
    } else if (this.businessUnit === this.sharedConstant.dbsBUText && this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
      /* Rule-sub-header for DBS with IGA access should not contain View and Instrument Group fields */
      this.filteredRequest = this.ruleSubHeaderColumns.filter(item => (item.field !== 'view'));
    } else if (this.businessUnit === this.sharedConstant.rbsBUText) {
      /* Rule-sub-header for RBS will contain all the fields */
      this.filteredRequest = this.ruleSubHeaderColumns;
    }
  }

  clearFilterForm(): void {
    this.ruleListSubHeaderForm.reset();
    this.ruleListSubHeaderForm.get('view').setValue({value: 'Client', label: 'Client'});
    this.ruleListSubHeaderForm.get('showRecords').setValue(this.emtrConstant.showRecords[0]);
  }

  getEmployeeGroupList(param) {
    const filterObj = {orgId : this.orgID, groupName: param.groupName, active: true};
    this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, filterObj).subscribe((value: any) => {
      this.empGroupDropDownOpts = [];
      this.empGroupDropDownOpts.unshift({label: this.sharedConstant.groupNameAll, value: this.sharedConstant.groupNameAll});
      value.data.content.forEach(empGroupName => {
        const data = {label: empGroupName.name, value: empGroupName.name};
        this.empGroupDropDownOpts.push(data);
      });
      this.ruleListSubHeaderForm.get('empGroupName').setValue(this.empGroupDropDownOpts[0].value);
    });
  }
  getInstrumentGroupList(param: string, isSearch: boolean) {
    let instrumentGroupName: string;
    if (param === 'reset') {
      instrumentGroupName = '';
    } else if (isSearch) {
      instrumentGroupName = param;
    } else {
      instrumentGroupName = this.stateVal && this.stateVal.instrumentSearchedKey ? this.stateVal.instrumentSearchedKey : '';
    }
    this.isInitialListUpdated = !!instrumentGroupName; // check if initial lists are updated
    const defaultObj = {
      name: instrumentGroupName,
      businessUnit : this.businessUnit,
      active: true
    };
    const pageObj = Object.assign({}, this.pageObject);
    pageObj.sortField = ['name,asc'];
    this.sharedService.stateValues.instrumentSearchedKey = instrumentGroupName;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    this.instrumentGroupService.getInstrumentGroupList(pageObj, defaultObj, this.sharedService.pageName).subscribe((response) => {
      this.instrumentGroupDropDownOpts = [];
      this.instrumentGroupDropDownOpts.unshift({label: this.sharedConstant.groupNameAll, value: 0});
      response.data.content.forEach(instrumentGroup => {
        const data = {label: instrumentGroup.name, value: instrumentGroup.id};
        this.instrumentGroupDropDownOpts.push(data);
      });
      if (this.instGroupDropdown && this.instGroupDropdown.options) {
        this.instGroupDropdown.options = this.instrumentGroupDropDownOpts;
      }
      this.ruleListSubHeaderForm.get('instrumentGroupId').setValue(this.instrumentGroupDropDownOpts[0].label);
    }, error => {
      return this.instrumentGroupDropDownOpts;
    });
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (this.clearOrgSubscription) {
      this.clearOrgSubscription.unsubscribe();
    }
  }

  searchedText(event, data) {
    if (data === this.sharedConstant.groupName) {
      this.groupPlanSearchValue = event.target.value;
      this.searchInputUpdate.next(this.groupPlanSearchValue);
    } else if (data === this.sharedConstant.instrumentGroup) {
      this.instrumentGroupSearchValue = event.target.value;
      this.searchInstrumentGroupUpdate.next(this.instrumentGroupSearchValue);
    }
  }

  clearSearch(dropdown: Dropdown) {
    if (!this.groupPlanSearchValue) {
      dropdown.resetFilter();
    }
  }
  clearInstrumentSearch(dropdown: Dropdown) {
    if (!this.instrumentGroupSearchValue) {
      dropdown.resetFilter();
    }
    this.isInstrumentFilterOpen = true;
  }

  groupNameUpdated() {
    if (this.dropdownInputValue && this.dropdownInputValue !== 'All') {
      this.ruleService.hasGroupPlanValue.emit(true);
    } else {
      this.ruleService.hasGroupPlanValue.emit(false);
    }
  }
  instrumentGroupNameUpdated($event) {
    this.instrumentGroupId = $event.value;
    if (this.instrumentGroupDropdownValue || this.instrumentGroupId === 0) {
      this.ruleService.hasInstrumentGroupValue.emit(true);
    } else {
      this.ruleService.hasInstrumentGroupValue.emit(false);
    }
  }

  updateGroupOrPlanName() {
    const name = this.sharedService.selectedGroupOrPlanName ? this.sharedService.selectedGroupOrPlanName : this.dropdownInputValue ? this.dropdownInputValue : 'All';
    const instrumentGroupId = this.instrumentGroupId ? this.instrumentGroupId : 0;
    this.ruleListSubHeaderForm.get('empGroupName').setValue(name);
    this.ruleListSubHeaderForm.get('instrumentGroupId').setValue(instrumentGroupId);
    const localUpdatedFormValues = this.ruleListSubHeaderForm.getRawValue();
    this.sendFilterObject.emit({
      ruleListSubHeaderForm: localUpdatedFormValues,
      filterBtnDisableForTradeSymbol: this.filterBtnDisableForTradeSymbol,
      filterBtnDisableForCusip: this.filterBtnDisableForCusip
    });
  }

  ngAfterViewInit() {
    if (this.groupDropdown && this.dropdownInputValue) {
      this.groupDropdown.filterValue = this.dropdownInputValue;
    }
  }

  updateFormValue() {
    this.stateVal = this.sharedService.getStateVal();
    const showRecordLabel = this.stateVal.filterObj.active ? 'Active' : 'Deleted';
    this.ruleListSubHeaderForm.patchValue({
      tradeSymbol: this.stateVal.filterObj.symbol,
      cusipSymbol: this.stateVal.filterObj.cusip,
      empGroupName: this.stateVal.filterObj.groupName,
      instrumentGroupId: this.stateVal.filterObj.instrumentGroupId,
      view: {value: this.stateVal.filterObj.view, label: this.stateVal.filterObj.view},
      showRecords: {value: this.stateVal.filterObj.active, label: showRecordLabel}
    });
    this.disableOrEnableShowRecord(this.stateVal.filterObj.view);
  }

  disableOrEnableShowRecord(value) {
    if (value) {
      if (!this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageInstruments.uuid, '', '') && value !== 'Client') {
        this.ruleListSubHeaderForm.get('showRecords').setValue(this.emtrConstant.showRecords[0]);
        this.ruleListSubHeaderForm.get('showRecords').disable();
      } else {
        this.ruleListSubHeaderForm.get('showRecords').enable();
      }
    }
  }
}
