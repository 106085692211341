<div>
    <div [formGroup]="calendarForm" novalidate>
        <div class="sdps-grid-container sdps-m-around_none rule-form-date-time-picker">
            <div class="col-6" formGroupName="dateFrom">
                <div class="sdps-p-vertical_small datePickerHeader"><strong>From (ET)</strong></div>
                <div class="sdps-grid-container sdps-m-around_none">
                    <tr-datepicker (calendarValidationEvent)="calendarValidationEvent($event)" [dateLabel]="getConstant.rulesConstant.startDate" [dateForm]="calendarForm.get('dateFrom')" [minDate]="minDate" [showAsterisk]="true" class="col-6 sdps-p-around_none"></tr-datepicker>
                    <tr-timepicker [timeForm]="calendarForm.get('dateFrom')" [timeLabel]="'Start Time*'" class="col-6 sdps-p-around_none"></tr-timepicker>
                </div>
            </div>
            <div class="col-6">
                <div class="sdps-p-vertical_small datePickerHeader">
                    <label>
                        <p-checkbox class="emtr-checkbox" name="datepickerDisabled" binary="true" formControlName="datepickerDisabled" (ngModelChange)="disableToForm()"></p-checkbox>
                        <span class="sdps-p-left_small to-calendar-select" [ngClass]="{'labelDisabled':!(calendarForm.get('datepickerDisabled').value)}"><strong>To (ET)</strong></span>
                    </label>
                </div>
                <div formGroupName="dateTo" class="sdps-grid-container sdps-m-around_none">
                    <tr-datepicker #emtrDatepickerComponent (calendarValidationEvent)="calendarValidationEvent($event)" [showErr]="dateErrCss" [dateLabel]="getConstant.rulesConstant.endDate" [dateForm]="calendarForm.get('dateTo')" [minDate]="minDate" [showAsterisk]="true" class="col-6 sdps-p-around_none"></tr-datepicker>
                    <tr-timepicker [showErr]="timeErrCss" [timeForm]="calendarForm.get('dateTo')" [timeLabel]="'End Time*'" class="col-6 sdps-p-around_none"></tr-timepicker>
                </div>
            </div>
        </div>
    </div>
</div>
