export const SharedConstant = {
  clientCount: 1,
  serviceError: 'Service Error: ',
  employeeHistory: 'Employee History',
  employeeGroupHistory: 'Employee Group History',
  showRecordsCount: [15, 25, 50, 100],
  groupTotalTxt: 'Total No. of Groups:',
  totalTxt: 'Total:',
  cancelText: 'Cancel',
  noRecords: 'No Records Found.',
  toolTipMsgexclamatoryIcon: 'Group update failed. Please re-submit changes to the group',
  defaultValue: 0,
  sessionExpired: 'Your session has expired!',
  pleaseCorrectFollowingError: 'Please correct following error.',
  emptyRecordsMessage: 'Searching...',
  clientChangeConfirmationMsg: 'Are you sure you want to change the Client? This will cancel your session and unsaved changes will be lost.',
  oneSeventyNineDays: 179,
  twentyNineDays: 29,
  showErrMsg: 'Mobile number already exists.',
  employeeAccountSelectionMessage: `<p>You have selected one or more accounts that are part of an Employee ID group.<strong> Would you like to include all grouped accounts in this assignment?</strong></p>
  <ul class="account-popup-content-list">
  <li><span>If <strong>Yes</strong>, all eligible grouped accounts will be auto-selected by the system.</span></li>
  <li><span>If <strong>No</strong>,  the assignment will only apply to your specific selections.</span></li>
  </ul>
   <p class="mt-10">A complete account list can be reviewed on the next step.</p>`,
  bulkGlobal: {
    noRecords: 'No Records Found.',
    multi: 'Multi',
    standard: 'Standard',
    internal: 'Internal',
    external: 'External',
    noMasterAccountToDelink: 'Unable to process this Delink request. The Master Account relationship does not exist.',
    bulkUploadAddlink: 'Bulk Upload - ADDLink'
  },
  bulkUpload: {
    bulkUploadText: 'Bulk Upload',
    bulkUploadManageEmployees: 'Bulk Upload - Manage Employees',
    noClientSelected: 'Please Select a Client to Continue.',
    maxFileSize: '10000000',
    uploadErrorsTxt: 'Upload Details',
    defaultTemplateLabel: 'Download ADDLink Bulk Upload Template',
    downloadTemplateTxt: 'Download Template',
    uploadText: 'Drop Files to Attach, or Browse File.  (10MB Limit. .CSV File Type Only)',
    uploadTxt: 'Upload',
    recordsTxt: 'records',
    errorTxt: 'Errors',
    uploadFileName: 'Filename',
    fileSize: 'File Size needs to be 10 MB.  Please choose a .CSV less than 10 MB.',
    fileType: 'File Type must be .CSV!  Please Select the correct format and try again.',
    uploadError: 'Error while uploading Bulk Upload file.',
    uploadMaximumCountError: 'Maximum Number of Rules is 100.  Please make sure the number of rows is 100 or less',
    reviewUploadTxt: 'Please Review the',
    necessaryChangesTxt: ', make any necessary changes and try again!',
    ruleBulkSuccessMsg: 'File Uploaded successfully.  All uploaded Rules will be available in the Rules Landing Page within 30 minutes.  Please Verify All Uploaded Rules',
    empGrpBulkMsg1: 'File Uploaded successfully.  All uploaded Employees added to',
    empGrpBulkMsg2: 'will be available in the Employee Group Landing Page within 30 minutes.  Please Verify All Employees uploaded',
    empBulkMsg: 'File Uploaded successfully. All uploaded Employees will be available in the Employee Landing Page within 30 minutes. Please Verify All Employees uploaded',
    foundInTxt: 'found in',
    printReportTxt: 'Print Report',
    closeTxt: 'Close',
    empGrpSavedMsg: ' Employee Group was successfully saved.',
    rulesTempFileName: 'EMTR_Rule_Bulk_Upload_Template',
    empGrpTempFileName: 'EMTR_EmployeeGroup_Bulk_Upload_Template',
    planTempFileName: 'EMTR_Plan_Bulk_Upload_Template',
    empTempFileName: 'EMTR_Employees_Bulk_Upload_Template',
    addlinkTemplate: 'ADDLink_Bulk_Upload_Template',
    exportTxt: 'Export',
    uploadID: 'Upload ID: ',
    fileName: 'FileName: ',
    failedMsgTxt: 'Failed Message',
    delayTime: 15000,
    fileProcessing: 'File Processing: ',
    fileReceived: 'File Received: Successful',
    processInProgress: 'In-Progress',
    addlinkSuccessMsg: 'Requests have been submitted. View the status of the Requests on the',
    uploadRedirectMsg: 'You will be redirected to the Uploads tab to view the status of your file.',
    requestList: 'Request List',
    masterAccountInstructionsForSingleSL: 'Note: Master Account value is not required in the upload file.',
    masterAccountInstructionsForMultipleSL: 'Populate the Master Account column in the template with the appropriate Master Account(s) from the following list:',
    masterAccountInstructionsLinkTypeStandard: 'Populate the Master Account column in the template with the appropriate Master Account from the following list:',
    toLinkToMultipleMasterAccounts: 'To Link/DeLink to multiple Master Accounts, insert all accounts in the same cell separated by semicolon (example: 08355415;08428763).',
    includeOnlyMasterAccountNumbers: 'Include only the Master Account Number(s) in the file',
    note: 'Note:',
    noteIncludeOnlyMasterAccountNumber: 'Note: Include only the Master Account Number in the file',
    chooseFileText: 'Choose file',
    removeFileText: 'Remove to select another file',
    downloadBulkSecurityTemplate: 'Bulk Load Template'
  },
  confirmationDialog: {
    title: 'Confirmation',
    accountSelectionConfirmationTitle: 'Account Selection Confirmation',
    acceptBtn: 'Yes',
    rejectBtn: 'No',
    message: '',
    hideAcceptButton: 'hideAcceptButton'
  },
  deleteModal: {
    modalTitle: 'Delete',
    removeEmployeeTxt: 'Remove Employee',
    confirmMsg: 'Are you sure you want to delete?',
    removeEmployeeAlert: 'Are you sure you want to remove Employee? They may be within Groups and behind Trade rules.This action will remove them from all Groups and Trade rules and <strong>cannot be undone.</strong><br>',
    cancelTxt: 'Cancel',
    successMsg: 'Employee Group has been deleted.',
    deleteSuccessMsg: 'has been removed',
    employeeTxt: 'Employee',
    participantTxt: 'Participant',
    yesTxt: 'Yes',
    noTxt: 'No'
  },
  viewHistory: {
    header: 'Header',
    searchTitle: 'Search for Historical Data',
    SelectDataType: 'Select Data Type'
  },
  manageEmployeeLabels: {
    customizeView: 'Customize View',
    manageEmployee: 'Manage Employees',
    delink: 'Delink'
  },
  doughnutChartLabels: {
    daysTxt: 'Days',
    internalChartTxt: 'em CharlesModern-Regular, Arial, Helvetica, sans-serif',
  },
  agreements: {
    fileName: 'AGREEMENTS_DISCLOSURES',
    sposName: 'AGREEMENTSANDDISCLOSURES'
  },
  date_format: {
    mm_dd_yy: 'MM-DD-YYYY'
  },
  filterButton: {
    clearAllButton: 'Clear',
    applyFilterButton: 'Apply'
  },
  textFieldErrorMsg: {
    invalidCharacterForName: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, ., -,_, \' (apostrophe), , (comma) and space. The name cannot start with special characters, spaces, any number, or end with special characters.',
    employeeIdErrorMsg: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9,- . \' (apostrophe).',
    accountNumberShouldContain8Digits: 'Account Number should contain 8 digits.',
    minCharLimitErrorMsg: 'Please enter at least 2 Characters before filtering.',
    uploadsFileNameError: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9,_.',
    invalidCharacterForGrpOrRuleName: 'Invalid Character Entered - Please Use Valid Characters Only A-Z, a-z, 0-9, +,-,_,(,),[,], . (period) and , (comma).',
    invalidNumericCharacterEntered: 'Invalid Character Entered - Please enter numeric values 0-9',
    cusipShouldContain9Digits: 'CUSIP should contain 9 characters.',
    reportWarnMsg: 'Report can only be run for Symbol or CUSIP.  Remove the current entry in order to change the report criteria.'
  },
  manageEmployeeActionItems: {
    editText: 'Edit',
    historyText: 'History',
    addEmployeeText: 'Add Employee',
    employeeId: 'empId',
    editEmployee: 'editEmployee',
    restore: 'Restore'
  },
  linkTypeStandard: 'Standard',
  actionData: [{value: 'All', label: 'All'}, {value: 'Delink', label: 'Delink'}, {value: 'Link', label: 'Link'}],
  liableData: [{value: 'All', label: 'All'}, {value: 'Yes', label: 'Yes'}, {value: 'No', label: 'No'}],
  statusData: [
    {value: 'Complete', label: 'Complete'},
    {value: 'DBS Action Needed', label: 'DBS Action Needed'},
    {value: 'Ineligible Request', label: 'Ineligible Request'},
    {value: 'On Hold', label: 'On Hold'},
    {value: 'Received', label: 'Received'},
    {value: 'Request Denied', label: 'Request Denied'},
    {value: 'Validating', label: 'Validating'},
    {value: 'Validation Mismatch', label: 'Validation Mismatch'}
  ],
  sourceData: [
    {value: 'ADDLink(DBS)', label: 'ADDLink(DBS)'},
    {value: 'Client Central', label: 'Client Central'},
    {value: 'SCT (Client)', label: 'SCT (Client)'}
  ],
  requireIndexing: 'Require Indexing',
  clientCentralLinks: 'Client Central Links',
  clientCentralDelinks: 'Client Central Delinks',
  accessDeniedMessage: 'You do not have permission to access this page.',
  DBS: {
    subTabName : 'Manage Employee Groups',
    createBtn: 'Add New Employee Group',
    step1IndicatorName: 'Enter Group Details',
    step2IndicatorName: 'Add Employees',
    toStep1 : '../create-employee-group',
    toStep3 : '../create-employee-group/step3',
    toLandingPage: '/ems/emtr/manage-employee-groups/employee-group-list',
    groupTotalTxt: 'Total No. of Groups:',
    mainRoute: 'manage-employee-groups',
    name: 'Group Name',
    description: 'Group Description',
    nameAndDescription: 'Group Name and Description',
    employeeGroupOrPlanHistory: 'Employee Group History',
    addNewEmpGrpOrPlan: 'Add New Employee Group',
    editEmpGrpOrPlan: 'Edit Employee Group',
    viewEmpGrpOrPlan: 'View Employee Group',
    uploadType:  [{value: 'All', label: 'All'}, {value: 'ADDLink', label: 'ADDLink'}, {
      value: 'Employee',
      label: 'Employee'
    }, {value: 'Employee Group', label: 'Employee Group'}, {value: 'Rule', label: 'Rule'}],
    addLinkQuickLink: [
      { name: 'Request List', link: 'ems/addLink/request-list', gutTrack: 'linkid-lc_ql_requestlist' },
      { name: 'Link', link: 'ems/addLink/link', gutTrack: 'linkid-lc_ql_link' },
      { name: 'Delink', link: 'ems/addLink/delink', gutTrack: 'linkid-lc_ql_delink' },
      { name: 'Bulk Upload - ADDLink', link: 'ems/addLink/addlink-upload', gutTrack: 'linkid-lc_ql_addlink_upload' },
      ],
    deleteEmpGroupMessage: ' is assigned to following Rules and will no longer be applicable to the Employee Accounts that are part of this Employee Group.  Please review the Rule assignment to assure the rule is still applicable.',
    masterGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    empGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    deleteEmpGroupOrPlanSuccessMsg: 'Employee Group has been deleted.',
    restoreEmpGroupOrPlanSuccessMsg: 'Employee Group has been restored.<br><p></p><strong>Note:</strong> This does not automatically restore the Group to any rules that previously used it. Re-assignments will need to be made manually.',
    empGrpOrPlanSavedMsg: ' Employee Group was successfully saved.',
    empOrParticipantsID: 'Employee ID',
    empOrParticipantsAccount: 'Account Number',
    empOrParticipantsLastName: 'Employee Last Name',
    empOrParticipantsFirstName: 'Employee First Name',
    empOrParticipantsStatus: 'Employee Status',
    empOrParticipantIdAssociation: 'Employee ID Association',
    employeeOrParticipant: 'Employees',
    employeeOrParticipantAddedOrRemoved: 'Added / Removed Employees',
    uploadEmployeeOrParticipantList : 'Upload Employee List',
    employeeOrParticipantHistory: 'Employee History',
    toolTipMsgexclamatoryIcon: 'Group update failed. Please re-submit changes to the group',
    empParticipantSubtabName: 'Manage Employees',
    empParticipantMainRoute: 'manage-employees',
    toEmpParticipantLandingPage: '/ems/emtr/manage-employees/employee-list',
    bulkUploadManageEmployeesOrParticipants: 'Bulk Upload - Manage Employees',
    addEmployeeOrParticipant: 'Add Employee',
    editEmployeeOrParticipant: 'Edit Employee',
    removeEmployeeOrParticipant: 'Remove Employee',
    removeEmployeeOrParticipantAlert: 'Are you sure you want to remove Employee? They may be within Groups and behind Trade rules.This action will remove them from all Groups and Trade rules and <strong>cannot be undone.</strong><br>',
    empGrpOrPlanownloadTemplateTxt: 'Download Manage Employee Groups Template',
    bulkUploadManageEmployeeGroupOrPlanTitle: 'Bulk Upload - Manage Employee Groups',
    toStep2FromBulkUpload: '/ems/emtr/manage-employee-groups/create-employee-group/step2',
    groupOrPlanDownloadTemplate: 'EMPGROUP',
    groupOrPlanBulkUploadRoute: '/ems/emtr/manage-employee-groups/emp-group-bulk-upload',
    employeeGrpOrPlan: 'Employee Groups',
    employeeOrParticipantFirstNameRequiredMsg: 'Employee first name is a required field!',
    employeeOrParticipantLastNameRequiredMsg: 'Employee last name is a required field!',
    groupOrPartcUploadTemplate: 'EmpGroupUpload',
    empGrpOrPlanExistTxt: 'employee group name already exists - please enter a unique employee group name',
    continueByAssigningRule: 'Continue by assigning rule to All Employees, Employee Groups, Employee ID or Employee Account #',
    employeeErrorMsgForAllGroup: 'Please enter a value in Employee Id or Account Number or Employee LastName or Account Holder LastName to run the report.',
    empOrParticipantAccountErrMsg: 'Please enter a value that is numeric for Employee Account.',
    overrideJustification: ['10b5-1', 'Legal Clearance', 'Tax Cover', 'Margin Sellout', 'Other'],
    enterGroupOrPlanName: 'Group Name',
    toEmployeeOrParticipantLandingPage: '/ems/emtr/manage-employees/employee-list',
    selectionWarningMessage: 'Rules can only be assigned to Employee Groups or Employees. Remove the current selection in order to change the assignment type.',
    empParticipantFilterGutTrackValue: 'adobe-fl_employeelistfilter|fireonce',
    addNewGutTrackValue: 'newemployeegroup',
    grpPlanFilterGutTrackValue: 'adobe-fl_manageemployeegroupsfilter|fireonce',
    grpPlanDownloadTemplateGTValue: 'adobe-dl_manageemployeegroupsupload_template|fireonce',
    grpPlanChooseFileGTValue: 'adobe-lc_manageemployeegroupsupload_choosefile|fireonce',
    grpPlanCancelBtnGTValue: 'manageemployeegroupsupload_cancel',
    grpPlanUploadBtnGTValue: 'manageemployeegroupsupload_upload',
    warningMsgForPresetRulesOrSecurities: 'Rules can only be created for Security Level or based on Asset Groups.'
  },
  SPS: {
    subTabName : 'Manage Employee Groups',
    createBtn: 'Add New Employee Group',
    workflow: 'manageEmpGrp',
    step1IndicatorName: 'Enter Group Details',
    step2IndicatorName: 'Add Employees',
    toStep1 : '../create-employee-group',
    toStep3 : '../create-employee-group/step3',
    toLandingPage: '/ems/emtr/manage-employee-groups/employee-group-list',
    groupTotalTxt: 'Total No. of Groups:',
    mainRoute: 'manage-employee-groups',
    name: 'Group Name',
    description: 'Group Description',
    nameAndDescription: 'Group Name and Description',
    employeeGroupOrPlanHistory: 'Employee Group History',
    addNewEmpGrpOrPlan: 'Add New Employee Group',
    editEmpGrpOrPlan: 'Edit Employee Group',
    viewEmpGrpOrPlan: 'View Employee Group',
    uploadType:  [{value: 'All', label: 'All'}, {value: 'ADDLink', label: 'ADDLink'}, {
      value: 'Employee',
      label: 'Employee'
    }, {value: 'Employee Group', label: 'Employee Group'}, {value: 'Rule', label: 'Rule'}],
    addLinkQuickLink: [
      { name: 'Request List', link: 'ems/addLink/request-list', gutTrack: 'linkid-lc_ql_requestlist' },
      { name: 'Link', link: 'ems/addLink/link', gutTrack: 'linkid-lc_ql_link' },
      { name: 'Delink', link: 'ems/addLink/delink', gutTrack: 'linkid-lc_ql_delink' },
      { name: 'Bulk Upload - ADDLink', link: 'ems/addLink/addlink-upload', gutTrack: 'linkid-lc_ql_addlink_upload' },
      ],
    deleteEmpGroupMessage: ' is assigned to following Rules and will no longer be applicable to the Employee Accounts that are part of this Employee Group.  Please review the Rule assignment to assure the rule is still applicable.',
    masterGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    empGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    deleteEmpGroupOrPlanSuccessMsg: 'Employee Group has been deleted.',
    restoreEmpGroupOrPlanSuccessMsg: 'Employee Group has been restored.<br><p></p><strong>Note:</strong> This does not automatically restore the Group to any rules that previously used it. Re-assignments will need to be made manually.',
    empGrpOrPlanSavedMsg: ' Employee Group was successfully saved.',
    empOrParticipantsID: 'Employee ID',
    empOrParticipantsAccount: 'Account Number',
    empOrParticipantsLastName: 'Employee Last Name',
    empOrParticipantsFirstName: 'Employee First Name',
    empOrParticipantsStatus: 'Employee Status',
    empOrParticipantIdAssociation: 'Employee ID Association',
    employeeOrParticipant: 'Employees',
    employeeOrParticipantAddedOrRemoved: 'Added / Removed Employees',
    uploadEmployeeOrParticipantList : 'Upload Employee List',
    employeeOrParticipantHistory: 'Employee History',
    toolTipMsgexclamatoryIcon: 'Group update failed. Please re-submit changes to the group',
    empParticipantSubtabName: 'Manage Employees',
    empParticipantMainRoute: 'manage-employees',
    toEmpParticipantLandingPage: '/ems/emtr/manage-employees/employee-list',
    bulkUploadManageEmployeesOrParticipants: 'Bulk Upload - Manage Employees',
    addEmployeeOrParticipant: 'Add Employee',
    editEmployeeOrParticipant: 'Edit Employee',
    removeEmployeeOrParticipant: 'Remove Employee',
    removeEmployeeOrParticipantAlert: 'Are you sure you want to remove Employee? They may be within Groups and behind Trade rules.This action will remove them from all Groups and Trade rules and <strong>cannot be undone.</strong><br>',
    empGrpOrPlanownloadTemplateTxt: 'Download Manage Employee Groups Template',
    bulkUploadManageEmployeeGroupOrPlanTitle: 'Bulk Upload - Manage Employee Groups',
    toStep2FromBulkUpload: '/ems/emtr/manage-employee-groups/create-employee-group/step2',
    groupOrPlanDownloadTemplate: 'EMPGROUP',
    groupOrPlanBulkUploadRoute: '/ems/emtr/manage-employee-groups/emp-group-bulk-upload',
    employeeGrpOrPlan: 'Employee Groups',
    employeeOrParticipantFirstNameRequiredMsg: 'Employee first name is a required field!',
    employeeOrParticipantLastNameRequiredMsg: 'Employee last name is a required field!',
    groupOrPartcUploadTemplate: 'EmpGroupUpload',
    empGrpOrPlanExistTxt: 'employee group name already exists - please enter a unique employee group name',
    continueByAssigningRule: 'Continue by assigning rule to All Employees, Employee Groups, Employee ID or Employee Account #',
    employeeErrorMsgForAllGroup: 'Please enter a value in Employee Id or Account Number or Employee LastName or Account Holder LastName to run the report.',
    empOrParticipantAccountErrMsg: 'Please enter a value that is numeric for Employee Account.',
    overrideJustification: ['10b5-1', 'Legal Clearance', 'Tax Cover', 'Margin Sellout', 'Other'],
    enterGroupOrPlanName: 'Group Name',
    toEmployeeOrParticipantLandingPage: '/ems/emtr/manage-employees/employee-list',
    selectionWarningMessage: 'Rules can only be assigned to Employee Groups or Employees. Remove the current selection in order to change the assignment type.',
    empParticipantFilterGutTrackValue: 'adobe-fl_employeelistfilter|fireonce',
    addNewGutTrackValue: 'newemployeegroup',
    grpPlanFilterGutTrackValue: 'adobe-fl_manageemployeegroupsfilter|fireonce',
    grpPlanDownloadTemplateGTValue: 'adobe-dl_manageemployeegroupsupload_template|fireonce',
    grpPlanChooseFileGTValue: 'adobe-lc_manageemployeegroupsupload_choosefile|fireonce',
    grpPlanCancelBtnGTValue: 'manageemployeegroupsupload_cancel',
    grpPlanUploadBtnGTValue: 'manageemployeegroupsupload_upload',
  },
  RBS: {
    subTabName : 'Manage Plans',
    createBtn: 'Add New Plan',
    workflow: 'managePlans',
    step1IndicatorName: 'Enter Plan Details',
    step2IndicatorName: 'Add Participants',
    toStep1 : '../create-plan',
    toStep3 : '../create-plan/step3',
    toLandingPage: '/ems/emtr/manage-plans/plans-list',
    groupTotalTxt: 'Total No. of Plans:',
    mainRoute: 'manage-plans',
    name: 'Plan Name',
    description: 'Plan Description',
    nameAndDescription: 'Plan Name and Description',
    employeeGroupOrPlanHistory: 'Plan History',
    addNewEmpGrpOrPlan: 'Add New Plan',
    editEmpGrpOrPlan: 'Edit Plan',
    viewEmpGrpOrPlan: 'View Plan',
    uploadType:  [{value: 'All', label: 'All'}, {value: 'Employee', label: 'Employee'},
      {value: 'Participant Plan', label: 'Participant Plan'}, {value: 'Rule', label: 'Rule'}],
    addLinkQuickLink: [
      { name: 'Request List', link: 'ems/addLink/request-list', gutTrack: 'linkid-lc_ql_requestlist' },
    ],
    deleteEmpGroupMessage: ' is assigned to following Rules and will no longer be applicable to the Participant Accounts that are part of this Plan.  Please review the Rule assignment to assure the rule is still applicable.',
    masterGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    empGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    deleteEmpGroupOrPlanSuccessMsg: 'Plan has been deleted.',
    restoreEmpGroupOrPlanSuccessMsg: 'Plan has been restored.<br><p></p><strong>Note:</strong> This does not automatically restore the Plan to any rules that previously used it. Re-assignments will need to be made manually.',
    empGrpOrPlanSavedMsg: ' Plan was successfully saved.',
    empOrParticipantsID: 'Participant ID',
    empOrParticipantsAccount: 'Account Number',
    empOrParticipantsLastName: 'Participant Last Name',
    empOrParticipantsFirstName: 'Participant First Name',
    empOrParticipantsStatus: 'Participant Status',
    empOrParticipantIdAssociation: 'Participant ID Association',
    employeeOrParticipant: 'Participants',
    employeeOrParticipantAddedOrRemoved: 'Added / Removed Participants',
    uploadEmployeeOrParticipantList : 'Upload Participant List',
    employeeOrParticipantHistory: 'Participant History',
    toolTipMsgexclamatoryIcon: 'Plan update failed. Please re-submit changes to the plan',
    empParticipantSubtabName: 'Manage Participants',
    empParticipantMainRoute: 'manage-participants',
    toEmpParticipantLandingPage: '/ems/emtr/manage-participants/participant-list',
    bulkUploadManageEmployeesOrParticipants: 'Bulk Upload - Manage Participants',
    addEmployeeOrParticipant: 'Add Participant',
    editEmployeeOrParticipant: 'Edit Participant',
    removeEmployeeOrParticipant: 'Remove Participant',
    removeEmployeeOrParticipantAlert: 'Are you sure you want to remove Participant? They may be within Plans and behind Trade rules.This action will remove them from all Plans and Trade rules and <strong>cannot be undone.</strong><br>',
    empGrpOrPlanownloadTemplateTxt: 'Download Manage Participant Plans Template',
    bulkUploadManageEmployeeGroupOrPlanTitle: 'Bulk Upload - Manage Participant Plans',
    toStep2FromBulkUpload: '/ems/emtr/manage-plans/create-plan/step2',
    groupOrPlanDownloadTemplate: 'PARTICIPANT',
    groupOrPlanBulkUploadRoute: '/ems/emtr/manage-plans/emp-plan-bulk-upload',
    employeeGrpOrPlan: 'Plans',
    employeeOrParticipantFirstNameRequiredMsg: 'Participant first name is a required field!',
    employeeOrParticipantLastNameRequiredMsg: 'Participant last name is a required field!',
    groupOrPartcUploadTemplate: 'PartcPlanUpload',
    empGrpOrPlanExistTxt: 'plan name already exists - please enter a unique plan name',
    continueByAssigningRule: 'Continue by assigning rule to All Participants, Plans, or Participant Account #',
    employeeErrorMsgForAllGroup: 'Please enter a value in Account Number or Participant LastName or Account Holder LastName to run the report.',
    empOrParticipantAccountErrMsg: 'Please enter a value that is numeric for Participant Account.',
    overrideJustification: ['Plan Sponsor', 'Record Keeper', 'Security Override', 'Trustee', 'Other'],
    enterGroupOrPlanName: 'Plan Name',
    toEmployeeOrParticipantLandingPage: '/ems/emtr/manage-participants/participant-list',
    selectionWarningMessage: 'Rules can only be assigned to Plans or Participants. Remove the current selection in order to change the assignment type.',
    empParticipantFilterGutTrackValue: 'adobe-fl_participantlistfilter|fireonce',
    addNewGutTrackValue: 'newplan',
    grpPlanFilterGutTrackValue: 'adobe-fl_manageplansfilter|fireonce',
    grpPlanDownloadTemplateGTValue: 'adobe-dl_manageplansupload_template|fireonce',
    grpPlanChooseFileGTValue: 'adobe-lc_manageplansupload_choosefile|fireonce',
    grpPlanCancelBtnGTValue: 'manageplansupload_cancel',
    grpPlanUploadBtnGTValue: 'manageplansupload_upload',
    warningMsgForPresetRulesOrSecurities: 'Rules can only be created for Security Level or as Preset Rules.'
  },
  MGP: {
    step1IndicatorName: 'Enter Group Details',
    step2IndicatorName: 'Add Master Accounts',
    toStep1 : '../create-master-group',
    toStep3 : '../create-master-group/step3',
    toLandingPage: '/ems/emtr/master-groups/master-groups-list',
    mainRoute: 'master-groups',
    name: 'Group Name',
    description: 'Group Description',
    nameAndDescription: 'Group Name and Description',
    employeeGroupOrPlanHistory: 'Master Group History',
    addNewEmpGrpOrPlan: 'Add New Master Group',
    editEmpGrpOrPlan: 'Edit Master Group',
    viewEmpGrpOrPlan: 'View Master Group',
    deleteEmpGroupMessage: ' is assigned to following Rules and will no longer be applicable to the Master Accounts that are part of this Master Group.  Please review the Rule assignment to assure the rule is still applicable.',
    masterGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    empGrpDeleteMsg: 'must be removed from the following assignment(s) before it can be deleted:',
    deleteEmpGroupOrPlanSuccessMsg: 'Master Group has been deleted.',
    restoreEmpGroupOrPlanSuccessMsg: 'Master Group has been restored.<br><p></p><strong>Note:</strong> This does not automatically restore the Group to any rules that previously used it. Re-assignments will need to be made manually.',
    empGrpOrPlanSavedMsg: ' Master Group was successfully saved.',
    employeeOrParticipantAddedOrRemoved: 'Added / Removed Master Account',
    toolTipMsgexclamatoryIcon: 'Group update failed. Please re-submit changes to the group',
    empGrpOrPlanExistTxt: 'master group name already exists - please enter a unique master group name',
    employeeGrpOrPlan: 'Master Groups',
    employeeOrParticipant: 'Master Accounts',
    overrideJustification: ['Plan Sponsor', 'Record Keeper', 'Security Override', 'Trustee', 'Other'],
    selectionWarningMesgForMGP: 'Global Rules can only be assigned to Master Groups or Master Accounts. Remove the current selection in order to change the assignment type.'
  },
  All: {
    addLinkQuickLink: [
      { name: 'Request List', link: 'ems/addLink/request-list', gutTrack: 'linkid-lc_ql_requestlist' },
      { name: 'Link', link: 'ems/addLink/link', gutTrack: 'linkid-lc_ql_link' },
      { name: 'Delink', link: 'ems/addLink/delink', gutTrack: 'linkid-lc_ql_delink' },
      { name: 'Bulk Upload - ADDLink', link: 'ems/addLink/addlink-upload', gutTrack: 'linkid-lc_ql_addlink_upload' },
      ]},
  instrumentGrpDeleteMsg: 'Instrument Group must be removed from the following Rule - Plan assignment(s) before it can be deleted: ',
  accessDeniedTooltipMessage: 'Selected client does not have permission to access this page.',
  rbsBUText: 'RBS',
  dbsBUText: 'DBS',
  spsBUText: 'SPS',
  instrumentGroupAdminText: 'Instrument Group Admin',
  dbsInstrumentGroupAdminText: 'DBS Instrument Group Admin',
  ruleAgentText: 'Rule Agent',
  rbsRuleAgentViewText: 'RBS Rule Agent View',
  rbsRuleAgentText: 'RBS Rule Agent',
  groupNameAll: 'All',
  groupName: 'groupName',
  instrumentGroup: 'instrumentGroup',
  userGuide: {
    downloadUserGuide: 'Download User Guide',
    ruleUG: {
      ruleUserGuideTxt: 'Trade Rules User Guide',
      RBS: {
        value: 'RULEUSERRBS',
        fileName: 'RBS_SchwabTradeRules_UserGuide',
      }
    }
  },
  clientNamePatternCheck: '^[a-zA-Z0-9+_()\[\\],.\\- &\'/]*$',
  globalTimeoutDialog: {
    title: 'Session Timeout',
    logOut: 'Log Out',
    stayLoggedIn: 'Stay Logged In',
    message: '<p><strong>Would you like to stay logged in?</strong></p>',
    sessionExpiredMessage: '<p>Your session will expire in:</p>',
    minsAndSec: '<p>minutes &nbsp&nbsp&nbsp seconds</p>'
  },
  manageMasterGroups: {
    masterGrpHistoryTxt: 'Master Group History',
    groupTypeMGP: 'MGP',
    acctTypeMSTR: 'MSTR'
  },
  errorMassageHeading: {
    userNameMsg: 'Username',
    userActionMsg: 'Action',
    userBrowserMsg: 'Browser',
    userTimeMsg: 'Time'
 },
 browserDetails: {
  uCBrowser: 'UCBrowser',
  edge: 'Edge',
  googleBot: 'GoogleBot',
  chromium: 'Chromium',
  firefox: 'Firefox',
  chrome: 'Chrome',
  safari: 'Safari',
  opera: 'Opera',
  iE: 'IE'
 },
  showRecords: [{value: true, label: 'Active'}, {value: false, label: 'Deleted'}]
};
