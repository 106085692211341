export const linkRequestColumns: any[] = [
  { field: 'requestId', header: 'Request ID' },
  { field: 'status', header: 'Status' },
  { field: 'masterNumber', header: 'Master Account' },
  { field: 'accountNumber', header: 'Account No.' },
  { field: 'employeeId', header: 'Employee ID' },
  { field: 'employeeFirstName', header: 'Employee First Name' },
  { field: 'employeeLastName', header: 'Employee Last Name' },
  { field: 'accountHolderFirstName', header: 'Account Holder First Name' },
  { field: 'accountHolderLastName', header: 'Account Holder Last Name' },
  { field: 'accountHolderAffiliation', header: 'Emp. to Acct. Holder Affiliation' },
  { field: 'indexNumber', header: 'Index Key' },
  { field: 'remarks', header: 'Remarks' },
  { field: 'actions', header: 'Action' }
];

export const delinkRequestColumns: any[] = [
  { field: 'requestId', header: 'Request ID' },
  { field: 'status', header: 'Status' },
  { field: 'accountNumber', header: 'Account No.' },
  { field: 'employeeFirstName', header: 'Employee First Name' },
  { field: 'employeeLastName', header: 'Employee Last Name' },
  { field: 'employeeId', header: 'Employee ID' },
  { field: 'masterAccountNumber', header: 'Master Account' },
  { field: 'remarks', header: 'Remarks' },
  { field: 'actions', header: 'Action' }
];

export const requestListHeaderColumns: any[] = [
  { field: 'requestId', header: 'Request Id', enum: 'textbox' },
  { field: 'action', header: 'Action', enum: 'dropdown'},
  { field: 'internalStatus', header: 'Status', enum: 'multiselect'},
  { field: 'status', header: 'Status', enum: 'multiselect'},
  { field: 'employeeId', header: 'Employee Id', enum: 'textbox' },
  { field: 'employeeName', header: 'Employee Name', enum: 'textbox'},
  { field: 'acctNumber', header: 'Account No.', enum: 'textbox' },
  { field: 'client', header: 'Client Name', enum: 'textbox' },
  { field: 'masterAccountNbr', header: 'Master Account', enum: 'textbox'}
];

export const requestListRequestColumns: any[] = [
  { field: 'requestId', header: 'Request Id' },
  { field: 'action', header: 'Action' },
  { field: 'internalStatus', header: 'Status' },
  { field: 'status', header: 'Status' },
  { field: 'employeeId', header: 'Employee Id' },
  { field: 'employeeName', header: 'Employee Name' },
  { field: 'acctNumber', header: 'Account No.' },
  { field: 'client', header: 'Client Name' },
  { field: 'masterAccountNbr', header: 'Master Account' },
  { field: 'acctHolderName', header: 'Account Holder Name' },
  { field: 'accountHolderAffiliation', header: 'Emp to Acct Affiliation' },
  { field: 'liable407', header: '407 Liable' },
  { field: 'indexKey', header: 'Index Key' },
  { field: 'commissionCode', header: 'Comm Code' },
  { field: 'authAgentName', header: 'Authorized Agent' },
  { field: 'source', header: 'Source' },
  { field: 'createdBy', header: 'Submitted By' },
  { field: 'createdDate', header: 'Submitted Date / Time' },
  { field: 'completedBy', header: 'Completed By' },
  { field: 'completedDate', header: 'Completed Date / Time' },
];


export const requestListDataRowColumns: any[] = [
  { field: 'acctHolderName', header: 'Account Holder Name', enum: 'textbox' },
  { field: 'accountHolderAffiliation', header: 'Emp to Acct Affiliation', enum: ' ' },
  { field: 'liable407', header: '407 Liable', enum: 'dropdown' },
  { field: 'source', header: 'Source', enum: 'multiselect' },
  { field: 'indexKey', header: 'Index Key', enum: 'textbox' },
  { field: 'commissionCode', header: 'Comm Code', enum: ' ' },
  { field: 'authAgentName', header: 'Authorized Agent', enum: 'multiselect'},
  { field: 'createdBy', header: 'Submitted By', enum: 'textbox'},
  { field: 'completedBy', header: 'Completed By',  enum: 'textbox'},
  { field: 'createdDate', header: 'Submitted Date / Time', enum: 'dateTime' },
  { field: 'completedDate', header: 'Completed Date / Time', enum: 'dateTime' }
];

export const requestListDataRowDateColumns: any[] = [

];

export const requestListSubHeaderDateColumns: any[] = [
  { field: 'createdDate', header: 'Submitted Date', enum: 'calendar' },
  { field: 'liable407', header: '407 Liable', enum: 'dropdown' },
  { field: 'source', header: 'Source', enum: 'multiselect' }

  /* { field: 'completedDate', header: 'Completed Date', enum: 'dateTime' } */
];

