import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-manage-clients',
  templateUrl: './manage-clients.component.html'
})
export class ManageClientsComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
