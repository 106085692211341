<ems-confirm-message #confirmationMessage></ems-confirm-message>
<ems-toast-message #toastMessage></ems-toast-message>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="(linkRequestForm.get('accountNumber').value && linkRequestForm.get('accountNumber').invalid)
                      || (linkRequestForm.get('accountNumber').value && isAccNumInvalid)
                      || (linkRequestForm.get('firstName').value && linkRequestForm.get('firstName').invalid)
                      || (linkRequestForm.get('employeeID').value && linkRequestForm.get('employeeID').invalid)
                      || (linkRequestForm.get('lastName').value && linkRequestForm.get('lastName').invalid)
                      || (linkRequestForm.get('accountHolderFirstName').value && linkRequestForm.get('accountHolderFirstName').invalid)
                      || (linkRequestForm.get('accountHolderLastName').value && linkRequestForm.get('accountHolderLastName').invalid)
                      || (linkRequestForm.get('planId').value && linkRequestForm.get('planId').invalid)">
  <div class="sdps-notification__text">
    <p [innerHTML]="addLinkConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="linkRequestForm.get('accountNumber').value && isAccNumInvalid"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.accountNumberShouldContain8Digits"></li>
      <li *ngIf="(linkRequestForm.get('accountNumber').value && linkRequestForm.get('accountNumber').invalid)"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.accountNumbershouldBeNumeric"></li>
      <li *ngIf="(linkRequestForm.get('employeeID').value && linkRequestForm.get('employeeID').invalid)"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.employeeIdErrorMsg"></li>
      <li *ngIf="(linkRequestForm.get('firstName').value && linkRequestForm.get('firstName').invalid)
                  || (linkRequestForm.get('lastName').value && linkRequestForm.get('lastName').invalid)
                  || (linkRequestForm.get('accountHolderFirstName').value && linkRequestForm.get('accountHolderFirstName').invalid)
                  || (linkRequestForm.get('accountHolderLastName').value && linkRequestForm.get('accountHolderLastName').invalid)"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.invalidCharacterForName"></li>
      <li *ngIf="(linkRequestForm.get('planId').value && linkRequestForm.get('planId').invalid)"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.invalidCharacterEnteredNumericValue"></li>
    </ul>
  </div>
</sdps-notification>

<sdps-notification type="information" class="sdps-m-top_none" *ngIf="!orgId">
  <p class="sdps-notification__text" [innerHTML]="addLinkConstant.noCientSelected"></p>
</sdps-notification>

<form [formGroup]="linkRequestForm" *ngIf="orgId">

  <div>
    <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block">Link</h2>

    <span class="sdps-m-horizontal_large client-selection-indicator"
          [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
          [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
        </span>

    <div *ngIf="!isAuthAgentRole" class="sdps-display-inline-block">
            <span *ngIf="linkType !== null"
                  [ngClass]="{'addlink-chip': true, 'addlink-chip_link-type-standard': linkType==='Standard', 'addlink-chip_link-type-multi': linkType!== 'Standard' }">
                {{linkType}}
            </span>
    </div>
    <div class="sdps-display-inline-block sdps-m-horizontal_large"
         *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.accountLinking.uuid,'','')">
      <button type="button" class="buttons" (click)="bulkSubmit()">{{addLinkConstant.addLkGlobal.bulkUploadAddlink}}</button>
    </div>
  </div>

  <div class="sdps-mosaic-tile sdps-m-bottom_large">

    <div class="sdps-mosaic-tile__content ">

      <div class="sdps-grid-fluid sdps-wrap">

        <div class="sdps-row">
          <div class="col-2">
            <div class="ems-input link-input-textbox "
                 [ngClass]="{'invalidField': (linkRequestForm.get('accountNumber').value && linkRequestForm.get('accountNumber').invalid) || (linkRequestForm.get('accountNumber').value && isAccNumInvalid)}">
                    <span class="p-float-label">
                      <input formControlName="accountNumber" [maxlength]="8" emsNumberPatterncheck
                             (focusout)="validateAccountNumber($event)" pInputText/>
                      <label>Account Number<em class="highlight-color">*</em></label>
                    </span>
            </div>
          </div>

          <div class="col-2">
            <div class="ems-input link-input-textbox" [ngClass]="{'invalidField': (linkRequestForm.get('employeeID').value && linkRequestForm.get('employeeID').invalid)}">
                    <span class="p-float-label">
                      <input type="text" formControlName="employeeID" [maxLength]="35"
                             emsEmployeeIdPatterncheck emsCharacterLimit [charMaxLimit]="commonConstants.charLimitValues.thirtyFiveMaxLimit"
                             (emsCharacterLimitCheck)="showEmpIdCharLimit = $event" pInputText/>
                      <label>Employee ID</label>
                    </span>
                  <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small" *ngIf="linkRequestForm.get('employeeID').value && linkRequestForm.get('employeeID').value.length > showEmpIdCharLimit">{{addLinkConstant.characterLimit}}
                    <span class="character-limit">{{linkRequestForm.get('employeeID').value.length}}/{{commonConstants.charLimitValues.thirtyFiveMaxLimit}}</span>
                  </p>
            </div>
          </div>

          <div class="col-2" *ngIf="showPlanIDField">
            <div class="ems-input link-input-textbox"
                 [ngClass]="{'invalidField': (linkRequestForm.get('planId').value && linkRequestForm.get('planId').invalid)}">
                    <span class="p-float-label">
                      <input type="number" onkeypress="return (event.which >= 48 && event.which <= 57 && this.value.toString().length < 10)" formControlName="planId" (blur)="removeLeadingZeroes()"
                             emsNumberPatterncheck pInputText/>
                      <label>Plan ID<em class="highlight-color">*</em></label>
                    </span>
            </div>
          </div>
        </div>

        <div class="sdps-row sdps-m-top_small">
          <div class="col-2">
            <div class="ems-input link-input-textbox"
                 [ngClass]="{'invalidField': (linkRequestForm.get('firstName').value && linkRequestForm.get('firstName').invalid)}">
                            <span class="p-float-label">
                                <input type="text" formControlName="firstName" [maxlength]="50"
                                       emsNamePatternCheck emsCharacterLimit
                                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText/>
                                <label>First Name<em class="highlight-color">*</em></label>
                            </span>
              <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
                 *ngIf="linkRequestForm.get('firstName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{linkRequestForm.get('firstName').value.length}}
                  /{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>
          </div>

          <div class="col-2">
            <div class="ems-input link-input-textbox "
                 [ngClass]="{'invalidField': (linkRequestForm.get('lastName').value && linkRequestForm.get('lastName').invalid)}">
                            <span class="p-float-label">
                                <input type="text" formControlName="lastName" [maxlength]="50"
                                       emsNamePatternCheck emsCharacterLimit
                                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText/>
                                <label>Last Name<em class="highlight-color">*</em></label>
                            </span>
              <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
                 *ngIf="linkRequestForm.get('lastName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{linkRequestForm.get('lastName').value.length}}
                  /{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>
          </div>

          <div class=" col-2">
            <div class="ems-input link-input-textbox "
                 [ngClass]="{'invalidField': (linkRequestForm.get('accountHolderFirstName').value && linkRequestForm.get('accountHolderFirstName').invalid)}">
                            <span class="p-float-label">
                                <input type="text" formControlName="accountHolderFirstName" [maxlength]="50"
                                       emsNamePatternCheck emsCharacterLimit
                                       (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                                       [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText/>
                                <label>Account Holder First Name<em *ngIf="linkRequestForm.get('accountHolderSameAsEmployee').value === false" class="highlight-color">*</em></label>
                            </span>
              <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
                 *ngIf="linkRequestForm.get('accountHolderFirstName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{linkRequestForm.get('accountHolderFirstName').value.length}}
                  /{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>

            <div class="ems-checkbox">
              <p-checkbox formControlName="accountHolderSameAsEmployee" label="Acct. Holder Same as Emp."
                          binary="true">
              </p-checkbox>
            </div>

          </div>

          <div class="col-2">
            <div class="ems-input link-input-textbox "
                 [ngClass]="{'invalidField': (linkRequestForm.get('accountHolderLastName').value && linkRequestForm.get('accountHolderLastName').invalid)}">
                    <span class="p-float-label">
                        <input type="text" formControlName="accountHolderLastName" [maxlength]="50"
                               emsNamePatternCheck emsCharacterLimit
                               (emsCharacterLimitCheck)="showTextboxCharLimit = $event"
                               [charMaxLimit]="commonConstants.charLimitValues.fiftyMaxLimit" pInputText/>
                        <label>Account Holder Last Name<em *ngIf="linkRequestForm.get('accountHolderSameAsEmployee').value === false" class="highlight-color">*</em></label>
                    </span>
              <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
                 *ngIf="linkRequestForm.get('accountHolderLastName').value.length > showTextboxCharLimit">{{addLinkConstant.characterLimit}}
                <span class="character-limit">{{linkRequestForm.get('accountHolderLastName').value.length}}
                  /{{commonConstants.charLimitValues.fiftyMaxLimit}}</span>
              </p>
            </div>
          </div>

          <div class="col-2">
            <ems-multiselect [highlightColor]="false" [singleSelect]="true" [label]="'Affiliation'" [searchable]="false"
                                     [options]="affiliations" formControlName="affiliation">
            </ems-multiselect>
          </div>
        </div>

        <div class="sdps-row col-10 sdps-m-top_small no-padding-left">

          <div class="col-3" *ngIf="(((linkType === addLinkConstant.addLkGlobal.multi && (countOfSL === 0 || countOfSL > 1)) || linkType === addLinkConstant.addLkGlobal.standard) && usertype === addLinkConstant.addLkGlobal.external) || usertype === addLinkConstant.addLkGlobal.internal">

            <ems-multiselect [highlightColor]="true" [singleSelect]="false" [label]="'Master Account'" [searchable]="false"
                                     [options]="masterAccountOptions" formControlName="masterAccounts"
                                     [linkType]="linkType" [disabled]="linkType === addLinkConstant.addLkGlobal.multi && countOfSL === 1 && usertype === addLinkConstant.addLkGlobal.internal">
            </ems-multiselect>

          </div>

          <div class="col-3" *ngIf="!isAuthAgentRole">

            <ems-multiselect [highlightColor]="true" [singleSelect]="true" [label]="'Authorized Agent'" [searchable]="true"
                                     [options]="authorizedAgents" formControlName="authAgent">
            </ems-multiselect>
            <div class="ems-add-auth"
                 *ngIf="isAAExceptBU && sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','')">
              <span (click)="displayAuthAgentPopup($event)">+Add Authorized Agent</span>
            </div>
          </div>

          <div class="col-2"
               *ngIf="!isAuthAgentRole && sharedService.checkEntitlements(entitlementConst.navUUID.accountLinking.uuid, '', '') && sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid, '', '')">
            <div class="ems-input ">
               <span class="p-float-label">
                 <input type="number" onkeypress="if(event.which<48 || event.which>57) return false;"
                        formControlName="indexNumber" pInputText/>
                 <label>Index Number<em *ngIf="!(((liable407 === 'N' || liable407 === 'n') && (blanket407 === 'N' || blanket407 === 'n')) || (liable407 === 'Y' || liable407 === 'y') && (blanket407 === 'Y' || blanket407 === 'y'))" class="highlight-color">*</em></label>
               </span>
            </div>
          </div>

          <div class="col-3 remark-container">
            <div class="ems-input link-input-textbox">
              <span class="p-float-label">
                <input type="text" formControlName="remarks" maxlength="1000" emsCharacterLimit
                  (emsCharacterLimitCheck)="showRemarksTxtboxCharLimit = $event"
                  [charMaxLimit]="commonConstants.charLimitValues.thousandMaxLimit" pInputText>
                <label>Remarks<em class="highlight-color"
                    *ngIf="linkRequestForm.get('doNotShowRemarkToClient').value === true">*</em></label>
              </span>
            </div>
            
            <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
              *ngIf="linkRequestForm.get('remarks').value.length > showRemarksTxtboxCharLimit">{{addLinkConstant.characterLimit}}
              <span class="character-limit">{{linkRequestForm.get('remarks').value.length}}
                /{{commonConstants.charLimitValues.thousandMaxLimit}}</span>
            </p>

            <div class="ems-checkbox"
              *ngIf="!isAuthAgentRole || sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','') && usertype === addLinkConstant.addLkGlobal.internal">
              <p-checkbox formControlName="doNotShowRemarkToClient" label="Do not show this remark to Client"
                binary="true"></p-checkbox>
            </div>
          
          </div>

          <div class="col-2">
                        <span>
                            <button type="button" class="ems-button ems-attach-files sdps-hide"
                                    [disabled]="linkRequestForm.disabled">
                                <em class="pi pi-cloud-upload ems-attach-files_icon"></em>
                                <span class="sdps-m-right_small ems-attach-files_text">Attach Files</span>
                            </button>
                        </span>
          </div>

        </div>

        <div class="sdps-row sdps-m-top_small">

        </div>

        <div class="sdps-row sdps-m-top_small">

          <div class="col">
                        <span class="sdps-pull-right">
                            <button type="button" class="buttons"
                                    (click)="sharedService.gutTracking('adobe-lc_reset|fireonce'); resetLinkRequestForm('reset')">Reset</button>
                            <button type="button" class="buttons button-save"
                                    [disabled]='!validForm() ||linkRequestForm.controls.accountNumber.value.toString().length !== 8'
                                    (click)="submitLinkRequest()">Submit</button>
                        </span>
          </div>

        </div>

      </div>


    </div>

  </div>

</form>

<div class="sdps-mosaic-tile sdps-m-bottom_large addlink-action-panel" *ngIf="requests.length > 0">

  <div class="sdps-mosaic-tile__content sdps-hide">
    <span class="ems-icon sch-fax-2x sdps-m-right_small"></span>
    <span class="ems-icon sch-file-pdf-2x sdps-m-right_small"></span>
    <span class="ems-icon sch-file-excel-2x"></span>
  </div>

</div>

<p-table [columns]="requestColumns" [value]="requests" [responsive]="true" class="common-data-table"
         *ngIf="requests.length > 0">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns">
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>

      <td *ngFor="let col of columns; let i = index;" class="addlink-hide-overflow">
        <span class="p-column-title">{{col.header}}</span>

        <span *ngIf="col.field === 'status'" title="{{rowData[col.field]}}">
                    <span *ngIf="rowData[columns[1].field] === 'Complete'"
                          class="ems-status sdps-p-around-xx-large ems-status-active"></span>
                    <span
                      *ngIf="rowData[columns[1].field] === 'On Hold' || rowData[columns[1].field] === 'Validating' || rowData[columns[1].field] === 'Received'"
                      class="ems-status sdps-p-around-xx-large ems-status-draft"></span>
                    <span
                      *ngIf="rowData[columns[1].field] === 'Ineligible Request' || rowData[columns[1].field] === 'Validation Mismatch'"
                      class="ems-status sdps-p-around-xx-large ems-status-expired"></span>
                    <span *ngIf="rowData[columns[1].field] === 'Require Indexing'"
                      class="ems-status sdps-p-around-xx-large ems-require-indexing"></span>
          {{rowData[col.field]}}
                </span>

        <span *ngIf="col.field === 'remarks' && rowData['requestId']"
              class="ems-icon sch-chat-2x sdps-m-right_xx-large icon-border cursor" id="rowData[col.field]"
              (click)="openRemarkPopup($event, rowData[columns[0].field], rowData[columns[3].field], rowData[columns[1].field])"></span>

        <span *ngIf="col.field !== 'remarks' && col.field !== 'status'" title="{{rowData[col.field]}}">
                    {{rowData[col.field]}}
                </span>

        <span *ngIf="col.field === 'actions' && (rowData['requestId'] && rowData['status'] === 'Validation Mismatch')">
              <button class="ems-icon pi pi-pencil sdps-m-right_xx-large icon-border cursor"
                (click)="editRequestPopup($event, rowData)" [disabled]="rowData['disableIcon']"></button>
        </span>
        <span *ngIf="col.field === 'actions' && (sharedService.isInternalApp() || (!sharedService.isInternalApp() && doNotShowResubmitBtn)) && (rowData['requestId'] && rowData['status'] === 'On Hold')">
              <button class="ems-icon sch-revert sdps-m-right_xx-large icon-border cursor buttons" title="Resubmit"
                      (click)="reSubmitRequests(rowData)" [disabled]="rowData['disableIcon']"></button>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>

<div class="sdps-m-bottom_large"></div>
<addlink-edit-requests-modal #editRequestsModal
                             (udpateRequest)="updateSingleRequest($event)"></addlink-edit-requests-modal>
<addlink-add-authorized-agent-modal #addAuthAgent (getAuthagents)="getAuthorizedAgents()">
</addlink-add-authorized-agent-modal>
<addlink-remarks-modal #remarks></addlink-remarks-modal>
