import { Directive, ElementRef } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[emsNamePatternCheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: NamePatternCheckDirective, multi: true}
  ]
})
export class NamePatternCheckDirective {

  newExp: any;

  constructor(public el: ElementRef) {
  }

  validate() {
    this.newExp = new RegExp('^(?!^ +$)([A-Za-z., ]+(?:[A-Za-z0-9.\'_\\-,]*[A-Za-z0-9., ]+)*)*$');

    this.el.nativeElement.value = !this.el.nativeElement.hasAttribute('restrictValidation') ? this.el.nativeElement.value.toUpperCase() : this.el.nativeElement.value;
    const elementVal = this.el.nativeElement.value;

    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
        emsAlphabetOnlyPattern: false
      };
    }

  }
}
