import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
const moment = require('moment-timezone');
import { commonConstants } from '@shell_components/constants/commonConstants';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import {emtrConstant} from '../../../../../../projects/ems/emtr/src/lib/constants/emtrConstants';
import { ReportsService } from '@core_services/report/reports.service';
@Component({
  selector: 'app-report-from-to-datepicker',
  templateUrl: './report-from-to-datepicker.component.html',
  styleUrls: ['./report-from-to-datepicker.component.scss']
})
export class ReportFromToDatepickerComponent implements OnInit {
  dateError: any = false;
  @Output() throwDateError: EventEmitter<string> = new EventEmitter<string>();
  @Output() throwDateRangeError: EventEmitter<string> = new EventEmitter<string>();
  @Output() calendarValidation: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() getEmittedVal: EventEmitter<any> = new EventEmitter();
  @Input() dateRange: any;
  @Input() initialDateRange: any;
  @Input() disabled: boolean;

  minDate: Date;
  maxDate: Date;
  getShellConstant = ShellConstant;
  getCommConst = commonConstants;
  getEmtrConstant = emtrConstant;
  emitValues: any = {
    fromDate: '',
    toDate: '',
    dateError: this.dateError
  };
  public reportName: any;
  dateErrCss = false;
  dateRangeErrCss = false;
  errorMessage = '';
  dateRangeErrorMsg = '';
  @Input() calendarForm: UntypedFormGroup = this.fb.group({
    dateTo: this.fb.group({
      date: ['', Validators.required]
    }),
    dateFrom: this.fb.group({
      date: ['', Validators.required]
    }),
  });
  dateErrCssFromDate = false;
  constructor(private fb: UntypedFormBuilder , public reportsService: ReportsService) {
    this.reportName = this.reportsService.getReportName();
   }

  // onChanges() {
  //   this.calendarForm.valueChanges.subscribe(() => {
  //     const fromDate = this.calendarForm.get('dateFrom').value;
  //     const toDate = this.calendarForm.get('dateTo').value;
  //     this.emitValues.fromDate = moment(fromDate.date, 'MM/DD/YYYY');
  //     this.emitValues.toDate = moment(toDate.date, 'MM/DD/YYYY');
  //     this.getEmittedVal.emit(this.emitValues);

  //     /* To verify End date is greater than From data */
  //     if (this.emitValues.fromDate > this.emitValues.toDate) {
  //       this.errorMessage = this.getEmtrConstant.rulesConstant.dateTimePicker.endDateError;
  //       this.dateErrCss = true;
  //     } else {
  //       this.errorMessage = '';
  //       this.dateErrCss = false;
  //     }
  //     const isValidFromDate = this.emitValues.fromDate.isValid() ;
  //     const isValidToDate = this.emitValues.toDate.isValid() ;
  //     if (!isValidFromDate) {
  //        this.errorMessage = this.getEmtrConstant.inValidDateMessage + ' for From Date.';
  //        this.dateErrCssFromDate = true;
  //     } else {
  //       this.dateErrCssFromDate = false;
  //       this.errorMessage = this.dateErrCss ? this.errorMessage : '';
  //     }
  //     if (!isValidToDate) {
  //       this.dateErrCss = true;
  //       this.errorMessage = this.getEmtrConstant.inValidDateMessage + ' for To Date.';
  //     } else {
  //       this.dateErrCss = this.emitValues.fromDate > this.emitValues.toDate ? this.dateErrCss : false;
  //       this.errorMessage = this.emitValues.fromDate > this.emitValues.toDate || this.dateErrCssFromDate ? this.errorMessage : '';
  //     }

  //     if (!isValidFromDate && !isValidToDate) {
  //       this.errorMessage = this.getEmtrConstant.inValidDateMessage + ' for From and To Date.';
  //     }
  //     this.throwDateError.emit(this.errorMessage);

  //     if (!this.dateRange) {
  //       if (moment(this.emitValues.toDate) > moment(this.emitValues.fromDate).add(90, 'days') && this.reportName === this.getShellConstant.reports.RuleAudit.name) {
  //         this.dateRangeErrorMsg = this.getShellConstant.reports.rangeErrorMessage + '90 days.' + this.getShellConstant.reports.rangeErrormessagePart2 + '90 days of current date';
  //         this.dateRangeErrCss = true;
  //       } else if (moment(this.emitValues.toDate) > moment(this.emitValues.fromDate).add(365, 'days')) {
  //         this.dateRangeErrorMsg = this.getShellConstant.reports.rangeErrorMessage + '365 days.' + this.getShellConstant.reports.rangeErrormessagePart2 + '365 days of current date';
  //         this.dateRangeErrCss = true;
  //       } else {
  //         this.dateRangeErrorMsg = '';
  //         this.dateRangeErrCss = false;
  //       }
  //       if (!this.dateErrCss) {
  //         this.throwDateRangeError.emit(this.dateRangeErrorMsg);
  //       } else {
  //         this.throwDateRangeError.emit('');
  //       }
  //     }
  //   });

  // }


  ngOnInit() {
    const today = new Date(moment().tz('America/New_York').format(this.getCommConst.date_format.mm_dd_yy));
    this.maxDate = this.minDate = new Date();
    const toDate = moment(today, 'MM/DD/YYYY').format('MM/DD/YYYY');
    if (this.dateRange) {
      const get60Days = moment(today).subtract(this.dateRange, 'days');
      this.minDate = new Date(get60Days._d.toDateString());
    } else {
      const setMinDate = new Date(moment('01/02/2013').tz('America/New_York').format(this.getCommConst.date_format.mm_dd_yy));
      // const getDays = moment(setMinDate).add(1, 'days');
      this.minDate = new Date(setMinDate.toDateString());
    }
    const fromDate = moment(today, 'MM/DD/YYYY').subtract(this.initialDateRange, 'days').format('MM/DD/YYYY');
    this.calendarForm.patchValue({
      dateTo: {
        date: toDate
      },
      dateFrom: {
        date: fromDate
      }
    });
    this.emitValues.fromDate = fromDate;
    this.emitValues.toDate = toDate;
    this.getEmittedVal.emit(this.emitValues);
    // this.onChanges();
  }

  calendarValidationEvent(value) {
    this.calendarValidation.emit(value);
    if (value.isFutureDate || value.isInValidDate || value.isPastDate) {
      this.emitValues.dateError = this.dateError = true;
    } else {
      this.emitValues.dateError = this.dateError = false;
    }
    this.getEmittedVal.emit(this.emitValues);
  }

  validateDateErrors(event) {
    if (event.dateLabel === 'From Date') {
      const fromDate =  moment(event.value);
      this.emitValues.fromDate = fromDate;
      this.emitValues.toDate = this.emitValues.toDate ? moment(this.emitValues.toDate) : moment(this.maxDate);
    }
    if (event.dateLabel === 'To Date') {
      const toDate =  moment(event.value);
      this.emitValues.toDate = toDate;
      this.emitValues.fromDate = this.emitValues.fromDate ? moment(this.emitValues.fromDate) : moment(this.calendarForm.get('dateFrom').get('date').value);
    }
    this.getEmittedVal.emit(this.emitValues);
    /* To verify End date is greater than From data */
    if (this.emitValues.fromDate > this.emitValues.toDate) {
      this.errorMessage = this.getEmtrConstant.rulesConstant.dateTimePicker.endDateError;
      this.dateErrCss = true;
    } else {
      this.errorMessage = '';
      this.dateErrCss = false;
    }
    const isValidFromDate = this.emitValues.fromDate.isValid() ;
    const isValidToDate = this.emitValues.toDate.isValid() ;
    if (!isValidFromDate) {
       this.errorMessage = this.getEmtrConstant.inValidDateMessage + ' for From Date.';
       this.dateErrCssFromDate = true;
    } else {
      this.dateErrCssFromDate = false;
      this.errorMessage = this.dateErrCss ? this.errorMessage : '';
    }
    if (!isValidToDate) {
      this.dateErrCss = true;
      this.errorMessage = this.getEmtrConstant.inValidDateMessage + ' for To Date.';
    } else {
      this.dateErrCss = this.emitValues.fromDate > this.emitValues.toDate ? this.dateErrCss : false;
      this.errorMessage = this.emitValues.fromDate > this.emitValues.toDate || this.dateErrCssFromDate ? this.errorMessage : '';
    }

    if (!isValidFromDate && !isValidToDate) {
      this.errorMessage = this.getEmtrConstant.inValidDateMessage + ' for From and To Date.';
    }
    this.throwDateError.emit(this.errorMessage);
    /* To verify the date range for various reports */
    if (!this.dateRange && moment(this.emitValues.toDate) > moment(this.emitValues.fromDate).add(365, 'days') && this.reportName !== this.getShellConstant.reports.RuleAudit.name) {
      // this.dateRangeErrorMsg = this.getShellConstant.reports.rangeErrorMessage + '365 days.' + this.getShellConstant.reports.rangeErrormessagePart2 + '365 days of current date.';
      this.dateRangeErrorMsg = this.getEmtrConstant.rulesConstant.dateTimePicker.dateRangeError;
      this.dateRangeErrCss = true;
    } else if (moment(this.emitValues.toDate) > moment(this.emitValues.fromDate).add(60, 'days') && this.reportName === this.getShellConstant.reports.OpenOrderDetails.openOrder) {
      this.dateRangeErrorMsg = this.getShellConstant.reports.rangeErrorMessage + '60 days. ' + this.getShellConstant.reports.rangeErrormessagePart2 + '60 days of current date.';
      this.dateRangeErrCss = true;
    } else if (this.dateRange && moment(this.emitValues.toDate) > moment(this.emitValues.fromDate).add(90, 'days') && this.reportName !== this.getShellConstant.reports.OpenOrderDetails.openOrder) {
      this.dateRangeErrorMsg = this.getShellConstant.reports.rangeErrorMessage + '90 days. ' + this.getShellConstant.reports.rangeErrormessagePart2 + '90 days of current date.';
      this.dateRangeErrCss = true;
    } else if (!this.dateRange && moment(this.emitValues.toDate) > moment(this.emitValues.fromDate).add(90, 'days') && this.reportName === this.getShellConstant.reports.RuleAudit.name) {
      this.dateRangeErrorMsg = this.getShellConstant.reports.rangeErrorMessage + '90 days. ' + this.getShellConstant.reports.rangeErrormessagePart2 + '90 days of current date.';
      this.dateRangeErrCss = true;
    } else {
      this.dateRangeErrorMsg = '';
      this.dateRangeErrCss = false;
    }
    if (!this.dateErrCss) {
      this.throwDateRangeError.emit(this.dateRangeErrorMsg);
    } else {
      this.throwDateRangeError.emit('');
    }
  }

}
