export interface ShellPagination {
  pageSize?: number;
  pageNo?: number;
  filterField?: string;
  sortField?: any;
}

export const ShellPaginationValues: ShellPagination = {
  pageSize: 15,
  pageNo: 0,
  sortField: ''
};
