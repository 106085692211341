 <div class="sdps-p-horizontal_medium">
 <form [formGroup]="ruleAuditReportForm">
  <div class="sdps-grid-container sdps-wrap">

    <div class="sdps-row sdps-p-top_x-large">
      <div class="display-flex">
        <app-report-from-to-datepicker (calendarValidation)="calendarValidation($event)" [dateRange]="null"
                                       [initialDateRange]="7"
                                       (getEmittedVal)="setDate($event)" [calendarForm]="ruleAuditReportForm"
                                       (throwDateError)="throwDateError($event)"
                                       (throwDateRangeError)="throwDateRangeError($event)"></app-report-from-to-datepicker>
      </div>
    </div>
  </div>
  <div class="sdps-row sdps-p-top_large select-report-type">
    <div class="col-4 sdps-p-left_medium sdps-p-right_small">
      <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (ruleAuditReportForm.get('masterAccountNumber').value && (ruleAuditReportForm.get('masterAccountNumber').invalid || ruleAuditReportForm.get('masterAccountNumber').value.length < 8)),'disabled-cursor-option' :(getOrgId && getOrgId !== 0)}">
        <span class="p-float-label">
          <input type="text" maxlength="8" onkeypress="if(event.which<48 || event.which>57) return false;" name="masterAccountNumber" emsNumberPatterncheck formControlName="masterAccountNumber" pInputText/>
          <label [innerHTML]="getShellConstant.reports.presetReports.masterAccountNumber"></label>
        </span>
      </div>
    </div>
  </div>
 </form>
 <ems-step-wizard-buttons [showCancelBtn]="true" (closeBtn)="cancelReport()"  [showRunReportBtn]="true" (runReport)="runReports()"
                           [disableBtn]="ruleAuditReportForm.invalid || endDateLessThanStartDate || dateRangeConstarint || dateError || (ruleAuditReportForm.get('masterAccountNumber').value && ruleAuditReportForm.get('masterAccountNumber').value.length < 8)"></ems-step-wizard-buttons>
</div>

