import {Injectable, EventEmitter} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmsSubjectService {

  currentOrg = new BehaviorSubject(null);
  private clientData: any;

// lib1PolicySubject = new BehaviorSubject(null);

  setClientData(clientObj) {
    this.clientData = clientObj;
  }

  getClientData() {
    return this.clientData;
  }
}
