<ems-confirm-message #confirmationMessage></ems-confirm-message>

<ems-bulk-upload [lib]="library" [downloadTemplate]="fileTemplate" [downloadTemplateName]="templateName"
                 [uploadFileApi]="uploadFilePoint" [parentPage]="navigateTo" [createdName]="nameEntered"
                 (FileUploadEmit)="getEmittedFileVal($event)" (hasFormValuesChanged)="hasFormChanged($event)" [linkType]="linkType" [uploadBtnGutTack] ='uploadBtnGutTack'
                 [downloadTemplateGutTack]="downloadTemplatDT" [chooseFileGutTack]="chooseFileDT"></ems-bulk-upload>

<div class="sdps-text-right sdps-m-top_x-large sdps-m-bottom_x-large" *ngIf="orgId">
    <button type="reset" class="buttons" (click)="cancelFileUpload()"
        [innerHTML]="getAddLinkConstant.requestListFilterButtons.cancelButton"></button>
    <button type="button" class="buttons button-save"
        [disabled]="isFileUploaded && !showError && validFile ? false : true" (click)="uploadFile();"
        [innerHTML]="getAddLinkConstant.bulkUpload.uploadTxt"></button>
</div>
