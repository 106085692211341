import {Injectable, Inject, EventEmitter} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {EmtrService} from '../../emtr.service';
import {emtrConstant} from '../../constants/emtrConstants';
import {ConfigUrlService, SharedService} from '@ems/shared';

@Injectable({
  providedIn: 'root'
})
export class RuleService {

  getConstant = emtrConstant;
  private subject = new ReplaySubject<any>();
  orgId: string;
  configURL = this.config.setApplicationUrl();
  showSchError = new EventEmitter<boolean>();
  showRequestIdError = new EventEmitter<boolean>();
  schErrorMsg = new EventEmitter<string[]>();
  toastMsg  = new EventEmitter<string>();
  toastType  = new EventEmitter<string>();
  duplicateRuleName = new BehaviorSubject(null);
  showSelectionMsg  = new EventEmitter<string>();
  scrollHeightValue = 0 ;
  checkIfChangesMadeInStep1 = false;
  checkIfChangesMadeInStep2 = false;
  filterClickedSubHeader = new EventEmitter<boolean>();
  invalidTradeSymbolField = new EventEmitter<boolean>();
  invalidCusipSymbolField = new EventEmitter<boolean>();
  clearClicked = new EventEmitter<boolean>();
  hasValueTradeSymbolField = new EventEmitter<boolean>();
  hasValueCusipField = new EventEmitter<boolean>();
  hasGroupPlanValue = new EventEmitter<boolean>();
  specialCharValidation = new EventEmitter<boolean>();
  hasValueViewField = new EventEmitter<boolean>();
  hasInstrumentGroupValue = new EventEmitter<boolean>();
  isSubHeaderFlag: boolean;
  previousStep = false;
  businessUnit = '';
  deletedRuleInfo = false;
  presetRuleRadioSelected = new EventEmitter<boolean>();
  oldRBSRuleNameForCopyFlow = '';
  originalRBSRuleNameForCopyFlow = '';

  constructor(private httpService: HttpClient, @Inject('env') public env, public config: ConfigUrlService, public sharedService: SharedService) {
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (clientInfo) {
      this.orgId = clientInfo && clientInfo.orgId;
      this.businessUnit = clientInfo.businessUnit;
      }
    });
  }

  checkRuleNameExists(ruleName, orgId, planId): Observable<any> {
    this.sharedService.decodeUrl = false;
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const ruleNameNew = encodeURIComponent(`${ruleName}`);
    if (planId) {
      return this.httpService.get(this.configURL.bffRulesEndPointV1 + '&name=' + ruleNameNew + '&planId=' + planId + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      return this.httpService.get(this.configURL.bffRulesEndPointV1 + '&name=' + ruleNameNew + '&Schwab-Client-Ids= ' + schwabClientHeader);
    }
  }

  saveRule(ruleObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const url = this.configURL.bffRulesEndPointV1 + '&Schwab-Client-Ids= '  + schwabClientHeader;
    if (ruleObj.id) {
      return this.httpService.put(url, ruleObj);
    } else {
      return this.httpService.post(url, ruleObj);
    }
  }

  getRules(pageObj, formFilterObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const ruleFilterInfo = formFilterObj;
    this.sharedService.stateValues.filterObj = ruleFilterInfo;
    this.sharedService.stateValues.pageObj.pageNo = pageObj.pageNo;
    this.sharedService.stateValues.pageObj.pageSize = pageObj.pageSize;
    this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
    this.sharedService.tableDropdownVal.next(pageObj.pageSize);
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    const endPoint = this.configURL.bffRulesSummaryEndPointV1 + '&pageNumber=' + (pageObj.pageNo + 1) + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= '  + schwabClientHeader;
    return this.httpService.post(endPoint, ruleFilterInfo);
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }

  getRuleData(ruleId: number, type) {
    let schwabClientHeader = [];
    let url = '';
    if ((type && type.type) && (type.type === 'GLOBAL' || type.type === 'SEMI_GLOBAL')) {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.ruleId, ruleId, schwabClientHeader);
      url = this.configURL.bffRulesEndPointV1 + '&globalRuleType=' + type.type + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.ruleId, ruleId, schwabClientHeader);
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
      url = this.configURL.bffRulesEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    }
    return this.httpService.get(url);
  }

  saveEditRuleRecurring(ruleObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    return this.httpService.post(this.configURL.rulesEndPoint + '/recurring' + '&Schwab-Client-Ids= '  + schwabClientHeader, ruleObj);
  }

  saveEditRuleCopy(ruleObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    return this.httpService.post(this.configURL.bffRulesEndPointV1 + '&Schwab-Client-Ids= '  + schwabClientHeader, ruleObj);
  }

  getSecurities(isRestrictShortSellsChecked: boolean) {
    return this.httpService.get(this.configURL.bffClassificationEndPointV1 + '&shortsell=' + isRestrictShortSellsChecked);
  }

  deleteRule(ruleId: number) {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.ruleId, ruleId, schwabClientHeader);
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    return this.httpService.delete(this.configURL.bffRulesEndPointV1 + '&Schwab-Client-Ids= '  + schwabClientHeader);
  }

  getAssettypes() {
    return this.httpService.get(this.configURL.bffSearchAssetTypeEndPointV1);
  }

  getAutoSecurities(query, type): Observable<any> {
    return this.httpService.get(this.configURL.bffSecurityEndPointV1 + '&searchValue=' + (query ? query.trim() : '') + '&searchType=' + type);
  }

  getSearchSecurities(searchObj): Observable<any> {
    if (searchObj.securityType.indexOf(this.getConstant.rulesConstant.autoComplete.exactMatchSymbol) !== -1 || searchObj.securityType.indexOf(this.getConstant.rulesConstant.autoComplete.exactMatchCusip) !== -1) {
      return this.httpService.get(this.configURL.bffSecurityEndPointV1 + '&searchValue=' + (searchObj.securitySearch ? encodeURIComponent(searchObj.securitySearch.trim()) : '') + '&searchType=' + searchObj.securityType);
    } else {
      return this.httpService.get(this.configURL.bffSecurityEndPointV1 + '&searchValue=' + (searchObj.securitySearch ? encodeURIComponent(searchObj.securitySearch.trim()) : '') + '&securityType=' + searchObj.securityType + '&page=' + searchObj.page + '&size=' + searchObj.size);
    }

  }

  assignEmployees(empObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const url = this.configURL.bffRulesAssignmentEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    return this.httpService.put(url, empObj, {observe: 'response'});
  }

  putSaveCall(saveObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const url = this.configURL.bffRulesEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    return this.httpService.put(url, saveObj, {observe: 'response'});
  }

  restoreRule(ruleId, type): Observable<any> {
    let schwabClientHeader = [];
    if (type === null) {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    }
    const url = this.configURL.bffRulesEndPointV1 + '&ruleId=' + ruleId + '&active=' + true + '&Schwab-Client-Ids= ' + schwabClientHeader;
    return this.httpService.put(url, '', {observe: 'response'});
  }

  validateNameAndPlanId(message, form) {
    const planIdTxt = 'planId';
    let validationfield;
    if (message === this.getConstant.rule10b5Msg.duplicateRuleNameAndPlanId) {
      form.controls[planIdTxt].setErrors({incorrect: true});
      validationfield = 'both';
    } else if (message === this.getConstant.rule10b5Msg.duplicatePlanId) {
      form.controls[planIdTxt].setErrors({incorrect: true});
      validationfield = 'planId';
    } else if (message === this.getConstant.rule10b5Msg.duplicateRuleName) {
      const ruleNameExistsMsg = this.getConstant.rulesConstant.ruleNameMsg.the + '<strong> "' + form.get('ruleName').value + '"</strong>' + this.getConstant.rulesConstant.ruleNameMsg.ruleNameExistWarningMsg;
      this.duplicateRuleName.next(true);
      /*To show the rulename exists warning msg*/
      this.toastType.emit('warn');
      this.toastMsg.emit(ruleNameExistsMsg);
      validationfield = 'ruleName';
    }
    return validationfield;
  }

  getAssetGroups(): Observable<any> {
    return this.httpService.get(this.configURL.bffAssetGroupEndPointV1 + '&businessUnit=' + this.businessUnit);
  }

  getPresetRules(): Observable<any> {
    return this.httpService.get(this.configURL.bffPresetRulesEndPointV1);
  }

  setAssetGrpCacheData(assetGrp) {
    /* Storing the asset group api response in cache */
    const localData = assetGrp.data;
    /* clear cached data before pushing the new value */
    this.sharedService.assetGrpCachedData = [];
    if (localData.length > 0) {
      localData.forEach(val => {
        /* We are iterating each value and pushing data into cache, to avoid the lose of attribute value */
        this.sharedService.assetGrpCachedData.push({
          bu: val.bu,
          code: val.code ? val.code : '',
          id: val.id,
          name: val.name,
          label: val.label ? val.label : '',
          attributes: val.attributes ? val.attributes : [],
          secCatgs1: val.secCatgs1,
          secCatgs2: val.secCatgs2,
          secCatgs3: val.secCatgs3,
          order: val.order
        });
      });
    }
  }

  maintainAssetGroupOrder(formData) {
    const displaySelectedAssetGrp = [];
    const assetGrp = formData.assetGroups;
    assetGrp.forEach(val => {
      /* Filter the selected asset grp value from the cache one after the other by comparing the code of the asset group */
      const localArr = this.sharedService.assetGrpCachedData.filter(x => x.code === val.code);
      /* After filtering the first value in the selected asset grp, attach the order value to the selected group which
      would be present in the localArr - localArr will contain only 1 object bec we will be filtering one asset group at
      a time */
      val.order = localArr[0].order;
      /* Now iterate the attributes of the selected asset group to attach the order value of the each attribute */
      val.attributes.forEach(attributeVal => {
        /* We will be filtering 1 attribute at a time based on the code to retrieve the order of the each attribute from
        the localArr and attach it to the selected attribute */
        const localAtrributeArr = localArr[0].attributes.filter(x => x.code === attributeVal.code);
        attributeVal.order = localAtrributeArr[0].order;
      });
      displaySelectedAssetGrp.push(val);
    });

    /* Once our displaySelectedAssetGrp is ready with selected asset group value and its corresponding order value,
    sort both at the asset group level and attribute level so that both asset group and its attributes are displayed
    in the required order */
    if (displaySelectedAssetGrp && displaySelectedAssetGrp.length > 0) {
      displaySelectedAssetGrp.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
      displaySelectedAssetGrp.forEach(latAttrbts => {
        latAttrbts.attributes.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
      });
    }

    return displaySelectedAssetGrp;
  }

  uploadSecurities(uploadObj): Observable<any> {
    const url = this.configURL.bffSecurityEndPointV1 + '&attributeName=SECURITIESLIST&page=0&size=300';
    return this.httpService.post(url, uploadObj);
  }

  /* To update the secCatgs value in the preset rules from the asset group secCatgs. In preset rules we only get the missing securities other than asset groups
  * hence to populate all the required value we need to get all the securities from preset rules and asset groups */
  updateSecCatgsInPresentRule(presetRules, assetGroups) {
    const updatePresetList = presetRules;
    /* Fetch each rule in the preset rules list */
    updatePresetList.forEach(rule => {
      let secCatgs1 = [];
      let secCatgs2 = [];
      let secCatgs3 = [];
      /* Iterate through the asset group list of the respective rule and check the assent group in rule against the asset groups list and fetch the corresponding all 3 secCatgs */
      /* Iterate through secCatgs1/secCatgs2/secCatgs3 array of assetGroups list, if the value is present then loop through the value and push each value into the const variable */
      rule.assetGroups.filter(asstGrp1 => assetGroups.some(grp => asstGrp1.name === grp.name && grp.secCatgs1 ? grp.secCatgs1.filter(security => secCatgs1.push(security)) : ''));
      rule.assetGroups.filter(asstGrp1 => assetGroups.some(grp => asstGrp1.name === grp.name && grp.secCatgs2 ? grp.secCatgs2.filter(security => secCatgs2.push(security)) : ''));
      rule.assetGroups.filter(asstGrp1 => assetGroups.some(grp => asstGrp1.name === grp.name && grp.secCatgs3 ? grp.secCatgs3.filter(security => secCatgs3.push(security)) : ''));

      /* Once the secCatgs is formed, then loop through secCatgs1/secCatgs2/secCatgs3 of rule and depending on the condition either [push or assign the values fetched from Asset group list */
      secCatgs1 && secCatgs1.length > 0 ? rule.secCatgs1 ? secCatgs1.filter(sec => rule.secCatgs1.indexOf(sec) === -1 ? rule.secCatgs1.push(sec) : '') : rule.secCatgs1 = secCatgs1 : '';
      secCatgs2 && secCatgs2.length > 0 ? rule.secCatgs2 ? secCatgs2.filter(sec => rule.secCatgs2.indexOf(sec) === -1 ? rule.secCatgs2.push(sec) : '') : rule.secCatgs2 = secCatgs2 : '';
      secCatgs3 && secCatgs3.length > 0 ? rule.secCatgs3 ? secCatgs3.filter(sec => rule.secCatgs3.indexOf(sec) === -1 ? rule.secCatgs3.push(sec) : '') : rule.secCatgs3 = secCatgs3 : '';
    });
    return updatePresetList;
  }
}
