import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonValidationService {

  constructor() {
  }

  /*check Entered Email Address is valid on focus-out*/
  validateEmailAddress(email) {
    const regExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (email && !regExp.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  validateOpaqueId(opaqueId) {
    const regExp = new RegExp(/^A[0-9]{6}$/);
    if (!(opaqueId && regExp.test(opaqueId))) {
      return true;
    } else {
      return false;
    }
  }
}
