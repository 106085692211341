import {AfterViewChecked, ChangeDetectorRef, Compiler, Component, HostListener, OnInit} from '@angular/core';
import {LocalStorageService} from '@core_services/local-storage.service';
import {UserIdleService} from 'angular-user-idle';
import * as temp from 'moment';
const moment = temp['default'];
import { HttpClient } from '@angular/common/http';

declare const walkMe: any;
import 'src/walkme/walkmeSnippet';
import {TokenService} from '@core_services/token.service';
import { commonConstants } from '@shell_components/constants/commonConstants';
import {SharedService} from '@ems/shared';
import {DynamicEnvironment} from '@environment/dynamic-environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterViewChecked {

  env = new DynamicEnvironment();

 constructor(public sharedService: SharedService, private localStorageService: LocalStorageService, private changeDetector: ChangeDetectorRef, private userIdle: UserIdleService, private tokenService: TokenService, private compiler: Compiler, public httpService: HttpClient) {
  }

  ngOnInit() {
    /*this.checkSession();*/
    sessionStorage.clear();
    walkMe(this.env.production);
    this.isUserAuthorized();
    this.compiler.clearCache();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }


  // checkSession() {
  //   this.userIdle.startWatching();
  //
  //   this.userIdle.onTimerStart().subscribe(() => {
  //   });
  //
  //   this.userIdle.onTimeout().subscribe(() => {
  //     location.reload();
  //   });
  //
  //   const loginTime = moment(new Date());
  //   this.userIdle.ping$.subscribe((count) => {
  //     const currentTime = moment(new Date());
  //     const diff = moment.duration(currentTime.diff(loginTime)).asMinutes();
  //     if (parseInt(diff, 10) > 55) {
  //       localStorage.clear();
  //       this.tokenService.getNewToken();
  //     }
  //   });
  // }

  isUserAuthorized() {
    if (window.location.pathname === '/unauthorized') {
      setTimeout(() => {
        localStorage.clear();
        this.tokenService.getNewToken();
        window.location.pathname = '/';
      }, commonConstants.delay);

    }
  }
}
