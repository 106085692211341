import {AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AppService} from '@core_services/app.service';
import {AddlinkService} from '@ems/addlink';
import {UploadsListTable, UploadsListTableHeader} from '@shell_components/model/uploads-list-table-header';
import {ShellPagination, ShellPaginationValues} from '@shell_components/model/pagination-model';
import {CustomSortService, DateRangeFilterComponent, SharedConstant, SharedService} from '@ems/shared';
import {MessageService} from 'primeng/api'
import {Paginator} from 'primeng/paginator';
import {UploadsService} from '@core_services/uploads/uploads.service';
import {UploadsModalComponent} from '../../uploads/uploads-modal/uploads-modal.component';
import {Table} from 'primeng/table';
import {LocalStorageService} from '@core_services/local-storage.service';
import {AddlinkConstant} from '../../../../projects/ems/addlink/src/lib/constants/addlinkConstants';
import * as temp from 'moment';
const moment = temp['default'];
import {HttpResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {entitlementConst} from '@shell_components/constants/entitlementConstants';
import {UploadListActionMenuItems} from '@shell_components/model/report-list-action-menu';

@Component({
  selector: 'app-uploads-list',
  templateUrl: './uploads-list.component.html',
  styleUrls: ['./uploads-list.component.scss']
})
export class UploadsListComponent implements OnInit, OnDestroy, AfterViewChecked {

  private currentOrgSubscription: Subscription;
  getConstant = ShellConstant;
  addLinkConstant = AddlinkConstant;
  getSharedConstant = SharedConstant;
  getOrgId: any;
  uploadTypeDropdownValue: any = [];
  uploadListTableCols: UploadsListTable[] = UploadsListTableHeader;
  pageObject: ShellPagination = ShellPaginationValues;
  @ViewChild('p') paginator: Paginator;
  @ViewChild(Table, { static: true }) tableComponent: Table;
  @ViewChild('toastMessage') msgToast: any;
  moment = moment;
  multiSortMeta: any = [];
  resetTable: any;
  uploadsList: any;
  totalUploads: any = 0;
  paginationCount: any = 0;
  @ViewChild('uploadsList') uploadsViewChild: UploadsModalComponent;
  @ViewChild('range') rangeCalendar: DateRangeFilterComponent;
  intervalJobs: any = {};
  progressValue: any = 0;
  uploadsListForm: UntypedFormGroup;
  toggle = false;
  filterSelectedDate: any;
  isClearBtnClicked = false;
  filterClicked = false;
  filterBtnDisableForfileName = false;
  filterBtnDisableForsubmittedBy = false;
  statusSelectedOptions = [];
  isDisabled = false;
  filterState: any;
  uploadExportMsg: any;
  processID: any = [];
  exportID: any = [];
  exportJobs: any = {};
  storeFilterObj = {};
  externalAccess = false;
  entitlementConst = entitlementConst;
  hasADDLinkEMTRAccess = true;
  batchCitiOrg: any;
  isSortingChanged = false;
  defaultSortDataField = {field: 'uploadId', order: -1};
  initialLoad = false;
  actionMenu: any[];
  uploadId: string;
  showActionMenu: boolean;

  constructor(private activatedRoute: ActivatedRoute, private shellService: AppService, private router: Router,
              public addlinkService: AddlinkService, public customSortService: CustomSortService, public el: ElementRef,
              public uploadService: UploadsService, public sharedService: SharedService, public fb: UntypedFormBuilder,
              public localStorageService: LocalStorageService, public messageService: MessageService) {
    this.getExportedMessage();
  }

  @HostListener('document:click', [])
  onClick() { /* Capture the Click Event outside the function */
    if (!this.showActionMenu) {
      this.uploadId = '';
    }
    this.showActionMenu = false;
  }

  ngOnInit() {
    this.initialLoad = true;
    this.batchCitiOrg = this.sharedService.applicationInfo.data.batchCitiOrg;
    this.uploadsListForm = this.fb.group({
      uploadId: [''],
      fileName: [''],
      uploadType: [''],
      status: [''],
      submittedDate: [''],
      submittedBy: ['']
    });

    this.pageObject.pageNo = 1;
    this.pageObject.pageSize = 15;
    this.customSortService.sortArr = [];
    this.multiSortMeta.push(this.defaultSortDataField);
    this.resetTable = true;
    this.actionMenu = UploadListActionMenuItems;
    this.currentOrgSubscription = this.sharedService.currentOrg.subscribe(clientInfo => {
      const clientId = this.localStorageService.getProfile();
      this.pageObject.pageSize = 15;
      this.sharedService.tableDropdownVal.next(15);
      this.getOrgId = clientInfo !== null ? clientInfo : clientId ? JSON.parse(clientId).orgId : clientInfo;
      this.sharedService.getOrgId = clientInfo !== null ? clientInfo : clientId ? JSON.parse(clientId).orgId : clientInfo;
      this.shellService.setCurrentOrg(this.getOrgId);
      JSON.parse(clientId) && JSON.parse(clientId).externalAccess === 'Y' ? this.externalAccess = true : this.externalAccess = false;
      this.resetForm();
      this.toggle = false;
      if (!this.resetTable) {
        this.multiSortMeta = [this.defaultSortDataField];
        this.customSortService.resetTable(this.defaultSortDataField, this.tableComponent);
      }
    });
    this.sharedService.selectedClientInfo.subscribe(value => {
      if (value) {
        const clientPolicy = this.sharedService.policy.data.userPolicy;
        const getUserEntitlement = clientPolicy.buPolicy && clientPolicy.buPolicy.length > 0 ? clientPolicy.buPolicy : clientPolicy.clientPolicy;
        const showPanel = this.sharedService.checkUserEntitlements(getUserEntitlement);
        this.hasADDLinkEMTRAccess = showPanel.hasAddlink || showPanel.hasEmtr;
      }
    });

    this.processID = [];
    this.exportID = [];
    const newObj = {label: 'All', value: 'All'};
    this.getConstant.uploadListPage.uploadsListFilter.status.forEach((item) => this.statusSelectedOptions.push(item.value));
    this.uploadsListForm.patchValue({uploadType: newObj, status: this.statusSelectedOptions});

    this.uploadsListForm.get('fileName').valueChanges.subscribe(value => {
      this.filterBtnDisableForfileName = this.sharedService.isValueLengthGreaterThan2(value);
    });

    this.uploadsListForm.get('submittedBy').valueChanges.subscribe(value => {
      this.filterBtnDisableForsubmittedBy = this.sharedService.isValueLengthGreaterThan2(value);
    });

    this.uploadsListForm.get('uploadId').valueChanges.subscribe(value => {
      if (value) {
        this.isDisabled = !this.isDisabled;
        this.filterState = 'disable';
      } else {
        this.isDisabled = !this.isDisabled;
        this.filterState = 'enable';
      }
      Object.keys(this.uploadsListForm.controls).forEach((controlName) => {
        if (controlName !== 'uploadId') {
          this.uploadsListForm.controls[controlName][this.filterState]();
          if (this.filterState === 'disable') {
            this.uploadsListForm.controls[controlName].reset();
            this.rangeCalendar.clearSelection();
          }
        }
      });
      if (this.filterState === 'disable') {
        this.uploadsListForm.patchValue({
          uploadType: this.uploadTypeDropdownValue.length === 1 ? this.uploadTypeDropdownValue[0] : newObj,
          status: this.statusSelectedOptions
        });
      }
    });
  }

  getExportedMessage() {
    this.uploadService.getMessage().subscribe((data) => {
      this.uploadExportMsg = data.text ? data.text : '';
      if (this.uploadExportMsg && this.uploadExportMsg !== null && this.uploadExportMsg !== '') {
        setTimeout(() => {
          if (this.uploadExportMsg && this.uploadExportMsg !== undefined) {
            this.msgToast.pushMessage('success', this.uploadExportMsg);
          }
        }, 1);
      }
    });

  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.uploadService.clearMessage();
    this.sharedService.updatedDate.next('');
    this.uploadService.showErrorMsg = false;
    if (this.processID.length > 0) {
      this.processID.map((data) => {
        clearInterval(this.intervalJobs[data]);
      });
    }
    if (this.exportID.length > 0) {
      this.exportID.map((data) => {
        clearInterval(this.exportJobs[data]);
      });
    }
  }

  customSort(event) {
    if (event.multiSortMeta && this.pageObject.sortField !== this.customSortService.customSort(event)) {
      const sortArray = this.customSortService.customSort(event);
      this.isSortingChanged = (JSON.stringify(event.multiSortMeta) === JSON.stringify([this.defaultSortDataField])) ? false : true;
      this.pageObject.pageNo = 1;
      if (this.totalUploads && this.totalUploads > 15) {
        this.paginator.changePage(0);
      }
      this.pageObject = {
        pageNo: this.pageObject.pageNo,
        pageSize: this.pageObject.pageSize,
        sortField: sortArray
      };
      if (this.initialLoad) {
        this.initialLoad = false;
      } else {
        this.clearSuccessAndErrorMessages();
      }
      this.getUploadList();
      if (this.paginator) {
        this.paginator.changePage(0);
      }
    }
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page + 1) {
      this.pageObject.pageNo = e.page + 1;
      this.clearSuccessAndErrorMessages();
      this.getUploadList();
    }
  }

  getUploadList() {
    const localUpdatedFormValues = this.uploadsListForm.getRawValue();
    const submittedDate = [];
    if (this.filterSelectedDate !== undefined && this.filterSelectedDate !== '' && this.filterSelectedDate !== null) {
      this.filterSelectedDate.forEach(date => {
        submittedDate.push(date);
      });
    }
    localUpdatedFormValues.status.sort();
    const isStatusAll = this.statusSelectedOptions.every((val, index) => val === localUpdatedFormValues.status[index]);
    const statusArr = [];
    if (!isStatusAll) {
      localUpdatedFormValues.status.forEach(status => {
        if (status === 'Processing') {
          status = 'Started';
        }
        statusArr.push(status);
      });
    }
    if (this.processID.length > 0) {
      this.processID.map((data) => {
        window.clearInterval(this.intervalJobs[data]);
      });
    }
    if (this.filterClicked) {
      const formFilterObj: any = {
        orgId: this.getOrgId ? this.getOrgId : 0,
        uploadId: localUpdatedFormValues.uploadId ? localUpdatedFormValues.uploadId : null,
        fileName: localUpdatedFormValues.fileName ? localUpdatedFormValues.fileName : null,
        uploadType: localUpdatedFormValues.uploadType.value,
        statusList: localUpdatedFormValues.status ? (isStatusAll ? localUpdatedFormValues.status = ['All'] : statusArr) : [],
        submittedFromDate: submittedDate.length > 0 ? moment(submittedDate[0]).format('MM/DD/YYYY') : '',
        submittedToDate: submittedDate.length > 0 ? moment(submittedDate[1]).format('MM/DD/YYYY') : '',
        submittedBy: localUpdatedFormValues.submittedBy ? localUpdatedFormValues.submittedBy.trim() : null
      };
      this.updateformValues(formFilterObj);
      this.storeFilterObj = formFilterObj;
    } else {
      const formFilterObj: any = {
        orgId: this.getOrgId ? this.getOrgId : 0,
        uploadId: null,
        fileName: null,
        uploadType: 'All',
        statusList: ['All'],
        submittedFromDate: '',
        submittedToDate: '',
        submittedBy: null
      };
      this.updateformValues('');
      this.storeFilterObj = formFilterObj;
    }
    if (this.hasADDLinkEMTRAccess) {
      this.uploadService.getUploads(this.pageObject, this.storeFilterObj).subscribe((uploads) => {
        if (uploads.data !== null || uploads.data !== undefined) {
          this.uploadsList = uploads.data.content;
          this.totalUploads = this.sharedService.totalElementsWithCommas(uploads.data.totalElements);
          this.paginationCount = uploads.data.totalElements;
          this.resetTable = false;
        }
        this.uploadsList.map(data => {
          data.progressValue = 0;
          if (data.status === 'STARTED' || data.status === 'RECEIVED' || data.status === 'Processing') {
            data.status = 'Processing';
            this.processID.push(data.uploadId);
            this.intervalJobs[data.uploadId] = setInterval(() => {
              this.getUploadStatus(data.uploadId);
            }, this.getConstant.reqDetailPollingInterval);
          }
        });
      });
    }
  }

  processUploadedFile(uploadID, fileName, totalCount, errorCount, status, exportStatus) {
    this.messageService.clear();
    this.uploadsViewChild.openModal(uploadID, fileName, totalCount, errorCount, status, exportStatus);
  }

  getUploadStatus(uploadIds) {
    this.sharedService.setRefresh.emit(true);
    this.uploadService.pollUploadsDetailsByUploadId(uploadIds).subscribe((res) => {
      for (const upload of res.data) {
        const data = {status: upload.status, uploadId: upload.uploadId, progressValue: Math.round((res.data[0].processCount / res.data[0].recordCount) * 100)};
        this.processWebsocketResponse(data);
        if (data.uploadId === uploadIds && (data.status !== 'Processing')) {
          window.clearInterval(this.intervalJobs[uploadIds]);
          this.sharedService.setRefresh.emit(false);
        }
      }
    }, error => {
      window.clearInterval(this.intervalJobs[uploadIds]);
    });
  }

  public processWebsocketResponse(responseObject: any): void {
    for (const upload of this.uploadsList) {
      if (upload.uploadId !== null && upload.uploadId === responseObject.uploadId) {
        upload.status = responseObject.status;
        upload.exportStatus = responseObject.exportStatus;
        upload.progressValue = responseObject.progressValue;
        break;
      }
    }
  }

  downloadExportedError(uploadID, fileName) {
    const exportFileName = fileName.split('.');
    this.messageService.clear();
    this.sharedService.downloadFileSystem(uploadID, 'upload').subscribe((response: HttpResponse<Blob>) => {
      this.sharedService.saveFile(response.body, 'Export_' + exportFileName[0] + '_' + uploadID + '.' + exportFileName[1]);
    });
  }

  downloadSubmittedFile(uploadID, fileName) {
    this.messageService.clear();
    this.sharedService.downloadFileSystem(uploadID, 'submittedFile').subscribe((response: HttpResponse<Blob>) => {
      this.sharedService.saveFile(response.body, fileName);
    });
  }

  navigateTo(link) {
    this.sharedService.loadDataTrackValue('client_redirect');
    switch (link) {
      case 'ADDLink': {
        this.router.navigate(['/ems/addLink/request-list'], {relativeTo: this.activatedRoute});
        break;
      }
      case 'Rule': {
        this.router.navigate(['/ems/emtr/rules/rules-list'], {relativeTo: this.activatedRoute});
        break;
      }
      case 'Employee': {
        this.router.navigate(['/ems/emtr/manage-employees/employee-list'], {relativeTo: this.activatedRoute});
        break;
      }
      case 'EmployeeGroup': {
        this.router.navigate(['/ems/emtr/manage-employee-groups/employee-group-list'], {relativeTo: this.activatedRoute});
        break;
      }
      case 'Employee Group': {
        this.router.navigate(['/ems/emtr/manage-employee-groups/employee-group-list'], {relativeTo: this.activatedRoute});
        break;
      }
      case 'Participant Plan': {
        this.router.navigate(['/ems/emtr/manage-plans/plans-list'], {relativeTo: this.activatedRoute});
        break;
      }
    }
  }

  toggleFunction() {
    this.sharedService.gutTracking('adobe-fl_uploadsfilter|fireonce');
    this.toggle = !this.toggle;
    if (this.toggle) {
      this.getUploadType();
    }
  }

  getUploadType() {
    const orgId = this.getOrgId;
    /* This If statement is checking whether records are present */
    this.uploadService.getUploadTypes(orgId).subscribe((upload) => {
      this.uploadTypeDropdownValue = [];
      upload.data.forEach(data => {
        const add = {label: data, value: data};
        this.uploadTypeDropdownValue.push(add);
        this.uploadTypeDropdownValue.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
      });
      const newObj = {label: 'All', value: 'All'};
      if (this.uploadTypeDropdownValue.length > 1) {
        this.uploadTypeDropdownValue.unshift(newObj);
        this.uploadsListForm.get('uploadType').setValue(newObj);
        this.uploadsListForm.get('uploadType').enable();
      } else {
        this.uploadsListForm.get('uploadType').setValue(this.uploadTypeDropdownValue[0]);
        this.uploadsListForm.get('uploadType').disable();
      }
    });
  }

  ngAfterViewChecked(): void {
    const elementRef = this.el.nativeElement.querySelector('.p-multiselect-label');
    this.sharedService.setMultiSelectDefaultLabel(elementRef, this.uploadsListForm, 'status', this.statusSelectedOptions.length);
  }

  getSelectedDate(date) {
    this.filterSelectedDate = date;
    this.uploadsListForm.get('submittedDate').setValue(this.filterSelectedDate);
    if (!this.isClearBtnClicked && this.filterSelectedDate) {
      this.uploadsListForm.markAsDirty();
    }
  }

  applyFilter() {
    /* Set the selected date value to the manageUserListForm before hitting the Filter API */
    if (this.rangeCalendar) {
      this.rangeCalendar.applyToDate();
      this.getSelectedDate(this.rangeCalendar.dateRangeForm.get('dateSelected').value);
    }
    this.pageObject.pageNo = 1;
    if (this.totalUploads && this.totalUploads > 15) {
      this.paginator.changePage(0);
    }
    this.filterClicked = true;
    this.clearSuccessAndErrorMessages();
    this.getUploadList();
  }

  clickedClearButton(resetSortFromUI?) {
    this.resetForm(resetSortFromUI);
    this.getUploadList();
  }

  resetForm(resetSortFromUI?) {
    if (resetSortFromUI) {
      this.multiSortMeta = [this.defaultSortDataField];
    }
    this.uploadsListForm.reset();
    const newObj = {label: 'All', value: 'All'};
    this.uploadsListForm.patchValue({uploadType: newObj, status: this.statusSelectedOptions});
    if (this.toggle) {
      this.isClearBtnClicked = true;
      this.rangeCalendar.clearSelection();
    }
    if (this.uploadTypeDropdownValue.length === 1) {
      this.uploadsListForm.get('uploadType').setValue(this.uploadTypeDropdownValue[0]);
      this.uploadsListForm.get('uploadType').disable();
    }
    this.pageObject.pageNo = 1;
    if (this.totalUploads && this.totalUploads > 15) {
      this.paginator.changePage(0);
    }
    this.filterClicked = false;
    this.isClearBtnClicked = false;
  }

  getExportID(event) {
   this.exportID.push(event);
   this.exportJobs[event] = setInterval(() => {
     this.getExportStatus(event);
   }, this.addLinkConstant.reqDetailPollingInterval);
  }
  getExportStatus(uploadIds) {
    this.sharedService.setRefresh.emit(true);
    this.uploadService.pollUploadsDetailsByUploadId(uploadIds).subscribe((res) => {
      for (const upload of res.data) {
        const data = {status: upload.status, uploadId: upload.uploadId, exportStatus: upload.exportStatus};
        this.processWebsocketResponse(data);
        if (data.uploadId === uploadIds && (data.exportStatus !== 'STARTED' && data.exportStatus !== 'Processing')) {
          window.clearInterval(this.exportJobs[uploadIds]);
          this.exportID.splice(this.exportID.indexOf(data.uploadId) , 1);
          this.sharedService.setRefresh.emit(false);
        }
      }
    }, error => {
      clearInterval(this.exportJobs[uploadIds]);
    });
  }

  updateformValues(formVals) {
    this.sharedService.updatedDate.next(formVals.submittedFromDate ? {name: 'Require Indexing', fromDate: formVals.submittedFromDate, toDate: formVals.submittedToDate } : '');
  }

  formMenuItem(rowData) {
    const actionItem = UploadListActionMenuItems;
    actionItem.forEach(data => {
      /* eslint-disable-next-line max-len */
      data.disabled = data.label === this.getConstant.uploadListPage.uploadActionMenu.exportExcel ? rowData.exportStatus !== this.getConstant.uploadListPage.complete : false;
    });
    this.actionMenu = actionItem;
  }

  onActionMenuClick(event: any, rowData: any) {
    const actionItem = event.target.parentElement.innerText;
    this.sharedService.clearErrorMessage();
    if (actionItem === this.getConstant.uploadListPage.uploadActionMenu.detailsReport) {
      this.processUploadedFile(rowData.uploadId, rowData.fileName, rowData.recordCount, rowData.errorCount, rowData.status, rowData.exportStatus);
    } else if (actionItem === this.getConstant.uploadListPage.uploadActionMenu.downloadFile) {
      this.downloadSubmittedFile(rowData.uploadId, rowData.fileName);
    } else if (actionItem === this.getConstant.uploadListPage.uploadActionMenu.exportExcel && rowData.exportStatus === this.getConstant.uploadListPage.complete) {
      this.downloadExportedError(rowData.uploadId, rowData.fileName);
    }
  }

  clearSuccessAndErrorMessages() {
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
  }
}
