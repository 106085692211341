import {Component, OnInit, ViewChild, Output, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import * as temp from 'moment';
const moment = temp['default'];
import {SharedConstant} from '../../constants/sharedConstants';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'ems-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DateRangeFilterComponent implements OnInit {
  dates: any;
  ranges: any[];
  dateValue: Date[];
  getCommConst = SharedConstant;
  isClicked: any;

  @ViewChild('myCalendar', { static: true }) datePicker;
  @Output() submittedDate = new EventEmitter<any>();
  @Input() disableForm = false;
  @Input() selectFuturedates = false;
  @Input() limitCustomRangeTo6Months: boolean;
  @Input() limitCustomRangeTo1Month: boolean;

  constructor(public fb: UntypedFormBuilder, public sharedService: SharedService) {
  }

  dateRangeForm: UntypedFormGroup = this.fb.group({
    dateSelected: ['', {standalone: true}]
  });
  maxDateValue: Date;
  public minDateValue: Date;

  ngOnInit() {
    this.ranges = this.limitCustomRangeTo6Months || this.limitCustomRangeTo1Month ? ['Today', 'Yesterday', 'Current Month', 'Custom Range'] : ['Today', 'Yesterday', 'Current Month', 'Current Year', 'Custom Range'];
    if (!this.selectFuturedates) {
      this.maxDateValue = new Date(moment().tz('America/New_York').format(this.getCommConst.date_format.mm_dd_yy));
      moment(this.maxDateValue, 'MM/DD/YYYY').format('MM/DD/YYYY');
    }

    if (this.limitCustomRangeTo6Months || this.limitCustomRangeTo1Month) {
      const dateVal = this.limitCustomRangeTo6Months ? this.getCommConst.oneSeventyNineDays : this.getCommConst.twentyNineDays;
      const getCurrentDate = new Date(moment().tz('America/New_York').format(this.getCommConst.date_format.mm_dd_yy));
      this.minDateValue = new Date(moment(getCurrentDate).subtract(dateVal, 'days')._d.toDateString());
      moment(this.minDateValue, 'MM/DD/YYYY').format('MM/DD/YYYY');
    }

    if (this.sharedService.requestStatus === this.getCommConst.requireIndexing || this.sharedService.requestStatus === this.getCommConst.clientCentralLinks || this.sharedService.requestStatus === this.getCommConst.clientCentralDelinks) {
      const fromDate = new Date(moment(this.sharedService.requestStatusFilterObj.fromDate));
      const toDate = new Date(moment(this.sharedService.requestStatusFilterObj.toDate));
      const date = [fromDate, toDate];
      this.dateValue = date;
    }

    this.sharedService.updatedDate.subscribe((data) => {
      if (data && data.name === 'Require Indexing') {
        const fromDate = new Date(moment(data.fromDate));
        const toDate = new Date(moment(data.toDate));
        const date = [fromDate, toDate];
        this.dateValue = date;
      }
    });
  }

  performRangeSelection(range) {
    const today = new Date(moment().tz('America/New_York').format(this.getCommConst.date_format.mm_dd_yy));
    switch (range) {
      case 'Yesterday':
        const firstDate = new Date(moment(today).subtract(1, 'days')._d.toDateString());
        this.dateValue = [firstDate, firstDate];
        break;
      case 'Current Month':
        this.dateValue = [new Date(moment().startOf('month')._d.toDateString()), new Date(moment().endOf('today')._d.toDateString())];
        break;
      case 'Current Year':
        this.dateValue = [new Date(moment().startOf('year')._d.toDateString()), new Date(moment().endOf('today')._d.toDateString())];
        break;
      case 'Custom Range':
        this.dateValue = [today];
        break;
      default:
        this.dateValue = [today, today];
        break;
    }
    this.submittedDate.emit(this.dateValue);
  }

  applyDate() {
    if (this.dateValue && this.dateValue[1] === null) {
      this.dateRangeForm.get('dateSelected').setValue('');
    }
    this.datePicker.overlayVisible = false;
    this.submittedDate.emit(this.dateRangeForm.get('dateSelected').value);
  }

  clearSelection() {
    this.isClicked = '';
    this.datePicker.overlayVisible = false;
    this.dateRangeForm.reset();
    this.submittedDate.emit(this.dateRangeForm.get('dateSelected').value);
  }

  openCalendar() {
    setTimeout(() => {
      const elem = document.querySelector('.ui-datepicker');
      if (elem instanceof HTMLElement) {
        const second = '40px';
        const localVar = parseInt(elem.style.left, 10) - parseInt(second, 10) + 'px';
        elem.style.left = localVar;
        elem.style.zIndex = '33333333333333333333';
      }
    }, 1);
    this.datePicker.overlayVisible = true;
  }

  applyToDate() {
    if (this.dateValue && this.dateValue[1] === null) {
      this.dateValue[1] = this.dateValue[0];
    }
  }
}
