<sdps-notification type="error" class="sdps-m-top_none" *ngIf="showSchError || showRequestError">
  <div class="sdps-notification__text">
    <p>Please correct following error.</p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngFor='let msg of schErrorMsg' [innerHTML]="msg"></li>
    </ul>
    <ul *ngIf="showRequestError">
      <li [innerHTML]="emtrConst.ruleSteps.step2.changeRequestIdInvalidMessage">
      </li>
    </ul>
  </div>
</sdps-notification>

<ems-toast-message *ngIf="toastMsg" #toastMessage></ems-toast-message>
<h2 class="page-title sdps-m-bottom_large" [innerHTML]="emtrConst.rulesConstant.addNewRule"></h2>
<ems-step-wizard [stepObj]="items"></ems-step-wizard>
