import {Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input} from '@angular/core';
import {FormDataService, SharedService} from '@ems/shared';
import {emtrConstant} from '../../constants/emtrConstants';
import {SecurityTableHeader, TableHeader} from '../../models/security-type.model';

@Component({
  selector: 'tr-security-type',
  templateUrl: './security-type.component.html',
  styleUrls: ['./security-type.component.scss']
})

export class SecurityTypeComponent implements OnInit {
  securityData: any[];
  columnsLevel: any[];
  currentParentNode: any;
  superParentNode: any;
  labels = '';
  nextParent: any;
  selectedNodes: any = [];
  checked = false;
  selectedObject: any = [];
  getConstant = emtrConstant;
  securityTableHeader: TableHeader[] = SecurityTableHeader;
  checkedLevel: any = {};

  @ViewChild('target') targetEl: ElementRef;

  @Input() selectedValues: any = [];
  @Input() reviewSecurity: boolean;
  @Input() securityRulesCategory: any;
  @Input() modalId: any;
  @Output() emitSecurityTypeIndividual: EventEmitter<string> = new EventEmitter<string>();

  constructor(public formDataService: FormDataService, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.columnsLevel = this.securityTableHeader;
    this.labels = this.reviewSecurity ? this.getConstant.presetRules.reviewSecTypeDetailsText : this.getConstant.rulesConstant.ruleTypeLabels.selectSecurity.concat((this.securityRulesCategory === 'RESTRICT_LST_ENG') ?
      this.getConstant.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG : (this.securityRulesCategory === 'Override') ?
        this.getConstant.rulesConstant.ruleGlobalConst.override : this.getConstant.rulesConstant.ruleGlobalConst.watchList);
  }

  /*Removing the "selected" property from the selectedType*/
  removeSelectedAll() {
    const wholeArray = this.selectedValues;
    Object.keys(wholeArray).forEach(key => {
      wholeArray[key].forEach(keys => {
        if (keys.children) {
          delete keys.data.selected;
        }
      });
    });
    this.selectedObject = {...wholeArray};
    return wholeArray;
  }

  /*openModal the Modal*/
  openModal(e: Event, data) {
    e.stopPropagation();
    this.securityData = this.formatJson(data);
    /* Populating the already selected type in Modal while loading */
    if (this.selectedValues !== undefined) {
      const that = this;
      /* selectAll checkbox should be checked if all records are selected */
      setTimeout(() => {
        that.unCheckSelectAll();
      }, 0);
      this.selectedNodes = this.removeSelectedAll();
    }
    this.sharedService.openModal(this.modalId);
  }

  /*closeModal the Modal*/
  closeModal() {
    this.selectedNodes = [];
    this.selectedNodes = this.selectedObject;
    this.selectedValues = this.selectedObject;
    /* Move the user back to the top of the modal once the modal is closed, so that everytime the user re-opens the modal he lands on starting section of the modal */
    // this.goToTopScenario();
  }

  /*Header-Checkbox Select-all functionality*/
  headerCheckbox(checkboxValue) {
    if (checkboxValue) {
      this.selectedNodes = this.selectAllCheckbox();
      this.securityData.forEach(element => {
        this.checkedLevel[element.data.desc] = true;
      });
    } else {
      this.securityData.forEach(element => {
        this.checkedLevel[element.data.desc] = false;
      });
      this.selectedNodes = [];
      this.checked = false;
    }
  }

  /*Header-Select-All to get the Grid records to check the checkboxes*/
  selectAllCheckbox() {
    const localSecurityObj: any = {}; /*Created local Variable*/
    let securityTypeData: any = [];
    this.securityData.forEach(element => {
      securityTypeData = [];
      securityTypeData.push(element);
      element.children.forEach(element2 => {
        securityTypeData.push(element2);
        element2.children.forEach(last => {
          securityTypeData.push(last);
        });
      });
      localSecurityObj[element.data.desc] = securityTypeData;
    });
    return localSecurityObj;
  }

  /*Un-Check and Check Select All Checkbox according to selected children checkbox*/
  unCheckSelectAll() {
    /* removing the property from SelectedNode if property contains empty array */
    Object.keys(this.selectedNodes).forEach((key) => ((this.selectedNodes[key] !== undefined && this.selectedNodes[key].length === 0)
      && delete this.selectedNodes[key]));
    const checkedData = this.selectAllCheckbox(); /*To get the Whole Array*/
    const selectLocalNodes = this.selectedNodes;  /*Assigning the selectedArray to local Variable*/
    const keys = Object.keys(checkedData);
    const keysSelected = Object.keys(selectLocalNodes);
    const entireCheckedArray: any = [];
    const selectedArray: any = [];
    keys.forEach(check => {
      entireCheckedArray.push({[check]: checkedData[check].length});
    });
    keysSelected.forEach(check1 => {
      selectedArray.push({[check1]: selectLocalNodes[check1].length});
    });
    if (JSON.stringify(this.sortSelectedArray(entireCheckedArray)) === JSON.stringify(this.sortSelectedArray(selectedArray))) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }

  /* Sorting the both whole Table Records array/selected Array in Alphabetical Order */
  sortSelectedArray(arr) {
    return arr.sort((a, b) => Object.keys(a) > Object.keys(b) ? 1 : (Object.keys(b) > Object.keys(a) ? -1 : 0 ));
  }

  /*Binding the Parent/Child Values in the TreeTable*/
  securityLevelTypeCheck(level, rowNode, rowData): boolean {
    if (level > 0) {
      this.nextParent = rowNode;
      return this.securityLevelTypeCheck(level - 1, rowNode.parent, rowData);
    } else {
      return (rowNode.children[0].data === this.nextParent.data || rowNode.children[0].data === rowData);
    }
  }

  /*Formatting the Backend JSON to Treetable JSON Formate*/
  formatJson(data) {
    return data.map(dataEle => {
      const subLevels = dataEle.subLevels;
      if(!this.reviewSecurity) {
        delete dataEle.subLevels;
      }
      const obj = {
        data: dataEle
      };
      if (subLevels) {
        /* eslint-disable @typescript-eslint/dot-notation */
        obj['children'] = this.formatJson(subLevels);
        obj['expanded'] = true;
      }
      return obj;
    });
  }

  /*Checkbox Parent Selection Functionality*/
  isParentNode(rowNode) {
    /* Remove pi-minus icon while clicking selectall*/
    if (this.checked) {
      rowNode.node.partialSelected = false;
    }
    if (!rowNode.level) {
      this.superParentNode = rowNode;
    }
    if (rowNode.level === 1) {
      this.currentParentNode = rowNode;
    }
    if (rowNode.parent && rowNode.parent.parent) {
      return true;
    } else {
      return false;
    }
  }

  /* Saving the SelectedNodes and closing the modal */
  saveSelectedTypes() {
    const localObj = {...this.selectedNodes};
    this.emitSecurityTypeIndividual.emit(localObj);
    this.closeModal();
    this.sharedService.closeModal(this.modalId);
  }

  /* Moving the Control to Top */
  goToTopScenario() {
    const ruleType = (this.securityRulesCategory === 'RESTRICT_LST_ENG') ?
      this.getConstant.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG : (this.securityRulesCategory === 'Override') ?
        this.getConstant.rulesConstant.ruleGlobalConst.override : this.getConstant.rulesConstant.ruleGlobalConst.watchList;
    /* When user opens the review security details modal for Preset Rules/Asset Groups/Miscellaneous Asset Grp the reviewSecurity will be set to True and the modal will have different reference id's */
    const id = this.reviewSecurity ? 'review-security-type-details-modal-title' : 'select-securities-to-' + ruleType.toLowerCase() + '-modal-title';
    const el = document.getElementById(id);
    el.scrollIntoView();
  }

  /*Traverse to selectedValue */
  traverseToType(data) {
    /* When user opens the review security details modal for Preset Rules/Asset Groups/Miscellaneous Asset Grp the reviewSecurity will be set to True and the modal will have different reference id's */
    const id = this.reviewSecurity ? data.desc + ' review' : data.desc;
    const el = document.getElementById(id);
    el.scrollIntoView();
  }

  /* disabling the Save button if no type was selected in the modal*/
  validateSelectedNode(data) {
    return (Object.keys(data).length === 0);
  }

  checkStatus(event, data) {
    if (event) {
      let securityTypeData: any = [];
      this.securityData.forEach(element => {
        if (element.data.desc === data.desc) {
          securityTypeData = [];
          securityTypeData.push(element);
          element.children.forEach(securityLevelData2 => {
            securityTypeData.push(securityLevelData2);
            securityLevelData2.children.forEach(last => {
              securityTypeData.push(last);
            });
          });
        }
      });
      this.selectedNodes[data.desc] = securityTypeData;
    }
  }
}
