import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {SharedConstant, SharedService} from '@ems/shared';
import {ConfirmationService} from 'primeng/api';
import {Observable, Observer} from 'rxjs';
import {DynamicEnvironment} from '@environment/dynamic-environment';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '@core_services/auth/auth.service';
import {TokenService} from '@core_services/token.service';
import { HostListener } from '@angular/core';
import {ShellConstant} from '@shell_components/constants/shellConstants';

@Component({
  selector: 'app-global-timeout',
  templateUrl: './global-timeout.component.html'
})
export class GlobalTimeoutComponent implements OnInit, OnDestroy {

  @ViewChild('cd') cd: any;

  sharedConstant = SharedConstant;
  logOut = this.sharedConstant.globalTimeoutDialog.logOut;
  stayLoggedIn = this.sharedConstant.globalTimeoutDialog.stayLoggedIn;
  countDown: any;
  counter = 180;
  showHtmlTimer: string;
  timerValue: any;
  clearTime: any = 0;
  timeleft: any;
  environment = new DynamicEnvironment();
  showModal = false;
  shellConstant = ShellConstant;

  constructor(public confirmationService: ConfirmationService, public sharedService: SharedService, public authService: AuthService,
              private httpService: HttpClient, private tokenService: TokenService) {
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:onmousemove', ['$event'])
  @HostListener('document:mouseover', ['$event'])
  @HostListener('document:keypress', ['$event'])
  @HostListener('document:onkeypress', ['$event'])
  @HostListener('document:keyCode', ['$event'])
  @HostListener('document:keys', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:keyup', ['$event'])
  startTimer(event: KeyboardEvent) {
    if (this.sharedService.applicationInfo && this.sharedService.applicationInfo.data && this.sharedService.applicationInfo.data.userSessionTimeout) {
      this.sharedService.sessionTimeoutValue = 1000 * 60 * (this.sharedService.applicationInfo.data.userSessionTimeout - 3);
      if (this.sharedService.sessionTimeoutValue && event) {
        clearInterval(this.clearTime);
        this.clearTime = setInterval(() => {
          this.showDialog();
          this.showModal = true;
        }, this.sharedService.sessionTimeoutValue);
      }
    }
  }

  ngOnInit() {
  }

  showGlobalTimeOutMsg(confirmObj, observer) {
    this.logOut = confirmObj.logOut || this.sharedConstant.globalTimeoutDialog.logOut;
    this.stayLoggedIn = confirmObj.stayLoggedIn || this.sharedConstant.globalTimeoutDialog.stayLoggedIn;
    /* Showing ConfirmationDialog Box while clicking Cancel/Away after edit */
    this.confirmationService.confirm({
      key: 'globalTimeout',
      header: confirmObj.header,
      message: confirmObj.message,
      accept: () => {
        observer.next(true);
        observer.complete();
      },
      reject: () => {
        observer.next(false);
        observer.complete();
      },
    });
  }

  /* Work against memory leak if component is destroyed */
  ngOnDestroy() {
    this.clearSetTimeInterval();
    if (this.clearTime) {
      clearInterval(this.clearTime);
    }
  }

  showDialog() {
    /* setInterval is called for every second to show the running timer on the pop-up modal */
    this.countDown = setInterval(() => {
      /* counter is initially set to 3 min(180) and is decremented by 1 every sec */
      --this.counter;
      /* assign counter value to timeleft variable and since we are decrementing the counter value, it will go to negative. hence math.max */
      this.timeleft = Math.max(0, this.counter);
      /* Calculating minutes from timeleft */
      const minutes = Math.floor(this.timeleft / 60);
      /* Calculating seconds */
      const seconds = Math.floor(this.timeleft - minutes * 60);
      /* In showHtmlTimer variable taking minutes and second as string and slice -2 because to show countdown format 00:00 */
      this.showHtmlTimer = (('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2));
      const confirmObj = {
        header: `${this.sharedConstant.globalTimeoutDialog.title}`,
        message: `${this.sharedConstant.globalTimeoutDialog.sessionExpiredMessage} ${'<br/>'} ${'<span>'} ${this.showHtmlTimer} ${'<span/>'} ${'<br/>'} ${this.sharedConstant.globalTimeoutDialog.minsAndSec} ${this.sharedConstant.globalTimeoutDialog.message}`,
        acceptBtn: this.sharedConstant.globalTimeoutDialog.logOut,
        rejectBtn: this.sharedConstant.globalTimeoutDialog.stayLoggedIn
      };
      /* when time reaches 00:00 show the user logged out pop-up with msg */
      if (this.timeleft === 0 && this.counter >= 0) {
        /* When timer has reached 00:00 call auto logout endpoint to notify the backend on session end */
        this.autoLoggedOutUser();
      } else {
        Observable.create((observer: Observer<boolean>) => {
          this.showGlobalTimeOutMsg(confirmObj, observer);
        }).subscribe(accept => {
          console.log('Global Timeout Modal is Opened');
        });
      }
    }, 1000);
  }

  /* Logout the user on click Logout button in Modal */
  logOutFn() {
    /* When user clicks logout button in modal, clear setInterval and call logout endpoint from auth service */
    this.clearSetTimeInterval();
    this.authService.logOutUser();
    this.showModal = false;
  }

  /* Keep the user logged-in by extending the session */
  stayLoggedInFn() {
    /* When user clicks stay logged in btn, clear setInterval and close the modal*/
    this.clearSetTimeInterval();
    this.showModal = false;
  }

  /* Clears the setInterval and setTimeout set counter back to 3 mins */
  clearSetTimeInterval() {
    if (this.countDown) {
      clearInterval(this.countDown);
      this.countDown = 0;
    }
    this.counter = 180;
  }

  /* Calling the auto logout endpoint when 3 mins over in Modal */
  autoLoggedOutUser() {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders('xx', 1, schwabClientHeader);
    this.httpService.delete(this.environment.webAppService + '/v1/logout' + '?Schwab-Client-Ids= ' + schwabClientHeader).subscribe(res => {
      const data = 'data';
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = encodeURI(res[data]);
    });
  }
}
