import {NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EmtrComponent} from './emtr.component';

import {PrimeNgModule} from './common_modules/prime-ng/prime-ng.module';

import {EmtrRoutingModule} from './emtr-routing.module';
import {RulesListComponent} from './rules/rules-list/rules-list.component';
import {RuleGroupsComponent} from './rule-groups/rule-groups.component';
import {SharedModule} from '@ems/shared';
import {RuleService} from './services/rule-service/rule.service';
import { EditRuleStep1Component } from './rules/two-step-edit-rule/edit-rule-step1/edit-rule-step1.component';
import { EditRuleStep2Component } from './rules/two-step-edit-rule/edit-rule-step2/edit-rule-step2.component';
import { EditRuleComponent } from './rules/two-step-edit-rule/edit-rule.component';
import { RulesComponent } from './rules/rules.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RulesBulkUploadComponent} from './rules-bulk-upload/rules-bulk-upload.component';
import {DatePipe} from '@angular/common';
import {CreateRuleComponent} from './rules/create-rule/create-rule.component';
import {RulesStep4Component} from './rules/create-rule/rules-step4-review-rule/rules-step4.component';
import {RulesStep2Component} from './rules/create-rule/rules-step2-select-attributes/rules-step2.component';
import {RulesStep3Component} from './rules/create-rule/rules-step3-assign-employees/rules-step3.component';
import {RulesStep1Component} from './rules/create-rule/rules-step1-rule-type/rules-step1.component';
import {RestrictCheckboxComponent} from './rules/restrict-checkbox/restrict-checkbox.component';
import {CustomMultiselectComponent} from './rules/custom-multiselect/custom-multiselect.component';
import {DescriptionAndNotesComponent} from './rules/description-and-notes/description-and-notes.component';
import {EmtrDatepickerComponent} from './rules/emtr-datepicker/emtr-datepicker.component';
import {EmtrTimepickerComponent} from './rules/emtr-timepicker/emtr-timepicker.component';
import {EmtrFromToDatepickerComponent} from './rules/emtr-from-to-datepicker/emtr-from-to-datepicker.component';
import {SecuritiesToRestrictComponent} from './rules/securities-to-restrict/securities-to-restrict.component';
import {RuleNameTypeComponent} from './rules/rule-name-type/rule-name-type.component';
import {SecurityTypeComponent} from './rules/security-type/security-type.component';
import {SearchSecurityModalComponent} from './rules/search-security-modal/search-security-modal.component';
import {SecurityRestrictComponent} from './rules/security-restrict/security-restrict.component';
import {EmployeeListComponent} from './manage-employees/employee-list/employee-list.component';
import {EditEmployeeModalComponent} from './manage-employees/edit-employee-modal/edit-employee-modal.component';
import {ManageEmployeesComponent} from './manage-employees/manage-employees.component';
import {EmpGroupBulkUploadComponent} from './emp-group-bulk-upload/emp-group-bulk-upload.component';
import {EmployeeGroupListComponent} from './manage-employee-groups/employee-group-list/employee-group-list.component';
import {CreateEmployeeGroupComponent} from './manage-employee-groups/create-employee-group/create-employee-group.component';
import {ManageEmployeeGroupsComponent} from './manage-employee-groups/manage-employee-groups.component';
import {EmployeeService} from './services/employee-service/employee.service';
import {Step1EnterGroupDetailsComponent} from './manage-employee-groups/create-employee-group/step1-enter-group-details/step1-enter-group-details.component';
import {Step2AddEmployeesComponent} from './manage-employee-groups/create-employee-group/step2-add-employees/step2-add-employees.component';
import {Step3ReviewAndSaveComponent} from './manage-employee-groups/create-employee-group/step3-review-and-save/step3-review-and-save.component';
import {InstrumentGroupsComponent} from './instrument-groups/instrument-groups.component';
import {InstrumentGroupListComponent} from './instrument-groups/instrument-group-list/instrument-group-list.component';
import {InstrumentGroupService} from './services/instrument-group-service/instrument-group.service';
import {CreateInstrumentGroupComponent} from './instrument-groups/create-instrument-group/create-instrument-group.component';
import {Step1EnterInstrumentGroupDetailsComponent} from './instrument-groups/create-instrument-group/step1-enter-instrument-group-details/step1-enter-instrument-group-details.component';
import {Step2AddInstrumentsComponent} from './instrument-groups/create-instrument-group/step2-add-instruments/step2-add-instruments.component';
import {Step3ReviewAndSaveInstrumentGroupComponent} from './instrument-groups/create-instrument-group/step3-review-and-save-instrument-group/step3-review-and-save-instrument-group.component';
import { SearchInstrumentGroupsComponent } from './rules/search-instrument-groups/search-instrument-groups.component';
import { PresetRulesAndAssetGroupComponent } from './rules/preset-rules-and-asset-group/preset-rules-and-asset-group.component';
import { ViewSymbolsModalComponent } from './rules/view-symbols-modal/view-symbols-modal.component';
import { RuleListSubHeaderComponent } from './rules/rule-list-sub-header/rule-list-sub-header.component';
import { ClientSelectionTableComponent } from './rules/client-selection-table/client-selection-table.component';
import { ManageMasterGroupsComponent } from './manage-master-groups/manage-master-groups.component';
import { MasterGroupsListComponent } from './manage-master-groups/master-groups-list/master-groups-list.component';

@NgModule({
  declarations: [
    EmtrComponent,
    RulesListComponent,
    RuleGroupsComponent,
    EditRuleStep1Component,
    EditRuleStep2Component,
    EditRuleComponent,
    RulesComponent,
    RulesBulkUploadComponent,
    RestrictCheckboxComponent,
    CustomMultiselectComponent,
    DescriptionAndNotesComponent,
    CreateRuleComponent,
    RulesStep1Component,
    RulesStep2Component,
    RulesStep3Component,
    RulesStep4Component,
    RuleNameTypeComponent,
    SecurityRestrictComponent,
    SecuritiesToRestrictComponent,
    SecurityTypeComponent,
    SearchSecurityModalComponent,
    EmtrDatepickerComponent,
    EmtrTimepickerComponent,
    EmtrFromToDatepickerComponent,
    ManageEmployeeGroupsComponent,
    EmployeeGroupListComponent,
    CreateEmployeeGroupComponent,
    Step1EnterGroupDetailsComponent,
    Step2AddEmployeesComponent,
    Step3ReviewAndSaveComponent,
    ManageEmployeesComponent,
    EmployeeListComponent,
    EditEmployeeModalComponent,
    EmpGroupBulkUploadComponent,
    InstrumentGroupsComponent,
    InstrumentGroupListComponent,
    CreateInstrumentGroupComponent,
    Step1EnterInstrumentGroupDetailsComponent,
    Step2AddInstrumentsComponent,
    Step3ReviewAndSaveInstrumentGroupComponent,
    SearchInstrumentGroupsComponent,
    PresetRulesAndAssetGroupComponent,
    ViewSymbolsModalComponent,
    RuleListSubHeaderComponent,
    ClientSelectionTableComponent,
    ManageMasterGroupsComponent,
    MasterGroupsListComponent
  ],
  providers: [RuleService, DatePipe, EmployeeService, InstrumentGroupService],
  imports: [
    EmtrRoutingModule,
    CommonModule,
    PrimeNgModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    EmtrComponent,
    EmtrRoutingModule,
    RulesListComponent,
    RuleGroupsComponent,
    EditRuleStep1Component,
    EditRuleStep2Component,
    EditRuleComponent,
    RulesBulkUploadComponent,
    RestrictCheckboxComponent,
    CustomMultiselectComponent,
    DescriptionAndNotesComponent,
    CreateRuleComponent,
    RulesStep1Component,
    RulesStep2Component,
    RulesStep3Component,
    RulesStep4Component,
    RuleNameTypeComponent,
    SecurityRestrictComponent,
    SecuritiesToRestrictComponent,
    SecurityTypeComponent,
    SearchSecurityModalComponent,
    EmtrDatepickerComponent,
    EmtrTimepickerComponent,
    EmtrFromToDatepickerComponent,
    ManageEmployeeGroupsComponent,
    EmployeeGroupListComponent,
    CreateEmployeeGroupComponent,
    Step1EnterGroupDetailsComponent,
    Step2AddEmployeesComponent,
    Step3ReviewAndSaveComponent,
    ManageEmployeesComponent,
    EmployeeListComponent,
    EditEmployeeModalComponent,
    EmpGroupBulkUploadComponent,
    InstrumentGroupsComponent,
    InstrumentGroupListComponent,
    CreateInstrumentGroupComponent,
    Step1EnterInstrumentGroupDetailsComponent,
    Step2AddInstrumentsComponent,
    Step3ReviewAndSaveInstrumentGroupComponent,
    SearchInstrumentGroupsComponent
  ]
})


export class EmtrModule {

  public static forRoot(commonConstants: any, entitlementConst: any): ModuleWithProviders<EmtrModule> {

    return {
      ngModule: EmtrModule,
      providers: [
        {
          provide: 'commonConstants', useValue: commonConstants
        },
        {
          provide: 'entitlementConst', useValue: entitlementConst
        }
      ]
    };
  }

}
