import {Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation} from '@angular/core';
import { AddlinkConstant } from '../../../../constants/addlinkConstants';
import {SharedService} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'addlink-delink-master-account-modal',
  templateUrl: './delink-master-account-modal.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DelinkMasterAccountModalComponent implements OnInit {

  @Output() getMasterAccVal = new EventEmitter();
  @Input() masterAccountsList: any;

  addLinkConstant = AddlinkConstant;
  title: any;
  masterAccs: any = [];
  public delinkMasterAccount: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.delinkMasterAccount = this.formBuilder.group({
      delinkMasterAccountSelected: ['']
    });
    this.title = this.addLinkConstant.delinkMasterAccModal.title;
  }

  public openModal() {
    this.sharedService.openModal('delink-master-acct-modal');
    this.delinkMasterAccount.reset();
  }

  public processAcc() {
    this.getMasterAccVal.emit(this.masterAccs);
    this.masterAccs = [];
    this.delinkMasterAccount.reset();
    this.sharedService.closeModal('delink-master-acct-modal');
  }

  public selectMasterAccs(val) {
   if (this.masterAccs.length > 0) {
      if (this.masterAccs.some(company => company.value === val.value)) {
        this.masterAccs.splice(this.masterAccs.indexOf(val.value), 1);
      } else {
        this.masterAccs.push(val);
      }
    } else {
      this.masterAccs.push(val);
    }
  }
}
