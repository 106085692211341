import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InputTextModule} from 'primeng/inputtext';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MultiSelectModule} from 'primeng/multiselect';
import {TabViewModule} from 'primeng/tabview';
import {ColorPickerModule} from 'primeng/colorpicker';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TreeTableModule} from 'primeng/treetable';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {PaginatorModule} from 'primeng/paginator';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {TooltipModule} from 'primeng/tooltip';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {ProgressBarModule} from 'primeng/progressbar';

const modules = [
  CommonModule,
  AccordionModule,
  AutoCompleteModule,
  CalendarModule,
  DropdownModule,
  KeyFilterModule,
  InputTextModule,
  MultiSelectModule,
  TreeTableModule,
  TabViewModule,
  CardModule,
  ColorPickerModule,
  CheckboxModule,
  RadioButtonModule,
  TreeTableModule,
  InputTextareaModule,
  TableModule,
  PaginatorModule,
  ToastModule,
  MenuModule,
  MenubarModule,
  TooltipModule,
  ConfirmDialogModule,
  FileUploadModule,
  ProgressSpinnerModule,
  ProgressBarModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    modules
  ],
  exports: [
    modules
  ]
})

export class PrimeNgModule {
}
