import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

/*ROUTING MODULE*/
import {AppRoutingModule} from '../../app/app-routing.module';

/*Shared Components Entry*/
import {WebStorageModule} from 'ngx-store';
import {HealthCheckComponent} from '@shell_components/core/health-check/health-check.component';
import {HeaderComponent} from '@shell_components/core/header/header.component';
import {FooterComponent} from '@shell_components/core/footer/footer.component';
import {NavigationComponent} from '@shell_components/core/navigation/navigation.component';
import {WorkbenchComponent} from '@shell_components/workbench/workbench.component';
import {PrimeNgModule} from '@ui_module/common_modules/prime-ng/prime-ng.module';
import {SharedModule} from '@ems/shared';
import {AdminMenuComponent} from '@shell_components/admin-menu/admin-menu.component';
import {ManageUsersComponent} from '@shell_components/admin-menu/manage-users/manage-users.component';
import {CreateUserComponent} from '@shell_components/admin-menu/manage-users/create-user/create-user.component';
import {ActivateRouteGuard} from '@core_services/route-activate-guard';
import {LocalStorageService} from '@core_services/local-storage.service';
import {AppService} from '@core_services/app.service';
import {ReportsComponent} from '@shell_components/reports/reports.component';
import {PresetReportsComponent} from '@shell_components/reports/preset-reports/preset-reports.component';
import {CustomReportsComponent} from '@shell_components/reports/custom-reports/custom-reports.component';
import {EmtrModule} from '@ems/emtr';
import { UnauthorizedComponent } from '@shell_components/core/unauthorized/unauthorized.component';
import {UserIdleModule} from 'angular-user-idle';
import {ManageClientsListComponent} from '@shell_components/admin-menu/manage-clients/manage-clients-list/manage-clients-list.component';
import {ManageClientsComponent} from '@shell_components/admin-menu/manage-clients/manage-clients.component';
import {CreateClientComponent} from '@shell_components/admin-menu/manage-clients/create-client/create-client.component';
import {ManageRolesComponent} from '@shell_components/admin-menu/manage-roles/manage-roles.component';
import {ChartModule} from 'primeng/chart';
import { WorkbenchPanelHeaderComponent } from '@shell_components/workbench/workbench-panel-header/workbench-panel-header.component';
import { WorkbenchLoaderComponent } from '@shell_components/workbench/workbench-loader/workbench-loader.component';
import { TokenService } from '@core_services/token.service';
import { OpenOrderComponent } from '@shell_components/reports/preset-reports/open-order/open-order.component';
import { ReportFromToDatepickerComponent } from '@shell_components/reports/preset-reports/shared/report-from-to-datepicker/report-from-to-datepicker.component';
import { TradeAttemptsComponent } from '@shell_components/reports/preset-reports/trade-attempts/trade-attempts.component';
import { EmployeeAccountDetailsComponent } from '@shell_components/reports/preset-reports/employee-account-details/employee-account-details.component';
import { RuleDetailsComponent } from '@shell_components/reports/preset-reports/rule-details/rule-details.component';
import { EmployeeGroupAssociationComponent } from '@shell_components/reports/preset-reports/employee-group-association/employee-group-association.component';
import {UploadsComponent} from '@shell_components/uploads/uploads.component';
import {UploadsListComponent} from '@shell_components/uploads/uploads-list/uploads-list.component';
import {UploadsModalComponent} from '@shell_components/uploads/uploads-modal/uploads-modal.component';
import {ReportsListComponent} from '@shell_components/reports/reports-list/reports-list.component';
import {AddlinkUsageComponent} from '@shell_components/reports/preset-reports/addlink-usage/addlink-usage.component';
import {ReportDetailsModalComponent} from '@shell_components/reports/report-details-modal/report-details-modal.component';
import {AddlinkRequestsComponent} from '@shell_components/reports/preset-reports/addlink-requests/addlink-requests.component';
import {RuleAuditComponent} from '@shell_components/reports/preset-reports/rule-audit/rule-audit.component';
import {GlobalTimeoutComponent} from '@shell_components/global-timeout/global-timeout.component';

const shellComponents = [
  HealthCheckComponent,
  HeaderComponent,
  FooterComponent,
  NavigationComponent,
  WorkbenchComponent,
  UnauthorizedComponent,
  ReportsComponent,
  PresetReportsComponent,
  CustomReportsComponent,
  ManageClientsComponent,
  ManageRolesComponent,
  ManageClientsListComponent,
  CreateClientComponent,
  WorkbenchPanelHeaderComponent,
  WorkbenchLoaderComponent,
  OpenOrderComponent,
  TradeAttemptsComponent,
  EmployeeAccountDetailsComponent,
  EmployeeGroupAssociationComponent,
  RuleDetailsComponent,
  ReportFromToDatepickerComponent,
  UploadsComponent,
  UploadsListComponent,
  UploadsModalComponent,
  ReportsListComponent,
  AddlinkUsageComponent,
  ReportDetailsModalComponent,
  AddlinkRequestsComponent,
  RuleAuditComponent,
  GlobalTimeoutComponent,
];

const addlinkComponents = [
  AdminMenuComponent,
  ManageUsersComponent,
  CreateUserComponent
];


@NgModule({
  declarations: [
    shellComponents,
    addlinkComponents
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    WebStorageModule,
    BrowserAnimationsModule,
    PrimeNgModule,
    SharedModule,
    EmtrModule,
    ChartModule,
    /* Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 3300 (55 minutes), `timeout` is 3300 (55 minutes)
    // and `ping` is 900 (15 minutes). */

    UserIdleModule.forRoot({idle: 3300, timeout: 3300, ping: 900})
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [
    AppRoutingModule,
    PrimeNgModule,
    shellComponents,
    addlinkComponents
  ],
  providers: [
    ActivateRouteGuard,
    LocalStorageService,
    AppService,
    TokenService
  ]
})
export class EmsShellModule {
}
