import {Component, OnInit} from '@angular/core';
import {EmsSubjectService} from '../services/ems-subject.service';
import {AddlinkService} from '@ems/addlink';
import {EmtrService} from '@ems/emtr';
import {SharedService} from '@ems/shared';
import {AuthService} from '@core_services/auth/auth.service';

@Component({
  selector: 'app-ems',
  templateUrl: './ems.component.html'
})
export class EmsComponent implements OnInit {

  currentOrg: any;

  /* eslint-disable-next-line max-len */
  constructor(private emsSub: EmsSubjectService, private addlinkService: AddlinkService, public authSer: AuthService, private emtrService: EmtrService, private sharedService: SharedService) {

    console.log('EmsComponent - const');

    const userPolicy = this.authSer.getUserPolicy();

    this.addlinkService.userPolicy.next(userPolicy);
    this.emtrService.userPolicy.next(userPolicy);

    emsSub.currentOrg.subscribe((org) => {

      console.log('Selected ORG in EmsComponent:: ' + org);

      this.currentOrg = org;

      this.addlinkService.currentOrg.next(this.currentOrg);

      this.emtrService.currentOrg.next(this.currentOrg);

      this.sharedService.currentOrg.next(this.currentOrg);

    });


  }

  ngOnInit() {


  }

}
