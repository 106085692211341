<div>
  <ems-toast-message *ngIf="(!(sharedService.errorMessage | async) || showToast)" #toastMessage></ems-toast-message>
  <ems-confirm-message #instrumentGrpDeleteConfirmMsg></ems-confirm-message>

  <sdps-notification type="error" class="sdps-m-top_none" *ngIf="schError.minCharacter === true || schError.invalidCharacter === true">
    <div class="sdps-notification__text">
      <p [innerText]="sharedConst.pleaseCorrectFollowingError"></p>
      <ul class="sdps-navigation--horizontal__list error-list">
        <li *ngIf="schError.minCharacter" [innerHTML]="sharedConst.textFieldErrorMsg.minCharLimitErrorMsg"></li>
        <li *ngIf="schError.invalidCharacter"
            [innerHTML]="sharedConst.textFieldErrorMsg.invalidCharacterForGrpOrRuleName"></li>
      </ul>
    </div>
  </sdps-notification>

  <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block"
      [innerHTML]="getEmtrConstants.instrumentGroups.instrumentGroupsText"></h2>
  <span class="sdps-m-horizontal_large sdps-display-inline-block client-selection-indicator"
        [innerHTML]="k4ClientName">
  </span>
</div>

<ems-view-history  #viewHistory [historyId]="instGrpHistoryRecord.historyId" [getOrgId]="getOrgId" [ruleSubType]="instGrpHistoryRecord.instGrpSecurities" [historyTitle]="instGrpHistoryRecord.instGrpName"
                   [historyData]="instGrpHistoryRecord.instGrpHistory" [totalElements]="instGrpHistoryRecord.historyRecords" (paginateHistoryList)="getHistoryPagination(instGrpHistoryRecord.historyId, $event)"></ems-view-history>

<div class="tbl-rec-count">
  <button type="button" class="buttons button-save sdps-pull-left sdps-m-top_small sdps-m-left_small"
          *ngIf="showActionItems" [routerLink]="getEmtrConstants.instrumentGroups.instrumentGroupList.toStep1"
          (click)="navigateToCreateInstrumentGroup()">
    <em class="pi pi-plus"></em>
    {{getEmtrConstants.instrumentGroups.instrumentGroupList.addNewInstrumentGroup}}
  </button>

  <ems-table-record-count class="tbl-rec-count" [showRecords]="paginationCount && paginationCount > 15" [count]="totalGroups" [showTotalForInstrumentGrps]="totalGroups"
                          (emitter)="pageObject.pageNo = 0;pageObject.pageSize = $event;applyFilter()"
                          [groupTotal]="totalGroups" [showHeaderIcons]="true"></ems-table-record-count>

  <form [formGroup]="instrumentGroupForm">
    <p-table [columns]="groupHeader" [value]="instrumentGrps" [customSort]="true" dataKey="id" sortMode="multiple" #empGrps
             metaKeySelection="true" [lazy]="true" (onLazyLoad)="customSort($event)" [multiSortMeta]="multiSortMeta"
             class="common-data-table">
      <ng-template pTemplate="header" let-columns>
        <tr class="filter-header-input">
          <th class="table-checkbox-selection filter-icon">
            <a (click)="toggleFunction()" class="cursor" href="javascript:void(0)">
              <em class="pi pi-filter"></em>
            </a>
          </th>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field"
              [pSortableColumnDisabled]="(col.field==='action')"
              [ngClass]="{'w-20-per': col.header === 'Name' || col.header === 'Description','w-18-per': col.header === 'Last Updated'}">
            {{col.header}}
            <p-sortIcon [field]="col.field" *ngIf="(col.field!=='action') && (paginationCount && paginationCount > 1 || filterClicked)"></p-sortIcon>
          </th>
        </tr>

        <tr *ngIf="toggle" class="filtered-row-input">
          <th></th>
          <th *ngIf="showSelection"></th>
          <th class="sdps-p-left_small" *ngFor="let col of columns ;let i = index;">
            <input pInputText formControlName="name" class="w-100-per" type="text" name="name" [maxLength]="30"
                   *ngIf="col.field === 'name'" emsAlphaNumericSpecialCharacterValidation
                   (keydown.enter)="!( instrumentGroupForm.invalid || filterBtnDisableForName) ? applyFilter(): ''"
                   onkeydown="return (event.keyCode !== 13);"
                   [ngClass]="{'invalidField': (filterBtnDisableForName || (instrumentGroupForm.get('name').value && instrumentGroupForm.get('name').invalid))}">

            <input pInputText formControlName="description" class="w-100-per" type="text" name="description"
                   (keydown.enter)="!( instrumentGroupForm.invalid || filterBtnDisableForName)? applyFilter(): ''"
                   onkeydown="return (event.keyCode !== 13);" *ngIf="col.field === 'description'" [maxLength]="30">

            <div class="timePickerForm" *ngIf="col.enum === 'dropdown'">
              <p-dropdown appendTo="body" placeholder="" formControlName="businessUnit" [options]="businessUnitData"
                          class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="name">
              </p-dropdown>
            </div>

            <div *ngIf="col.field === 'updTs'">
              <ems-date-range-filter #range (submittedDate)="getSelectedDate($event);"></ems-date-range-filter>
            </div>
            <div *ngIf="col.field === 'action'" class="w-200-px">
              <button class="buttons button-filter" [disabled]="!instrumentGroupForm.dirty && !isSortingChanged"
                [innerHTML]="sharedConst.filterButton.clearAllButton"
                (click)="isClickedClearBtn = true; filterClicked? clickedClearButton(isSortingChanged) : resetForm(isSortingChanged)"></button>
              <button class="buttons button-save button-filter" (click)="applyFilter()"
                [disabled]="filterBtnDisableForName || instrumentGroupForm.invalid"
                [innerHTML]="sharedConst.filterButton.applyFilterButton"></button>
            </div>
          </th>
        </tr>

        <tr *ngIf="toggle && showActionItems" class="filter-header-input">
          <th></th>
          <th *ngFor="let col of groupSubHeader">
            {{col.header}}
          </th>
        </tr>

        <tr *ngIf="toggle && showActionItems" class="filtered-row-input sdps-p-bottom_small">
          <th></th>
          <th class="sdps-p-left_small sdps-p-bottom_medium igfiltersec" *ngFor="let col of groupSubHeader">
            <div class="timePickerForm" *ngIf="col.enum === 'dropdown'">
              <p-dropdown appendTo="body" placeholder="" formControlName="showRecords" [options]="getEmtrConstants.showRecords"
                          class="sdps-p-top_small editableTimepicker request-list-header-dropdown" optionLabel="label">
              </p-dropdown>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length + 1" class="sdps-text-center sdps-font-weight-bold"
              [innerHTML]="getEmtrConstants.rulesConstant.autoComplete.noRecords"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td class="table-checkbox-selection filter-icon"></td>
          <ng-container *ngFor="let col of columns">
            <td *ngIf="col.field !== 'action'" class="sdps-truncate--ellipses">
              <span [title]="rowData[col.field]" *ngIf="col.field === 'name'" (click)="editInstrumentGroup(rowData)" class="ruleGrpLinks cursor">{{ rowData[col.field]}}</span>
              <span [title]="rowData[col.field]" *ngIf="col.field !== 'name' && col.field !== 'updTs'" [innerHTML]='rowData[col.field]'></span>
              <span [title]="rowData[col.field]" *ngIf="col.field ==='updTs'"
                    [innerHTML]="(rowData[col.field]) ? moment(rowData[col.field]).format('MM/DD/YYYY') : ''"></span>
            </td>
          </ng-container>
          <td>
            <div class="action-menu-dropdown">
              <button type="button" class="cursor sdps-p-around_xx-small"
                      (click)="showActionMenu=true;  groupId=rowData['id']; formData();"
                      [ngClass]="{'active-menu':(groupId===rowData['id'])}">
                <em class="pi pi-ellipsis-h sdps-p-around_xx-small"></em>
              </button>
              <p-menu [model]="actionMenu" *ngIf="groupId===rowData['id']" class="menu-dropdown"
                (click)="onActionMenuClick($event, rowData)">
              </p-menu>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <p-paginator class="common-pagination" [rows]="pageObject.pageSize" [alwaysShow]="paginationCount && paginationCount > 15 ? true : false" [totalRecords]="paginationCount"
               (onPageChange)="paginate($event)" #p>
  </p-paginator>
</div>
