<ul class="sdps-pull-left sdps-list--inline grid-header-icon sdps-hide">
  <li>
    <a>
      <em class="pi pi-clock history-icon"></em> <span>History</span>
    </a>
  </li>
  <li *ngFor="let icon of iconHeaderList">
    <a>
      <em [ngClass]="icon.icoClass" [title]="icon.icoName"></em>
    </a>
  </li>
</ul>
