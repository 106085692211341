import { Component, OnInit, Output, EventEmitter, Injector } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { commonConstants } from '@shell_components/constants/commonConstants';
import { ReportsService } from '@core_services/report/reports.service';
import { AppService } from '@core_services/app.service';
import { PresetReportsComponent } from '@shell_components/reports/preset-reports/preset-reports.component';
import {SharedService, SharedConstant} from '@ems/shared';

@Component({
  selector: 'app-open-order',
  templateUrl: './open-order.component.html'
})
export class OpenOrderComponent implements OnInit {

  getShellConstant = ShellConstant;
  getSharedConstant = SharedConstant;
  reportDetails: UntypedFormGroup;
  getCommConst = commonConstants;
  tradeType: any;
  @Output() runReport = new EventEmitter<any>();
  parent: PresetReportsComponent;
  fromDate: any;
  toDate: any;
  dateError: any;
  endDateLessThanStartDate = '';
  public getOrgId: any;
  public showClientMsg: any = true;
  public reportName: any;
  dateRangeConstarint = '';

  constructor(private injector: Injector, private activatedRoute: ActivatedRoute, private appService: AppService, public router: Router, private fb: UntypedFormBuilder, public reportService: ReportsService, public sharedService: SharedService) {
    this.reportName = this.reportService.getReportName();
    this.tradeType = [
      {label: this.getShellConstant.reports.RulesDetails.all, value: ''},
      {
        label: this.getShellConstant.reports.OpenOrderDetails.buy,
        value: this.getShellConstant.reports.OpenOrderDetails.buy.toUpperCase()
      },
      {
        label: this.getShellConstant.reports.OpenOrderDetails.sell,
        value: this.getShellConstant.reports.OpenOrderDetails.sell.toUpperCase()
      },
      {
        label: this.getShellConstant.reports.OpenOrderDetails.shortSell,
        value: this.getShellConstant.reports.OpenOrderDetails.shortSellCaps
      }
    ];
    this.parent = this.injector.get(PresetReportsComponent);
   }

  ngOnInit() {
    this.reportDetails = this.fb.group({
      reportName: [{value: '', disabled: true}, Validators.required],
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      employeeAccount: new UntypedFormControl(''),
      tradeSymbol: '',
      cusipSymbol: '',
      selectedTradeType: ''
    });

    /*Freeze the global client selector when the report is selected*/
    this.reportService.freezeGlobalClientSelector(this.reportDetails);

    this.reportService.makeFormDirty(this.reportDetails, this.parent);
    this.onChanges();
    this.sharedService.currentOrg.subscribe(clientInfo => {
      this.getOrgId = clientInfo;
    });
    this.reportService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.getOrgId > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });
  }

  runReports() {
    const localInput = {
      accountNumber: this.reportDetails.get('employeeAccount').value ? this.reportDetails.get('employeeAccount').value.trim() : '',
      cusip: this.reportDetails.get('cusipSymbol').value ? this.reportDetails.get('cusipSymbol').value.trim() : '',
      symbol: this.reportDetails.get('tradeSymbol').value ? this.reportDetails.get('tradeSymbol').value.trim() : '',
      endDate: encodeURIComponent(this.reportService.formatDate(this.toDate)),
      startDate: encodeURIComponent(this.reportService.formatDate(this.fromDate)),
      orgId: this.reportService.orgId,
      tradeType: this.reportDetails.get('selectedTradeType').value ? this.reportDetails.get('selectedTradeType').value.trim() : 'ALL',
      clientName: this.reportService.clientName,
      clientId: this.reportService.clientId
    };
    this.reportService.executeReports(this.parent, localInput, this.reportService.selectedReport, this.getShellConstant.reports.OpenOrderDetails.openOrder, this.getShellConstant.reports.OpenOrderDetails.runReportFor,
      this.getShellConstant.reports.OpenOrderDetails.passReportName, this.appService, '');
  }

  setDate(event) {
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.dateError = event.dateError;
  }

  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }
  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    this.endDateLessThanStartDate = event;
    this.parent.throwDateError(event);
  }

  onChanges() {
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'cusipSymbol', 9, this.getSharedConstant.textFieldErrorMsg.cusipShouldContain9Digits);
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'employeeAccount', 8, this.getSharedConstant.textFieldErrorMsg.accountNumberShouldContain8Digits);
    this.reportDetails.get('tradeSymbol').valueChanges.subscribe(tradeSymbolValue => {
      this.reportService.invalidSymbol = tradeSymbolValue && this.reportDetails.get('tradeSymbol').invalid;
      this.reportService.disableReportField(this.reportDetails, tradeSymbolValue, 'cusipSymbol');
      this.parent.msgToast.pushMessage('warn', this.getSharedConstant.textFieldErrorMsg.reportWarnMsg);
      if (!tradeSymbolValue) {
        this.parent.msgToast.pushMessage('', '');
      }
    });
    this.reportDetails.get('cusipSymbol').valueChanges.subscribe(cusipSymbolValue => {
      this.reportService.invalidCusip = cusipSymbolValue && this.reportDetails.get('cusipSymbol').invalid;
      this.reportService.disableReportField(this.reportDetails, cusipSymbolValue, 'tradeSymbol');
      this.parent.msgToast.pushMessage('warn', this.getSharedConstant.textFieldErrorMsg.reportWarnMsg);
      if (!cusipSymbolValue) {
        this.parent.msgToast.pushMessage('', '');
      }
    });
  }

  cancelReport() {
    this.reportService.cancelReport(this.reportDetails, this.parent);
  }
}
