<sdps-modal [id]="modalId" [sdpsTitle]="labels" class="sdps-custom-modal" [size]="'flex--large'"
    (close)="closeModal()" [backDropCloseDisabled]="true">
    <div slot="modal-body">
        <span #target></span>
        <div *ngFor="let employee of securityData;let index = index" class="treetable-outline">
            <div [id]="reviewSecurity ? employee.data.desc + ' review' : employee.data.desc" class="securityLevelType subNavHeading">
                <p-treeTable [value]=[employee] [columns]="columnsLevel" [resizableColumns]="true" [dataKey]="reviewSecurity ? 'code' : ''"
                    [(selection)]="selectedNodes[employee.data.desc]" class="table-caption-header" selectionMode="checkbox">
                    <ng-template pTemplate="caption">
                        <div class="select-all" *ngIf="index === 0">
                            <p-checkbox [(ngModel)]="checked" [ngClass]="reviewSecurity ? 'not-clickable' : ''" binary="true" class="emtr-checkbox" (ngModelChange)="headerCheckbox(checked)" [disabled]="reviewSecurity">
                            </p-checkbox>
                            <div class="selectAll-holder">Select All -
                                <span class="options" *ngFor="let employee of securityData;let i = index" (click)="traverseToType(employee.data)">{{employee.data.desc}}
                                    <span class="select-difference" *ngIf="i !== securityData.length-1">|</span>
                                </span>
                            </div>
                        </div>
                        <div class="security-title">
                            <p-treeTableHeaderCheckbox class="emtr-checkbox" [ngClass]="reviewSecurity ? 'not-clickable p-disabled' : ''" [disabled]="reviewSecurity" value="test" (click)="unCheckSelectAll();checkedLevel[employee.data.desc] = !checkedLevel[employee.data.desc];checkStatus(checkedLevel[employee.data.desc], employee.data)"></p-treeTableHeaderCheckbox>
                            <span>{{employee.data.desc}} (Select All)</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns" ttResizableColumn>
                                <p-treeTableHeaderCheckbox class="emtr-checkbox" [disabled]="reviewSecurity" [ngClass]="reviewSecurity ? 'not-clickable p-disabled' : ''" (click)="checkedLevel[employee.data.desc] = !checkedLevel[employee.data.desc];unCheckSelectAll();checkStatus(checkedLevel[employee.data.desc], employee.data)">
                                </p-treeTableHeaderCheckbox>
                                {{col.header}}
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
                        <tr *ngIf="isParentNode(rowNode)">
                            <td *ngFor="let col of columns; let nodelevel = index">
                                <p-treeTableCheckbox [ngClass]="reviewSecurity ? 'not-clickable' : ''" [disabled]="reviewSecurity" (click)="unCheckSelectAll();checkedLevel[employee.data.desc] = false;" class="emtr-checkbox" [value]="superParentNode" id="{{rowNode.parent.data.code}}" (change)="checkStatus(checkedLevel[employee.data.desc], employee.data )"
                                    *ngIf="col.level === 'SC1' && (securityLevelTypeCheck(columns.length-nodelevel-1, rowNode,rowData)) && (securityLevelTypeCheck(columns.length-nodelevel-2, rowNode,rowData))">
                                </p-treeTableCheckbox>
                                <p-treeTableCheckbox [ngClass]="reviewSecurity ? 'not-clickable' : ''" [disabled]="reviewSecurity" (click)="unCheckSelectAll();checkedLevel[employee.data.desc] = false;" class="emtr-checkbox" [value]="currentParentNode"
                                    *ngIf="col.level === 'SC2' && (securityLevelTypeCheck(columns.length-nodelevel-1, rowNode,rowData))">
                                </p-treeTableCheckbox>
                                <p-treeTableCheckbox [ngClass]="reviewSecurity ? 'not-clickable' : ''" [disabled]="reviewSecurity" (click)="unCheckSelectAll();checkedLevel[employee.data.desc] = false;" class="emtr-checkbox" [value]="rowNode"
                                    *ngIf="col.level === 'SC3'"></p-treeTableCheckbox>
                                <span
                                    *ngIf="col.level === 'SC1' && (securityLevelTypeCheck(columns.length-nodelevel-1, rowNode,rowData)) && (securityLevelTypeCheck(columns.length-nodelevel-2, rowNode,rowData))">{{rowNode.parent.parent.data.desc}}</span>
                                <span
                                    *ngIf="col.level === 'SC2' && (rowNode.parent.children[0].data === rowData)">{{rowNode.parent.data.desc}}</span>
                                <span *ngIf="col.level === 'SC3'">{{rowData['desc']}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-treeTable>
            </div>
            <div class="sdps-p-bottom_x-large goto-top" (click)="goToTopScenario()"><span class="security-goTo sdps-pull-right">{{getConstant.rulesConstant.ruleTypeLabels.goToTop}}</span></div>
        </div>
    </div>

    <!-- Footer Buttons -->
  <div slot="modal-footer">
    <div class="step-action-buttons sdps-pull-left">
      <button class="buttons" type="button" (click)="closeModal();sharedService.closeModal(this.modalId)"
              [innerHTML]="reviewSecurity? 'Close' : 'Cancel'">Cancel</button>
      <button *ngIf="!reviewSecurity" type="button" [disabled]="validateSelectedNode(selectedNodes)"
              class="buttons button-save save" (click)="saveSelectedTypes()">Save</button>
    </div>
  </div>
</sdps-modal>
