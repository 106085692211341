<sdps-notification type="error" class="sdps-m-top_none" *ngIf="showSchError">
  <div class="sdps-notification__text">
    <p [innerHTML]="getConstant.rulesConstant.pleaseCorrectFollowingError"></p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngFor='let msg of schErrorMsg' [innerHTML]="msg"></li>
    </ul>
  </div>
</sdps-notification>
<div class="add-new-rule-group">
  <h2 *ngIf="this.grpData.mode !== 'EDIT'" class="page-title sdps-m-bottom_large"
      [innerHTML]="sharedConstant[businessUnit].addNewEmpGrpOrPlan"></h2>
  <h2
    *ngIf="this.grpData.mode === 'EDIT' && (sharedService.groupType !== sharedConstant.manageMasterGroups.groupTypeMGP ? (!sharedService.deletedGrpOrPlan && sharedService.checkEntitlements(entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, '', '')) : (!sharedService.deletedMastGrp && sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageMasterAccountGroup.uuid, '', '')))"
    class="page-title sdps-m-bottom_large" [innerHTML]="sharedConstant[businessUnit].editEmpGrpOrPlan"></h2>
  <h2
    *ngIf="this.grpData.mode === 'EDIT' && (sharedService.groupType !== sharedConstant.manageMasterGroups.groupTypeMGP ? (sharedService.deletedGrpOrPlan || !sharedService.checkEntitlements(entitlementConst.navUUID.addLinkAppAccess.manageEmpGroup.uuid, '', '')) : (sharedService.deletedMastGrp || !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.manageMasterAccountGroup.uuid, '', '')))"
    class="page-title sdps-m-bottom_large" [innerHTML]="sharedConstant[businessUnit].viewEmpGrpOrPlan"></h2>
  <ems-step-wizard [stepObj]="items" *ngIf="items"></ems-step-wizard>
</div>
