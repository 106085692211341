export interface TableHeader {
    header?: string;
    field?: string;
}
export const ViweHistoryTableHeader: TableHeader[] = [
    { field: 'fieldName', header: 'Field Name' },
    { field: 'newValue', header: 'New Value' },
    { field: 'oldValue', header: 'Old Value' },
    { field: 'action', header: 'Action' },
    { field: 'date', header: 'Date & Time' },
    { field: 'changedBy', header: 'Changed By' }
  ];
