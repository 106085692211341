import {Component} from '@angular/core';

@Component({
  selector: 'ems-shared',
  template:'',
  styleUrls: []
})
export class SharedComponent {

  constructor() {
  }

}
