import {Injectable} from '@angular/core';
import {Workflow, Workflows} from '../../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  public workflow: Workflows = Workflow;

  constructor() {
  }

  validateStep(step: string, path: string, valid) {
    /*If the state is found, set the valid field to true*/
    let found = false;

    this.workflow[path].forEach((currentWorkflow) => {
      if (currentWorkflow.step === step) {
        found = currentWorkflow.valid = valid;
      }
    });
  }

  resetSteps(path) {
    /*Reset all the steps in the Workflow to be invalid*/
    this.workflow[path].forEach(element => {
      element.valid = false;
    });
  }

  getFirstInvalidStep(step: string, path: string): string {
    /*If all the previous steps are validated, return blank
    Otherwise, return the first invalid step*/
    const redirectToStep = '';

    /*Uncomment below code step click to work*/
    /*let found = false;
    let valid = true;

    *for (let i = 0; i < this.workflow[path] && this.workflow[path].length && !found && valid; i++) {
      const item = this.workflow[path][i];
      if (item.step === step) {
        found = true;
        redirectToStep = '';
      } else {
        valid = item.valid;
        redirectToStep = item.step;
      }
    }*/
    return redirectToStep;
  }

  getWorkflowSteps(stepName) {
    return this.workflow[stepName];
  }

  validateAllSteps(path: string) {
    this.workflow[path].forEach((currentWorkflow) => {
      currentWorkflow.valid = true;
    });
  }
}
