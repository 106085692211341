<div class="sdps-navigation--horizontal" role="application">
  <nav aria-label="Expanded Summary panel with five nested items"
       class="sdps-navigation--horizontal__levelFour subNavigation" id="unique-secondaryNav">
    <ul aria-label="Open an Summary steps" class="sdps-navigation--horizontal__nav" role="group">
        <li class="sdps-navigation--horizontal__item" role="group" routerLinkActive="sdps-is-active" *ngFor="let nav of reportsSubMenu">
          <a class="sdps-navigation--horizontal__link">
            <button class="buttons sub-navigation-button" [routerLink]="nav.route" [innerHTML]="nav.name"
                    (click)="sharedService.loadDataTrackValue(nav.route)"></button>
          </a>
        </li>
    </ul>
  </nav>

  <div class="content-area sdps-p-around_large">
    <router-outlet></router-outlet>
  </div>
</div>
