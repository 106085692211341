export interface RuleListTable {
    field?: string;
    header?: string;
    enum?: string;
}
export const RuleListTableHeader: RuleListTable[] = [
    { field: 'name', header: 'Rule Name', enum: 'textbox' },
    { field: 'status', header: 'Status', enum: 'multiselect' },
    { field: 'ruleType', header: 'Rule Type', enum: 'multiselect' },
    { field: 'subType', header: 'Sub-Type', enum: 'dropdown' },
    { field: 'startDt', header: 'Start Date', enum: 'datePicker' },
    { field: 'endDt', header: 'End Date', enum: 'datePicker' },
    { field: 'countOfAccountsAssigned', header: 'No. of Accounts', enum: '' },
    { field: 'updTs', header: 'Last Updated', enum: 'dateRange' },
    { field: 'actions', header: 'Action', enum: 'heading' }
];

export const ruleListSubHeaderDateColumns: any[] = [
    { field: 'tradeSymbol', header: 'Symbol', enum: 'textbox' },
    { field: 'cusipSymbol', header: 'CUSIP', enum: 'textbox' },
    { field: 'empGroupName', header: 'Group Name', enum: 'dropdown' },
    { field: 'instrumentGroup', header: 'Instrument Group', enum: 'dropdown' },
    { field: 'view', header: 'View', enum: 'dropdown' },
    { field: 'showRecords', header: 'Show Records', enum: 'dropdown' }
];

