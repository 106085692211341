import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {SharedConstant} from '../../constants/sharedConstants';
import {ConfirmationService} from 'primeng/api';
import {ConfirmMessage, DefaultConfirmMessage} from '../../models/confirm-message.model';
import {SharedService} from '../../shared.service';
import { ConfirmDialog } from 'primeng/confirmdialog';

@Component({
  selector: 'ems-confirm-message',
  templateUrl: './confirm-message.component.html'
})
export class ConfirmMessageComponent implements OnInit, AfterViewInit {
  @ViewChild('inputEl', {static: false}) inputEl: ElementRef;
  @ViewChild('cd') cd: ConfirmDialog;
  acceptButton: any = SharedConstant.confirmationDialog.acceptBtn;
  rejectButton: any = SharedConstant.confirmationDialog.rejectBtn;
  confirmMessage: ConfirmMessage = DefaultConfirmMessage;
  shouldHideAcceptButton: boolean;
  closeIconButton = true;
  cancelBtnRejectGutTack = '';

  constructor(public confirmationService: ConfirmationService, private sharedService: SharedService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    // this.inputEl.nativeElement.focus();
  }
  
  ngAfterViewInit(): void {
    this.inputEl.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }

  showConfirmMsg(confirmObj, observer) {
    this.shouldHideAcceptButton = confirmObj.acceptBtn !== SharedConstant.confirmationDialog.hideAcceptButton;
    this.acceptButton = confirmObj.acceptBtn || SharedConstant.confirmationDialog.acceptBtn;
    this.rejectButton = confirmObj.rejectBtn || SharedConstant.confirmationDialog.rejectBtn;
    this.closeIconButton = confirmObj.hasOwnProperty('closeIconButton') ? confirmObj.closeIconButton : true;
    /* Showing ConfirmationDialog Box while clicking Cancel/Away after edit */
    this.confirmationService.confirm({
      key: 'confirmBox',
      header: confirmObj.header,
      message: confirmObj.message,
      accept: () => {
        observer.next(true);
        observer.complete();
        confirmObj.message = '';
        this.sharedService.isLoadPage = false;
      },
      reject: () => {
        observer.next(false);
        observer.complete();
        confirmObj.message = '';
        if (this.sharedService.isLoadPage) {
          this.sharedService.isLoadPage = false;
          /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
          this.sharedService.isDTBtnClicked = true;
          this.sharedService.loadDataTrackValue(this.cancelBtnRejectGutTack);
          this.sharedService.isDTValueUpdated = false;
          this.sharedService.loadSpsPageCall();
        }
      }
    });
  }
}
