import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigUrlService, SharedService} from '@ems/shared';

@Injectable({
  providedIn: 'root'
})
export class UploadsService {

  configURL = this.configService.setApplicationUrl();
  private subject = new ReplaySubject<any>();
  showErrorMsg =  false;

  constructor(private httpService: HttpClient, @Inject('env') public env, private configService: ConfigUrlService, public sharedService: SharedService) { }

  getUploads(pageObj, formFilterObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, formFilterObj.orgId, schwabClientHeader);
    let endPoint;
    const uploadFilterInfo = formFilterObj;
    const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
    if (formFilterObj.orgId && formFilterObj.orgId !== 0) {
      endPoint = this.configURL.bffBatchUploadListEndpointV1 + '&pageNumber=' + (pageObj.pageNo) + '&pageSize=' + pageObj.pageSize + sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      endPoint = this.configURL.bffBatchUploadListEndpointV1 + '&pageNumber=' + (pageObj.pageNo) + '&pageSize=' + pageObj.pageSize + sortField;
    }
    return this.httpService.post(endPoint, uploadFilterInfo);
  }

  getUploadTypes(orgId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const url = this.configURL.bffBatchUploadTypeEndpointV1;
    if (orgId && orgId !== 0) {
      return this.httpService.get(url + '&Schwab-Client-Ids= ' + schwabClientHeader);
    } else {
      return this.httpService.get(url);
    }
  }

  public pollUploadsDetailsByUploadId(uploadID): Observable<any>  {
    let schwabClientHeader = [];
    const uploadIds = [];
    uploadIds.push({
      id: uploadID
    });
    const body = {
      uploads: uploadIds
    };
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.sharedService.getOrgId, schwabClientHeader);
    let url;
    if (this.sharedService.getOrgId && this.sharedService.getOrgId !== 0) {
      url = this.configURL.bffBatchUploadStatusEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    } else {
      url = this.configURL.bffBatchUploadStatusEndpointV1;
    }
    return this.httpService.post(url, body);
  }

  public exportUploadErrors(exportUpload, uploadID): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.sharedService.getOrgId, schwabClientHeader);
    let url;
    if (this.sharedService.getOrgId && this.sharedService.getOrgId !== 0) {
      url = this.configURL.bffBatchUploadEndpointV1.replace('PassThrough?', `PassThrough/${uploadID}/results?`) + '&Schwab-Client-Ids= ' + schwabClientHeader;
      return this.httpService.post(url, '');
    } else {
      url = this.configURL.bffBatchUploadEndpointV1.replace('PassThrough?', `PassThrough/${uploadID}/results?`);
    }
    return this.httpService.post(url, '');
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }


}
