import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../../services/common.service';
import { AddlinkConstant } from '../../../../constants/addlinkConstants';
import {SharedService} from '@ems/shared';

@Component({
  selector: 'addlink-client-central-notes',
  templateUrl: './client-central-notes.component.html',
  styleUrls: ['./client-central-notes.component.scss']
})
export class ClientCentralNotesComponent implements OnInit {

  addLinkConstant = AddlinkConstant;
  public requestId: string;
  public accountNumber: string;
  public displayMessage: any;
  public notesArr: any;
  constructor(private commonService: CommonService, public sharedService: SharedService) {
  }

  ngOnInit() {
  }

  /* Opens The Modal */
  public openModal(e: Event, requestId, accountNumber): void {
    e.stopPropagation();
    this.requestId = requestId;
    this.accountNumber = accountNumber;
    this.sharedService.openModal('beta-client-central-modal');
    this.getNotes();
  }

  public getNotes() {
    this.commonService.getClientCentralNotes(this.accountNumber).subscribe(result => {
      this.notesArr = result.data ? result.data.notesList : [];
      if (this.notesArr === null || this.notesArr.length === 0) {
        this.displayMessage = this.addLinkConstant.clientCentralNotes.noClientMsg;
      } else {
        this.displayMessage = '';
      }
    }, error => {
      this.displayMessage = this.addLinkConstant.clientCentralNotes.errorMsg;
    });
  }
}
