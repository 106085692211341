<div class="sdps-p-horizontal_medium">
  <sdps-notification type="information" class="sdps-m-top_none" *ngIf="showClientMsg">
    <p class="sdps-notification__text" [innerHTML]="getShellConstant.noCientSelected"></p>
  </sdps-notification>
</div>
<div class="sdps-p-horizontal_medium" *ngIf="!showClientMsg">
  <form [formGroup]="reportDetails">
    <div class="sdps-grid-container sdps-wrap report-dropdown">
      <div class="sdps-row sdps-p-top_x-large ng-star-inserted">
        <div class="display-flex">
          <app-report-from-to-datepicker (calendarValidation)="calendarValidation($event)" [dateRange]="60" [initialDateRange]="60" (getEmittedVal)="setDate($event)"
                                         [calendarForm]="reportDetails" (throwDateError)="throwDateError($event)" (throwDateRangeError)="throwDateRangeError($event)"></app-report-from-to-datepicker>
        </div>
      </div>

      <div class="sdps-row sdps-p-top_large sdps-p-bottom_x-small select-report-type">

        <!-- Account Number section -->
        <div class="col-3 sdps-p-left_none">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('employeeAccount').value && reportDetails.get('employeeAccount').value.length < 8)}">
            <span class="p-float-label">
            <input type="text" name="accountNumber" [maxlength]="8" onkeypress="if(event.which<48 || event.which>57) return false;" formControlName="employeeAccount"
              pInputText/>
              <label>{{getShellConstant.reports.presetReports.accountNumber}}</label>
            </span>
          </div>
        </div>

        <!-- Trade Type section -->
        <div class="col-3 sdps-p-left_none employee-dropdown employee-association-dropdown">
          <span class="p-float-label">
          <p-dropdown #dropdown [options]="tradeType" formControlName="selectedTradeType"></p-dropdown>
             <label [ngClass]="{'emp-label': dropdown.value !== null}" [innerHTML]="getShellConstant.reports.OpenOrderDetails.tradeType"></label>
              </span>
        </div>

        <!-- Symbol section -->
        <div class="col-3 sdps-p-left_none">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('tradeSymbol').value && reportDetails.get('tradeSymbol').invalid), 'disabled-grey': reportDetails.get('tradeSymbol').disabled}">
            <span class="p-float-label">
            <input type="text" name="tradeSymbol" [maxlength]="100" formControlName="tradeSymbol" emsAlphaNumericSpecialCharacterValidation
              pInputText/>
              <label>{{getShellConstant.reports.RulesDetails.symbol}}</label>
            </span>
          </div>
        </div>

        <!-- CUSIP section -->
        <div class="col-3 sdps-p-left_none">
          <div class="report-textbox-border sdps-p-around_medium" [ngClass]="{'invalidField': (reportDetails.get('cusipSymbol').value && (reportDetails.get('cusipSymbol').invalid || reportDetails.get('cusipSymbol').value.length < 9)),'disabled-grey': reportDetails.get('cusipSymbol').disabled}">
            <span class="p-float-label">
            <input type="text" name="cusipSymbol" [maxlength]="9" emsAlphaNumericSpecialCharacterValidation formControlName="cusipSymbol"
              pInputText/>
              <label>{{getShellConstant.reports.RulesDetails.cusip}}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ems-step-wizard-buttons [showCancelBtn]="true" (closeBtn)="cancelReport()" [showRunReportBtn]="true" (runReport)="runReports()"
                           [disableBtn]="reportDetails.invalid || endDateLessThanStartDate || dateError || (reportDetails.get('employeeAccount').value && reportDetails.get('employeeAccount').value.length < 8) || (reportDetails.get('cusipSymbol').value && reportDetails.get('cusipSymbol').value.length < 9)"></ems-step-wizard-buttons>
</div>
