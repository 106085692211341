import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate} from '@angular/router';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CanDeactivateGuard implements CanDeactivate<any> {
  canDeactivate(
    component: any,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    const subject = new Subject<boolean>();
    if (component && component.canDeactivate) {
      if (component.canDeactivate(event, subject) === true) {
        return true;
      }
      return subject.asObservable();
    }
    return true;
  }
}
