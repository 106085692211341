import {Directive, ElementRef} from '@angular/core';
import {NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[emsUploadsFileNamePatterncheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: UploadsFileNamePatterncheckDirective, multi: true}
  ]
})
export class UploadsFileNamePatterncheckDirective {
  newExp: any;

  constructor(public el: ElementRef) {
  }

  validate() {
    this.newExp = new RegExp('^[a-zA-Z0-9_\']*$');
    const elementVal = this.el.nativeElement.value;
    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
        fileNamePatternCheck: false
      };
    }
  }
}
