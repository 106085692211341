<ems-confirm-message #confirmationMessage></ems-confirm-message>
<ems-toast-message #toastMessage></ems-toast-message>

<sdps-notification type="error" class="sdps-m-top_none" *ngIf="(delinkRequestForm.get('accountNumber').value && delinkRequestForm.get('accountNumber').invalid)
                      || (delinkRequestForm.get('accountNumber').value && isAccNumInvalid)">
  <div class="sdps-notification__text">
    <p>Please correct following error.</p>
    <ul class="sdps-navigation--horizontal__list error-list">
      <li *ngIf="delinkRequestForm.get('accountNumber').value && isAccNumInvalid"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.accountNumberShouldContain8Digits"></li>
      <li *ngIf="(delinkRequestForm.get('accountNumber').value && delinkRequestForm.get('accountNumber').invalid)"
          [innerHTML]="addLinkConstant.textFieldErrorMsg.accountNumbershouldBeNumeric"></li>
    </ul>
  </div>
</sdps-notification>

<sdps-notification type="information" class="sdps-m-top_none" *ngIf="!orgId || showInfoMsg">
  <p class="sdps-notification__text" *ngIf="!orgId" [innerHTML]="addLinkConstant.noCientSelected"></p>
  <p class="sdps-notification__text" *ngIf="showInfoMsg" [innerHTML]="addLinkConstant.addLkGlobal.noMasterAccountToDelink"></p>
</sdps-notification>

<!--<sdps-notification type="information" class="sdps-m-top_none" *ngIf="showInfoMsg">-->
<!--  <p class="sdps-notification__text" [innerHTML]="addLinkConstant.addLkGlobal.noMasterAccountToDelink"></p>-->
<!--</sdps-notification>-->

<form [formGroup]="delinkRequestForm" *ngIf="orgId">

    <div>
        <h2 class="page-title sdps-m-bottom_large sdps-display-inline-block">Delink</h2>

        <span class="sdps-m-horizontal_large client-selection-indicator"
              [ngClass]="this.externalAccess ? 'external-access' : (addlinkService.clientInformation | async)?.businessUnit | lowercase"
              [innerHTML]="(addlinkService.clientInformation | async)?.k4ClientName">
        </span>

        <div class="sdps-display-inline-block sdps-m-horizontal_large" *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.accountLinking.uuid,'','')">
            <button type="button" class="buttons" (click)="bulkSubmit()">{{addLinkConstant.addLkGlobal.bulkUploadAddlink}}</button>
        </div>
    </div>

    <div class="sdps-mosaic-tile sdps-m-bottom_large ">

        <div class="sdps-mosaic-tile__content ">

            <div class="sdps-grid-fluid sdps-wrap">

                <div class="sdps-row">

                  <div class="col-2">
                    <div class="ems-input delink-input-textbox"
                         [ngClass]="{'invalidField': (delinkRequestForm.get('accountNumber').value && delinkRequestForm.get('accountNumber').invalid) || (delinkRequestForm.get('accountNumber').value && isAccNumInvalid)}">
                      <span class="p-float-label">
                        <input formControlName="accountNumber" (keyup)="populateMasterAccounts($event)"
                               (focusout)="validateAccountNumber($event)" [maxlength]="8" emsNumberPatterncheck pInputText/>
                        <label>Account Number<em class="highlight-color">*</em></label>
                      </span>
                    </div>
                  </div>
                  <div class="col-3" *ngIf="!isAuthAgentRole">

                        <ems-multiselect [highlightColor]="true" [singleSelect]="true" [label]="'Authorized Agent'" [searchable]="true"
                            [options]="authorizedAgents" formControlName="authAgent">
                        </ems-multiselect>
                        <div class="ems-add-auth" *ngIf="isAAExceptBU && sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','')">
                            <span (click)="displayAuthAgentPopup($event)">+Add Authorized Agent</span>
                        </div>
                  </div>

                    <div class="col-2">
                        <div class="ems-data-field">
                            <label>Employee Name</label>
                            <div>{{employeeName}}</div>
                        </div>
                    </div>


                </div>

                <div class="sdps-row sdps-m-top_small">

                    <div class="col-8 remark-container">
                        <div class="ems-input link-input-textbox">
                            <span class="p-float-label">
                                <input type="text" formControlName="remarks" maxlength="1000" emsCharacterLimit (emsCharacterLimitCheck)="showRemarksTxtboxCharLimit = $event"
                                          [charMaxLimit]="commonConstants.charLimitValues.thousandMaxLimit" pInputText>
                                <label>Remarks<em class="highlight-color" *ngIf="delinkRequestForm.get('doNotShowRemarkToClient').value === true">*</em></label>
                            </span>
                        </div>
                        <p class="sdps-text-right sdps-m-around_none sdps-text-small line-height-nml"
                        *ngIf="delinkRequestForm.get('remarks').value.length > showRemarksTxtboxCharLimit">
                        {{addLinkConstant.characterLimit}}
                        <span class="character-limit">{{delinkRequestForm.get('remarks').value.length}}
                            /{{commonConstants.charLimitValues.thousandMaxLimit}}</span>
                    </p>
                    <div class="display-flex">
                        <div class="sdps-display-inline-block sdps-m-right_xx-large ems-checkbox"
                            *ngIf="!isAuthAgentRole || sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid,'','') && usertype === addLinkConstant.addLkGlobal.internal">
                            <p-checkbox formControlName="doNotShowRemarkToClient" label="Do not show this remark to Client"
                                binary="true"></p-checkbox>
                        </div>
                    </div>
                    </div>

                    <div class="col-2 sdps-hide">
                        <span>
                            <button type="button" class="ems-button ems-attach-files"
                                [disabled]="delinkRequestForm.disabled">
                                <em class="pi pi-cloud-upload ems-attach-files_icon"></em>
                                <span class="sdps-m-right_small ems-attach-files_text">Attach Files</span>
                            </button>
                        </span>
                    </div>

                </div>

                <div class="sdps-row sdps-m-top_small">

                    <div class="col">
                        <span class="sdps-pull-right">
                            <button type="button" class="buttons"
                                (click)="sharedService.gutTracking('adobe-lc_reset|fireonce'); resetDelinkRequestForm('reset')">{{addLinkConstant.delinkMasterAccModal.resetTxt}}</button>
                            <button type="button" class="buttons button-save"
                                    [disabled]='!validForm() || showInfoMsg || delinkRequestForm.controls.accountNumber.value.toString().length!==8 || showDelinkBtn.length === 0'
                                    (click)="delinkRequest()">{{addLinkConstant.delinkMasterAccModal.submitTxt}}</button>
                        </span>
                    </div>

                </div>

            </div>


        </div>

    </div>

</form>

<div class="sdps-mosaic-tile sdps-m-bottom_larg addlink-action-panel" *ngIf="requests.length > 0">

    <div class="sdps-mosaic-tile__content sdps-hide">
        <span class="ems-icon sch-fax-2x sdps-m-right_small"></span>
        <span class="ems-icon sch-file-pdf-2x sdps-m-right_small"></span>
        <span class="ems-icon sch-file-excel-2x"></span>
    </div>

</div>

<p-table [columns]="requestColumns" [value]="requests" [responsive]="true" *ngIf="requests.length > 0" class="common-data-table">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
                {{col.header}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td class="addlink-hide-overflow">
                <span class="p-column-title">{{columns[0].header}}</span>
                {{rowData[columns[0].field]}}
            </td>
            <td class="addlink-hide-overflow" title="{{rowData[columns[1].field]}}">
                <span class="p-column-title">{{columns[1].header}}</span>
                <span *ngIf="rowData[columns[1].field] === 'Complete'"
                    class="ems-status sdps-p-around-xx-large ems-status-active"></span>
                <span *ngIf="rowData[columns[1].field] === 'On Hold' || rowData[columns[1].field] === 'Validating' || rowData[columns[1].field] === 'Received'"
                    class="ems-status sdps-p-around-xx-large ems-status-draft"></span>
                <span *ngIf="rowData[columns[1].field] === 'DBS Action Needed'"
                    class="ems-status sdps-p-around-xx-large ems-status-expired"></span>
                {{rowData[columns[1].field]}}
            </td>
            <td class="addlink-hide-overflow" title="{{rowData[columns[2].field]}}">
                <span class="p-column-title">{{columns[2].header}}</span>
                {{rowData[columns[2].field]}}
            </td>
            <td class="addlink-hide-overflow" title="{{rowData[columns[3].field]}}">
                <span class="p-column-title">{{columns[3].header}}</span>
                {{rowData[columns[3].field]}}
            </td>
            <td class="addlink-hide-overflow" title="{{rowData[columns[4].field]}}">
                <span class="p-column-title">{{columns[4].header}}</span>
                {{rowData[columns[4].field]}}
            </td>
            <td class="addlink-hide-overflow" title="{{rowData[columns[5].field]}}">
                <span class="p-column-title">{{columns[5].header}}</span>
                {{rowData[columns[5].field]}}
            </td>
            <td class="addlink-hide-overflow" title="{{rowData[columns[6].field]}}">
                <span class="p-column-title word-break">{{columns[6].header}}</span>
                {{rowData[columns[6].field]}}
            </td>
            <td class="addlink-hide-overflow">
                <span class="p-column-title">{{columns[7].header}}</span>
                <span class="ems-icon sch-chat-2x sdps-m-right_small icon-border cursor" id="rowData[columns[0].field]"
                      *ngIf="rowData['requestId']"
                    (click)="openRemarkPopup($event, rowData[columns[0].field], rowData[columns[2].field], rowData[columns[1].field])"></span>
            </td>
          <td class="addlink-hide-overflow">
            <span *ngIf="columns[8].header === 'Action' && (rowData['requestId'] && rowData[columns[1].field] === 'On Hold')">
              <button class="ems-icon sch-revert sdps-m-right_xx-large icon-border cursor" title="Resubmit"
                      (click)="reSubmitRequests(rowData)" [disabled]="rowData['disableIcon']"></button>
            </span>
          </td>
        </tr>
    </ng-template>
</p-table>

<div class="sdps-m-bottom_large"></div>

<addlink-delink-master-account-modal #masterAcc [masterAccountsList]="masterAccountOptions" (getMasterAccVal)="processMasterAcc($event)"></addlink-delink-master-account-modal>

<addlink-add-authorized-agent-modal #addAuthAgent (getAuthagents)="getAuthorizedAgents()">
</addlink-add-authorized-agent-modal>

<addlink-remarks-modal #remarks></addlink-remarks-modal>
