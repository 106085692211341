<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading-font sdps-p-bottom_small sdps-display-inline"
     [innerHTML]="getConstant.manageEmployeeGroups.empGroupStep3.reviewAndSave"></div>

<!-- Name, Description and BU section -->
<ems-accordion [label]="getSharedConstant.DBS.nameAndDescription" [editable]="showActionItems"
               [showEditIcon]="showActionItems"
               class="disabled-button-icon" [disabledButton]="true" [toolTip]="true" [message]="toolTipMessage">
  <div class="sdps-p-around_large select-rule-type">
    <form [formGroup]="reviewInstrumentGrpForm">
      <div class="sdps-grid-container sdps-wrap">
        <div class="sdps-row">
          <div class="rule-type-textbox sdps-p-around_medium col-3" [ngClass]="{'input-disabled': !showActionItems, 'invalidField': ((reviewInstrumentGrpForm.get('groupName').value && (reviewInstrumentGrpForm.get('groupName').invalid)) || groupNameExists)}">
            <span class="p-float-label">
              <input type="text" name="groupName" [maxlength]="100" formControlName="groupName"
                     #focusName
                     (ngModelChange)="editGroupNameAndDescription()" emsAlphaNumericSpecialCharacterValidation emsCharacterLimit
                     [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                     (emsCharacterLimitCheck)="showGroupNameCharLimit = $event" pInputText/>
              <label>{{getSharedConstant.DBS.name}}
                <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
              </label>
            </span>
          </div>
          <div class="col-6 sdps-p-left_xx-large sdps-flex">
            <p class="sdps-p-top_medium sdps-p-right_x-small">
              <span [innerHTML]="getConstant.instrumentGroups.instrumentGrpStep1.businessUnit"></span>
              <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
            </p>
            <div class="sdps-p-top_x-small timePickerForm col-4">
              <p-dropdown [options]="businessUnitData" placeholder=" " [disabled]="businessUnitData.length === 1"
                          class="timePickerForm editableTimepicker business-unit-dropdown step3-bu" optionLabel="name"
                          formControlName="businessUnit">
              </p-dropdown>
            </div>
          </div>
        </div>
        <p class="col-3 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
           *ngIf="reviewInstrumentGrpForm.get('groupName').value && reviewInstrumentGrpForm.get('groupName').value.trim().length > 90">{{getConstant.rulesConstant.characterLimit}}
          <span class="character-limit">{{reviewInstrumentGrpForm.get('groupName').value.trim().length}}
            /{{commonConstants.charLimitValues.hundredMaxLimit}}</span>
        </p>

        <div class="sdps-row sdps-p-top_large">
          <div class="rule-type-textbox sdps-p-around_medium col-6" [ngClass]="{'input-disabled': !showActionItems}">
          <span class="p-float-label">
            <textarea class="sdps-p-vertical_x-small group-desc" rows="5" maxlength="300" formControlName="description"
            (ngModelChange)="editGroupNameAndDescription()" name="description" emsCharacterLimit (emsCharacterLimitCheck)="showGroupDescCharLimit = $event"
                      [charMaxLimit]="commonConstants.charLimitValues.threeHundredMaxLimit" pInputTextarea></textarea>
            <label>{{getSharedConstant.DBS.description}}</label>
          </span>
          </div>
        </div>
        <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
           *ngIf="reviewInstrumentGrpForm.get('description').value && reviewInstrumentGrpForm.get('description').value.length > 270">{{getConstant.rulesConstant.characterLimit}}
          <span class="character-limit">{{reviewInstrumentGrpForm.get('description').value.length}}
            /{{commonConstants.charLimitValues.threeHundredMaxLimit}}</span>
        </p>
      </div>
    </form>
  </div>
</ems-accordion>

<!-- Added or Removed security section -->
<ems-accordion *ngIf="addOrRemovedSecurities && addOrRemovedSecurities.length > 0" [editable]="showActionItems"
               [label]="getConstant.instrumentGroups.instrumentGrpStep2.addedOrRemovedInstruments"
               (editPanel)="goToPreviousStep('../step2')" [showEditIcon]="showActionItems">
  <ems-table-record-count [showRecords]="addOrRemovedSecurities && addOrRemovedSecurities.length && addOrRemovedSecurities.length > 15" [count]="addOrRemovedSecurities && addOrRemovedSecurities.length" [showHeaderIcons]="false"
                          (emitter)="indTable.reset();receiveDataFromChild($event)"></ems-table-record-count>
  <p-table [columns]="securityTableHeader" [value]="addOrRemovedSecurities" [paginator]="(addOrRemovedSecurities && addOrRemovedSecurities.length && addOrRemovedSecurities.length > 15) ? true : false" [rows]="noOfRecords" [totalRecords]="addOrRemovedSecurities && addOrRemovedSecurities.length"
           #indTable
           class="common-data-table">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-indSec let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <span *ngIf="col.field === 'name'"
                [ngClass]="indSec['selectedSec'] === true ? 'addedEmp' : 'removedEmp'">{{indSec[col.field] | titlecase}}</span>
          <span *ngIf="col.field !== 'name' && col.field !== 'tickerSymbol'"
                [ngClass]="indSec['selectedSec'] === true ? 'addedEmp' : 'removedEmp'">{{indSec[col.field]}}</span>
          <span *ngIf="col.field === 'tickerSymbol'"
                [ngClass]="indSec['selectedSec'] === true ? 'addedEmp' : 'removedEmp'">{{indSec[col.field]}}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ems-accordion>

<!-- Selected Securities - Edit flow when no changes is made to securities selection -->
<ems-accordion [label]="getConstant.instrumentGroups.instrumentGrpStep2.selectInstruments" [editable]="showActionItems"
               *ngIf="reviewInstGroupData.mode === 'EDIT' && addOrRemovedSecurities && addOrRemovedSecurities.length === 0"
               (editPanel)="goToPreviousStep('../step2')" [showEditIcon]="showActionItems">
  <ems-table-record-count [count]="selectedSecurities && selectedSecurities.length" [showRecords]="selectedSecurities && selectedSecurities.length && selectedSecurities.length > 15" [showHeaderIcons]="false"
                          (emitter)="indTable.reset();receiveDataFromChild($event)"></ems-table-record-count>
  <p-table [columns]="securityTableHeader" [value]="selectedSecurities" [paginator]="(selectedSecurities && selectedSecurities.length && selectedSecurities.length > 15) ? true : false" [rows]="noOfRecords" [totalRecords]="selectedSecurities && selectedSecurities.length"
           #indTable
           class="common-data-table">
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{col.header}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-indSec let-columns="columns">
      <tr>
        <td *ngFor="let col of columns">
          <span *ngIf="col.field === 'name'">{{indSec[col.field] | titlecase}}</span>
          <span *ngIf="col.field !== 'name'">{{indSec[col.field]}}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ems-accordion>

<ems-step-wizard-buttons [showPreviousBtn]="showActionItems" (goToPrevious)="goToPreviousStep('../step2')"
                         [showCancelBtn]="showActionItems" (closeBtn)="cancelInstGroup()"
                         [showRevertBtn]="showActionItems"
                         [revertDisable]="revertDisable" (revert)="revertChanges()"
                         [showCloseBtn]="!showActionItems"
                         [disableBtn]="reviewInstrumentGrpForm?(reviewInstrumentGrpForm.invalid || groupNameExists || (reviewInstrumentGrpForm.get('groupName').value && reviewInstrumentGrpForm.get('groupName').value.trim().length === 0)):''"
                         [showSaveBtn]="showActionItems" (save)="saveGroup()"></ems-step-wizard-buttons>
