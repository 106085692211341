export const entitlementConst = {
  navUUID: {
    addLinkAccess: {
      entitlementName: 'ADDLINK_APP',
      uuid: '990bf1ef-b9d0-4601-b41f-2cfbdb755aa5'
    },
    accountLinking: {
      entitlementName: 'ACCOUNT_LINKING',
      uuid: '3142c1dd-dc63-4fdd-a614-162717c34ae8'
    },
    accountSummary: {
      entitlementName: 'ACCOUNT_SUMMARY',
      uuid: 'd3388f20-8124-4f38-8580-2961038c1c87'
    },
    addlnkAdminResource: {
      entitlementName: 'ADDLINK_ADMIN_RESOURCES',
      uuid: 'ac7e16a4-43a3-469f-bd91-e0d74f6841f3'
    },
    manageUser: {
      entitlementName: 'MANAGE_USERS',
      uuid: '09d3972a-1fb5-4620-9227-46be5140a914'
    },
    manageRole: {
      entitlementName: 'MANAGE_ROLES',
      uuid: '6ac08acf-3bc5-421c-9c68-9ed775f049a0'
    },
    clientList: {
      entitlementName: 'CLIENT_LIST',
      uuid: '754c24ba-9ed2-45d2-a460-fa1d010a5850'
    },
    manageClient: {
      entitlementName: 'MANAGE_CLIENTS',
      uuid: 'c1b9b614-2d6c-48b6-82f0-1815ab4d205d'
    },
    emtrAccess: {
      emtrAppAccess: {
        entitlementName: 'EMTR_APP',
        uuid: '2f353889-a190-4e72-b045-47e613b9e64c'
      },
      ruleSummary: {
        entitlementName: 'RULES_SUMMARY',
        uuid: 'ad0762ba-8fc7-4e4a-9537-835b39938e5b'
      },
      manageRule: {
        entitlementName: 'MANAGE_RULES',
        uuid: 'd2604926-f652-4841-b691-a087bede2a34'
      },
      instrumentsSummary: {
        entitlementName: 'INSTRUMENTS_SUMMARY',
        uuid: '05d89104-6ca5-11ec-90d6-0242ac120003'
      },
      manageInstruments: {
        entitlementName: 'MANAGE_INSTRUMENTS',
        uuid: 'f857952a-6ca4-11ec-90d6-0242ac120003'
      },
      hideSecurityTypeList: {
        entitlementName: 'HIDE_SECURITY_TYPE_LIST',
        uuid: '86620440-80fa-4eba-9806-22254f4a6903'
      },
      presetRuleSummary: {
        entitlementName: 'PRESET_RULE_SUMMARY',
        uuid: 'c16d3dde-8e6f-11ec-b909-0242ac120002'
      },
      globalRuleSummary: {
        entitlementName : 'GLOBALRULE_SUMMARY',
        uuid : '38f510b1-82ed-4ef0-887d-4862744fc22f'
      },
      manageGlobalRule: {
        entitlementName : 'MANAGE_GLOBALRULE',
        uuid : '0ad9bf06-39b2-4fd3-9ce6-7ee772ffe301'
      },
      masterAccountGroupSummary:  {
        entitlementName : 'MASTERGROUP_SUMMARY',
        uuid : '2b50226f-e596-40b0-8d86-b8d6c55db468'
      },
      manageMasterAccountGroup: {
        entitlementName : 'MANAGE_MASTER_GROUP',
        uuid : '8c1a9171-1e03-4539-bc0d-efacabbe9693'
      }
    },
    addLinkAppAccess : {
      manageEmployee: {
        entitlementName: 'MANAGE_EMPLOYEES',
        uuid: 'b5d58a6a-6476-4130-97cd-0ed1cfa77c1d'
      },
      employeeSummary: {
        entitlementName: 'EMPLOYEE_SUMMARY',
        uuid: '18773818-8284-4111-8397-845da1e7251a'
      },
      manageEmpGroup: {
        entitlementName: 'MANAGE_EMP_GROUP',
        uuid: '87f0d90d-706d-45b3-8eb7-6755a3dd24a3'
      }
    }
  }
};
