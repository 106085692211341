import {Injectable, EventEmitter} from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient} from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { CookiesStorageService } from 'ngx-store';
import { SharedService } from '@ems/shared';
@Injectable()
export class LocalStorageService {
  constructor(private http: HttpClient, private authService: AuthService, private sharedService: SharedService, private cookiesStorage: CookiesStorageService) {
  }

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token) {
    localStorage.setItem('token', token);
  }

  isTokenPresent() {
    if (!localStorage.getItem('token')) {
      localStorage.clear();
    }
  }

  setProfile(clientProfile) {
    localStorage.setItem('client', clientProfile);
    const clientProfileParse = JSON.parse(clientProfile);
    if (clientProfileParse.clientName === 'All') {
      clientProfileParse.clientId = 0;
      clientProfileParse.businessUnit = 'All';
    }
    localStorage.setItem('UUID', `${this.sharedService.getUserId()}|${this.authService.getUsertype()}`);
    localStorage.setItem('db_clientSelected', `${clientProfileParse.clientId}|${clientProfileParse.businessUnit}`);
    this.cookiesStorage.set('UUID', `${this.sharedService.getUserId()}|${this.authService.getUsertype()}`);
    this.cookiesStorage.set('db_clientSelected', `${clientProfileParse.clientId}|${clientProfileParse.businessUnit}`);
    this.cookiesStorage.set('Adobe UUID', `${this.sharedService.getHashedUserId()}`);
    this.cookiesStorage.set('Adobe User Type', `${this.authService.getUsertype()}`);
  }

  getProfile() {
    return localStorage.getItem('client');
  }

  setExpiryDateTime(time) {
    localStorage.setItem('tknExpireTime', time);
  }

  getExpiryDateTime() {
    return localStorage.getItem('tknExpireTime');
  }

  clearLocalStorage() {
    localStorage.clear();
    this.cookiesStorage.clear();
  }

}
