import { Directive, Output, EventEmitter, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ems-drag-drop]'
})
export class DragDropDirective {
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}
  @Output() Select = new EventEmitter<any>();
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.addClass(this.elementRef.nativeElement, 'drag-drop-border');
  }
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.elementRef.nativeElement, 'drag-drop-border');
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.Select.emit(files);
    }

  }

}
