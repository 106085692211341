export interface InstrumentGroupAssignInfo {
  orgId?: string;
  businessUnit?: any[];

  clear();
}

export const instrmntGrpAssignInfo: InstrumentGroupAssignInfo = {
  orgId: '',
  businessUnit: [],
  clear() {
    this.instrmntIds = [];
    this.id = '';
    this.orgId = '';
    this.businessUnit = [];
  }
};

export interface InstrumentGroupFormDataModel {
  name?: string;
  orgId?: string;
  description?: string;
  businessUnit?: any;
  securityInfoList?: any[];
  previousSecurityInfoList?: any[];
  currentEditedName?: string;
  instrumentGrpAssignInfo?: InstrumentGroupAssignInfo;
  mode?: string;
  id: '';

  clear();

  setData(data);
}

export const instrmntGroupFormDataModel: InstrumentGroupFormDataModel = {
  name: '',
  orgId: '',
  id: '',
  description: '',
  businessUnit: '',
  securityInfoList: [],
  previousSecurityInfoList: [],
  mode: '',
  instrumentGrpAssignInfo: instrmntGrpAssignInfo,

  clear() {
    this.id = '';
    this.name = '';
    this.currentEditedName = '';
    this.description = '';
    this.orgId = '';
    this.businessUnit = [];
    this.securityInfoList = [];
    this.previousSecurityInfoList = [];
    this.mode = '';
    this.instrumentGrpAssignInfo = instrmntGrpAssignInfo;
    this.instrumentGrpAssignInfo.clear();
  },

  setData(data) {
    this.id = data.id || '';
    this.name = data.name || '';
    this.currentEditedName = data.name || '';
    this.description = data.description || '';
    this.employeeGroupAssignInfo = data.employeeGroupAssignInfo || {};
    this.orgId = data.orgId || '';
    this.businessUnit = {name: data.businessUnit, value: data.businessUnit} || '';
    this.securityInfoList = data.securityInfoList || [];
    this.previousSecurityInfoList = data.securityInfoList || [];
  }
};

export class InstrumentGrpStep1 {
  name = '';
  groupDesc = '';
  businessUnit = '';
}

export class InstrumentGrpStep2 {
  securityInfoList: any[] = [];
}
