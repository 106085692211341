import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ConfigUrlService, SharedService} from '@ems/shared';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstrumentGroupService {
  configURL = this.config.setApplicationUrl();
  deletedIG = false;
  orgId: any;
  businessUnit: any;

  constructor(private httpService: HttpClient, @Inject('env') public env, public config: ConfigUrlService, public sharedService: SharedService) {
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      if (clientInfo) {
        this.orgId = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo.businessUnit;
      }
    });
  }

  getInstrumentGroupList(pageObj, filterObj, pagename?: string): Observable<any> {
    if (pagename === this.sharedService.pageName) {
      const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
      const url = this.configURL.bffSecurityGroupListEndPointV1 + '&page=' + (pageObj.pageNo + 1) + '&size=' + pageObj.pageSize + sortField;
      return this.httpService.post(url, filterObj);
    } else if (filterObj.searchType !== 'AUTOCOMPLETE') {
      this.sharedService.stateValues.filterObj = filterObj;
      this.sharedService.stateValues.pageObj.pageNo = pageObj.pageNo;
      this.sharedService.stateValues.pageObj.pageSize = pageObj.pageSize;
      this.sharedService.setStateVal(JSON.stringify(this.sharedService.stateValues));
      this.sharedService.tableDropdownVal.next(pageObj.pageSize);
      const sortField = (pageObj.sortField) ? pageObj.sortField.map(data => '&sortField=' + data).join('') : '';
      const url = this.configURL.bffSecurityGroupListEndPointV1 + '&page=' + (pageObj.pageNo + 1) + '&size=' + pageObj.pageSize + sortField;
      return this.httpService.post(url, filterObj);
    } else {
      let schwabClientHeader = [];
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
      pageObj.businessUnit = this.businessUnit;
      pageObj.orgId = this.orgId;
      /* eslint-disable-next-line max-len */
      const url = this.configURL.bffSecurityGroupListEndPointV1 + '&searchType=' + filterObj.searchType + '&searchValue=' + filterObj.searchValue + '&securityType=' + filterObj.securityType + '&page=' + filterObj.page + '&size=' + filterObj.size + '&sortField=' + filterObj.sortField + '&Schwab-Client-Ids= ' + schwabClientHeader;
      return this.httpService.post(url, pageObj);
    }
  }

  deleteInstrumentGrp(id: number , forceDel: boolean) {
    return this.httpService.delete(this.configURL.bffSecurityGroupDeleteEndPointV1 + '&id=' + id + '&forceDelete=' + forceDel);
  }

  getSecurityGroupbyID(Id, businessUnit, getOrgId?: any): Observable<any> {
    let schwabClientHeader = [];
    if (getOrgId) {
      schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, getOrgId, schwabClientHeader);
    }
    return this.httpService.get(this.configURL.bffSecurityGroupSaveEndPointV1 + '&id=' + Id + '&bu=' + businessUnit + '&Schwab-Client-Ids= '  + schwabClientHeader);
  }

  saveInstrumentGroup(inputData): Observable<any> {
    /* if inputData.active is set to true then we are making an restore IG call */
    if (inputData.id && inputData.active) {
      return this.httpService.put(this.configURL.bffSecurityGroupSaveEndPointV1 + '&securityGroupId=' + inputData.id + '&active=' + true, '');
    } else if (inputData.id) {
      /* if inputData.id is present then we are making an edit IG call */
      return this.httpService.put(this.configURL.bffSecurityGroupSaveEndPointV1, inputData);
    } else {
      return this.httpService.post(this.configURL.bffSecurityGroupSaveEndPointV1, inputData);
    }
  }

  checkWhetherInstGrpExists(groupName, businessUnit): Observable<any> {
    const groupNameNew = encodeURIComponent(`${groupName}`);
    return this.httpService.get(this.configURL.bffSecurityGroupSaveEndPointV1 + '&name=' + groupNameNew + '&bu=' + businessUnit);
  }

  getAutoInstrumentGrps(query, type): Observable<any> {
    return this.httpService.get(this.configURL.bffSecurityGroupSaveEndPointV1 + '&searchValue=' + (query ? query.trim() : '') + '&searchType=' + type);
  }
}
