<div class="report-container">
  <div class="image-block">
    <div class="info-container sdps-is-relative sdps-p-vertical_none sdps-p-right_x-small">
        <div class="coming-soon-image sdps-is-absolute sdps-backdrop--open">
        </div>
        <div class="coming-soon-txt report-coming-soon">
          <span class="sdps-display-inline-block sdps-p-vertical_medium sdps-p-horizontal_small" [innerHtml]="reportConst.comingSoonTxt"></span>
        </div>
      </div>
  </div>
</div>
