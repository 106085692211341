<sdps-modal id='beta-edit-index-modal' [sdpsTitle]="title" [size]="'flex--small'" (close)="resetDisableIcon()">

  <div slot="modal-body">

    <p class="adl-modal__heading">
      <span><strong>Request ID: {{ requestId }}</strong></span>
      <span class="sdps-pull-right"><strong>Account No: {{ accountNumber }}</strong></span>
    </p>

    <div class="sdps-grid-fluid sdps-wrap">
      <div class="sdps-row sdps-m-vertical_large sdps-wrap">
        <div class="col-4" *ngIf="sharedService.checkEntitlements(entitlementConst.navUUID.accountLinking.uuid, '', '') && sharedService.checkEntitlements(entitlementConst.navUUID.addlnkAdminResource.uuid, '', '')">
          <div class="ems-input ">
            <span class="p-float-label">
              <input type="number" onkeypress="if(event.which<48 || event.which>57) return false;" [(ngModel)]="indexKey" pInputText />
              <label>Index Key<em>*</em></label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="closeModal(); resetDisableIcon()">Cancel</button>
    <button type="button" [disabled]="!isIndexKeyValid()" (click)="editIndexkeySubmit()" class="buttons button-save">Resubmit</button>
  </div>
</sdps-modal>

