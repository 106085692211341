import {Inject, Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedService } from '../../shared.service';
import 'rxjs/add/operator/filter';
import * as temp from 'moment';
const moment = temp['default'];
import { SharedConstant } from '../../constants/sharedConstants';

@Injectable({
  providedIn: 'root'
})
export class RolePermissionGuard implements CanActivate {

  userRole;
  sharedConstant = SharedConstant;

  constructor(public sharedService: SharedService, public router: Router, private r: ActivatedRoute, @Inject('entitlementConst') public entitlementConst) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const entitlementRoleName = route.data.entitlementName;
    return new Promise(res => {
      this.sharedService.clearErrorMessage();
      /* Not Performing policy if response exists in userRole */
      if (this.userRole) {
        this.verifyRoute(res, entitlementRoleName);
      } else {
        const getExpiryTime = moment(localStorage.getItem('tknExpireTime'), 'YYYY-MM-DDTHH:mm:ss');
        const currentTime = new Date(moment().format('YYYY-MM-DDTHH:mm:ss'));

        if (localStorage.getItem('token') !== null && !getExpiryTime.isBefore(currentTime)) {
          /* Performing policy API  only once while loading the rolePermisssionGuard to fetch the entitlementNames */

          this.sharedService.usersPolicy().subscribe(response => {
            if (response.data !== null) {
              this.userRole = response.data;
              this.performRelease(response, res, entitlementRoleName);
              this.sharedService.userName = response.data;
              this.sharedService.setUserID(response.data.userId);
              this.sharedService.setHashedUserID(response.data.userTag);
            } else {
              this.failRedirect();
            }
          }, e => {
            if (e.status === 500) {
              this.sharedService.sendErrorMessage(`${e.error.message} - ${e.status} ${e.statusText}`);
            }
            this.failRedirect();
          });
        } else {
          localStorage.clear();
          this.sharedService.setHideUnauthorizedFlag(true);
          this.router.navigate(['/unauthorized']);
        }
      }
    });
  }

  performRelease(response, res, entitlementRoleName) {
    this.sharedService.getReleaseVersion().subscribe(release => {
        if (release.data && (!release.data.hasOwnProperty('queryParamMap') || release.data.queryParamMap === null || Object.keys(release.data.queryParamMap).length === 0)) {
          this.router.navigate(['/unauthorized']);
        } else {
          this.sharedService.setReleaseResponse(release);
          this.sharedService.setPolicyResponse(response, 'success');
          this.sharedService.getUserPolicyEntitlementList();
          this.sharedService.getBusinessUnitList();
          this.sharedService.userEntitlementList = {
            workbench: this.checkEntitlementExists(this.entitlementConst.navUUID.addLinkAccess.entitlementName) || this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.entitlementName),
            addLink: this.checkEntitlementExists(this.entitlementConst.navUUID.addLinkAccess.entitlementName),
            accountLinking: this.checkEntitlementExists(this.entitlementConst.navUUID.accountLinking.entitlementName),
            emtrOrInstGrp: this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.ruleSummary.entitlementName) || this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.entitlementName),
            emtr: this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.ruleSummary.entitlementName),
            instrumentsGrp: this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.entitlementName),
            masterGrp: this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.masterAccountGroupSummary.entitlementName),
            uploads: this.checkEntitlementExists(this.entitlementConst.navUUID.addLinkAccess.entitlementName) || this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.entitlementName),
            reports: this.checkEntitlementExists(this.entitlementConst.navUUID.addLinkAccess.entitlementName) || this.checkEntitlementExists(this.entitlementConst.navUUID.emtrAccess.emtrAppAccess.entitlementName),
            admin: this.checkEntitlementExists(this.entitlementConst.navUUID.manageClient.entitlementName) || this.checkEntitlementExists(this.entitlementConst.navUUID.manageUser.entitlementName) || this.checkEntitlementExists(this.entitlementConst.navUUID.manageRole.entitlementName),
            manageClients: this.checkEntitlementExists(this.entitlementConst.navUUID.manageClient.entitlementName),
            manageUsers: this.checkEntitlementExists(this.entitlementConst.navUUID.manageUser.entitlementName)
          };
          this.verifyRoute(res, entitlementRoleName);
        }
      },

      (err) => {
        if (err.status === 500 || err.status === 404) {
          this.router.navigate(['/unauthorized']);
        }
      });
  }

  /* Validate whether entitlementName exists in policy entitlement response */
  checkEntitlementExists(name) {
    name = name.split('/');
    return this.sharedService.userPolicyEntitlementList.some(val => val.entitlementName === name[0] || val.entitlementName === name[1]);
  }

  verifyRoute(promiseRes, name) {
    console.log('verify route--', name);
    if (this.checkEntitlementExists(name)) {
      promiseRes(true);
    } else {
      /* Addlink not having request-list navigating to manage-employees only if role 'REQUEST_LIST_TAB' not exists ---> ROLE ID = 5 emtr internal admin*/
      if (this.checkEntitlementExists('EMPLOYEE_SUMMARY') && this.checkEntitlementExists('ACCOUNT_SUMMARY') && name === 'ACCOUNT_SUMMARY') {
        this.router.navigate(['ems/addLink/manage-employees']);
        promiseRes(true);
      } else if (this.checkEntitlementExists('MANAGE_USERS') && (name === 'ADDLINK_APP/EMTR_APP' || name === 'MANAGE_CLIENTS')) {
        console.log('check route here----', name);
        /* Admin not having manage-Client navigating to manage-user only if role 'MANAGE_CLIENTS_TAB' not exists, also workbench not exists ROLE ID = 3 user admin*/
        this.router.navigate(['ems/admin/manage-users']);
        promiseRes(true);
      } else if (this.checkEntitlementExists('MANAGE_CLIENTS') && (name === 'ADDLINK_APP/EMTR_APP')) {
        this.router.navigate(['ems/admin/manage-clients']);
        promiseRes(true);
      } else if (this.checkEntitlementExists('INSTRUMENTS_SUMMARY') && (name === 'EMTR_APP' || name === 'ADDLINK_APP/EMTR_APP')) {
        this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
        promiseRes(true);
      } else {
        this.router.navigate(['unauthorized']);
      }
    }
  }

  failRedirect() {
    localStorage.clear();
    this.router.navigate(['/unauthorized']);
  }
}
