import {Component, OnDestroy, OnInit} from '@angular/core';
import {SharedService} from '@ems/shared';
import {entitlementConst} from '@shell_components/constants/entitlementConstants';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {Router} from '@angular/router';

@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html'
})
export class UploadsComponent implements OnInit, OnDestroy {

  uploadsSubMenu: any;
  clientInfo: any;
  entitlementConst = entitlementConst;
  shellConstant = ShellConstant;
  isUploadComponent = true;

  constructor(public sharedService: SharedService, public router: Router) {
    /* Navigate to respective page based on the selected client access */
    this.sharedService.selectedClientInfo.subscribe(clientInfo => {
      this.clientInfo = clientInfo;
      if (this.isUploadComponent) {
        this.setNavigation();
      }
    });
  }

  ngOnInit() {
    this.setNavigation();
  }

  setNavigation() {
    const entitlement = entitlementConst.navUUID;
    this.uploadsSubMenu = [
      {
        id: 1,
        name: 'Uploads',
        route: 'uploads-list',
        showLink: this.sharedService.checkEntitlements(entitlement.addLinkAccess.uuid, entitlement.emtrAccess.emtrAppAccess.uuid, '')
      }
    ];

    /* If client is selected and the selected client does not have access to Rule Agent and Auth Agent access, then navigate to Admin menu */
    if (this.clientInfo && !this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, entitlementConst.navUUID.accountSummary.uuid, '')) {
      this.sharedService.loadDataTrackValue('client_redirect');
      this.isUploadComponent = false;
      if (this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
        this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
      } else {
        this.router.navigate(['ems/admin/manage-clients']);
      }
    }
  }

  ngOnDestroy(): void {
    this.isUploadComponent = false;
  }
}
