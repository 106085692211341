<div class="sdps-navigation--horizontal" role="application" *ngIf="clientInfo ? sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, entitlementConst.navUUID.accountSummary.uuid, '') : true">
  <nav aria-label="Expanded Summary panel with five nested items"
       class="sdps-navigation--horizontal__levelFour subNavigation" id="unique-secondaryNav">
    <ul aria-label="Open an Summary steps" class="sdps-navigation--horizontal__nav" role="group">
      <ng-container *ngFor="let nav of uploadsSubMenu">
        <li class="sdps-navigation--horizontal__item" role="group" routerLinkActive="sdps-is-active">
          <a aria-describedby="level4Option1" class="sdps-navigation--horizontal__link"
             [routerLink]="nav.route">{{nav.name}}</a>
        </li>
      </ng-container>
    </ul>
  </nav>

  <div class="content-area sdps-p-around_large">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="sdps-p-around_large"
     *ngIf="clientInfo ? !sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, entitlementConst.navUUID.accountSummary.uuid, '') : false">
  <div class="sdps-messaging sdps-messaging--error sdps-p-vertical_medium" role="alert" tabindex="0">
    <span aria-label="Error message" class="sdps-messaging__icon sch-x-circle-2x sdps-p-top_xx-small"
          role="text"></span>
    <span [innerHTML]="shellConstant.accessDeniedMessage"></span>
  </div>
</div>
