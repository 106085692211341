import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { ConfigUrlService, SharedService } from '@ems/shared';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CommonService {
  selectedMasterAccnt = new BehaviorSubject(null);
  public env: any;
  configURL = this.configService.setApplicationUrl();
  getOrgId = 0;
  constructor(@Inject('env') public e, private http: HttpClient, private configService: ConfigUrlService, public sharedService: SharedService) {
    this.env = e;
  }

 public saveAuthorizedAgent(orgId, firstName, lastName): Observable<any> {
   let schwabClientHeader = [];
   schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
   const url = this.configURL.bffAuthAgentEndpointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
   const body = {
     active: 'Y',
     authAgentFirstNm: firstName,
     authAgentLastNm: lastName,
     authAgentNm: lastName, firstName,
     orgId
   };
   return this.http.post(url, body);
  }

  public getRemarks(requestId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, this.getOrgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkRequestEndPointV1.replace('PassThrough?', `PassThrough/${requestId}/remarks?`);
    if (this.getOrgId && this.getOrgId !== 0) {
      return this.http.get(url + '&Schwab-Client-Ids=' + schwabClientHeader);
    } else {
      return this.http.get(url);
    }
  }

  public getJobIdSequence(): Observable<any> {
    const url = this.configURL.bffAddlinkSequenceIdEndPointV1.replace('PassThrough?', `PassThrough/jobId?`);
    return this.http.get(url);
  }

  public getAAExceptionsBUs(): Observable<any> {
    const url = this.configURL.addlinkBusinessUnitExceptionEndPointV1;
    return this.http.get(url);
  }

  public pollRequestDetailsByRequestId(requestId, orgId): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.orgId, orgId, schwabClientHeader);
    const url = this.configURL.bffAddlinkRequestEndPointV1.replace('PassThrough?', `PassThrough/${requestId}?`);
    if (orgId && (orgId !== null && orgId !== 0)) {
      return this.http.get(url + '&Schwab-Client-Ids=' + schwabClientHeader);
    } else {
      return this.http.get(url);
    }
  }

  public getClientCentralNotes(accountNumber): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.sharedService.setSchwabClientHeaders(this.sharedService.applicationInfo.data.queryParamMap.subAccount, accountNumber, schwabClientHeader);
    const url = this.configURL.bffAddlinkClientCentralNoteEndPointV1 + '&Schwab-Client-Ids= ' + schwabClientHeader;
    return this.http.get(url);
  }

}
