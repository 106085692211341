import {Injectable, Inject, EventEmitter} from '@angular/core';
import {BehaviorSubject, Subject, Observable, ReplaySubject} from 'rxjs';
import { EmployeeDetails } from '../../models/EmployeeDetails';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SharedService } from '../../shared.service';
import {ConfigUrlService} from '../config-url/config-url.service';

@Injectable({
  providedIn: 'root'
})
export class ManageEmployeeDataService {

  constructor(private http: HttpClient, private service: SharedService, @Inject('env') public env, public config: ConfigUrlService) {
    this.service.currentOrg.subscribe(orgId => {
      this.orgId = orgId;
    });
  }
  orgId: any;
  public employeeDetail: EmployeeDetails = {};
  public employeeDetailSource = new BehaviorSubject<EmployeeDetails>(this.employeeDetail);
  configURL = this.config.setApplicationUrl();
  private subject = new ReplaySubject<any>();
  clearClicked = new EventEmitter<boolean>();
  filterToggleClicked = false;

  setEmployeeDetails(empDetails: EmployeeDetails) {
    this.employeeDetailSource.next(empDetails);
  }

  updateEmployee(EmpIdObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.service.setSchwabClientHeaders(this.service.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const url = this.configURL.bffEmployeeAccountEndpointV1 + '&Schwab-Client-Ids= '  + schwabClientHeader;
    return this.http.put(url, EmpIdObj);
  }

  addEmployee(addEmpObj): Observable<any> {
    let schwabClientHeader = [];
    schwabClientHeader = this.service.setSchwabClientHeaders(this.service.applicationInfo.data.queryParamMap.orgId, this.orgId, schwabClientHeader);
    const url = this.configURL.bffEmployeeEndpointV1 + '&Schwab-Client-Ids= '  + schwabClientHeader;
    return this.http.post(url , addEmpObj);
  }

  sendMessage(message: any) {
    this.subject.next({text: message});
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  clearMessage() {
    this.subject.next({text: ''});
  }

}
