import {Directive, ElementRef} from '@angular/core';
import {Validator, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[emsEmployeeIdPatterncheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: EmployeeIdPatterncheckDirective, multi: true}
  ]
})
export class EmployeeIdPatterncheckDirective implements Validator {
  newExp: any;

  constructor(public el: ElementRef) {
  }

  validate() {
    this.newExp = new RegExp('^[a-zA-Z0-9-.\' ]*$');
    const elementVal = this.el.nativeElement.value;
    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
        idPatternCheck: false
      };
    }
  }
}
