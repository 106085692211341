import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {WorkbenchComponent} from '@shell_components/workbench/workbench.component';

import {EmsComponent} from './ems/ems.component';

import {AdminMenuComponent} from './admin-menu/admin-menu.component';
import {ManageUsersComponent} from './admin-menu/manage-users/manage-users.component';
import {CreateUserComponent} from './admin-menu/manage-users/create-user/create-user.component';
import {ActivateRouteGuard} from '@core_services/route-activate-guard';
import {ReportsComponent} from './reports/reports.component';
import {CustomReportsComponent} from './reports/custom-reports/custom-reports.component';
import {PresetReportsComponent} from './reports/preset-reports/preset-reports.component';
import {UnauthorizedComponent} from '@shell_components/core/unauthorized/unauthorized.component';
import {CanDeactivateGuard, WorkflowGuard, RolePermissionGuard} from '@ems/shared';
import {HealthCheckComponent} from '@shell_components/core/health-check/health-check.component';
import {ManageClientsComponent} from '@shell_components/admin-menu/manage-clients/manage-clients.component';
import {ManageClientsListComponent} from '@shell_components/admin-menu/manage-clients/manage-clients-list/manage-clients-list.component';
import {CreateClientComponent} from '@shell_components/admin-menu/manage-clients/create-client/create-client.component';
import {ManageRolesComponent} from '@shell_components/admin-menu/manage-roles/manage-roles.component';
import { OpenOrderComponent } from './reports/preset-reports/open-order/open-order.component';
import { TradeAttemptsComponent } from '@shell_components/reports/preset-reports/trade-attempts/trade-attempts.component';
import { EmployeeAccountDetailsComponent } from '@shell_components/reports/preset-reports/employee-account-details/employee-account-details.component';
import { RuleDetailsComponent } from '@shell_components/reports/preset-reports/rule-details/rule-details.component';
import { EmployeeGroupAssociationComponent } from '@shell_components/reports/preset-reports/employee-group-association/employee-group-association.component';
import {UploadsComponent} from '@shell_components/uploads/uploads.component';
import {UploadsListComponent} from '@shell_components/uploads/uploads-list/uploads-list.component';
import {ReportsListComponent} from '@shell_components/reports/reports-list/reports-list.component';
import {AddlinkUsageComponent} from '@shell_components/reports/preset-reports/addlink-usage/addlink-usage.component';
import {AddlinkRequestsComponent} from '@shell_components/reports/preset-reports/addlink-requests/addlink-requests.component';
import { RuleAuditComponent } from '@shell_components/reports/preset-reports/rule-audit/rule-audit.component';
import {environment} from '@environment/environment';
import { CommonModule } from '@angular/common';


const routes: Routes = [


  {path: '', redirectTo: 'ems', pathMatch: 'full'},

  {
    path: 'ems', component: EmsComponent, canActivate: [ActivateRouteGuard],
    children: [

      {path: '', redirectTo: 'workbench', pathMatch: 'full'},
      {path: 'workbench', component: WorkbenchComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'ADDLINK_APP/EMTR_APP'}},
      {
        path: 'addLink', loadChildren: () => import('../module/addlink-wrapper/addlink-wrapper.module').then(m => m.AddlinkWrapperModule),
        data: {envProp: environment}
      },
      {
        path: 'emtr', loadChildren: () => import('../module/emtr-wrapper/emtr-wrapper.module').then(m => m.EmtrWrapperModule),
        data: {envProp: environment}
      },
      {
        path: 'admin', component: AdminMenuComponent,
        children: [
          {path: '', redirectTo: 'manage-clients', pathMatch: 'full'},
          {
            path: 'manage-clients', component: ManageClientsComponent,
            children: [
              {path: 'manage-clients-list', component: ManageClientsListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'MANAGE_CLIENTS'}},
              {path: 'create-client', component: CreateClientComponent, canDeactivate: [CanDeactivateGuard], canActivate: [WorkflowGuard]},
              {path: '', redirectTo: '/ems/admin/manage-clients/manage-clients-list', pathMatch: 'full'}
            ]
          },

          {path: 'manage-users', component: ManageUsersComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'MANAGE_USERS'}},
          {
            path: 'manage-users',
            children: [
              {
                path: 'create-user',
                component: CreateUserComponent,
                data: {workflow: 'manage-users', entitlementName: 'MANAGE_USERS'},
                canActivate: [WorkflowGuard, RolePermissionGuard],
                canDeactivate: [CanDeactivateGuard],
                pathMatch: 'full'
              }]
          },
          {path: 'manage-roles', component: ManageRolesComponent},
        ]
      },
      {
        path: 'reports', component: ReportsComponent,
        children: [
          {path: '', redirectTo: 'reports-list', pathMatch: 'full'},
          {path: 'reports-list', component: ReportsListComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'ADDLINK_APP/EMTR_APP'}},
          {path: 'preset-reports', component: PresetReportsComponent, canDeactivate: [CanDeactivateGuard], canActivate: [RolePermissionGuard], data: {entitlementName: 'ADDLINK_APP/EMTR_APP'}
        , children: [
          {path: 'open-order', component: OpenOrderComponent},
          {path: 'trade-attempts', component: TradeAttemptsComponent},
          {path: 'employee-account', component: EmployeeAccountDetailsComponent},
          {path: 'rule-details', component: RuleDetailsComponent},
          {path: 'employee-group', component: EmployeeGroupAssociationComponent},
          {path: 'participant-plans', component: EmployeeGroupAssociationComponent},
          {path: 'participant-account', component: EmployeeAccountDetailsComponent},
          {path: 'addlink-usage', component: AddlinkUsageComponent},
          {path: 'addlink-requests', component: AddlinkRequestsComponent},
          {path: 'rule-audit', component: RuleAuditComponent}
        ]
        },
          {path: 'custom-reports', component: CustomReportsComponent}
        ]
      },
      {
        path: 'uploads', component: UploadsComponent,
        children: [
          {path: '', redirectTo: 'uploads-list', pathMatch: 'full'},
          {path: 'uploads-list', component: UploadsListComponent, canActivate: [RolePermissionGuard], data: {entitlementName: 'ADDLINK_APP/EMTR_APP'}}
        ]
      }
      ]
  },
  {path: 'unauthorized', component: UnauthorizedComponent},
  {path: 'healthcheck', component: HealthCheckComponent},
  {path: 'statuscheck', component: HealthCheckComponent},
  {path: '**', redirectTo: 'ems', pathMatch: 'full'}

];


@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

