import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountNumberValidationService {

  constructor() {
  }

  /*check Account Number contains 8 digits*/
  validateAccountNumber(accountNumber) {
    const regExp = new RegExp('^[0-9]*$');
    /* If entered Account Number count is below 8 show the Error Message 'Account Number should contain 8 digits with no hyphen' */
    if (accountNumber && (accountNumber.length < 8 && regExp.test(accountNumber))) {
      return true;
    } else {
      return false;
    }
  }
}
