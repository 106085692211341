import { Component, OnInit, Input } from '@angular/core';
import {emtrConstant} from '../../constants/emtrConstants';

@Component({
    selector: 'tr-rule-name-type',
    templateUrl: './rule-name-type.component.html',
    styleUrls: ['./rule-name-type.component.scss']
})
export class RuleNameTypeComponent {
@Input() ruleName: string;
@Input() ruleType: string;
@Input() showRuleName = true;
getConstant = emtrConstant;
}
