import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AddlinkModule} from '@ems/addlink';
import {EmtrModule} from '@ems/emtr';
import {EmsShellModule} from '@ui_module/ems-shell-module/ems-shell.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmsComponent} from './ems/ems.component';
import {HttpConfigInterceptor} from '@core_services/interceptor/httpConfig.interceptor';
import {SharedModule} from '@ems/shared';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {commonConstants} from '@shell_components/constants/commonConstants';
import { entitlementConst } from '@shell_components/constants/entitlementConstants';
import {AppInitService} from '@shell_components/app-init.service';
import {DynamicEnvironment} from '@environment/dynamic-environment';

export function init_app(appLoadService: AppInitService) {
  return () => appLoadService.init();
}
@NgModule({
  declarations: [
    AppComponent,
    EmsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AddlinkModule,
    EmtrModule,
    EmsShellModule,
    SharedModule.forRoot(commonConstants, entitlementConst)
  ],
  exports: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: 'env', useValue: DynamicEnvironment
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
