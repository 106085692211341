import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomSortService {
  sortArr: any = [];
  constructor() { }

  customSort(event) {
    const sortArr = [];
    let sortCondition = '';
    if (event.multiSortMeta.length > 2) {
      event.multiSortMeta.shift();
    }
    event.multiSortMeta.map(data => {
      sortCondition = data.order === 1 ? data.field + ',asc' : data.field + ',desc';
      sortArr.push(sortCondition);
    });
    return sortArr;
  }


  resetTable(obj, tableComponent) {
    this.sortArr = [];
    if (tableComponent) {
      tableComponent.reset();
      tableComponent.multiSortMeta = [obj];
    }
  }
}
