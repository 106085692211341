export interface HistoryMenu {
    label?: string;
}
export const HistoryMenu: HistoryMenu[] = [
    { label: 'Field Name'},
    { label: 'New Value'},
    { label: 'Old Value'},
    { label: 'Action'},
    { label: 'Date & Time'},
    { label: 'Changed By'}
];
