import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AddlinkComponent} from './addlink.component';
import {CanDeactivateGuard, RolePermissionGuard} from '@ems/shared';
import {DelinkComponent} from './core/delink/delink.component';
import {RequestListComponent} from './core/request-list/request-list.component';
import {LinkComponent} from './core/link/link.component';
import {UploadComponent} from './core/upload/upload.component';

const addlinkRoutes: Routes = [

  {
    path: '', component: AddlinkComponent,
    children: [
      {path: '', redirectTo: 'request-list', pathMatch: 'full'},
      {
        path: 'request-list',
        component: RequestListComponent,
        canActivate: [RolePermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {entitlementName: 'ACCOUNT_SUMMARY'}
      },
      {
        path: 'link',
        component: LinkComponent,
        canActivate: [RolePermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {entitlementName: 'ACCOUNT_LINKING'}
      },
      {
        path: 'delink',
        component: DelinkComponent,
        canActivate: [RolePermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {entitlementName: 'ACCOUNT_LINKING'}
      },
      {
        path: 'addlink-upload',
        component: UploadComponent,
        canActivate: [RolePermissionGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {entitlementName: 'ACCOUNT_LINKING'}
      }
    ]
  },
  {path: '**', redirectTo: 'login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forChild(addlinkRoutes)],
  exports: [RouterModule]
})
export class AddlinkRoutingModule {
}
