import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, filter, take, flatMap, delay } from 'rxjs/operators';
import { SharedConstant, SharedService } from '@ems/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicEnvironment } from '@environment/dynamic-environment';
import { UUID } from 'angular2-uuid';
import { TokenService } from '@core_services/token.service';
import { LocalStorageService } from '@core_services/local-storage.service';
import * as temp from 'moment';
const moment = temp['default'];
import { commonConstants } from '@shell_components/constants/commonConstants';
import { AppService } from '@core_services/app.service';
import { ChartsService } from '@core_services/charts/charts.service';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  refreshWholeScreen: any;
  uuidValue: any;
  moment = moment;
  userAgent: any;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new BehaviorSubject<any>(null);
  environment = new DynamicEnvironment();

  /* eslint-disable-next-line max-len */
  constructor(public sharedService: SharedService, private router: Router, private r: ActivatedRoute, private tokenService: TokenService, private localStorageService: LocalStorageService, private appService: AppService, public chartsService: ChartsService) {

    /* While Clicking refresh icon in authAgents not showing loading in Full Screen */
    this.sharedService.setRefresh.subscribe(value => {
      this.refreshWholeScreen = value;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.uuidValue = UUID.UUID();
    /* refreshWholeScreen to hide/show full page loader in workbench Page */
    if (!this.refreshWholeScreen) {
      this.sharedService.showLoader();
    }
    let finalURL = '';
    let schwabClientHeader;
    const urlObj = new URL(request.url);
    const params = new URLSearchParams(urlObj.search);

    if (urlObj.search !== '' && params.has('Schwab-Client-Ids')) {
      schwabClientHeader = params.get('Schwab-Client-Ids');
      params.delete('Schwab-Client-Ids');
      urlObj.search = params.toString().replace(/\+/g, ' ');
      finalURL = this.sharedService.decodeUrl ? decodeURIComponent(urlObj.href) : urlObj.href;
    }

    const reqHeaders = {
      setHeaders: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Schwab-RRBus-PilotRollout': this.environment.domain,
        'Schwab-Client-CorrelId': this.uuidValue,
        'Schwab-Client-Ids': schwabClientHeader ? schwabClientHeader : '',
      }, url: finalURL
    };
    if (request.url.indexOf('BatchFileV1-Upload') !== -1) {
      delete reqHeaders.setHeaders['Content-Type'];
    }
    request = request.clone(reqHeaders);


    /* Allowing only if a API is toksvc/extaccstokn */
    if (request.url.indexOf('/v1/token') !== -1 && request.url.indexOf('/v1/toksvc') === -1) {
      if (this.localStorageService.getToken() !== null) {
        /* If Token Exists already passing token in toksvc/extaccstokn */
        return this.handleRequest(this.injectToken(request), next);
      } else {
        return this.handleRequest(request, next);
      }
    } else if (request.url.indexOf('/v1/toksvc') !== -1) {
      return this.handleRequest(request, next);
    }


    const isTokenExpired = this.tokenService.isTokenExpired();
    if (isTokenExpired  && !this.sharedService.isInternalApp()) {
      return this.externalTokenRefresh(request, next);
    } else {
      return this.handleRequest(this.injectToken(request), next);
    }
    this.sharedService.hideLoader();
  }

  public externalTokenRefresh(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      /* Performing Refresh Token for External User */
      return this.tokenService.getEATToken().pipe(flatMap(result => {
        if (result) {
          const data = 'data';
          const errorCode = 'errorCode';
          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(result);
          if (result[errorCode] !== null && result[errorCode].errorCode === 302) {
            window.location.href = encodeURI(result[data]);
            return this.handleRequest(request, next);
          } else {
            const getExpiryTime = ((result.data.expiry - 30) / 60);
            const currentDate = new Date(moment().format('YYYY-MM-DDTHH:mm:ss'));
            const setExpiryTime = moment(currentDate).add(getExpiryTime, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
            this.localStorageService.setToken(decodeURIComponent(result.data.token));
            this.localStorageService.setExpiryDateTime(setExpiryTime);
            console.log('ExternalToken Refresh');
            return this.handleRequest(this.injectToken(request), next);
          }
        }
      }));
    } else {
      /* Holding Multiple Requests until token refreshed */
      return this.refreshTokenSubject.pipe(
        filter(result => result !== null),
        take(1),
        switchMap((res) => {
          console.log('Holding Multiple Requests until token refreshed line 127');
          return this.handleRequest(this.injectToken(request), next);
        })
      );
    }
  }

  public handleRequest(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.sharedService.hideLoader();
        }
        return event;
      }),
      catchError((errRes: any) => {
        this.refreshTokenInProgress = false;
        this.sharedService.hideLoader();
        if (errRes.status === 404) {
          if (errRes.error.errorResponse) {
            this.sharedService.sendErrorMessage(errRes.error.errorResponse.errorMessage);
            console.error(`${errRes.error.error}`);
          } else {
            this.sharedService.sendErrorMessage(commonConstants.errorMessage);
            const browserAndVersion = this.getBrowser().replace('/', ` `);
            const currentDateMsg = new Date();
            const curentDateFormate = moment(currentDateMsg).tz('America/New_York').format('MMMM DD YYYY h:mm:ss A');
            const errorMsg = '<p>' + commonConstants.errorMessage + '</p> <p>'
              + '<b>' + SharedConstant.errorMassageHeading.userNameMsg + ' : ' + '</b>' + this.sharedService.userName.emailAddress + '</P> <p>'
              + '<b>' + SharedConstant.errorMassageHeading.userActionMsg + ' : ' + '</b>' + errRes.url + '</p><p>'
              + '<b>' + SharedConstant.errorMassageHeading.userBrowserMsg + ' : ' + '</b>' + browserAndVersion + '</p><p>'
              + '<b>' + SharedConstant.errorMassageHeading.userTimeMsg + ' : ' + '</b>' + curentDateFormate + '</p>';
            this.sharedService.sendErrorMessage(errorMsg);
          }
          console.error(`${errRes.error.message} - ${errRes.status} ${errRes.statusText}`);
        } else if (errRes.status === 401) {
          this.sharedService.sendErrorMessage(commonConstants.errorMessage);
          console.error(errRes.status + ' ' + errRes.message);
          if (errRes.statusText === 'Unauthorized') {
            localStorage.clear();
            console.log('Unauthorized Token for getToken line 163 --------- ');
            this.tokenService.getToken();
          } else if (errRes.error === 'invalid_token') {
            this.tokenService.getNewToken();
          } else {
            this.router.navigate(['/unauthorized']);
          }
        } else if (errRes.status === 500) {
          /********
           * when the service takes more than 10 sec to respond, go in the IF BLOCK
           *************/

          if (errRes.error.error) {
            const browserAndVersion = this.getBrowser().replace('/', ` `);
            this.sharedService.sendErrorMessage(commonConstants.errorMessage);
            console.error(`${errRes.error.error}`);
            const currentDateMsg = new Date();
            const curentDateFormate = moment(currentDateMsg).tz('America/New_York').format('MMMM DD YYYY h:mm:ss A');
            const errorMsg = '<p>' + commonConstants.errorMessage + '</p> <p>'
              + '<b>' + SharedConstant.errorMassageHeading.userNameMsg + ' : ' + '</b>' + this.sharedService.userName.emailAddress  + '</P> <p>'
              + '<b>' + SharedConstant.errorMassageHeading.userActionMsg + ' : ' + '</b>' + errRes.url + '</p><p>'
              + '<b>' + SharedConstant.errorMassageHeading.userBrowserMsg + ' : ' + '</b>' + browserAndVersion + '</p><p>'
              + '<b>' + SharedConstant.errorMassageHeading.userTimeMsg + ' : ' + '</b>' + curentDateFormate + '</p>';
            this.sharedService.sendErrorMessage(errorMsg);
            console.error(`${errRes.error.error}`);
           } else {
            if (!this.appService.handleErrorMessage(errRes.error.message)) {
              this.sharedService.sendErrorMessage(`${errRes.error.message}`);
            } else {
              this.sharedService.sendErrorMessage(commonConstants.errorMessage);
            }
            console.error(`${errRes.error.message}`);
          }
        } else {
          this.sharedService.sendErrorMessage(commonConstants.errorMessage);
          this.sharedService.setHideUnauthorizedFlag(false);
          console.error(errRes.status + ' service is down. Please check after sometime.');
        }

        return throwError(errRes);
      }));
  }

  public injectToken(request) {
    if (this.localStorageService.getToken() !== null) {
      console.log('Inject Token for get Token --------- ');
      let tokenValue = 'Bearer ' + this.tokenService.getToken();
      if (request.url.includes('/v1/token') || request.url.includes('/v1/logout')) {
        tokenValue = this.localStorageService.getToken();
      }

      return request.clone({
        headers: request.headers.set('Authorization', tokenValue)
      });
    } else if (this.localStorageService.getToken() === null) {
      console.log('Inject Token for get Token is Null --------- ');
      this.tokenService.getToken();
    }
  }

  getBrowser() {
    const userAgent = navigator.userAgent;
    let browser = 'unkown';
    /* Detect browser name */
    browser = (/ucbrowser/i).test(userAgent) ? SharedConstant.browserDetails.uCBrowser : browser;
    browser = (/edg/i).test(userAgent) ? SharedConstant.browserDetails.edge : browser;
    browser = (/googlebot/i).test(userAgent) ? SharedConstant.browserDetails.googleBot : browser;
    browser = (/chromium/i).test(userAgent) ? SharedConstant.browserDetails.chromium : browser;
    browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? SharedConstant.browserDetails.firefox : browser;
    browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? SharedConstant.browserDetails.iE : browser;
    browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? SharedConstant.browserDetails.chrome : browser;
    browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? SharedConstant.browserDetails.safari : browser;
    browser = (/opr|opera/i).test(userAgent) ? SharedConstant.browserDetails.opera : browser;

    /* detect browser version */
    switch (browser) {
      case SharedConstant.browserDetails.uCBrowser: return `${browser}/${this.browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.edge: return `${browser}/${this.browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.googleBot: return `${browser}/${this.browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.chromium: return `${browser}/${this.browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.firefox: return `${browser}/${this.browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.chrome: return `${browser}/${this.browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.safari: return `${browser}/${this.browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.opera: return `${browser}/${this.browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
      case SharedConstant.browserDetails.iE: const version = this.browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
        /* IE version is mapped using trident version */
        /* IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0*/
        /* eslint-disable-next-line @typescript-eslint/indent */
        return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
      default: return `unknown/0.0.0.0`;
    }
  }

  browserVersion(userAgent, regex) {
    return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
  }
}
