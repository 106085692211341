import {Component, OnInit} from '@angular/core';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {SharedConstant, SharedService} from '@ems/shared';
import {ReportsService} from '@core_services/report/reports.service';

@Component({
  selector: 'app-report-details-modal',
  templateUrl: './report-details-modal.component.html',
  styleUrls: ['./report-details-modal.component.scss']
})
export class ReportDetailsModalComponent implements OnInit {

  sharedConstant = SharedConstant;
  shellConstant = ShellConstant;
  reportId: string;
  reportType: string;
  modalTitle: any;
  reportDetails: any;

  constructor(public reportsService: ReportsService, public sharedService: SharedService) {
  }

  ngOnInit() {
    this.modalTitle = this.shellConstant.reports.reportsListPage.reportDetailsModalTitle;
  }

  /* Opens The Modal */
  public openModal(reportId, reportType, showSnapshotReportDetails): void {
    this.sharedService.openModal('report-details-modal');
    this.reportId = reportId;
    this.reportType = reportType;
    this.getReportDetails(reportId, showSnapshotReportDetails);
  }

  getReportDetails(reportId, showSnapshotReportDetails) {
    this.reportsService.getReportDetails(showSnapshotReportDetails, reportId).subscribe(value => {
      if (value) {
        this.reportDetails = value.data;
      }
    });
  }

  returnZero() {
    return 0;
  }

}
