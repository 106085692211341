import { Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SharedService } from '../../shared.service';


@Component({
  selector: 'ems-multiselect',
  templateUrl: './ems-multiselect.component.html',
  styleUrls: ['./ems-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmsMultiselectComponent),
      multi: true
    }
  ]
})
export class EmsMultiselectComponent implements ControlValueAccessor, OnInit {

  // External
  @Input() singleSelect = false;
  @Input() searchable = false;
  @Input() disabled = false;
  @Input() label = 'EMS Multiselect';
  @Input() changeHeight = false;
  @Input() highlightColor: any;
  @Input() linkType: any;
  public optionsT: any[];
  @Input() get options(): any[] {
    return this.optionsT;
  }

  set options(val: any[]) {
    this.optionsT = val;
    this.optionsToShow = val;
    // Code to autoselect masterAcc
    this.autoSelectMasterAcc(this.optionsToShow);
  }

  // Internal
  public filterValue: any = '';
  public optionsToShow: any[] = [];
  public selectAll = false;
  public dropdownVisible = false;
  public itemSelected = false;

  public value: any[] = [];
  public prevOption: any;
  @ViewChild('checkboxElement') checkboxElement: any;

  public onModelChange = (value: any[]) => {
  }
  public onModelTouched = (value: any[]) => {
  }

  constructor(private ef: ElementRef, public sharedService: SharedService) {
  }

  ngOnInit() {

  }

  autoSelectMasterAcc(optionsToShow) {
    let faCount = 0;
    let slCount = 0;
    // code to find count of FA and SL
    if (optionsToShow) {
      optionsToShow.map(data => {
        if (data.accountType ? data.accountType.indexOf('FA') !== -1 : data.label.indexOf('FA') !== -1) {
          faCount++;
        } else if (data.accountType ? data.accountType.indexOf('SL') !== -1 : data.label.indexOf('SL') !== -1) {
          slCount++;
        }
      });
    }
    if (this.linkType === 'Multi' || this.linkType === 'Dual') {
        if ((faCount === 1 && slCount === 1) || slCount === 1) {
          this.selectAll = true;
          this.selectAllItems();
        }

    } else if (this.linkType && this.linkType.toLowerCase() === 'standard') {
        this.singleSelect = false;
        if ((faCount === 1 && slCount === 1) || (faCount === 1 && slCount > 1) || (faCount === 1 && slCount === 0)) {
            optionsToShow.length > 0 ? this.value.push(optionsToShow[0]) : this.value = [];
            this.onChange(optionsToShow);
        }
    }
  }

  @HostListener('document:click', ['$event'])
  public onClick(event): void {
    if (!this.ef.nativeElement.contains(event.target)) {
      this.dropdownVisible = false;
      this.filterValue = '';
      this.optionsToShow = this.options;

    }
  }

  public toggleDropdown() {
    if (this.disabled || this.value === []) {
      return;
    }
    if (this.value && this.value.length === this.optionsToShow.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
    this.dropdownVisible = !this.dropdownVisible;
    this.filterValue = '';
    this.optionsToShow = this.options;
  }

  public onChange(option: any): void {

    if (this.singleSelect) {
      if (this.prevOption === option) {
        this.value = [];
        this.prevOption = null;
      } else {
        this.value = [option];
        this.prevOption = option;
        this.dropdownVisible = false;
        this.filterValue = '';
        this.optionsToShow = this.options;
      }

      this.onModelChange(this.value);
      this.prevOption = null;
      return;
    } else {
      let faCount = 0;
      for (const entry of this.value) {
        const type = entry.label.split(' ')[1];
        if (type === '(FA)') {
          faCount++;
        }
      }
      if (faCount > 1) {
        const tempValue = this.value;
        this.value = [];
        for (const entry of tempValue) {
          if (entry !== option) {
            this.value.push(entry);
          }
        }
      }
    }

    if (this.value !== null && this.value.length === 0) {
      this.selectAll = false;
    } else {
      if (this.value.length === this.optionsToShow.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
    this.sharedService.selectedMasterAccnt.next(this.value);
    this.onModelChange(this.value);
  }

  public selectAllItems() {
    if (this.selectAll === false) {
      this.value = [];
    } else {
      this.value = this.optionsToShow;

      let faCount = 0;
      for (const entry of this.value) {
        const type = entry.label.split(' ')[1];
        if (type === '(FA)') {
          faCount++;
        }
      }
      if (faCount > 1) {
        alert('Select All is not a valid option. Only 1 FA Master can be selected per request. Please select Master Accounts for this request individually.');
        this.selectAll = false;
        this.value = [];
        this.checkboxElement.checked = false;
      }
    }
    this.onChange(null);
  }

  public isSelected(item: any): boolean {
    if (this.value !== null) {
      if (this.value.indexOf(item) !== -1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public filterOptions() {
    let fil = this.filterValue;
    fil = fil.replace(/\(/g, '\\(');
    fil = fil.replace(/\)/g, '\\)');

    this.optionsToShow = this.options.filter((option) => {
      return new RegExp(fil, 'gi').test(option.label);
    });

    if (this.value.length === this.optionsToShow.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
