import {Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewChecked} from '@angular/core';
import {UntypedFormGroup, UntypedFormBuilder, Validators, FormsModule} from '@angular/forms';
import {ManageUsersService} from '@core_services/manage-users/manage-user.service';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {ToastMessageComponent, SharedService, CommonValidationService, SharedConstant} from '@ems/shared';
import {ShellConstant} from '@shell_components/constants/shellConstants';
import {IUser} from '@shell_components/model/user';
import {InputMask} from 'primeng/inputmask';
import {PhoneNumberValidationService} from '@core_services/phone-number-validation/phone-number-validation.service';
import {InviteInfo} from '@shell_components/model/inviteInfo';
import {AuthService} from '@core_services/auth/auth.service';
import {commonConstants} from '@shell_components/constants/commonConstants';
import {Observable, Observer, Subject} from 'rxjs';
import {HeaderService} from '@core_services/header/header.service';
import {emtrConstant} from '../../../../../projects/ems/emtr/src/lib/constants/emtrConstants';
import {CountryConstant} from '@shell_components/constants/countryCodeConstants';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-users.component.scss']
})

export class CreateUserComponent implements OnInit, OnDestroy, AfterViewChecked {
  public roles: any[];
  public businessUnits: any[] = [];
  public createUserForm: UntypedFormGroup;
  public user: IUser;
  getEMTRConstant = emtrConstant;
  getUsersConstant = ShellConstant;
  getCommonConstant = commonConstants;
  getCountryConstant = CountryConstant;
  getSharedConstant = SharedConstant;
  checkDirtyForm = true;
  @ViewChild(ToastMessageComponent, { static: true }) msgToast: ToastMessageComponent;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild('phoneNumber') phoneNumber: InputMask;
  isPhoneValid = true;
  isUserTypeInt: boolean;
  rolesDesc = [];
  showToast: string;
  destroySubject: string;
  landingPageMsg: string;
  isExternalAdmin = false;
  selectedBusinessUnit = [];
  selectedCustId = [];
  selectedClientId = [];
  clientRecords = [];
  clientObj: any;
  selectedClient = [];
  selectedClientWithNoAccess = [];
  duplicateClientError = false;
  duplicateClientErrorMsg: string;
  assignClientsArr = [];
  listOfClientSelected = [];
  showCharLimit: boolean;
  getUsertype: string;
  isEmailIdInvalid = false;
  isOpaqueIdInvalid = true;
  countryCode: string;
  myaccessModeEnabled = true;
  selectedCountryCode: string;
  rolesDropdownData: any = [];
  userRoles = this.formBuilder.group({});
  userPolicy: any;
  clientAccess = false;
  clientAccessMsg: string;
  disableDBS = false;
  disableSPS = false;
  disableRBS = false;
  displayAllCheckbox = true;
  noUAEntForDBS = false;
  noUAEntForRBS = false;
  noUAEntForSPS = false;
  disableAssignClientRadioBtn = false;
  disableBURadioBtn = false;
  rolesWithNoAccessToUser = [];
  isDropdownOpened = false;
  scrollTopGlobal: number;
  isSearchedEnabled = false;
  @ViewChild('dropdown') dropdown: any;
  scrolledEle: any;
  pageNumber: any = 1;
  pageSize: any = 15;
  loadedCompleteData = false;
  initialClientRecords = [];
  clientSearchVal = '';
  searchInputUpdate = new Subject<any>();
  initialsItemsCounts = 1;
  searchedDataLoadedComplete = false;
  globalPageNumber = 1;
  emptyDisplayMessage: string;
  isExternalUserDeActive = false;
  sendInviteAddMoreSuccessMsgVisible = false;
  showErrorMsgPhone: boolean;
  showErrorMsgEmail: boolean;
  constructor(
    public formBuilder: UntypedFormBuilder,
    public userService: ManageUsersService, public formsModule: FormsModule,
    public router: Router, public el: ElementRef,
    private messageService: MessageService,
    public phoneNumberService: PhoneNumberValidationService,
    private authService: AuthService,
    public manageUserService: ManageUsersService,
    private headerService: HeaderService,
    public sharedService: SharedService,
    public commonValidationService: CommonValidationService) {
    this.formPhoneNumber();
    this.isUserTypeInt = true;
    const stateValues = this.sharedService.getStateVal();
    if (stateValues && stateValues.filterObj && stateValues.filterObj.custId) {
      stateValues.filterObj.custId = '';
      this.sharedService.setStateVal(JSON.stringify(stateValues));
    }
  }

  formPhoneNumber() {
    this.getCountryConstant.countryCode.forEach((data: any) => {
      data.value = data.phoneCode.replace('+', '');
      data.countryCode = data.name + '\xa0\(' + data.phoneCode + ')';
    });
  }

  userCreateForm() {
    this.createUserForm = this.formBuilder.group({
      opaqueId: [''],
      firstName: ['', {validators: [Validators.required]}],
      lastName: ['', {validators: [Validators.required]}],
      emailAddress: ['', {validators: [Validators.required]}],
      phoneNumber: ['', Validators.required],
      clientIds: [],
      businessUnit: [''],
      inviteInfo: [''],
      status: [],
      businessUnitOrAssignClient: [this.getUsersConstant.manageUserPage.businessUnits],
      clientNameDropdown: [''],
      custIds: [],
      countryCode: [{}, {validators: [Validators.required]}],
      userRoles: this.userRoles,
      userType: ['', Validators.required],
      userName: ['']
    });
  }

  ngOnInit() {
    this.roles = this.manageUserService.roles;
    if (this.roles) {
      this.sharedService.isValueObject = true;

      /* eslint-disable-next-line max-len */
      this.displayAllCheckbox = this.roles[this.getUsersConstant.manageUserPage.rbs] && this.roles[this.getUsersConstant.manageUserPage.rbs].length > 0 ? !(this.roles[this.getUsersConstant.manageUserPage.rbs].findIndex(val => val.roleName === 'RBS_RULE_AGENT') !== -1 && this.roles[this.getUsersConstant.manageUserPage.rbs].findIndex(val => val.roleName === 'RBS_RULE_AGENT_VIEW') !== -1) : true;
    }
    this.userCreateForm();
    this.userPolicy = this.sharedService.getPolicyResponse('success');
    if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
      this.rolesDropdownData = {};
    } else {
      this.rolesDropdownData = [];
    }
    const defaultCountryCode: any = {phoneCode: '+1', name: 'United States', value: '1'};
    defaultCountryCode.countryCode = defaultCountryCode.name + '\xa0\(' + defaultCountryCode.phoneCode + ')';
    this.createUserForm.patchValue({
      countryCode: defaultCountryCode
    });
    this.selectedCountryCode = '1';
    this.getUsertype = this.authService.getUsertype();
    this.myaccessModeEnabled = this.sharedService.applicationInfo.data.myaccessModeEnabled;
    if (this.myaccessModeEnabled && !this.userService.editMode) {
      this.createUserForm.get('userType').setValue(this.getUsersConstant.manageUserPage.external);
      this.createUserForm.get('userType').disable();
      this.createUserForm.get('businessUnitOrAssignClient').setValue(this.getUsersConstant.manageUserPage.assignClientToUser);
      this.rolesDropdownData = [];
    }

    if (this.getUsertype !== this.getUsersConstant.manageUserPage.internal) {
      this.isExternalAdmin = true;
      this.isUserTypeInt = false;
      this.createUserForm.get('userType').setValue(this.getUsersConstant.manageUserPage.external);
    } else {
      if (this.userService.editMode || !this.myaccessModeEnabled) {
        if (!this.myaccessModeEnabled) {
          this.createUserForm.get('userType').setValue(this.getUsersConstant.manageUserPage.internal);
        }
        this.createUserForm.get('clientNameDropdown').disable();
      }
    }
    this.fetchManageUserClientProfile();
    this.getBusinessUnits();
    if (this.userService.editMode) {
      this.createUserForm.get('userType').disable();
      this.editUser();
    } else {
      this.checkIfInternalUser(this.createUserForm.get('userType').value);
      this.getRoles(this.userPolicy.data.userId, 'EXT');
    }

    if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
      this.clientRecords = this.clientRecords.filter(item => (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === item.businessUnit && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
    } else {
      this.clientRecords = this.clientRecords.filter(item => (this.userPolicy.data.userPolicy.clientPolicy.some(data => data.clientId === item.clientId && data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
    }
    this.onChanges();

    if (!(this.isExternalAdmin && this.sharedService.clientSummary.length === 1)) {
      /* To disable the client dropdown in the header and set the client dropdown value to all */
      this.sharedService.emitClientDropdownDisableValue.emit(true);
    }
    this.searchInputUpdate
      .pipe(debounceTime(2000))
      .subscribe((results) => {
        if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0 && !this.loadedCompleteData) {
          this.clientSearchVal = encodeURIComponent(results);
          this.autoCompleteSearch();
        }
      });
  }

  updatePhoneNumber(event) {
    this.selectedCountryCode = event.value.value;
    this.createUserForm.get('phoneNumber').setValue('');
  }
  fetchManageUserClientProfile() {
    const key = 'data';
    if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
      this.headerService.getManageUserBUProfile(this.pageSize, 1, '').subscribe(res => {
        const clientList = this.filterRBSClient(res[key]);
        if (clientList && clientList.length > 0) {
          this.initialClientRecords = clientList;
          this.clientRecords = clientList;
        }
      });
    } else {
      this.headerService.getManageUserClientProfile().subscribe(res => {
        const clientList = this.filterRBSClient(res[key]);
        if (clientList && clientList.length > 0) {
          this.initialClientRecords = clientList;
          this.clientRecords = clientList;
        }
      });
    }
  }

  onDropdownItemShow() {
    this.isDropdownOpened = true;  // to check if user leave without selecting
    this.scrollToIndex(0);
    this.attachScrollEvent();
  }

  attachScrollEvent() {
    setTimeout(() => {
      const panel = this.dropdown.containerViewChild.nativeElement.querySelector('.p-dropdown-panel > .p-dropdown-items-wrapper > p-scroller > .p-scroller');
      if (panel) {
        panel.addEventListener('scroll', event => this.loadNextSetOfData(event));
      }
    }, 0);
  }

  loadNextSetOfData(event) {
    this.scrolledEle = event.target;
    // calculate if scroll bottom
    const scrollTo = this.scrolledEle.scrollHeight - this.scrolledEle.offsetHeight;
    if (this.isSearchedEnabled) {
      if ((this.scrollTopGlobal !== scrollTo) && (this.scrolledEle.children[0].childElementCount !== 0 && this.scrolledEle.children[0].childNodes.length >= this.initialsItemsCounts) && (scrollTo === (Math.trunc(this.scrolledEle.scrollTop))) && !this.loadedCompleteData) {
        this.pageNumber += 1;
        this.fetchNextSearchSetClientProfile(this.pageNumber);
        this.scrollTopGlobal = scrollTo;
        this.initialsItemsCounts = this.scrolledEle.children[0].childNodes.length;
      } else if ((this.scrolledEle.children[0].childElementCount !== 0 && this.scrolledEle.children[0].childNodes.length >= this.initialsItemsCounts) && (scrollTo === (Math.trunc(this.scrolledEle.scrollTop))) && !this.loadedCompleteData && !this.searchedDataLoadedComplete) {
        this.pageNumber += 1;
        this.fetchNextSearchSetClientProfile(this.pageNumber);
        this.scrollTopGlobal = scrollTo;
        this.initialsItemsCounts = this.scrolledEle.children[0].childNodes.length;
      }
    } else {
      if ((this.scrollTopGlobal !== scrollTo) && (this.scrolledEle.children[0].childElementCount !== 0 ) && scrollTo === Math.trunc(this.scrolledEle.scrollTop) && !this.loadedCompleteData) {
        this.pageNumber += 1;
        this.fetchNextSetClientProfile(this.pageNumber);
        this.scrollTopGlobal = scrollTo;
        this.initialsItemsCounts = this.scrolledEle.children[0].childElementCount;
      } else if ((this.scrollTopGlobal !== scrollTo) && (this.scrolledEle.children[0].childElementCount !== 0 ) && (scrollTo === (Math.trunc(this.scrolledEle.scrollTop) - 1)) && !this.loadedCompleteData) {
        this.pageNumber += 1;
        this.fetchNextSetClientProfile(this.pageNumber);
        this.scrollTopGlobal = scrollTo;
        this.initialsItemsCounts = this.scrolledEle.children[0].childElementCount;
      } else if ((this.scrollTopGlobal !== scrollTo) && (this.scrolledEle.children[0].childElementCount !== 0 ) && (scrollTo === (Math.trunc(this.scrolledEle.scrollTop) + 1)) && !this.loadedCompleteData) {
        this.pageNumber += 1;
        this.fetchNextSetClientProfile(this.pageNumber);
        this.scrollTopGlobal = scrollTo;
        this.initialsItemsCounts = this.scrolledEle.children[0].childElementCount;
      }
    }
  }
  searchedText(event) {
    this.isSearchedEnabled = true;
    this.emptyDisplayMessage = this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0 ? this.getSharedConstant.emptyRecordsMessage : this.getSharedConstant.noRecords;
    this.searchInputUpdate.next(event.target.value);
  }
  fetchNextSearchSetClientProfile(pageNo) {
    // search case
    const key = 'data';
    this.headerService.getManageUserBUProfile(this.pageSize, pageNo , this.clientSearchVal).subscribe(res => {
      if (res[key].length > 0) {
        Array.prototype.push.apply(this.clientRecords, this.filterRBSClient(res[key]));
        this.dropdown.options = this.clientRecords;
      } else {
        this.searchedDataLoadedComplete = true;
      }
    });
  }
  fetchNextSetClientProfile(pageNumber: number) {
    const key = 'data';
    if (!this.isSearchedEnabled) {
      // for normal lazy case
      this.headerService.getManageUserBUProfile(this.pageSize, pageNumber, this.clientSearchVal).subscribe(res => {
        if (res[key].length > 0) {
          Array.prototype.push.apply(this.clientRecords, this.filterRBSClient(res[key]));
          this.initialClientRecords = [...this.clientRecords];
          this.dropdown.options = this.clientRecords;
          this.globalPageNumber = pageNumber;
        } else {
          this.loadedCompleteData = true;
        }
      });
    }
  }
  autoCompleteSearch() {
    this.headerService.getManageUserBUProfile(this.pageSize, 1, this.clientSearchVal).subscribe(res => {
      const key = 'data';
      if (res[key] && res[key].length > 0) {
        this.loadedCompleteData = false;
        if (this.clientSearchVal === '') {
          this.isSearchedEnabled = false;
        }
        this.pageNumber = 1;
        const clientList = this.filterRBSClient(res[key]);
        if (clientList && clientList.length > 0) {
          this.clientRecords = clientList;
          this.initialsItemsCounts = this.clientRecords.length;
          if (this.searchedDataLoadedComplete) {
            this.searchedDataLoadedComplete = false;
            this.attachScrollEvent();
          }
        } else {
          this.searchedDataLoadedComplete = true;
        }
      } else {
        this.searchedDataLoadedComplete = true;
      }
      this.emptyDisplayMessage = this.getSharedConstant.noRecords;
    });
  }
  scrollToIndex(index) {
    setTimeout(() => {
      if (this.dropdown && this.dropdown.viewPort) {
        this.dropdown.viewPort.scrollToIndex(index);
      }
    }, 0);
  }
  resetFilterValue() {
    this.dropdown.containerViewChild.nativeElement.querySelector('p-dropdown input').blur();
    this.dropdown.resetFilter();
    if (this.isSearchedEnabled) {
      this.isSearchedEnabled =  false;
      this.loadedCompleteData = false;
    }
    if (this.isDropdownOpened) {
      this.clientRecords = [...this.initialClientRecords];
      this.clientSearchVal = '';
      this.pageNumber = this.globalPageNumber;
    }
  }
  filterRBSClient(clientRecords) {
    /* While creating an external user the client dropdown should not be displayed with RBS clients */
    if (this.createUserForm.get('userType').value === this.getUsersConstant.manageUserPage.external) {
      return clientRecords.filter(item => (item.businessUnit !== this.getUsersConstant.rbsBUText));
    } else {
      return clientRecords;
    }
  }

  onChanges(): void {
    this.createUserForm.get('emailAddress').valueChanges.subscribe(() => {
      this.showErrorMsgEmail = false;
      this.validateEmailAddress(event);
      if(!this.showErrorMsgPhone){
      this.sharedService.clearErrorMessage();
      }
    });

    this.createUserForm.get('phoneNumber').valueChanges.subscribe(() => {
      this.showErrorMsgPhone = false;
    if(!this.showErrorMsgEmail){
      this.sharedService.clearErrorMessage();
    }
    });

    this.createUserForm.get('opaqueId').valueChanges.subscribe(() => {
      this.isOpaqueIdInvalid = true;
    });
    this.createUserForm.get('businessUnitOrAssignClient').valueChanges.subscribe((value) => {
      if (value === this.getUsersConstant.manageUserPage.businessUnits) {
        /* To disable and reset the assign client list when business unit is selected*/
        this.selectedCustId.forEach(custId => {
          if (this.user && this.user.clientSelections) {
            this.user.clientSelections.forEach(client => {
              if (client.custId === custId) {
                this.userRoles.get(custId).get('roles').setValidators(null);
                this.userRoles.removeControl(custId);
              }
            });
          } else {
            this.userRoles.get(custId).get('roles').setValidators(null);
            this.userRoles.removeControl(custId);
          }
        });
        this.userRoles.updateValueAndValidity();
        this.selectedClient = [];
        this.selectedCustId = [];
        this.selectedClientId = [];
        this.listOfClientSelected = [];
        this.rolesDropdownData = [];
        this.createUserForm.get('clientNameDropdown').disable();
        this.createUserForm.get('businessUnit').enable();
        this.duplicateClientError = false;

        /* Call getRoles with only roleType when business uint is selected and also when activating the user */
        if (!this.user.userId) {
          this.getRoles(this.userPolicy.data.userId, '');
        }
      } else {
        /* To disable and reset the business uint value when assign clients is selected*/
        this.selectedBusinessUnit.forEach(bu => {
          this.userRoles.get(bu).get('roles').setValidators(null);
          this.userRoles.removeControl(bu);
        });
        this.userRoles.updateValueAndValidity();
        this.createUserForm.get('businessUnit').reset();
        this.selectedBusinessUnit = [];
        this.rolesDropdownData = [];
        this.createUserForm.get('businessUnit').disable();
        this.createUserForm.get('clientNameDropdown').enable();
      }
    });

    /* To enable the assign client list when external user radiobutton is selected */
    this.createUserForm.get('userType').valueChanges.subscribe(value => {
      /* While creating or editing an external user the client dropdown should not be displayed with RBS clients */
      if (value === this.getUsersConstant.manageUserPage.external) {
        this.clientRecords = this.clientRecords.filter(item => (item.businessUnit !== this.getUsersConstant.rbsBUText));
      }
      if (!this.userService.editMode) {
        /* To reset the assign client list while switching between the internal and external user */
        this.selectedClient = this.selectedClientId = this.selectedCustId = this.listOfClientSelected = this.rolesDropdownData = this.selectedBusinessUnit = [];
        this.duplicateClientError = false;
        this.userRoles.reset();

        if (value !== this.getUsersConstant.manageUserPage.internal) {
          this.createUserForm.get('clientNameDropdown').enable();
          /* To disable and reset the business uint value when external user radiobutton is selected */
          this.createUserForm.get('businessUnit').reset();
          this.getRoles(this.user.userId, 'EXT');
        } else {
          this.createUserForm.get('clientNameDropdown').disable();
          this.createUserForm.get('businessUnitOrAssignClient').setValue(this.getUsersConstant.manageUserPage.businessUnits);
        }
      }
    });
  }

  checkIfInternalUser(userType) {
    /* set value to isUserTypeInt while switching between the userType (Internal /External) during create user */
    if (userType === this.getUsersConstant.manageUserPage.internal) {
      this.isUserTypeInt = true;
    } else {
      this.isUserTypeInt = false;
    }
  }

  getClientDetails(e) {
    this.duplicateClientError = false;

    this.clientObj = {
      clientName: e.clientName,
      k4ClientName: e.k4ClientName,
      clientId: e.clientId,
      custId: e.custId,
      businessUnit: e.businessUnit,
      applications: e.applications,
      clientNameSelected: true,
      orgId: e.orgId
    };

    /* Do not add duplicate client name */
    const duplicatedClient = this.selectedClient.filter(data => data.clientId === e.clientId);
    if (!duplicatedClient.length) {
      this.assignClientValues(this.clientObj, this.clientObj.custId, this.clientObj.clientId);
    } else {
      this.duplicateClientError = true;
      this.duplicateClientErrorMsg = this.getUsersConstant.manageUserPage.errorMsg.client + '<strong>' + this.clientObj.k4ClientName + '</strong>' + this.getUsersConstant.manageUserPage.errorMsg.duplicateClientSelected;
    }
    this.createUserForm.get('clientNameDropdown').reset();
    this.pageNumber = this.globalPageNumber;
    this.clientRecords = this.initialClientRecords;
    this.initialsItemsCounts = this.clientRecords.length;
  }

  getRoles(userId, type) {
    this.manageUserService.getRoles(userId, type).subscribe(success => {
      this.roles = [];
      this.roles = success.data;
      this.sharedService.isValueObject = true;
    }, error => {
      this.roles = null;
    });
  }

  populateRolesInDropdown(bu, application) {
    const array = [];
    if (this.roles !== undefined && this.roles !== null && this.roles.length !== 0) {
      if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
        if (this.createUserForm.get('businessUnitOrAssignClient').value === this.getUsersConstant.manageUserPage.businessUnits) {
          if (this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(bu) !== -1) {
            this.roles[bu].forEach(role => {
              array.push(this.formRoleDropdownObject(role));
            });
          }
        } else {
          this.roles[bu].forEach(role => {
            if (role.roleDesc !== this.getSharedConstant.instrumentGroupAdminText && role.roleDesc !== this.getSharedConstant.dbsInstrumentGroupAdminText) {
              if (application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1 || (application.indexOf(this.getUsersConstant.manageUserPage.addlink) !== -1 && application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1)) {
                array.push(this.formRoleDropdownObject(role));
              } else {
                if (role.roleDesc !== this.getSharedConstant.ruleAgentText) {
                  array.push(this.formRoleDropdownObject(role));
                }
              }
            }
          });
        }
        const roleObj = {
          DBS: bu === this.getUsersConstant.manageUserPage.dbs ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.dbs] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.dbs] : '',
          SPS: bu === this.getUsersConstant.manageUserPage.sps ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.sps] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.sps] : '',
          RBS: bu === this.getUsersConstant.manageUserPage.rbs ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.rbs] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.rbs] : '',
          /* eslint-disable-next-line max-len */
          DBSEMTR: bu === this.getUsersConstant.manageUserPage.dbs && (application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1 || (application.indexOf(this.getUsersConstant.manageUserPage.addlink) !== -1 && application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1)) ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.dbsEmtr] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.dbsEmtr] : '',
          /* eslint-disable-next-line max-len */
          DBSADDLink: bu === this.getUsersConstant.manageUserPage.dbs && !(application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1 || (application.indexOf(this.getUsersConstant.manageUserPage.addlink) !== -1 && application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1)) ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.dbsAddlink] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.dbsAddlink] : '',
          /* eslint-disable-next-line max-len */
          SPSEMTR: bu === this.getUsersConstant.manageUserPage.sps && (application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1 || (application.indexOf(this.getUsersConstant.manageUserPage.addlink) !== -1 && application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1)) ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.spsEmtr] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.spsEmtr] : '',
          /* eslint-disable-next-line max-len */
          SPSADDLink: bu === this.getUsersConstant.manageUserPage.sps && !(application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1 || (application.indexOf(this.getUsersConstant.manageUserPage.addlink) !== -1 && application.indexOf(this.getUsersConstant.manageUserPage.emtr) !== -1)) ? array : this.rolesDropdownData && this.rolesDropdownData[this.getUsersConstant.manageUserPage.spsAddlink] ? this.rolesDropdownData[this.getUsersConstant.manageUserPage.spsAddlink] : ''
        };
        /* If Business Unit radio button is selected then rolesDropdownData will be an Object */
        if (this.createUserForm.get('businessUnitOrAssignClient').value === this.getUsersConstant.manageUserPage.businessUnits) {
          this.rolesDropdownData = roleObj;
        } else {
          /* If Assign Clients radio button is selected then rolesDropdownData will be an array */
          this.rolesDropdownData.push(array);
        }
      } else {
        if (this.selectedBusinessUnit.length === 0) {
          this.roles[bu].forEach(clientRole => {
            array.push(this.formRoleDropdownObject(clientRole));
          });

          /* When logged in user has client level access - we will not have BU value of the selected client in the roles variable */
          /* roles[bu] - here bu will hold the custId of the client and since it will run for all the selected client and we will not know the BU value of the selected client
             once the displayAllCheckbox is set to false we will not change the value of it again bec if we do not do this then the displayAllCheckbox get set to true for DBS
             clienst as they will not have access to the RBS Rule agent roles. */
          /* eslint-disable-next-line max-len */
          this.displayAllCheckbox = !this.displayAllCheckbox ? this.displayAllCheckbox : this.roles[bu] && this.roles[bu].length > 0 ? !(this.roles[bu].findIndex(val => val.roleName === 'RBS_RULE_AGENT') !== -1 && this.roles[bu].findIndex(val => val.roleName === 'RBS_RULE_AGENT_VIEW') !== -1) : true;
          this.rolesDropdownData.push(array);
        }
      }
    }
  }

  getBusinessUnits() {
    this.manageUserService.getBusinessUnits().subscribe(success => {
      const respData = success.data;
      /* eslint-disable-next-line max-len */
      this.disableSPS = this.userPolicy.data.userPolicy.buPolicy ? this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(this.getUsersConstant.manageUserPage.sps) === -1 || (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.getUsersConstant.manageUserPage.sps && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1)) : true;
      /* eslint-disable-next-line max-len */
      this.disableDBS = this.userPolicy.data.userPolicy.buPolicy ? this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(this.getUsersConstant.manageUserPage.dbs) === -1 || (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.getUsersConstant.manageUserPage.dbs && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1)) : true;
      /* eslint-disable-next-line max-len */
      this.disableRBS = this.userPolicy.data.userPolicy.buPolicy ? this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(this.getUsersConstant.manageUserPage.rbs) === -1 || (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === 'RBS' && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1)) : true;
      if (this.userPolicy.data.userPolicy.buPolicy) {
        this.noUAEntForRBS = (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.getUsersConstant.manageUserPage.rbs && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1));
        this.noUAEntForDBS = (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.getUsersConstant.manageUserPage.dbs && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1));
        this.noUAEntForSPS = (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === this.getUsersConstant.manageUserPage.sps && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1));
      }
      respData.forEach(data => {
        this.businessUnits.push({
          name: data.name,
          disabled: data.name === this.getUsersConstant.manageUserPage.dbs ? this.disableDBS : data.name === this.getUsersConstant.manageUserPage.sps ? this.disableSPS : this.disableRBS
        });
      });
    }, error => {
      this.businessUnits = null;
    });
  }

  addBusinessUnit(selectedBusinessUnit) {
    /* Add or remove business unit when business unit checkbox is selected or unselected */
    this.userRoles.removeControl(selectedBusinessUnit);
    selectedBusinessUnit ? this.selectedBusinessUnit.indexOf(selectedBusinessUnit) === -1 ? this.selectedBusinessUnit.push(selectedBusinessUnit) : this.selectedBusinessUnit.splice(this.selectedBusinessUnit.indexOf(selectedBusinessUnit), 1) : this.selectedBusinessUnit = [];
    if (this.selectedBusinessUnit.length > 0) {
      this.selectedBusinessUnit.forEach((bu) => {
        this.userRoles.addControl(bu, this.formBuilder.group({
          roles: ['', Validators.required]
        }));
      });
      this.populateRolesInDropdown(selectedBusinessUnit, '');
      this.selectedBusinessUnit.forEach((bu, index) => {
        const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[index];
        this.sharedService.setMultiSelectDefaultLabel(elementRef, this.userRoles.get(bu), 'roles', this.rolesDropdownData[bu].length);
      });
    } else {
      this.userRoles.removeControl(selectedBusinessUnit);
    }
  }

  assignClientValues(selectedClient, custId, clientId) {
    /* Add or remove custId , selectedClient and clientId when client name checkbox is selected or unselected while assigning a client*/
    selectedClient ? this.assignClientsArr.indexOf(selectedClient) === -1 ? this.assignClientsArr.push(selectedClient) : this.assignClientsArr.splice(this.assignClientsArr.indexOf(selectedClient), 1) : this.assignClientsArr = [];
    custId ? this.selectedCustId.indexOf(custId) === -1 ? this.selectedCustId.push(custId) : this.selectedCustId.splice(this.selectedCustId.indexOf(custId), 1) : this.selectedCustId = [];
    clientId ? this.selectedClientId.indexOf(clientId) === -1 ? this.selectedClientId.push(clientId) : this.selectedClientId.splice(this.selectedClientId.indexOf(clientId), 1) : this.selectedClientId = [];
    if (selectedClient && this.listOfClientSelected.indexOf(selectedClient) === -1) {
      this.listOfClientSelected.push(selectedClient);
    }

    if (this.selectedCustId.length === 1 && !this.manageUserService.editMode) {
      if (this.user !== undefined && this.user !== null) {
        this.getRoles(this.user.userId, '');
      }
    }
    /* Not-Resetting selectedRole if assign client is clicked */
    this.checkIfInternalUser(this.createUserForm.get('userType').value);
    /* Call getRoles on selecting the client and assign the selected the custId to the custId in the service */
    if (this.listOfClientSelected.length > 0) {
      setTimeout(() => {
        if (selectedClient.clientNameSelected === true && (selectedClient && this.selectedClient.indexOf(selectedClient) === -1)) {
          this.selectedClient.push(selectedClient);
          this.populateRolesInDropdown(this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0 ? selectedClient.businessUnit : selectedClient.clientId, selectedClient.applications);
        }
      }, 425);
      this.userRoles.addControl(custId, this.formBuilder.group({
        roles: ['', Validators.required]
      }));
      if (selectedClient.clientNameSelected === false) {
        this.userRoles.get(custId).get('roles').setValidators(null);
      } else {
        this.userRoles.get(custId).get('roles').setValidators(Validators.required);
      }
      this.userRoles.get(custId).get('roles').updateValueAndValidity();
      setTimeout(() => {
        this.assignClientsArr.forEach((custID, index) => {
          /* eslint-disable-next-line max-len */
          const buOrClient = this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0 ? this.createUserForm.get('businessUnitOrAssignClient').value !== this.getUsersConstant.manageUserPage.businessUnits ? custID.businessUnit !== 'RBS' ? custID.businessUnit !== 'DBS' ? custID.applications[1] === 'EMTR' ? 'SPSEMTR' : 'SPSADDLink' : custID.applications[1] === 'EMTR' ? 'DBSEMTR' : 'DBSADDLink' : custID.businessUnit : custID.businessUnit : '';
          const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[index];
          if (this.rolesDropdownData && this.rolesDropdownData[buOrClient] && this.rolesDropdownData[buOrClient].length > 0) {
            this.sharedService.setMultiSelectDefaultLabel(elementRef, this.userRoles.get(custID.custId), 'roles', this.rolesDropdownData[buOrClient].length);
          }
        });
      }, 50);
    } else {
      this.userRoles.removeControl(custId);
    }
  }

  public showMangeUser() {
    this.destroySubject = 'test';
    this.userService.clearMessage();
    this.messageService.clear();
    this.userService.showSuccessMsg = false;
    this.router.navigateByUrl(this.getUsersConstant.manageUserPage.landingPageURL);
  }

  public saveUserDetails(val) {
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
    this.destroySubject = val;

    this.createUserForm.get('custIds').setValue(this.selectedCustId);
    this.createUserForm.get('clientIds').setValue(this.selectedClientId);

    /*Add invite info*/
    const invite = new InviteInfo();

    if (this.authService.getUserPolicy() !== null) {
      invite.sentBy = this.authService.getUsername();
    }

    this.createUserForm.patchValue({
      inviteInfo: invite
    });
    /*--------------------------------------------*/
    const phoneCode = this.createUserForm.get('countryCode').value;
    const localUpdatedFormValues = this.createUserForm.getRawValue();

    /*trim the first and last name for space*/
    localUpdatedFormValues.firstName = this.createUserForm.get('firstName').value.trim();
    localUpdatedFormValues.lastName = this.createUserForm.get('lastName').value.trim();
    /*trim the opaqueId)schwabId for spaces */
    localUpdatedFormValues.opaqueId = this.createUserForm.get('opaqueId').value.trim();
    localUpdatedFormValues.businessUnits = [];
    /* converting phoneNumber formControlName to object and pushing it to an array */
    localUpdatedFormValues.phoneNumbers = [];
    localUpdatedFormValues.phoneNumbers.push({
      phoneNumber: this.createUserForm.get('phoneNumber').value,
      countryCode: phoneCode.value
    });

    /* converting emailAddresses formControlName to object and pushing it to an array */
    localUpdatedFormValues.emailAddresses = [];
    localUpdatedFormValues.emailAddresses.push({emailAddress: this.createUserForm.get('emailAddress').value.trim()});

    /* Removed phoneNumber & emailAddress from Request Payload */
    delete localUpdatedFormValues.phoneNumber;
    delete localUpdatedFormValues.emailAddress;
    delete localUpdatedFormValues.clientNameDropdown;
    delete localUpdatedFormValues.businessUnitOrAssignClient;
    let roleList = [];
    if (this.selectedBusinessUnit.length > 0) {
      this.selectedBusinessUnit.forEach((bu, index) => {
        this.userRoles.get(bu).get('roles').value.forEach(roleDetail => {
          roleList.push(this.formRoleObject(roleDetail));
        });
        const mainObj = {
          name: bu,
          roleInfoList: roleList
        };
        roleList = [];
        if (localUpdatedFormValues.businessUnits.map(x => x.name).indexOf(bu) === -1) {
          localUpdatedFormValues.businessUnits.push(mainObj);
        }
      });
    }

    if (this.selectedClient.length > 0) {
      localUpdatedFormValues.clientSelections = [];
      delete localUpdatedFormValues.businessUnits;
      this.selectedClient.forEach((data, index) => {
        if (data.clientNameSelected) {
          this.userRoles.get(data.custId).get('roles').value.forEach(roleDetail => {
            roleList.push(this.formRoleObject(roleDetail));
          });
          if (this.rolesWithNoAccessToUser[index] && this.rolesWithNoAccessToUser[index].length > 0) {
            this.rolesWithNoAccessToUser[index].forEach(noAccessRoles => {
              roleList.push(this.formRoleObject(noAccessRoles));
            });
          }
          localUpdatedFormValues.clientSelections.push(this.formClientObject(data, roleList));
          roleList = [];
        }
      });
    }

    if (this.manageUserService.editMode) {
      if (this.selectedClientWithNoAccess.length > 0) {
        this.selectedClientWithNoAccess.forEach(data => {
          this.userRoles.get(data.custId).get('roles').value.forEach(roleDetails => {
            roleList.push(this.formRoleObject(roleDetails));
          });
          localUpdatedFormValues.clientSelections.push(this.formClientObject(data, roleList));
          roleList = [];
        });
      }
    }

    delete localUpdatedFormValues.userRoles;
    delete localUpdatedFormValues.clientIds;
    delete localUpdatedFormValues.custIds;
    delete localUpdatedFormValues.businessUnit;


    if (!this.userService.editMode) {
      this.userService.createUser(localUpdatedFormValues).subscribe(success => {
        this.formMessage(localUpdatedFormValues, val);
        this.createUserSuccessResponse(success, val);
        this.checkDirtyForm = false;
      }, error => {
        const checkMsgCreate = error.error.errorResponse.errorMessage.toUpperCase();
        if(checkMsgCreate === this.getSharedConstant.showErrMsg.toUpperCase()){
          this.errorMessage(error);
          this.showErrorMsgPhone = true;
          } else {
          this.showErrorMsgEmail = true;
          }
      });
    } else {
      localUpdatedFormValues.userId = this.userService.getUserID;
      const resendInvite = this.user.status === 'Pending' && val === 'resend';
      localUpdatedFormValues.resendInvite = resendInvite;
      localUpdatedFormValues.action = 'Edit';
      this.userService.updateUser(localUpdatedFormValues).subscribe(success => {
        this.isUserTypeInt ? this.formMessage(localUpdatedFormValues, 'Update') : this.formMessage(localUpdatedFormValues, val);
        this.createUserSuccessResponse(success, '');
        this.checkDirtyForm = false;
        this.showErrorMsgPhone = false;
        this.showErrorMsgEmail = false;
      }, error => {
        const checkMsgUpdate = error.error.errorResponse.errorMessage.toUpperCase();
        if(checkMsgUpdate === this.getSharedConstant.showErrMsg.toUpperCase()){
        this.errorMessage(error);
        this.showErrorMsgPhone = true;
        } else {
        this.showErrorMsgEmail = true;
        }
      });
    }
  }

  formRoleObject(roleDetails) {
    const roleObj = {
      roleId: roleDetails.roleId,
      roleName: roleDetails.roleName,
      roleDesc: roleDetails.roleDesc,
    };
    return roleObj;
  }

  formRoleDropdownObject(role) {
    const obj = {
      label: role.roleDesc,
      value: {roleDesc: role.roleDesc, roleId: role.roleId, roleName: role.roleName},
      disabled: false
    };
    return obj;
  }

  formClientObject(data, roleList) {
    const obj = {
      custId: data.custId,
      clientId: data.clientId,
      businessUnit: data.businessUnit,
      orgId: data.orgId,
      roleInfoList: roleList
    };
    return obj;
  }

  errorMessage(error) {
    if (error.status !== 500) {
      this.handleMessage(error.error.errorResponse.errorMessage, 'error');
    }
  }

  formMessage(localUpdatedFormValues, data) {
    const userName = '<strong>' + localUpdatedFormValues.firstName + '&nbsp;' + localUpdatedFormValues.lastName + '</strong>&nbsp;';
    const sendInvite = this.getUsersConstant.manageUserPage.wasSuccuessfullyInvited;
    const sendModify = this.getUsersConstant.manageUserPage.wasSuccuessfullyModified;
    switch (true) {
      case (data === 'resend' && this.user.status === 'Pending' && !this.isUserTypeInt):
        this.landingPageMsg = userName + this.getUsersConstant.manageUserPage.resendSuccessfully;
        break;
      case (data === 'resend' && this.user.status === 'Active' && !this.isUserTypeInt):
        this.landingPageMsg = userName + this.getUsersConstant.manageUserPage.alreadyActive;
        break;
      case (data === 'resend' && this.user.status === 'Inactive' && !this.isUserTypeInt):
        this.landingPageMsg = userName + this.getUsersConstant.manageUserPage.resendSuccessfully;
        break;
      case (data === 'Update' && this.isUserTypeInt):
        this.landingPageMsg = userName + this.getUsersConstant.manageUserPage.wasSuccuessfullyModified;
        break;
      case (data === '' && this.isUserTypeInt):
        this.landingPageMsg = userName + this.getUsersConstant.manageUserPage.wasSuccuessfullyCreated;
        break;
      case ((data === 'sendInvite' || data === 'add') && !this.isUserTypeInt):
        this.sendInviteAddMoreSuccessMsgVisible = true;
        this.landingPageMsg = userName + sendInvite;
        break;
      case ((data === 'sendModify') && !this.isUserTypeInt):
        this.landingPageMsg = userName + sendModify;
        break;
      case (data === 'deactivate'):
        this.landingPageMsg = this.getUsersConstant.deactivate.userName + userName + this.getUsersConstant.deactivate.deactivated;
        break;
    }
    this.userService.sendMessage(this.landingPageMsg);
  }

  private handleMessage(obj, type) {
    this.messageService.clear();
    this.sharedService.clearErrorMessage();
    this.messageService.add({detail: obj, severity: type, sticky: true});
  }

  private createUserSuccessResponse(success, val) {
    this.messageService.add({detail: success.message, severity: 'success', sticky: true});
    if (val !== 'add') {
      this.messageService.clear();
      this.sharedService.clearErrorMessage();
      this.userService.addSuccessMsg(success);
      this.router.navigateByUrl('/ems/admin/manage-users');
    } else {
      this.userService.getMessage().subscribe((data) => {
        this.showToast = data ? data.text : '';
        this.userCreateForm();
        this.selectedCustId.forEach(custId => {
          this.userRoles.removeControl(custId);
        });
        const defaultCountryCode: any = {phoneCode: '+1', name: 'United States', value: '1'};
        defaultCountryCode.countryCode = defaultCountryCode.name + '\xa0\(' + defaultCountryCode.phoneCode + ')';
        this.createUserForm.patchValue({
          countryCode: defaultCountryCode,
          businessUnitOrAssignClient: this.getUsersConstant.manageUserPage.assignClientToUser
        });
        this.selectedCountryCode = '1';
        this.selectedClient = [];
        this.listOfClientSelected = [];
        this.selectedCustId = [];
        this.rolesDesc = [];
        this.rolesDropdownData = [];
        this.selectedClientId = [];
        this.assignClientsArr = [];
        this.createUserForm.get('userType').setValue('External');
      });
    }
  }

  ngOnDestroy() {
    if (this.destroySubject && this.destroySubject === 'add') {
      this.userService.clearMessage();
    }
    this.sharedService.isValueObject = false;

    if (this.sharedService.clientSummary.length > 1) {
      /* To enable the client dropdown in the header */
      this.sharedService.selectedClientInfo.next('');
      this.sharedService.emitClientDropdownDisableValue.emit(false);
    }
    if (this.router.routerState.snapshot.url.indexOf('manage-users') === -1) {
      this.sharedService.clearLocalStorage();
    }
  }

  canDeactivate(event, observer) {
    const confirmObj = {
      header: this.getUsersConstant.manageClients.clientDetailsPage.confirmation,
      message: this.getUsersConstant.manageUserPage.cancelMsgConfirmation
    };
    if (this.createUserForm.untouched && !this.landingPageMsg) {
      this.userService.clearMessage();
    }
    if (this.createUserForm.dirty && this.checkDirtyForm) {
      return this.confirmationMessage.showConfirmMsg(confirmObj, observer);
    }
    return true;
  }

  deactivateManagerUser() {
    const userName = this.createUserForm.get('firstName').value + ' ' + this.createUserForm.get('lastName').value;
    const confirmMsg = {
      header: this.getUsersConstant.manageClients.clientDetailsPage.confirmation,
      message: this.getUsersConstant.deactivate.deactivateMsgConfirmation + userName + this.getUsersConstant.deactivate.questionMark
    };
    Observable.create((observer: Observer<boolean>) => {
      this.confirmationMessage.showConfirmMsg(confirmMsg, observer);
    }).subscribe(accept => {
      if (accept) {
        const localValues = {
          userId: this.userService.getUserID,
          action: 'Inactive'
        };
        this.manageUserService.deactivateManageUser(localValues).subscribe((res) => {
          this.formMessage(this.createUserForm.value, 'deactivate');
          this.router.navigateByUrl('/ems/admin/manage-users');
        });
      }
    });
  }

  isInputMaskFilled(event) {
    this.isPhoneValid = this.phoneNumberService.validatePhoneNumber(event.target.value, this.selectedCountryCode);
    /* Setting the phoneNumber field as Error if entered values less than 10 digits */
    if (!this.isPhoneValid) {
      setTimeout(() => {
        const that = this;
        /* Manually setting the phoneNumber field in form as invalid */
        that.createUserForm.get('phoneNumber').setErrors({someError: true});
      }, 1);
    }
  }

  editUser() {
    this.userService.getUserById(this.userService.getUserID).subscribe(success => {
      this.getUserSuccessResponse(success);
    }, error => {
      this.handleMessage(error.error.errorResponse.errorMessage, 'error');
    });
  }

  private getUserSuccessResponse(success) {
    if (success.data !== null) {
      this.user = success.data;
      this.createUserForm.get('userType').patchValue(this.user.userType);
      this.createUserForm.get('businessUnitOrAssignClient').patchValue((this.user.clientSelections && this.user.clientSelections.length > 0) ? this.getUsersConstant.manageUserPage.assignClientToUser : this.getUsersConstant.manageUserPage.businessUnits);
      if ((this.user.businessUnits && this.user.businessUnits.length > 0) && ((this.userPolicy.data.userPolicy.clientPolicy && this.userPolicy.data.userPolicy.clientPolicy.length > 0) || this.userPolicy.data.userPolicy.clientPolicy !== null)) {
        this.clientAccess = true;
        this.clientAccessMsg = this.getUsersConstant.manageUserPage.errorMsg.clientAccess;
      }
      this.checkIfInternalUser(this.user.userType);
      if (this.user.businessUnits) {
        this.user.businessUnits.forEach(data => {
          if (this.userPolicy.data.userPolicy.buPolicy !== null && this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(data.name) === -1) {
            this.disableAssignClientRadioBtn = true;
          }
          this.selectedBusinessUnit.push(data.name);
          setTimeout(() => {
            this.populateRolesInDropdown(data.name, '');
          }, 500);
        });
      }
      this.formSelectedClientOrBUValues('');
      let retrieveCountryCode = {};
      let defaultCountryCode = {};
      this.getCountryConstant.countryCode.forEach(data => {
        if (this.user.phoneNumbers && this.user.phoneNumbers.length > 0 && data.phoneCode.replace('+', '') === this.user.phoneNumbers[0].countryCode) {
          retrieveCountryCode = data;
          this.selectedCountryCode = data.phoneCode.replace('+', '').split(' ')[0];
        }
        if (data.phoneCode === '+1') {
          defaultCountryCode = data;
        }
      });
      this.patchRolesForBUSelection();
      this.patchRolesForClientSelection();
      if (this.manageUserService.editMode) {
        if (this.selectedClientWithNoAccess.length > 0) {
          this.selectedClientWithNoAccess.forEach(data => {
            this.userRoles.addControl(data.custId, this.formBuilder.group({
              roles: ['', Validators.required]
            }));
            const roleInfo = [];
            data.roleInfoList.forEach(roleDetails => {
              const obj = {roleDesc: roleDetails.roleDesc, roleId: roleDetails.roleId, roleName: roleDetails.roleName};
              if (roleInfo.findIndex(x => x.roleId === obj.roleId) === -1) {
                roleInfo.push(obj);
              }
              this.userRoles.get(data.custId).get('roles').patchValue(roleInfo);
            });
          });
        }
      }

      /* Set the fetched value to the createUserForm */
      this.createUserForm.patchValue({
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        opaqueId: this.user.opaqueId,
        emailAddress: this.user.emailAddresses[0].emailAddress,
        phoneNumber: this.user.phoneNumbers.length > 0 ? this.user.phoneNumbers[0].phoneNumber : '',
        clientIds: this.user.clientIds ? this.user.clientIds : [],
        inviteInfo: this.user.inviteInfo,
        status: this.user.status,
        custIds: this.user.custIds ? this.user.custIds : [],
        countryCode: (this.user.phoneNumbers.length > 0 && this.user.phoneNumbers[0].countryCode) ? retrieveCountryCode : defaultCountryCode
      });

      if (this.myaccessModeEnabled && this.user.userType === this.getUsersConstant.manageUserPage.internal) {
        this.createUserForm.get('firstName').disable();
        this.createUserForm.get('lastName').disable();
        this.createUserForm.get('opaqueId').disable();
        this.createUserForm.get('emailAddress').disable();
        this.createUserForm.get('phoneNumber').disable();
        this.createUserForm.get('countryCode').disable();
      }

      this.isOpaqueIdInvalid = this.commonValidationService.validateOpaqueId(this.user.opaqueId);
      setTimeout(() => {
        if (success.data.clientsAggregateInfo && success.data.clientsAggregateInfo.length > 0) {
          this.selectedClient = [];
          this.selectedClientWithNoAccess = [];
          this.formSelectedClientOrBUValues('ClientBU');
          this.selectedClient.map(data => {
            data.clientNameSelected = true;
            this.assignClientsArr.push(data);
          });
        }
      }, 750);
      if (this.createUserForm.get('userType').value === 'External' || this.createUserForm.get('businessUnitOrAssignClient').value === this.getUsersConstant.manageUserPage.assignClientToUser) {
        this.createUserForm.get('clientNameDropdown').enable();
      }
      if (this.createUserForm.get('userType').value === 'External' && this.userService.editMode) {
        this.createUserForm.get('userName').disable();
        this.createUserForm.get('userName').setValue(this.user.userName);
      }
      if (this.user.userType === this.getUsersConstant.manageUserPage.external && this.user.status === 'Inactive') {
        this.isExternalUserDeActive = true;
        this.createUserForm.disable();
        this.createUserForm.get('phoneNumber').enable();
        this.createUserForm.get('emailAddress').enable();
      }
    } else {
      this.handleMessage(success.errorResponse && success.errorResponse.message || success.message, 'error');
    }
  }

  patchRolesForBUSelection() {
    if (this.selectedBusinessUnit.length > 0) {
      this.rolesWithNoAccessToUser = [];
      this.selectedBusinessUnit.forEach(bu => {
        this.user.businessUnits.forEach(data => {
          if (bu === data.name) {
            if ((bu === 'DBS' && !this.disableDBS) || (bu === 'SPS' && !this.disableSPS) || (bu === 'RBS' && !this.disableRBS)) {
              this.userRoles.addControl(bu, this.formBuilder.group({
                roles: ['', Validators.required]
              }));
            } else {
              this.userRoles.addControl(bu, this.formBuilder.group({
                roles: ['']
              }));
            }
            this.userRoles.updateValueAndValidity();
            const roleInfo = [];
            let actual = [];
            if (this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
              setTimeout(() => {
                actual = data.roleInfoList.filter(roles => this.rolesDropdownData[bu].some(list => list.value.roleId === roles.roleId));
                this.rolesWithNoAccessToUser[bu] = data.roleInfoList.filter(roles => !this.rolesDropdownData[bu].some(list => list.value.roleId === roles.roleId));
                actual.forEach(roleData => {
                  const obj = {roleDesc: roleData.roleDesc, roleId: roleData.roleId, roleName: roleData.roleName};
                  if (roleInfo.findIndex(x => x.roleId === obj.roleId) === -1) {
                    roleInfo.push(obj);
                  }
                  this.userRoles.get(bu).get('roles').patchValue(roleInfo);
                });
                if (this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(bu) === -1 || (this.userPolicy.data.userPolicy.buPolicy.some(val => val.businessUnit === bu && val.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') === -1))) {
                  this.rolesWithNoAccessToUser[bu].forEach(noAccess => {
                    const buObj = {roleDesc: noAccess.roleDesc, roleId: noAccess.roleId, roleName: noAccess.roleName};
                    if (roleInfo.findIndex(x => x.roleId === buObj.roleId) === -1) {
                      roleInfo.push(buObj);
                    }
                    this.userRoles.get(bu).get('roles').patchValue(roleInfo);
                  });
                }
              }, 750);
            }
          }
        });
      });
      this.createUserForm.get('businessUnit').patchValue(this.user.businessUnit);
    }
  }

  patchRolesForClientSelection() {
    if (this.selectedClient.length > 0) {
      this.rolesWithNoAccessToUser = [];
      this.selectedClient.sort((a, b) => parseFloat(a.custId) - parseFloat(b.custId));
      this.user.clientsAggregateInfo.sort((a, b) => parseFloat(a.custId) - parseFloat(b.custId));
      this.selectedClient.forEach((client, index) => {
        this.user.clientsAggregateInfo.forEach(data => {
          if (client.custId === data.custId) {
            this.userRoles.addControl(data.custId, this.formBuilder.group({
              roles: ['', Validators.required]
            }));
            const roleInfo = [];
            let actual = [];
            setTimeout(() => {
              /* eslint-disable-next-line max-len */
              actual = client.roleInfoList.filter(roles => this.rolesDropdownData && this.rolesDropdownData[index].some(list => list.value.roleId === roles.roleId));
              this.rolesWithNoAccessToUser[index] = client.roleInfoList.filter(roles => !this.rolesDropdownData[index].some(list => list.value.roleId === roles.roleId));
              actual.forEach(roleData => {
                const obj = {roleDesc: roleData.roleDesc, roleId: roleData.roleId, roleName: roleData.roleName};
                if (roleInfo.findIndex(x => x.roleId === obj.roleId) === -1) {
                  roleInfo.push(obj);
                }
                this.userRoles.get(client.custId).get('roles').patchValue(roleInfo);
              });
            }, 750);
          }
        });
      });
    }
  }

  formSelectedClientOrBUValues(type) {
    if (type === 'ClientBU') {
      if (this.user.clientSelections && this.user.clientsAggregateInfo && this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
        this.selectedClient = this.user.clientsAggregateInfo.filter(client => this.userPolicy.data.userPolicy.buPolicy.some(data => (client.businessUnit === data.businessUnit) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.selectedClientWithNoAccess = this.user.clientsAggregateInfo.filter(client => !this.userPolicy.data.userPolicy.buPolicy.some(data => (client.businessUnit === data.businessUnit) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.selectedCustId = this.selectedClient.map(custId => custId.custId);
        this.selectedClientId = this.selectedClient.map(clientId => clientId.clientId);
      }

      if (this.user.clientSelections && this.user.clientsAggregateInfo && (this.userPolicy.data.userPolicy.clientPolicy && this.userPolicy.data.userPolicy.clientPolicy.length > 0)) {
        this.selectedClient = this.user.clientsAggregateInfo.filter(client => this.userPolicy.data.userPolicy.clientPolicy.some(data => (client.clientId === data.clientId) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.selectedClientWithNoAccess = this.user.clientsAggregateInfo.filter(client => !this.userPolicy.data.userPolicy.clientPolicy.some(data => (client.clientId === data.clientId) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.selectedCustId = this.selectedClient.map(custId => custId.custId);
        this.selectedClientId = this.selectedClient.map(clientId => clientId.clientId);
      }
    } else {
      if (this.user.clientSelections && this.user.clientsAggregateInfo && this.userPolicy.data.userPolicy.buPolicy && this.userPolicy.data.userPolicy.buPolicy.length > 0) {
        this.selectedClient = this.user.clientSelections.filter(client => this.userPolicy.data.userPolicy.buPolicy.some(data => (client.businessUnit === data.businessUnit) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.selectedClientWithNoAccess = this.user.clientSelections.filter(client => !this.userPolicy.data.userPolicy.buPolicy.some(data => (client.businessUnit === data.businessUnit) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.listOfClientSelected = this.user.clientsAggregateInfo.filter(client => this.userPolicy.data.userPolicy.buPolicy.some(data => (client.businessUnit === data.businessUnit) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.listOfClientSelected.sort((a, b) => parseFloat(a.custId) - parseFloat(b.custId));
        this.listOfClientSelected.forEach(data => {
          setTimeout(() => {
            this.populateRolesInDropdown(data.businessUnit, data.applications);
          }, 300);
        });

        this.selectedClientWithNoAccess.forEach(client => {
          if (this.userPolicy.data.userPolicy.buPolicy.map(x => x.businessUnit).indexOf(client.businessUnit) === -1) {
            this.disableBURadioBtn = true;
          }
        });
      }

      if (this.user.clientSelections && this.user.clientsAggregateInfo && (this.userPolicy.data.userPolicy.clientPolicy && this.userPolicy.data.userPolicy.clientPolicy.length > 0)) {
        this.selectedClient = this.user.clientSelections.filter(client => this.userPolicy.data.userPolicy.clientPolicy.some(data => (client.clientId === data.clientId) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.selectedClientWithNoAccess = this.user.clientSelections.filter(client => !this.userPolicy.data.userPolicy.clientPolicy.some(data => (client.clientId === data.clientId) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.listOfClientSelected = this.user.clientsAggregateInfo.filter(client => this.userPolicy.data.userPolicy.clientPolicy.some(data => (client.clientId === data.clientId) && (data.entitlementList.map(y => y.entitlementName).indexOf('MANAGE_USERS') !== -1)));
        this.listOfClientSelected.sort((a, b) => parseFloat(a.custId) - parseFloat(b.custId));
        this.listOfClientSelected.forEach(clientSelected => {
          setTimeout(() => {
            this.populateRolesInDropdown(clientSelected.clientId, clientSelected.applications);
          }, 500);
        });
      }
    }
  }

  validateEmailAddress(event) {
    this.isEmailIdInvalid = this.commonValidationService.validateEmailAddress(event.target.value);
  }

  validateOpaqueId(event) {
    this.isOpaqueIdInvalid = this.commonValidationService.validateOpaqueId(event.target.value);
  }

  ngAfterViewChecked(): void {
    if (this.sendInviteAddMoreSuccessMsgVisible) {
      this.createUserForm.valueChanges.subscribe(res => {
        if (res) {
          this.messageService.clear();
        }
      });
    }
    if (this.userRoles.value) {
      if (this.selectedBusinessUnit.length > 0) {
        this.selectedBusinessUnit.forEach((bu, index) => {
          const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[index];
          if (this.rolesDropdownData && this.rolesDropdownData[bu] && this.rolesDropdownData[bu].length > 0) {
            this.sharedService.setMultiSelectDefaultLabel(elementRef, this.userRoles.get(bu), 'roles', this.rolesDropdownData[bu].length);
          } else {
            elementRef.innerText = '';
          }
          this.userRoles.get(bu).get('roles').valueChanges.subscribe(data => {
            if (data) {
              this.sharedService.setMultiSelectDefaultLabel(elementRef, this.userRoles.get(bu), 'roles', this.rolesDropdownData[bu].length);
              /* If the selected BU is RBS then call disableRoleInDropdown method to disable/enable RBS RA/RBS RAView role depending on user selection */
              if (bu === this.getSharedConstant.rbsBUText) {
                this.disableRoleInDropdown(data, bu);
              }
            }
          });
        });
      } else {
        if (this.listOfClientSelected.length > 0) {
          this.listOfClientSelected.forEach((client, index) => {
            /* eslint-disable-next-line max-len */
            const buOrClient = index;
            const elementRef = this.el.nativeElement.querySelectorAll('.p-multiselect-label')[index];
            if (this.rolesDropdownData && this.rolesDropdownData[buOrClient] && this.rolesDropdownData[buOrClient].length > 0) {
              this.sharedService.setMultiSelectDefaultLabel(elementRef, this.userRoles.get(client.custId), 'roles', this.rolesDropdownData[buOrClient].length);
            }
            this.userRoles.get(client.custId).get('roles').valueChanges.subscribe(data => {
              if (data) {
                this.sharedService.setMultiSelectDefaultLabel(elementRef, this.userRoles.get(client.custId), 'roles', this.rolesDropdownData && this.rolesDropdownData[buOrClient].length);
                /* For Internal User if the selected client is RBS client then call disableRoleInDropdown method to disable/enable RBS RA/RBS RAView role depending on user selection */
                if (this.createUserForm.get('userType').value !== this.getUsersConstant.manageUserPage.external && client.businessUnit === this.getSharedConstant.rbsBUText) {
                  this.disableRoleInDropdown(data, buOrClient);
                }
              }
            });
          });
        }
      }
    }
  }

  /* Disable/Enable RBS RA/RBS RA View role depending on user selection in RBS role dropdown */
  disableRoleInDropdown(data, buOrClient) {
    /* Initially set the setMode to Both -> to enable both RBS RA and RBS RA View */
    let setMode = 'both';
    data.forEach(val => {
      /* If user selects RBS RA View from the dropdown then set the setMode to RBS RA -> to disable RBS RA */
      if (val.roleDesc === this.getSharedConstant.rbsRuleAgentViewText) {
        setMode = this.getSharedConstant.rbsRuleAgentText;
      } else if (val.roleDesc === this.getSharedConstant.rbsRuleAgentText) {
        /* If user selects RBS RA from the dropdown then set the setMode to RBS RA View -> to disable RBS RA View */
        setMode = this.getSharedConstant.rbsRuleAgentViewText;
      }
    });
    this.rolesDropdownData[buOrClient].forEach(rbsRoles => {
      if (setMode === 'both') {
        /* If setMode is Both -> Make disabled as false for both RBS RA and RBS RA view */
        if (rbsRoles.label === this.getSharedConstant.rbsRuleAgentText || rbsRoles.label === this.getSharedConstant.rbsRuleAgentViewText) {
          rbsRoles.disabled = false;
        }
      } else if (rbsRoles.label === setMode) {
        /* If setMode is RBS RA/RBS RA View -> Make disabled as true depending on user selection */
        rbsRoles.disabled = true;
      }
    });
  }
}
