import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-workbench-panel-header',
  templateUrl: './workbench-panel-header.component.html',
  styleUrls: ['./workbench-panel-header.component.scss']
})
export class WorkbenchPanelHeaderComponent implements OnInit {
  @Input() lastUpdatedTxt;
  @Input() headerTxt;
  @Input() lastUpdatedTime;
  @Output() refreshState = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  refreshSection() {
    this.refreshState.emit();
  }

}
