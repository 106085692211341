import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AddlinkService} from '../../addlink.service';
import {AddlinkConstant} from '../../constants/addlinkConstants';
import {BulkUploadComponent, SharedService} from '@ems/shared';
import {Router, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'addlink-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UploadComponent implements OnInit, OnDestroy {

  getAddLinkConstant = AddlinkConstant;

  public addLinkConstant: any;
  public fileTemplate: string;
  public library: string;
  public navigateTo: string;

  public nameEntered: string;
  public orgId: any;
  public uploadFilePoint: any;
  public isFileUploaded = false;
  public isBulkUploadFormDirty = false;
  public showError = false;
  public confirmMessage: any;
  public templateName: string;
  public currentOrgSubscription: Subscription;
  public clientId: string;
  public linkType: any;
  public businessUnit: any;
  public masterAccountOptions: any[];
  downloadTemplatDT = 'adobe-dl_addlinkupload_template|fireonce';
  chooseFileDT = 'adobe-lc_addlinkupload_choosefile|fireonce';
  uploadBtnGutTack = 'addlinkupload_upload';
  cancelBtnGutTack = 'addlinkupload_cancel';

  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(BulkUploadComponent, { static: true }) bulkUploadComponent: BulkUploadComponent;
  validFile: boolean;

  constructor(private addlinkService: AddlinkService, private router: Router, private activatedRoute: ActivatedRoute,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.addLinkConstant = AddlinkConstant;
    this.templateName = this.addLinkConstant.bulkUpload.addlinkTemplate;

    // this.library = 'addlinkRequest';
    this.library = 'ADDLINK';
    this.navigateTo = this.sharedService.parentPage ? this.sharedService.parentPage : '../request-list';
    this.nameEntered = '';

    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.orgId = clientInfo.orgId;
        this.clientId = clientInfo.clientId;
        this.linkType = clientInfo.linkType;
        this.businessUnit = clientInfo.businessUnit;
      }
      const postURL = '/addlink?name=AddlinkUpload';

      if (this.orgId === 0) {
        this.uploadFilePoint = postURL;
      } else {
        this.uploadFilePoint = {clientId : this.clientId, name: 'AddlinkUpload', url: '/addlink'};
      }
      this.fileTemplate = this.businessUnit === 'DBS' ? (this.sharedService.isInternalApp() ? 'ADDLINKDBSINT' : 'ADDLINKDBSEXT') : 'ADDLINKSPS';
    });
    this.confirmMessage = this.confirmationMessage.confirmMessage;

    this.sharedService.specificClientView.next(true);
  }

  getEmittedFileVal(data) {
    this.showError = data.showError;
    this.isFileUploaded = data.isFileUploaded;
    if (this.isFileUploaded || this.isBulkUploadFormDirty) {
      this.sharedService.isConfirmationRequiredClientChange = true;
    } else {
      this.sharedService.isConfirmationRequiredClientChange = false;
    }
    this.validFile = data.validFile;
  }

  hasFormChanged(event) {
    this.isBulkUploadFormDirty = event;
    if (this.isFileUploaded || this.isBulkUploadFormDirty) {
      this.sharedService.isConfirmationRequiredClientChange = true;
    } else {
      this.sharedService.isConfirmationRequiredClientChange = false;
    }
  }

  uploadFile() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue(this.uploadBtnGutTack);
    this.isFileUploaded = false;
    this.isBulkUploadFormDirty = false;
    this.bulkUploadComponent.uploadFile();
  }

  cancelFileUpload() {
    /* Set isDTBtnClicked value to true, generate the data track value with 'linkid-lc' . */
    this.sharedService.isDTBtnClicked = true;
    this.sharedService.loadDataTrackValue(this.cancelBtnGutTack);
    this.confirmMessage.message = this.getAddLinkConstant.confirmationMessage.cancelBtnClickMsg;
    this.sharedService.parentPage = '';
    this.router.navigate([this.navigateTo], { relativeTo: this.activatedRoute });
  }

  canDeactivate(event, observer) {
    if (this.businessUnit === 'RBS') {
      this.isFileUploaded = false;
      this.isBulkUploadFormDirty = false;
    }
    if (this.confirmMessage) {
      this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.getAddLinkConstant.confirmationMessage.menuClickMsg;
    }
    if (this.isFileUploaded || this.isBulkUploadFormDirty) {
      this.sharedService.isLoadPage = true;
      this.confirmationMessage.cancelBtnRejectGutTack = this.cancelBtnGutTack;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    }
    return true;
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    this.sharedService.isConfirmationRequiredClientChange = false;
    this.sharedService.specificClientView.next(false);
  }
}
