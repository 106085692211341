import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonService} from '../../../services/common.service';
import {AddlinkService} from '../../../../addlink.service';
import {SharedService} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {AddlinkConstant} from '../../../../constants/addlinkConstants';
import {RequestListService} from '../../../services/request-list.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'addlink-remarks-modal',
  templateUrl: './remarks-modal.component.html',
  styleUrls: ['./remarks-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RemarksModalComponent implements OnInit {
  public remarksFormGroup: UntypedFormGroup;
  public title: string;
  public requestId: string;
  public accountNumber: string;
  public remarksList: any = {};
  public status: string;
  public roles: any[] = [];
  public usertype: string;
  isHidden: boolean;
  showActionItems: boolean;
  showRemarksCharLimit: boolean;
  getConstant = AddlinkConstant;
  isQaReviewedFlag: string;
  clientInformationSubscribe: Subscription;
  clientsInfo: any;

  constructor(private commonService: CommonService, private addlinkService: AddlinkService, @Inject('commonConstants') public commonConstants,
              @Inject('entitlementConst') public entitlementConst, public sharedService: SharedService, private fb: UntypedFormBuilder,
              private requestListService: RequestListService) {
  }

  /* Initialize component variables */
  ngOnInit() {
    this.title = 'Remarks';
    this.addlinkService.userType.subscribe(result => {
      this.usertype = result;
    });

    this.addlinkService.userPolicy.subscribe(result => {
      const value = result.getValue();
      this.roles = value.roles;
      if (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.addLinkAccess.uuid, '', '') && this.usertype === 'External') {
        this.isHidden = true;
      }
    });

    this.remarksFormGroup = this.fb.group({
      remarksEnterComments: [''],
      hideFromClient: false,
      qaReviewed : false
    });

    this.remarksFormGroup.get('remarksEnterComments').valueChanges.subscribe(value => {
      if (!value) {
        this.remarksFormGroup.get('hideFromClient').reset();
      }
    });

    this.clientInformationSubscribe = this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.commonService.getOrgId = clientInfo.orgId;
      }
    });
  }

  /* Opens The Modal */
  public openModal(e: Event, requestId: string, accountNumber: string, status: string, showActionItems: boolean): void {
    this.commonService.getRemarks(requestId).subscribe(result => {
      this.remarksList = result.data.remarkList.reverse();
      this.requestId = requestId;
      this.accountNumber = accountNumber;
      this.showActionItems = showActionItems;
      this.status = status;
      this.isQaReviewedFlag = result.data.qaReviewed;
      if (this.isQaReviewedFlag === 'Y') {
        this.remarksFormGroup.get('qaReviewed').setValue(true);
      }
      if (this.status === 'Complete') {
        this.remarksFormGroup.get('remarksEnterComments').disable();
      } else {
        this.remarksFormGroup.get('remarksEnterComments').enable();
      }
      e.stopPropagation();
      this.sharedService.openModal('beta-remarks-modal');
    });
  }

  /* Reset The Form data */
  public resetForm(): void {
    this.remarksFormGroup.reset();
  }

  public isHiddenFromClient(index: number): boolean {
    if (this.remarksList[index].hideFromClient.toLowerCase() === 'y') {
      return true;
    }
    return false;
  }

  public saveComments() {
    const getLocalFormValue = this.remarksFormGroup.getRawValue();
    const requestObj = {
      hideFromClient: getLocalFormValue.hideFromClient ? getLocalFormValue.hideFromClient : false,
      remarksAsText: getLocalFormValue.remarksEnterComments,
      requestId: this.requestId,
      qaReviewed : getLocalFormValue.qaReviewed ? 'Y' : 'N',
      orgId:  this.commonService.getOrgId === 0 ? null :  this.commonService.getOrgId
    };
    this.requestListService.saveCommentsRequests(requestObj).subscribe((data) => {
    });
    this.resetForm();
    this.sharedService.closeModal('beta-remarks-modal');
  }
}
