<sdps-modal id='beta-deny-request-modal'  [sdpsTitle]="title" [size]="'flex--small'">
  <div slot="modal-body">
    <p *ngIf="title === addLinkConstant.denyRequestModalLabel" class="adl-modal__heading"><strong>{{addLinkConstant.requestWillBeCancelled}}</strong></p>
    <p *ngIf="title === addLinkConstant.resolveRequestModalLabel" class="adl-modal__heading"><strong>{{addLinkConstant.requestWillBeResolved}}</strong></p>

    <div *ngIf="requestIds && requestIds.length > 0" class="adl-modal__body">
      <div *ngFor="let requestId of requestIds">#{{requestId}}</div>
    </div>

    <p *ngIf="requestDenied > 0">{{addLinkConstant.requestsAlreadyInRequestDeniedStatusMsg}}</p>
  </div>

  <div slot="modal-footer">
    <button class="buttons" type="reset" (click)="sharedService.closeModal('beta-deny-request-modal')">
      {{addLinkConstant.requestListFilterButtons.cancelButton}}</button>
    <button type="button" class="buttons button-save" [disabled]="requestIds && this.requestIds.toString() === ''"
              (click)="takeRequestAction()">{{addLinkConstant.confirmBtnLabel}}</button>
  </div>
</sdps-modal>
