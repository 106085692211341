<sdps-notification type="information" class="sdps-m-top_none" *ngIf="!orgId">
  <p class="sdps-notification__text" [innerHTML]="getConstant.rulesConstant.ruleGlobalConst.noCientSelected"></p>
</sdps-notification>
<ems-confirm-message #confirmationMessage></ems-confirm-message>
<ems-bulk-upload [lib]="library" [downloadTemplate]="fileTemplate" [uploadFileApi]="uploadFilePoint"
                 [downloadTemplateLabel]="downloadTemplateLabel" [parentPage]="navigateTo"
                 [createdName]="nameEntered" [groupDesc]="this.getStep1Details.groupDesc" *ngIf="orgId" (FileUploadEmit)="getEmittedFileVal($event)"
                 [bulkUploadTitles]="bulkUploadTitleName" [downloadTemplateGutTack]="downloadTempGutTrackValue"
                 [chooseFileGutTack]="chooseFileGutTrackValue" [uploadBtnGutTack]="uploadBtnGutTack"></ems-bulk-upload>
<div *ngIf="orgId" class="sdps-text-right sdps-m-top_x-large sdps-m-bottom_x-large">
  <button type="reset" class="buttons" (click)="cancelFileUpload()"
          [innerHTML]="getConstant.rulesConstant.ruleGlobalConst.cancel"></button>
  <button type="button" class="buttons button-save"
          [disabled]="isFileUploaded ? isFileUploaded && showError : !isFileUploaded"
          (click)="uploadFile();"
          [innerHTML]="getConstant.bulkUpload.uploadTxt"></button>
</div>
