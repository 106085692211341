import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html'
})
export class HealthCheckComponent implements OnInit {

  constructor() { }

  healthMsg = 'Application is up and running!!!';

  ngOnInit() {
  }

}
