import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {emtrConstant} from '../../constants/emtrConstants';

@Component({
    selector: 'tr-timepicker',
    templateUrl: './emtr-timepicker.component.html',
    styleUrls: ['./emtr-timepicker.component.scss']
})
export class EmtrTimepickerComponent implements OnInit {
    @Input() timeForm: UntypedFormGroup = this.fb.group({
        timeMeridian: ['', Validators.required],
    });
    @Input() timeLabel = 'Time';
    @Input() timeMeridian: any;
    @Input() showErr: any;

    public timeData: any;
    public timeMeridianData: any;
    getConstant = emtrConstant;
    constructor(private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.timeData = this.getConstant.rulesConstant.timePicker.map( time => ({value : time, label: time}));
    }

}
