import {Injectable} from '@angular/core';
import {WorkflowService} from '@ems/shared';
import {Router} from '@angular/router';
import {EmpGroupFormDataModel, empGroupFormDataModel, EmpGrpStep1} from './emp-group-form-data.model';

@Injectable({
  providedIn: 'root'
})
export class EmpGroupFormDataService {

  public formData = empGroupFormDataModel;

  constructor(private workflowService: WorkflowService, private router: Router) {
  }

  setStep1(data: any, valid: boolean) {
    /* Update the the emp group Step 1 data only when the the emp group Step 1 Form had been validated successfully */
    this.formData.name = data.groupName;
    this.formData.orgId = data.orgId;
    this.formData.description = data.description;
    this.formData.employeeGroupAssignInfo.orgId = data.orgId;
    this.formData.planId = data.planId;
    /* Validate Personal Step in Workflow */
    this.workflowService.validateStep('step1', 'manageEmpGrp', valid);
  }

  getEmpGrpStep1(): EmpGrpStep1 {
    const step1: EmpGrpStep1 = {
      name: this.formData.name,
      groupDesc: this.formData.description
    };
    return step1;
  }

  setId(id: any) {
    this.formData.id = id;
  }

  setStep2(data: any, valid: boolean) {
    /* Update the the emp group Step 2 data only when the the emp group Step 2 Form had been validated successfully */
    this.formData.employeeGroupAssignInfo.employeeAccounts = data.employeeAccounts;
    this.formData.employeeGroupAssignInfo.empIds = data.empIds;
    this.formData.employeeGroupAssignInfo.subAccntNumbers = data.subAccntNumbers;
    this.formData.employeeGroupAssignInfo.newSelectedRecords = data.newSelectedRecords;
    this.formData.employeeGroupAssignInfo.removedExistingSelected = data.removedExistingSelected;
    this.formData.employeeGroupAssignInfo.assignEmpIds = data.assignEmpIds;
    this.formData.employeeGroupAssignInfo.assignSubAcctNumbers = data.assignSubAcctNumbers;
    this.formData.employeeGroupAssignInfo.unAssignEmpIds = data.unAssignEmpIds;
    this.formData.employeeGroupAssignInfo.unAssignSubAcctNumbers = data.unAssignSubAcctNumbers;
    this.formData.employeeGroupAssignInfo.subAccntNewlySelected = data.subAccntNewlySelected;
    this.formData.employeeGroupAssignInfo.removedExistingEmpId = data.removedExistingEmpId;

    /* Validate Personal Step in Workflow */
    this.workflowService.validateStep('step2', 'manageEmpGrp', valid);
  }

  getFormData(): EmpGroupFormDataModel {
    /* Return the entire Form Data */
    return this.formData;
  }

  resetForm() {
    this.formData.clear();
    this.workflowService.resetSteps('manageEmpGrp');
  }

  getLoginInfo() {
    return JSON.parse(sessionStorage.getItem('ngx_login_session'));
  }

  setFormData(formData) {
    this.formData.setData(formData);
  }

  setMode(mode) {
    this.formData.mode = mode;
  }

  validateAllSteps() {
    this.workflowService.validateAllSteps('manageEmpGrp');
  }

  setStep3(data: any, valid: boolean) {
    /*Validate Personal Step in Workflow*/
    this.workflowService.validateStep('step3', 'manageEmpGrp', valid);
  }
}
