import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedConstant, SharedService} from '@ems/shared';
import {AddlinkService} from './addlink.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'addlink-addlink',
  templateUrl: './addlink.component.html',
  styles: []
})
export class AddlinkComponent implements OnInit, OnDestroy {

  envProp: any;
  addLinkSubMenu: any;
  policy: any;
  clientInfo: any;
  sharedConstant = SharedConstant;
  hasAccountSummaryAcc = true;
  public clientInfoSubscribe: Subscription;
  charitableClient = false;
  orgId = 0;

  constructor(private route: ActivatedRoute, public addlinkService: AddlinkService, public sharedService: SharedService,
              @Inject('entitlementConst') public entitlementConst, public router: Router) {
    this.route.data.subscribe(data => {
      this.sharedService.clearErrorMessage();
      this.envProp = data.envProp;
      this.addlinkService.setEnvironment(this.envProp);
      console.log(':::::::::::::::::::::::::  ENV PROP FROM SHELL::::::::::: AddlinkComponent :::::::::');
      console.log(this.envProp);
    });

    this.addlinkService.userPolicy.subscribe((policy) => {
      console.log('Current userPolicy in ADDLINK: ' + policy);
      this.policy = policy;
    });
  }

  ngOnInit() {
    /* Navigate to respective page based on the selected client access */
    this.clientInfoSubscribe = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.orgId = clientInfo.orgId;
        this.charitableClient = clientInfo.charitable && clientInfo.orgId !== 0 ? true : false;
        this.clientInfo = clientInfo;
      }

    this.hasAccountSummaryAcc = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.accountSummary.uuid, '', '');
      this.setNavigation();
    });

    this.addlinkService.userPolicy.subscribe(userPolicy => {
      this.setNavigation();
    });
  }

  setNavigation() {
    const getUUID = this.entitlementConst.navUUID;
    this.addLinkSubMenu = [
      {
        id: 1,
        name: 'Request List',
        route: 'request-list',
        accessName: 'addLink',
        showLink: this.sharedService.checkEntitlements(getUUID.accountSummary.uuid, '', '')
      },
      {
        id: 2,
        name: 'Link',
        route: 'link',
        accessName: 'accountLinking',
        showLink: this.sharedService.checkEntitlements(getUUID.accountLinking.uuid, '', '')
      },
      {
        id: 3,
        name: 'Delink',
        route: 'delink',
        accessName: 'accountLinking',
        showLink: this.sharedService.checkEntitlements(getUUID.accountLinking.uuid, '', '')
      },
      {
        id: 4,
        name: 'ADDLink Upload',
        route: 'addlink-upload',
        accessName: 'accountLinking',
        showLink: this.sharedService.checkEntitlements(getUUID.accountLinking.uuid, '', '')
      }
    ];

    /* Setting charitableClient flag to false when client slected from dropdown is 'All' so that tabs doesn't get disabled */
    if (this.orgId === 0 && this.clientInfo) {
      this.charitableClient = false;
    } else if (this.orgId !== 0 && this.clientInfo) {
      this.charitableClient = this.clientInfo.charitable;
    }
    /* When client is selected and the selected client does not have access to ACCOUNT_LINKING */
    if ((this.clientInfo && !this.sharedService.checkEntitlements(getUUID.accountLinking.uuid, '', '')) || this.charitableClient) {
      if (this.hasAccountSummaryAcc) {
        this.sharedService.loadDataTrackValue('client_redirect');
        this.router.navigate(['ems/addLink/request-list']);
      } else if (!this.sharedService.checkEntitlements(getUUID.emtrAccess.ruleSummary.uuid, getUUID.accountSummary.uuid, getUUID.emtrAccess.instrumentsSummary.uuid)) {
        this.sharedService.loadDataTrackValue('client_redirect');
        /* If client is selected and the selected client does not have access to Rule Agent and Auth Agent access, then navigate to Admin menu */
        this.router.navigate(['ems/admin/manage-clients']);
      } else if (this.sharedService.checkEntitlements(getUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
        this.sharedService.loadDataTrackValue('client_redirect');
        this.router.navigate(['/ems/emtr/instrument-groups/instrument-group-list']);
      } else if (!this.hasAccountSummaryAcc) {
        this.sharedService.loadDataTrackValue('client_redirect');
        /* If client is selected and the selected client does not have access to Auth Agent access, then navigate to Workbench */
        this.router.navigate(['ems/workbench']);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.clientInfoSubscribe) {
      this.clientInfoSubscribe.unsubscribe();
    }
  }
}
