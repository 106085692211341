import {Component, OnInit, ViewChild, OnDestroy, Inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import * as temp from 'moment';
const moment = temp['default'];
import {SessionStorage, SessionStorageService} from 'ngx-store';
import {DatePipe} from '@angular/common';
import {Observable} from 'rxjs';
import {Observer} from 'rxjs';
import {emtrConstant} from '../../../constants/emtrConstants';
import {FormDataService, SharedService, SharedConstant, empGrpOrPlanTableHeader} from '@ems/shared';
import {RuleService} from '../../../services/rule-service/rule.service';
import {ConfirmMessageComponent, masterGroupHeaderTableColoumn} from '@ems/shared';
import {AllSecurityRecords} from '../../../models/custom-multiselect.model';
import {empAccntOrParticipantTableHeader} from '../../../models/magage-emp-grp-acc-table-header';
import { DefaultSecurityGridHeader } from '../../../models/search-security-modal.model';
import {FundFamilyTableHead, searchFundFamilyTableHead} from '../../../models/search-fund-Family-table-header.model';
import { EmtrService } from '../../../emtr.service';
import {Paginator} from 'primeng/paginator';
import {RulesSelectInstrumentGroupTableHeader} from '../../../models/instrument-group-header';
import { SecurityTypeComponent } from '../../security-type/security-type.component';

@Component({
  selector: 'tr-rules-step4-review-rule',
  templateUrl: './rules-step4.component.html',
  styleUrls: ['./rules-step4.component.scss']
})

export class RulesStep4Component implements OnInit, OnDestroy {

  @ViewChild(ConfirmMessageComponent, { static: true }) child: ConfirmMessageComponent;
  @ViewChild('reviewSecurity', { static: true }) childSecurityType: SecurityTypeComponent;
  @ViewChild('p') paginator: Paginator;

  reviewRuleForm: UntypedFormGroup = this.fb.group({
    reviewRuleName: ['', {validators: [Validators.required]}],
    restrictForm: this.fb.group({
      restrictBuys: '',
      restrictSells: '',
      restrictShortSells: ''
    }),
    calendarForm: this.fb.group({
      dateTo: this.fb.group({
        date: [''],
        time: [''],
        timeMeridian: ['']
      }),
      dateFrom: this.fb.group({
        date: [''],
        time: [''],
        timeMeridian: ['']
      }),
      datepickerDisabled: false
    }),
    descriptionForm: this.fb.group({
      description: '',
      notes: '',
      changeRequestId: ''
    }),
    indSecs: '',
    underlyingOpts: '',
    justification: ['', Validators.required],
    otherInput: ''
  });
  toolTipMessage: any;
  indSecs: any;
  instGroup: any;
  employee: any;
  employeeGroups: any;
  fundFamilyTableHeadCols: FundFamilyTableHead[] = searchFundFamilyTableHead;
  @SessionStorage({key: 'ruleData'}) sessionRuleData: any;
  securityTableHeader: any = [];
  instGrpTableHeader: any = [];
  employeeGroupTableHeader: any = empGrpOrPlanTableHeader;
  masterGroupTableHeader: any = masterGroupHeaderTableColoumn;
  employeeAccTableHeader: any = empAccntOrParticipantTableHeader;
  reviewRuleData: any;
  appliesTo = '';
  securityType: any[] = [];
  ruleType: string;
  noOfRecordsIndSecs: any = 15;
  noOfRecordsInstGroup: any = 15;
  noOfRecordsEmpGroup: any = 15;
  noOfRecordsEmp: any = 15;
  noOfRecordsClient: any = 15;
  revertDisable = true;
  isFieldEditted = false;
  dateSelectionType = 'date';
  emtrConst = emtrConstant;
  sharedConstant = SharedConstant;
  targetElem: any;
  isClicked = true;
  localObserver: any;
  showContinueBtn = true;
  currentParentNode: any;
  verifyData: any;
  cols: any;
  rulesCategory: any;
  justification: any;
  otherInput: any;
  override = '';
  overrideTooltip: any;
  tableCountData: any = {
    empCount: 0, empSelected: 0,
    subAccCount: 0, subAccSelected: 0
  };
  message: string;
  isPlanIdVisible = false;
  multiLevelSecurityType: any = {};
  multiLevelUpdatedList: any;
  subTypeLabel: string;
  warningMessage: any;
  informationMessage: any;
  landingPageMsg: any;
  cancelButton = false;
  disableEmployeeParticipant = false;
  disableEmployeeGroupPlan = false;
  activeIndex = 0;
  confirmMessage: any;
  moment = moment;
  showCharLimit: boolean;
  errorMsg = [];
  is10b5Client = false;
  totalRecords: any;
  pageObject = {
    pageSize: 15,
    pageNo: 0,
    sortField: ['empId,desc']
  };
  businessUnit: string;
  showActionItems = false;
  disableBtn = false;
  disableEditIcon = false;
  assetGroups = [];
  formObjCompare = false;
  oldFormObj: any;
  showActionItemForGRule = false;
  displaySelectedAssetGrp = [];
  viewIG: any;
  securityData = [];

  constructor(private datePipe: DatePipe, private fb: UntypedFormBuilder, private router: Router, private r: ActivatedRoute,
              public formDataService: FormDataService,
              public ruleService: RuleService, private sessionStorageService: SessionStorageService,
              @Inject('commonConstants') public commonConstants,
              public sharedService: SharedService, public emtrService: EmtrService, @Inject('entitlementConst') public entitlementConst) {
    this.reviewRuleData = this.formDataService.getFormData();
    this.reviewRuleForm.valueChanges.subscribe(data => {
      if (this.reviewRuleData.status === 'ACTIVE' || this.reviewRuleData.status === 'SCHEDULED') {
        if (data && this.reviewRuleForm.dirty && JSON.stringify(this.oldFormObj) !== JSON.stringify(this.reviewRuleForm.getRawValue())) {
          this.formObjCompare = false;
        } else {
          this.formObjCompare = true;
          this.revertDisable = true;
        }
      } else {
        this.formObjCompare = false;
      }
    });
    this.sharedService.groupType = this.reviewRuleData.globalRule ? this.sharedConstant.manageMasterGroups.groupTypeMGP : '';
  }

  ngOnInit() {
    this.sharedService.clientInformation.subscribe(clientInfo => {
      this.businessUnit = this.reviewRuleData.globalRule ? this.sharedConstant.manageMasterGroups.groupTypeMGP : clientInfo.businessUnit;
      this.showActionItems = this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageRule.uuid, '', '') && !this.ruleService.deletedRuleInfo;
      this.showActionItemForGRule = this.reviewRuleData.globalRule ? (this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.manageGlobalRule.uuid, '', '') && !this.ruleService.deletedRuleInfo) : true;
    });
    this.viewIG = (this.reviewRuleData.mode === 'EDIT') && this.businessUnit === 'DBS' && (this.reviewRuleData.instrumentGroups && this.reviewRuleData.instrumentGroups.length > 0);
    const header = this.reviewRuleData.globalRule ? this.masterGroupTableHeader : empGrpOrPlanTableHeader[this.businessUnit];
    this.employeeGroupTableHeader = this.sharedService.removeActionsForRules('rule-Step4', header);
    this.cols = this.employeeAccTableHeader[this.businessUnit];

    /* If ruleType is 'fundFamily' assigning fundFamily Columns */
    this.securityTableHeader = (this.reviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? this.fundFamilyTableHeadCols : DefaultSecurityGridHeader;
    this.instGrpTableHeader = RulesSelectInstrumentGroupTableHeader;
    const that = this;
    setTimeout(() => {
      that.reviewRuleForm.controls.calendarForm.get('dateTo').disable({onlySelf: true});
      /* eslint-disable @typescript-eslint/dot-notation */
      that.reviewRuleForm.controls.calendarForm['controls'].dateTo.controls['time'].disable({onlySelf: true});
      /* eslint-disable @typescript-eslint/dot-notation */
      that.reviewRuleForm.controls.calendarForm['controls'].dateTo.controls['date'].disable({onlySelf: true});
    }, 10);
    this.toolTipMessage = this.emtrConst.ruleSteps.step4.descTooltip;
    this.overrideTooltip = this.emtrConst.ruleSteps.step4.overrideTooltip;

    if (this.reviewRuleData.isEmpGrpModified !== '') {
      this.fetchEmpGrpRecords();
    }
    this.populateSelectedEmployeeData();

    if (this.reviewRuleData.mode !== 'EDIT' && this.reviewRuleData.mode !== 'COPY') {
      this.updateSecurityValues(this.reviewRuleData.chooseSecurityType);
    }
    this.populateData();
    if (this.reviewRuleData.mode === 'EDIT' && this.reviewRuleData.override === 'Y' && this.reviewRuleData.ruleSubCategory === this.emtrConst.rule10b5Msg.label10b5Txt) {
      this.isPlanIdVisible = false;
    } else if (this.reviewRuleData.rulesCategory === 'RESTRICT_LST_ENG' && this.reviewRuleData.ruleSubCategory === this.emtrConst.rule10b5Msg.label10b5Txt) {
      this.isPlanIdVisible = true;
    } else {
      this.isPlanIdVisible = false;
    }

    /* If the selected client is RBS client and the rule created/edited/copied is of Restriction and Security type only then sort the asset group data */
    /* eslint-disable-next-line max-len */
    if (this.reviewRuleData.assetGroups && this.reviewRuleData.assetGroups.length > 0 && this.reviewRuleData.rulesCategory !== 'WATCH_LST_ENG' && this.reviewRuleData.ruleType === 'SECTYP' && (this.businessUnit === this.sharedConstant.rbsBUText || this.businessUnit === this.sharedConstant.manageMasterGroups.groupTypeMGP || this.businessUnit === this.sharedConstant.dbsBUText)) {
      /* If the asset group data is already stored in the cache then call the maintaAssetGroupOrder method to sort the data based on order value */
      if (this.sharedService.assetGrpCachedData && this.sharedService.assetGrpCachedData.length > 0 && this.businessUnit === this.sharedService.assetGrpCachedData[0].bu) {
        this.displaySelectedAssetGrp = this.ruleService.maintainAssetGroupOrder(this.reviewRuleData);
      } else {
        /* If the asset group data is not yet stored in the cache then make the asset group endpoint call to fetch the data */
        this.fetchAssetGroups();
      }

      setTimeout(() => {
        if ((this.sharedService.presetRuleCachedData && this.sharedService.presetRuleCachedData.length > 0) || this.businessUnit === 'DBS') {
          this.formSecCatgsForAssetGrp();
        } else {
          this.fetchPresetRules();
        }
      }, 500);
    }

    /*const tempEmpAccounts = this.reviewRuleData.employeeAccounts;*/
    const tempSessionRuleData = {...this.reviewRuleData};
    delete tempSessionRuleData.employeeAccounts;
    delete tempSessionRuleData.chooseSecurityType;

    this.sessionRuleData = tempSessionRuleData;
    this.sessionRuleData.save();
    if (this.reviewRuleData.rulesCategory === 'RESTRICT_LST_ENG' && this.reviewRuleData.override !== 'Y') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.restriction;
    } else if (this.reviewRuleData.rulesCategory === 'WATCH_LST_ENG') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.watchList;
    } else if (this.reviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.fundFamily;
    } else {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.override;
    }
    this.message = '<strong>' + this.reviewRuleData.name + '</strong>' + ' - ' + this.ruleType;
    if (this.reviewRuleData.ruleType === 'INDSEC' && this.rulesCategory === 'RESTRICT_LST_ENG' && this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
      this.subTypeLabel = this.emtrConst.rulesConstant.autoComplete.selectedSecOrInstGrpRestrictText;
    } else if(this.reviewRuleData.ruleType === 'INDSEC' && this.rulesCategory === 'Override' && this.businessUnit !== 'SPS' && this.sharedService.checkEntitlements(this.entitlementConst.navUUID.emtrAccess.instrumentsSummary.uuid, '', '')) {
        this.subTypeLabel = this.emtrConst.rulesConstant.ruleTypeLabels.selectedSecOrInstGrpOverrideText;
    } else {
      /* eslint-disable-next-line max-len */
      this.subTypeLabel = this.reviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt && this.rulesCategory === 'RESTRICT_LST_ENG' ? this.emtrConst.rulesConstant.autoComplete.selectFundFamiliesToRestrictTxt : this.emtrConst.rulesConstant.ruleTypeLabels.selectedSecurity.concat((this.rulesCategory === 'RESTRICT_LST_ENG') ? this.emtrConst.rulesConstant.ruleTypeLabels.RESTRICT_LST_ENG : (this.rulesCategory === 'Override') ? this.emtrConst.rulesConstant.ruleGlobalConst.override : this.emtrConst.rulesConstant.ruleGlobalConst.watchList);
    }
    this.changeJustificationOpt();

    if (this.reviewRuleData.mode === 'EDIT') {
      this.disableBtn = (this.reviewRuleData.ruleType === 'INDSEC' && this.rulesCategory !== this.emtrConst.rulesConstant.ruleTypeLabels.WATCH_LST_ENGText) && (this.indSecs && this.indSecs.length === 0) && (this.instGroup && this.instGroup.length === 0);
    }

    if (this.reviewRuleData.status === 'ACTIVE' || this.reviewRuleData.status === 'SCHEDULED') {
      /* eslint-disable-next-line max-len */
      if (this.disableBtn && !this.ruleService.deletedRuleInfo) {
        this.warningMessage = this.emtrConst.rulesConstant.reviewRuleMsg.missingFieldWarningMsg;
      }
      // this.warningMessage = this.message + this.emtrConst.rulesConstant.reviewRuleMsg.ruleIsCurrently + this.reviewRuleData.status + this.emtrConst.rulesConstant.reviewRuleMsg.anyUpdatesMustBeSaved;
    } else if (this.reviewRuleData.status === 'EXPIRED') {
      /* eslint-disable-next-line max-len */
      this.informationMessage = this.message + this.emtrConst.rulesConstant.reviewRuleMsg.ruleIsCurrently + this.reviewRuleData.status + this.emtrConst.rulesConstant.reviewRuleMsg.expiredRuleWarningMsg;
    } else {
      this.warningMessage = this.emtrConst.rulesConstant.reviewRuleMsg.activateWarmingMsg;
    }
    this.confirmMessage = this.child.confirmMessage;
    this.assetGroups = this.reviewRuleData.assetGroups;
    this.onChanges();
    if (this.reviewRuleData.status === 'EXPIRED' || !this.showActionItems || !this.showActionItemForGRule) {
      this.reviewRuleForm.get('reviewRuleName').disable();
      this.reviewRuleForm.get('justification').disable();
      this.reviewRuleForm.get('descriptionForm').disable();
      this.reviewRuleForm.get('otherInput').disable();
    }

    if (this.showActionItems && this.showActionItemForGRule) {
      if (this.warningMessage) {
        /*To show the warning msg for active/draft/scheduled*/
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.warningMessage);
      } else if (this.informationMessage) {
        /*To show the information msg for expired rule*/
        this.ruleService.toastType.emit('info');
        this.ruleService.toastMsg.emit(this.informationMessage);
      }
    }
    this.is10b5Client = this.sharedService.get10b5ClientDetails();
  }

  populateSelectedEmployeeData() {
    const formData = this.reviewRuleData;
    formData.indSecsInfo = this.reviewRuleData.indSecs;
    formData.instrumentGroupsInfos = this.reviewRuleData.instrumentGroups;
    formData.employeeGroups = (this.employeeGroups && this.employeeGroups.length > 0) ? this.employeeGroups : this.reviewRuleData.employeeGroups;
    formData.unassignedEmpGroup = this.reviewRuleData.unassignedEmpGroup ? this.reviewRuleData.unassignedEmpGroup : [];
    formData.newSelectedRecords = this.reviewRuleData.newSelectedRecords ? this.reviewRuleData.newSelectedRecords : [];
    formData.removedExistingSelected = this.reviewRuleData.removedExistingSelected ? this.reviewRuleData.removedExistingSelected : [];
    formData.assignEmpIds = this.reviewRuleData.assignEmpIds ? this.reviewRuleData.assignEmpIds : [];
    formData.assignSubAcctNumbers = this.reviewRuleData.assignSubAcctNumbers ? this.reviewRuleData.assignSubAcctNumbers : [];
    formData.unAssignEmpIds = this.reviewRuleData.unAssignEmpIds ? this.reviewRuleData.unAssignEmpIds : [];
    formData.unAssignSubAcctNumbers = this.reviewRuleData.unAssignSubAcctNumbers ? this.reviewRuleData.unAssignSubAcctNumbers : [];
    formData.empSelected = this.reviewRuleData.empSelected ? this.reviewRuleData.empSelected : [];
    formData.subAccSelected = this.reviewRuleData.subAccSelected ? this.reviewRuleData.subAccSelected : [];
    formData.subAccntNewlySelected = this.reviewRuleData.subAccntNewlySelected ? this.reviewRuleData.subAccntNewlySelected : [];
    formData.removedExistingEmpId = this.reviewRuleData.removedExistingEmpId ? this.reviewRuleData.removedExistingEmpId : [];
    formData.unassignOrgIds = this.reviewRuleData.unassignOrgIds ? this.reviewRuleData.unassignOrgIds : [];
    formData.assignOrgIds = this.reviewRuleData.assignOrgIds ? this.reviewRuleData.assignOrgIds : [];
    formData.globalRule = this.reviewRuleData.globalRule;
    formData.global = this.reviewRuleData.global;
    formData.empAccounts = [];
    formData.employeeAccounts = [];

    this.sharedService.getEmployeeList(this.pageObject, '', null, this.reviewRuleData.id).subscribe((res: any) => {
      if (res.data) {
        this.totalRecords = res.data.totalElements;
        this.sharedService.totalEmployeeRecords.totalSelectedRecords = this.totalRecords || 0;
        formData.empAccounts = res.data.content;
        formData.employeeAccounts = res.data.content;
        this.verifyData = res.data.content;
      }
      this.formDataService.setFormData(formData);
      this.disableEmployeeGroupPlan = this.verifyData && (this.verifyData.length !== 0);
      if (this.disableEmployeeGroupPlan) {
        this.activeIndex = 1;
      }

      if (this.reviewRuleData.mode === 'EDIT' && (this.reviewRuleData.status === 'ACTIVE' || this.reviewRuleData.status === 'SCHEDULED')) {
        if (!this.reviewRuleData.globalRule || (this.reviewRuleData.globalRule && this.reviewRuleData.global && this.reviewRuleData.global.type === this.emtrConst.rulesConstant.semiGlobalObj.type)) {
          this.disableEditIcon = (this.employeeGroups && this.employeeGroups.length === 0) && (this.verifyData === undefined || (this.verifyData && this.verifyData.length === 0));
        }
        if (this.reviewRuleData.globalRule && this.reviewRuleData.global && this.reviewRuleData.global.type === 'GLOBAL') {
          this.disableEmployeeParticipant = true;
        }

        if (this.disableEditIcon && !this.ruleService.deletedRuleInfo) {
          this.warningMessage = this.emtrConst.rulesConstant.reviewRuleMsg.missingFieldWarningMsg;
          /*To show the warning msg for active/draft/scheduled*/
          this.ruleService.toastType.emit('warn');
          this.ruleService.toastMsg.emit(this.warningMessage);
        }
      }
    });
  }

  fetchEmpGrpRecords() {
    if (this.reviewRuleData.global) {
      if (this.reviewRuleData.assignBU && this.reviewRuleData.assignBU.length > 0) {
        this.reviewRuleData.global.type = 'GLOBAL';
      } else {
        this.reviewRuleData.global.type = 'SEMI_GLOBAL';
      }
    }
    this.ruleService.getRuleData(this.reviewRuleData.id, this.reviewRuleData.global).subscribe((ruleData: any) => {
      this.employeeGroups = ruleData.data.employeeGroups;
      this.reviewRuleData.global = ruleData.data.global;
    });
  }

  changeJustificationOpt() {
    if (this.reviewRuleForm.get('justification').value !== 'Other') {
      this.reviewRuleForm.get('otherInput').disable();
    }
    this.reviewRuleForm.get('justification').valueChanges.subscribe((value) => {
      if (value === 'Other') {
        this.reviewRuleForm.get('otherInput').enable();
      } else {
        this.reviewRuleForm.get('otherInput').setValue('');
        this.reviewRuleForm.get('otherInput').disable();
      }
    });
  }

  canDeactivate(event, observer) {
    this.confirmMessage.message = (this.confirmMessage.message !== undefined && this.confirmMessage.message) ? this.confirmMessage.message : this.emtrConst.rulesConstant.confirmationMessage.menuClickMsg;
    if (!this.revertDisable && this.isClicked === true) {
      this.localObserver = observer;
      return this.child.showConfirmMsg(this.confirmMessage, observer);
    } else {
      return true;
    }
  }

  populateData() {
    this.rulesCategory = (this.reviewRuleData.override === 'Y') ? 'Override' : this.reviewRuleData.rulesCategory;
    const startDt = (this.reviewRuleData.startDt) ? this.reviewRuleData.startDt : '';
    const endDt = (this.reviewRuleData.endDt) ? this.reviewRuleData.endDt : '';
    this.indSecs = (this.reviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) ? this.reviewRuleData.fndFmlyInfo : this.reviewRuleData.indSecs;
    this.instGroup = this.reviewRuleData.instrumentGroups ;
    this.employeeGroups = this.reviewRuleData.employeeGroups;
    this.disableEmployeeParticipant = this.employeeGroups && (this.employeeGroups.length !== 0);
      if (this.disableEmployeeParticipant) {
        this.activeIndex = 0;
      }
    this.employee = this.reviewRuleData.employeeAccounts;

    this.ruleType = this.reviewRuleData.ruleType;
    this.justification = this.reviewRuleData.justification;
    this.otherInput = this.reviewRuleData.otherInput;
    this.appliesTo = this.reviewRuleData.appliesTo;
    this.securityType = (this.reviewRuleData.securityType) ? this.reviewRuleData.securityType : [];

    if (this.showActionItems && !this.reviewRuleData.globalRule) {
      this.ruleService.getAssettypes().subscribe((datas: any) => {
        if (datas.data && this.securityType.length === datas.data.length) {
          this.securityType = [AllSecurityRecords];
        }
      });
    }

    this.reviewRuleForm.patchValue({
      reviewRuleName: this.reviewRuleData.name,
      ruleType: this.reviewRuleData.ruleType,
      appliesTo: this.reviewRuleData.appliesTo,
      underlyingOpts: this.reviewRuleData.appliesTo === 'BTH' || this.reviewRuleData.appliesTo === 'OPT',
      indSecs: this.reviewRuleData.appliesTo === 'BTH' || this.reviewRuleData.appliesTo === 'IND',
      justification: this.reviewRuleData.justification,
      otherInput: this.reviewRuleData.otherInput,
      restrictForm: {
        restrictSells: (this.reviewRuleData.rstrctSell === 'Y') ? true : '',
        restrictBuys: (this.reviewRuleData.rstrctBuy === 'Y') ? true : '',
        restrictShortSells: (this.reviewRuleData.rstrctShortSell === 'Y') ? true : '',
      },
      calendarForm: {
        dateFrom: {
          date: new Date(startDt),
          time: moment(startDt).format('hh:mm'),
          timeMeridian: moment(startDt).format('a').toUpperCase()
        },
        datepickerDisabled: (endDt) ? true : '',
        dateTo: {
          date: (endDt) ? new Date(endDt) : null,
          time: (endDt) ? moment(endDt).format('hh:mm') : null,
          timeMeridian: (endDt) ? moment(endDt).format('a').toUpperCase() : null
        }
      },
      descriptionForm: {
        description: this.reviewRuleData.desc,
        notes: this.reviewRuleData.note,
        changeRequestId: this.reviewRuleData.changeRequestId
      }
    });
    this.reviewRuleForm.disable();
    this.reviewRuleForm.get('reviewRuleName').enable();
    this.reviewRuleForm.get('descriptionForm').enable();
    if (this.reviewRuleData.override === 'Y') {
      this.reviewRuleForm.get('justification').enable();
      this.reviewRuleForm.get('otherInput').enable();
    }
    this.reviewRuleForm.get('restrictForm').disable();
    this.revertDisable = true;
    this.isFieldEditted = false;
    this.reviewRuleForm.get('calendarForm').get('dateTo').disable();
    /* eslint-disable-next-line max-len */
    if ((this.reviewRuleData.mode === 'EDIT' || this.reviewRuleData.mode === 'COPY') && this.reviewRuleData.ruleType === 'SECTYP' && (this.reviewRuleData.assetGroups === undefined || (this.reviewRuleData.assetGroups && this.reviewRuleData.assetGroups.length <= 0))) {
      this.fetchAndPupulateSecurityTypeList(this.formDataService.formData);
    }
    this.oldFormObj = this.reviewRuleForm.getRawValue();
  }

  editRuleNameDescriptionNotes() {
    if (this.formObjCompare) {
      this.revertDisable = true;
    } else {
      this.revertDisable = false;
    }
    this.isFieldEditted = true;
    this.changeJustificationOpt();
  }

  navigateTo(path) {
    this.formDataService.formData.showToast = false;
    if (path.indexOf('2') > -1) {
      const reviewDataCopy: any = { ...this.reviewRuleData };
      const formObj = {
        employeeAccounts: reviewDataCopy.employeeAccounts,
        employeeGroups: reviewDataCopy.employeeGroups,
        empIds: (reviewDataCopy.empIds && reviewDataCopy.empIds.length > 0) ? reviewDataCopy.empIds : [],
        subAccntNumbers: (reviewDataCopy.subAccntNumbers && reviewDataCopy.subAccntNumbers.length > 0) ? reviewDataCopy.subAccntNumbers : [],
        assignOrgIds: reviewDataCopy.assignOrgIds
      };
      this.formDataService.setStep4('', false);
      this.formDataService.setStep3(formObj, false);
    } else if (path.indexOf('3') > -1) {
      this.formDataService.setStep4('', false);
    }
    if (this.reviewRuleData.mode !== 'EDIT') {
      /* upon hitting cancel backend will not have any updated rule name from Step 2,  so here use api returned rulename on the success message */
      if (!this.cancelButton  && this.businessUnit === 'RBS') {
        if (this.ruleService.originalRBSRuleNameForCopyFlow !== this.reviewRuleForm.get('reviewRuleName').value.trim()) {
          this.message = '<strong>' + this.ruleService.originalRBSRuleNameForCopyFlow + '</strong>' + ' - ' + this.ruleType;
        }
      }
      this.landingPageMsg = this.message + this.emtrConst.rulesConstant.ruleMessages.ruleWaSuccessfullySavedAsDraft;
      this.ruleService.sendMessage(this.landingPageMsg);
    }
    this.router.navigate([path], {relativeTo: this.r});
  }

  revertChanges() {
    this.reviewRuleForm.get('reviewRuleName').setValue(this.reviewRuleData.name);
    this.reviewRuleForm.get('justification').setValue(this.reviewRuleData.justification);
    this.reviewRuleForm.get('otherInput').setValue(this.reviewRuleData.otherInput);
    this.reviewRuleForm.get('descriptionForm').get('description').setValue(this.reviewRuleData.desc);
    this.reviewRuleForm.get('descriptionForm').get('notes').setValue(this.reviewRuleData.note);
    this.reviewRuleForm.get('descriptionForm').get('changeRequestId').setValue(this.reviewRuleData.changeRequestId);
    this.revertDisable = true;
    this.isFieldEditted = false;
    if (this.reviewRuleData.status === 'ACTIVE' || this.reviewRuleData.status === 'SCHEDULED') {
      this.formObjCompare = true;
    } else {
      this.formObjCompare = false;
    }
  }

  receiveDataFromChild(count, actionMenu) {
    if (actionMenu === 'indSecs') {
      this.noOfRecordsIndSecs = count;
      this.indSecs = [...this.indSecs];
    } else if (actionMenu === 'instGroup') {
      this.noOfRecordsInstGroup = count;
      this.instGroup = [...this.instGroup];
    } else if (actionMenu === 'employeeGroups') {
      this.noOfRecordsEmpGroup = count;
      this.employeeGroups = [...this.employeeGroups];
    } else if (actionMenu === 'verifyData') {
      this.noOfRecordsEmp = count;
      this.pageObject.pageSize = count;
      this.pageObject.pageNo = 0;
      this.populateSelectedEmployeeData();
      this.verifyData = [...this.verifyData];
    }
  }

  getFormValues() {
    this.reviewRuleData.name = this.reviewRuleForm.get('reviewRuleName').value.trim();
    this.reviewRuleData.desc = this.reviewRuleForm.get('descriptionForm').get('description').value;
    this.reviewRuleData.note = this.reviewRuleForm.get('descriptionForm').get('notes').value;
    this.reviewRuleData.changeRequestId = this.reviewRuleForm.get('descriptionForm').get('changeRequestId').invalid ? this.reviewRuleData.changeRequestId : this.reviewRuleForm.get('descriptionForm').get('changeRequestId').value;
    this.reviewRuleData.justification = this.reviewRuleForm.get('justification').value;
    this.reviewRuleData.otherInput = this.reviewRuleForm.get('otherInput').value;
  }

  ruleSaveAndClose() {
    this.saveRule('save');
  }

  setTime(form) {
    const date = form.get('date').value;
    const time = form.get('time').value;
    const timeMeridian = form.get('timeMeridian').value;
    let hours = time.split(':')[0];
    const mins = time.split(':')[1];
    if (timeMeridian === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    const newDate = new Date(date);
    newDate.setHours(parseInt(hours, 10));
    newDate.setMinutes(parseInt(mins, 10));
    return newDate;
  }

  saveRule(inputData) {
    this.isClicked = false;
    this.getFormValues();
    this.message = '<strong>' + this.reviewRuleData.name + '</strong>' + ' - ' + this.ruleType;
    const currentDate = new Date(moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'));
    const currentTime = currentDate.getTime() > this.setTime(this.reviewRuleForm.get('calendarForm').get('dateFrom')).getTime();
    const fromDate = this.reviewRuleForm.get('calendarForm').get('dateFrom').value;
    const dateCheck = (moment(currentDate).format('YYYY/MM/DD') === moment(fromDate.date).format('YYYY/MM/DD'));
    if (this.reviewRuleData.status === 'DRAFT' && currentTime && dateCheck) {
        /* eslint-disable-next-line max-len */
        this.reviewRuleData.startDt = (fromDate.date) ? (moment.tz(moment(new Date(moment(fromDate.date).format('YYYY/MM/DD') + ' ' + moment(currentDate).format('hh:mm') + ' ' + fromDate.timeMeridian).getTime()).format('YYYY MM DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS')) : '';
    }

    const reviewDataCopy: any = {...this.reviewRuleData};
    delete reviewDataCopy.chooseSecurityType;
    if (reviewDataCopy.ruleType !== 'SECTYP') {
      delete reviewDataCopy.secCatgs1;
      delete reviewDataCopy.secCatgs2;
      delete reviewDataCopy.secCatgs3;
    }
    if (!this.is10b5Client) {
      delete reviewDataCopy.planId;
      delete reviewDataCopy.ruleSubCategory;
      delete reviewDataCopy.isSavedAsDraft;
    }
    if (this.reviewRuleData.override === 'Y') {
      delete reviewDataCopy.planId;
      delete reviewDataCopy.ruleSubCategory;
    }
    if (this.reviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) {
      delete reviewDataCopy.appliesTo;
      delete reviewDataCopy.indSecs;
      delete reviewDataCopy.instrumentGroups;
      delete reviewDataCopy.rstrctShortSell;
      delete reviewDataCopy.fndFmlyInfo;
    } else {
      delete reviewDataCopy.fndFmlyInfo;
      delete reviewDataCopy.fndFmly;
    }
    /* If the rule edited or created is an global rule then attach the global obj value in the payload */
    if (this.reviewRuleData.globalRule) {
      delete reviewDataCopy.assignOrgIds;
      delete reviewDataCopy.unassignOrgIds;
      delete reviewDataCopy.globalRule;
      delete reviewDataCopy.micAGSelectedFlag;
      delete reviewDataCopy.employeeAccounts;
    }

    if (this.reviewRuleData.assetGroups && this.reviewRuleData.assetGroups.length > 0) {
      delete reviewDataCopy.secCatgs1;
      delete reviewDataCopy.secCatgs2;
      delete reviewDataCopy.secCatgs3;
    }

    this.deleteEmpAssignments(reviewDataCopy);
    if (inputData) {
      delete reviewDataCopy.instrumentGroupsInfos;
      this.ruleService.putSaveCall(reviewDataCopy).subscribe((data: any) => {
        if (this.reviewRuleData.activate) {
          if (moment(currentDate).isBefore(moment(fromDate.date))) {
            const newDate = moment(fromDate.date).format('MM/DD/YYYY @ hh:mm A');
            this.landingPageMsg = this.message + this.emtrConst.rulesConstant.ruleMessages.ruleWasSuccessfullySavedAndHasBeenScheduledToStart + '<strong>' + newDate + '</strong>';
          } else if (this.reviewRuleData.mode === 'EDIT') {
            this.landingPageMsg = this.emtrConst.rulesConstant.ruleMessages.ruleChangesHaveBeenSavedAndUpdated;
          } else {
            this.landingPageMsg = this.message + this.emtrConst.rulesConstant.ruleMessages.ruleWasSuccessfullySavedAndActivated;
          }
        } else {
          this.landingPageMsg = this.message + this.emtrConst.rulesConstant.ruleMessages.ruleWaSuccessfullySavedAsDraft;
        }
        this.ruleService.sendMessage(this.landingPageMsg);
        this.formDataService.resetForm();
        delete this.formDataService.formData['indSecsInfo'];
        delete this.formDataService.formData['empAccounts'];
        this.router.navigate(['/ems/emtr/rules/rules-list'], {relativeTo: this.r});
      });
    } else {
      return true;
    }

  }

 /* Handling the Save from confirm-message clicking 'Yes' */
 goToPreviousStep(path) {
  if (this.reviewRuleForm.dirty  && !this.revertDisable) {
    this.confirmMessage.message = this.emtrConst.rulesConstant.confirmationMessage.previousBtnClickMsg;
    Observable.create((observer: Observer<boolean>) => {
      this.child.showConfirmMsg(this.confirmMessage, observer);
    }).subscribe(accept => {
      if (accept) {
        this.isClicked = false;
        this.getFormValues();
        const reviewDataCopy: any = { ...this.reviewRuleData };
        delete reviewDataCopy.employeeAccounts;
        delete reviewDataCopy.chooseSecurityType;
        if (reviewDataCopy.ruleType !== 'SECTYP') {
          delete reviewDataCopy.secCatgs1;
          delete reviewDataCopy.secCatgs2;
          delete reviewDataCopy.secCatgs3;
        }

        if (this.reviewRuleData.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) {
          delete reviewDataCopy.appliesTo;
          delete reviewDataCopy.indSecs;
          delete reviewDataCopy.instrumentGroups;
          delete reviewDataCopy.rstrctShortSell;
          delete reviewDataCopy.fndFmlyInfo;
        } else {
          delete reviewDataCopy.fndFmlyInfo;
          delete reviewDataCopy.fndFmly;
        }
        /* If the rule edited or created is an global rule then attach the global obj value in the payload */
        if (this.reviewRuleData.globalRule) {
          reviewDataCopy.global = this.emtrConst.rulesConstant.globalObj;
          delete reviewDataCopy.assignOrgIds;
          delete reviewDataCopy.unassignOrgIds;
          delete reviewDataCopy.globalRule;
          delete reviewDataCopy.micAGSelectedFlag;
        }

        this.deleteEmpAssignments(reviewDataCopy);
        this.ruleService.putSaveCall(reviewDataCopy).subscribe(data => {
          this.navigateTo(path);
        });
      }
    });
  } else {
    this.navigateTo(path);
  }
}

  activateRule(event) {
    this.reviewRuleData.activate = true;
    if (this.reviewRuleData.mode === '') {
      /* Activate button click GUTtracking value for adobe analytics - Only in create flow */
      this.sharedService.gutTracking('adobe-lc_rule_step4activate');
    }
    this.saveRule('save');
  }

  isParentNode(rowNode) {
    if (!rowNode.level) {
      this.currentParentNode = rowNode;
      return false;
    }
    return true;
  }

  onClose() {
    this.showContinueBtn = false;
  }

  updateSecurityValues(selectedValues) {
    this.multiLevelSecurityType = Object.assign({}, selectedValues);
    const wholeArray = Object.assign({}, this.multiLevelSecurityType);
    Object.keys(wholeArray).forEach((key) => {
      wholeArray[key].forEach((keys) => {
        if (keys.children) {
          keys.data.selected = 'ALL';
          keys.children.forEach((record) => {
            wholeArray[key] = wholeArray[key].filter(item => item.data.code !== record.data.code);
          });
        }
      });
      if (this.reviewRuleData.assetGroups && this.reviewRuleData.assetGroups.length > 0) {
        /* when we are getting few securities from preset and few from asset group, and if the formed secCatgs2 has all the Level2 of a specific security selected then we are making the Level1 selection for that security */
        const secCatgs = {
          secCatgs1: [],
          secCatgs2: [],
          secCatgs3: []
        }
        /* Clearing this.multiLevelSecurityType[sec.desc] so that duplicate data are not pushed so the 'Select All' checkbox on modal is selected when all securities are selected */
        /* eslint-disable-next-line max-len */
        this.securityData.some(sec => sec.desc == key && sec.subLevels.length === wholeArray[key].length ? (secCatgs.secCatgs1.indexOf({secTypNm: sec.desc, secTypCd: sec.code}) === -1 ? secCatgs.secCatgs1.push({secTypNm: sec.desc, secTypCd: sec.code}) : '', this.multiLevelSecurityType[sec.desc] = [],this.populateSecurityTypeData(secCatgs)) : '')
      }
    });
    if((this.reviewRuleData.assetGroups === undefined || (this.reviewRuleData.assetGroups && this.reviewRuleData.assetGroups.length <= 0))) {
      this.multiLevelUpdatedList = wholeArray;
    }
    return (Object.keys(this.multiLevelSecurityType) && Object.keys(this.multiLevelSecurityType).length > 0) ? this.multiLevelSecurityType : false;
  }

  ngOnDestroy() {
    this.sharedService.groupType = '';
    this.sessionStorageService.remove('ruleData');
    this.ruleService.toastMsg.emit('');
    this.ruleService.toastType.emit('');
    this.ruleService.showRequestIdError.emit(false);
  }

  ruleSave(event) {
    this.reviewRuleData.activate = true;
    this.saveRule('save');
  }

  ruleCancel() {
    this.cancelButton = true;
    if (this.reviewRuleData.mode === '') {
      /* Close button click GUTtracking value for adobe analytics - Only in create flow */
      this.sharedService.gutTracking('adobe-lc_rule_step4close');
    }
  }

  /*Formatting the Backend JSON to Treetable JSON Formate*/
  formatJson(data) {
    return data.map(dataEle => {
      const subLevels = dataEle.subLevels;
      // delete dataEle.subLevels;
      const obj = {
        data: dataEle
      };
      if (subLevels) {
        obj['children'] = this.formatJson(subLevels);
        obj['expanded'] = true;
      }
      return obj;
    });
  }

  formatEmployeeJson(data, type) {
    return data.map((dataEle) => {
      if (dataEle !== null && dataEle !== undefined) {
        const children = dataEle.accounts;
        delete dataEle.accounts;
        if (type) {
          dataEle.empId = dataEle.empId + dataEle.subAcctNum;
        }
        const obj = {
          data: dataEle
        };
        if (children) {
          obj['children'] = this.formatEmployeeJson(children, 'children');
          obj['expanded'] = true;
        }
        return obj;
      }
    });
  }

  deleteEmpAssignments(formData) {
    if (formData.mode === 'EDIT') {
      delete formData.assignEmpIds;
      delete formData.assignSubAcctNumbers;
      delete formData.newSelectedRecords;
      delete formData.removedExistingEmpId;
      delete formData.removedExistingSelected;
      delete formData.subAccSelected;
      delete formData.employeeAccounts;
      delete formData.subAccntNewlySelected;
      delete formData.unAssignEmpIds;
      delete formData.unAssignSubAcctNumbers;
      delete formData.empSelected;
      delete formData.empAccounts;
      delete formData.oldRuleIdForCopy;
      delete formData.isEmpGrpModified;
    }
    return formData;
  }

  recurrsive(data, array) {
    if (data.subLevels) {
      data.subLevels.forEach((subLevel) => {
        if (subLevel.subLevels) {
          array.push({...subLevel});
          this.recurrsive(subLevel, array);
        } else {
          array.push({...subLevel});
        }
      });
      return array;
    } else {
      return data;
    }
  }

  populateSecurityTypeData(SecurityValue) {
      const securityData = this.securityData;
      if (securityData) {
        const secCatgs1 = this.formatSecurities(SecurityValue.secCatgs1, 'SC1');
        const secCatgs2 = this.formatSecurities(SecurityValue.secCatgs2, 'SC2');
        const secCatgs3 = this.formatSecurities(SecurityValue.secCatgs3, 'SC3');
        const selectedValues = secCatgs1.concat(secCatgs2).concat(secCatgs3);
        selectedValues.forEach(selectedData => {
          securityData.forEach(secData => {
            if (JSON.stringify(secData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
              if (!this.multiLevelSecurityType[secData.desc]) {
                this.multiLevelSecurityType[secData.desc] = [];
              }
              if (selectedData.data.level === 'SC1') {
                this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                  .concat(this.formatJson(this.recurrsive({...secData}, [])))
                  .concat(this.formatJson([{...secData}]));
              } else if (selectedData.data.level === 'SC2') {
                secData.subLevels.forEach(secSubLevelData => {
                  if (JSON.stringify(secSubLevelData).indexOf('\"code\":\"' + selectedData.data.code + '"') !== -1) {
                    this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                      .concat(this.formatJson(this.recurrsive({...secSubLevelData}, [])))
                      .concat(this.formatJson([{...secSubLevelData}]));
                  }
                });
              } else {
                this.multiLevelSecurityType[secData.desc] = this.multiLevelSecurityType[secData.desc]
                  .concat(this.formatJson([selectedData.data]));
              }
            }
          });
        });
        this.updateSecurityValues(this.multiLevelSecurityType);
      }
  }

  formatSecurities(securities, level) {
    return securities.map(item => {
      return {
        data: {
          desc: item.secTypNm,
          code: item.secTypCd,
          level
        }
      };
    });
  }

  formatDate(date) {
    const formattedDate = date.split('T');
    const splitedData = formattedDate[0];
    const newDate = this.datePipe.transform(splitedData, 'MM/dd/yy');
    return newDate;
  }

  onChanges(): void {
    this.reviewRuleForm.get('reviewRuleName').valueChanges.subscribe((ruleNameValue) => {
      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (ruleNameValue && !regExp.test(ruleNameValue)) {
        if (this.errorMsg.indexOf(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter) === -1) {
          this.errorMsg.push(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter);
        }
        this.ruleService.showSchError.emit(true);
      } else {
        this.errorMsg = [];
        this.ruleService.showSchError.emit(false);
      }
      this.ruleService.schErrorMsg.emit(this.errorMsg);
    });
  }

  paginate(e) {
    if (this.pageObject.pageNo !== e.page) {
      this.pageObject.pageNo = e.page;
      this.populateSelectedEmployeeData();
    }
  }

  fetchAssetGroups() {
    /* Fetch Asset group and global asset group. Also store the fetched response data in the cache to utilise it later */
    this.ruleService.getAssetGroups().subscribe(assetGrp => {
      this.ruleService.setAssetGrpCacheData(assetGrp);
      /* Once the data is fetched and stored in cache, call the maintainAssetGroupOrder to sort the selected asset grp
     data and display the sorted data to user */
      this.displaySelectedAssetGrp = this.ruleService.maintainAssetGroupOrder(this.reviewRuleData);
    });
  }

  fetchPresetRules() {
    /* Fetch preset rules and store the fetched response data in the cache to utilise it later */
    this.ruleService.getPresetRules().subscribe(presetData => {
      this.sharedService.presetRuleCachedData = this.ruleService.updateSecCatgsInPresentRule(presetData.data, this.sharedService.assetGrpCachedData);
      this.formSecCatgsForAssetGrp();
    });
  }

  openSecurityType() {
    if (this.securityData) {
      this.childSecurityType.openModal(event, this.securityData);
    }
  }

  fetchAndPupulateSecurityTypeList(SecurityValue) {
    let isRestrictShortSellsChecked = false;
    if ((this.reviewRuleData.assetGroups === undefined || (this.reviewRuleData.assetGroups && this.reviewRuleData.assetGroups.length <= 0))) {
      isRestrictShortSellsChecked = !(this.reviewRuleData.rstrctBuy === 'Y' || this.reviewRuleData.rstrctSell === 'Y');
    }
    if (this.securityData && this.securityData.length === 0) {
      this.ruleService.getSecurities(isRestrictShortSellsChecked).subscribe((data: any) => {
        this.securityData = data.esoLevels;
        this.populateSecurityTypeData(SecurityValue);
      });
    } else {
      this.populateSecurityTypeData(SecurityValue);
    }
  }

  formSecCatgsForAssetGrp() {
    const secCatgs = {
      secCatgs1: [],
      secCatgs2: [],
      secCatgs3: []
    }
    if (this.reviewRuleData.presetRuleId && this.reviewRuleData.presetRuleId !== 1) {
      this.sharedService.presetRuleCachedData.forEach(rule => {
        /* Compare the selected preset rule with each key value iterated from the presetRules list */
        if (this.reviewRuleData.presetRuleId === rule.id) {
          /* The selected rule will have all the required secCatgs, and to form the selected security array to display on the review security details modal call fetchAndPupulateSecurityTypeList */
          secCatgs.secCatgs1 = rule.secCatgs1 ? rule.secCatgs1 : [];
          secCatgs.secCatgs2 = rule.secCatgs2 ? rule.secCatgs2 : [];
          secCatgs.secCatgs3 = rule.secCatgs3 ? rule.secCatgs3 : [];
        }
      });
    } else {
      this.reviewRuleData.assetGroups.forEach(assetGrp => {
        this.sharedService.assetGrpCachedData.some(grp => assetGrp.name === grp.name && grp.secCatgs1 ? grp.secCatgs1.filter(security => secCatgs.secCatgs1.push(security)) : '');
        this.sharedService.assetGrpCachedData.some(grp => assetGrp.name === grp.name && grp.secCatgs2 ? grp.secCatgs2.filter(security => secCatgs.secCatgs2.push(security)) : '');
        this.sharedService.assetGrpCachedData.some(grp => assetGrp.name === grp.name && grp.secCatgs3 ? grp.secCatgs3.filter(security => secCatgs.secCatgs3.push(security)) : '');
      });
    }
    this.fetchAndPupulateSecurityTypeList(secCatgs);
  }
}
