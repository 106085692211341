import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormDataService, SharedConstant, SharedService, ManageEmpGrpService} from '@ems/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {emtrConstant} from '../../../constants/emtrConstants';
import * as temp from 'moment';
const moment = temp['default'];
import {RuleService} from '../../../services/rule-service/rule.service';
import {EmtrPagination, EmtrPaginationValues} from '../../../models/pagination-model';
import {SharedManageEmployeeAccountsComponent, SharedManageEmployeeGroupComponent, SharedMasterGroupsComponent} from '@ems/shared';
import {EmtrService} from '../../../emtr.service';
import { ClientSelectionTableComponent } from '../../client-selection-table/client-selection-table.component';

@Component({
  selector: 'tr-edit-rule-step2',
  templateUrl: './edit-rule-step2.component.html'
})
export class EditRuleStep2Component implements OnInit, OnDestroy {
  reviewEditRuleForm: UntypedFormGroup;
  emtrConst = emtrConstant;
  reviewEditRuleData: any;
  dateSelectionType: any = 'date';
  dateError: any = '';
  employeeGroups: any[] = [];
  employeeAccounts: any[] = [];
  isRowSelected = false;
  empIds: any[] = [];
  subAccntNumbers: any[] = [];
  isClicked = true;
  message: string;
  ruleType: string;
  orgId: any;
  tableCountData: any = {
    empCount: 0,
    subAccount: 0,
    empSelected: 0,
    subAccSelected: 0,
    totalRecords: 0,
    paginationCount: 0
  };
  pageObject: EmtrPagination = EmtrPaginationValues;
  employees: any[] = [];
  confirmMessage: any;
  localObserver: any;
  landingPageMsg: any;
  showCharLimit: boolean;
  errorMsg = [];
  dateErrorMsg = '';
  isInValidFromDate = false;
  isPastFromDate = false;
  isInValidToDate = false;
  isPastToDate = false;
  ruleNameError = false;
  loadedModuleType = 'TwoStepRule';
  modifiedSubAccnts: any[] = [];
  shouldDisabledEmployeesOrParticipants = false;
  shouldDisabledGroupsOrPlans = false;
  showSelectionMessage = false;
  selectionWarningMessage: string;
  shouldDisabledMasterGroups = false;
  selectionWarningMesgForMGP: string;
  shouldDisabledMasterAccounts = false;
  showActionBtn = false;
  masterGroups: any[] = [];
  unAssignMstrAccts: any[] = [];
  activeIndex = 0;
  unassignedEmpGroup: any;
  unassignOrgIds: any[] = [];
  assignOrgIds: any[] = [];
  selectedMasterGrp: any;
  assignBU: any[] = [];
  unAssignBU: any[] = [];
  newlyAssignedEmployeeAccounts: any = {};
  selectedMasterAcc = false;
  masterAccChange: any;
  changeInObj: any;
  buSelected = false;
  modifiedSelectedRecords: any = {};

  @ViewChild(SharedManageEmployeeAccountsComponent) sharedEmpAcc: SharedManageEmployeeAccountsComponent;
  @ViewChild(SharedManageEmployeeGroupComponent) sharedEmpGrp: SharedManageEmployeeGroupComponent;
  @ViewChild('confirmationMessage', { static: true }) confirmationMessage: any;
  @ViewChild(SharedMasterGroupsComponent) sharedMasterGrp: SharedMasterGroupsComponent;
  @ViewChild(ClientSelectionTableComponent) clientSelection: ClientSelectionTableComponent;
  private getInitialData: any;
  rulesCategory: any;
  sharedConstant = SharedConstant;
  businessUnit: string;
 /* eslint-disable-next-line max-len */
  constructor(private formDataService: FormDataService, private fb: UntypedFormBuilder, private router: Router, private r: ActivatedRoute, private ruleService: RuleService, @Inject('entitlementConst') public entitlementConst, @Inject('commonConstants') public commonConstants, public sharedService: SharedService, public emtrService: EmtrService,
              public manageEmpGrpService: ManageEmpGrpService) {
    this.pageObject.sortField = '';
    this.sharedService.clientInformation.subscribe(clientInfo => {
      if (clientInfo) {
        this.businessUnit = clientInfo.businessUnit;
      }
    });
  }

  ngOnInit() {
    this.reviewEditRuleData = this.formDataService.getFormData();
    this.sharedService.groupType = this.reviewEditRuleData.globalRule ? this.sharedConstant.manageMasterGroups.groupTypeMGP : '';
    this.selectionWarningMessage = this.sharedConstant[this.businessUnit].selectionWarningMessage;
    this.selectionWarningMesgForMGP = this.sharedConstant.MGP.selectionWarningMesgForMGP;
    this.rulesCategory = (this.reviewEditRuleData.override === 'Y') ? this.emtrConst.rulesConstant.ruleGlobalConst.override : this.reviewEditRuleData.rulesCategory;
    this.reviewEditRuleForm = this.fb.group({
      reviewRuleName: ['', {validators: [Validators.required]}],
      calendarForm: this.fb.group({
        dateTo: this.fb.group({
          date: ['', Validators.required],
          time: ['', Validators.required],
          timeMeridian: ['', Validators.required]
        }),
        dateFrom: this.fb.group({
          date: ['', Validators.required],
          time: ['', Validators.required],
          timeMeridian: ['', Validators.required]
        }),
        datepickerDisabled: false
      }),
      descriptionForm: this.fb.group({
        description: '',
        notes: '',
        changeRequestId: ''
      }),
      datepickerDisabled: '',
      underlyingOpts: '',
      justification: ['', Validators.required],
      otherInput: ''
    });
    this.orgId = this.ruleService.orgId;
    this.populateData();
    this.confirmMessage = this.confirmationMessage.confirmMessage;
    this.onChanges();

    this.sharedService.schErrorMsg.subscribe(value => {
      this.ruleService.schErrorMsg.emit(value);
      if (value.length > 0) {
        this.ruleService.showSchError.emit(true);
      } else {
        this.ruleService.showSchError.emit(false);
      }
    });
  }
  updatedMasterAcc(event) {
    this.employeeAccounts = event;
    this.shouldDisabledMasterGroups = this.employeeAccounts && (this.employeeAccounts.length !== 0);
    if (this.shouldDisabledMasterGroups) {
      this.activeIndex = 1;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMesgForMGP);
        this.showSelectionMessage = true;
        this.sharedService.showRulesDraftSaveSuccessMsg = false;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledMasterGroups && !this.shouldDisabledMasterAccounts) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
    event.forEach(val => {
      (val.firstName && val.selectedAcct) ? this.selectedMasterAcc = true : this.selectedMasterAcc = false;
    });
    this.masterAccChange = event.length;
  }

  /* Populating Data */
  populateData() {
    this.reviewEditRuleForm.patchValue({
      reviewRuleName: this.reviewEditRuleData.name,
      justification: this.reviewEditRuleData.justification ? this.reviewEditRuleData.justification : '',
      otherInput: this.reviewEditRuleData.otherInput ? this.reviewEditRuleData.otherInput : '',
      calendarForm: {
        dateFrom: {
          date: (this.reviewEditRuleData.startDt) ? new Date(this.reviewEditRuleData.startDt) : '',
          time: (this.reviewEditRuleData.startDt) ? moment(this.reviewEditRuleData.startDt).format('hh:mm') : '',
          timeMeridian: (this.reviewEditRuleData.startDt) ? moment(this.reviewEditRuleData.startDt).format('a').toUpperCase() : ''
        },
        datepickerDisabled: (this.reviewEditRuleData.endDt) ? true : '',
        dateTo: {
          date: (this.reviewEditRuleData.endDt) ? new Date(this.reviewEditRuleData.endDt) : '',
          time: (this.reviewEditRuleData.endDt) ? moment(this.reviewEditRuleData.endDt).format('hh:mm') : '',
          timeMeridian: (this.reviewEditRuleData.endDt) ? moment(this.reviewEditRuleData.endDt).format('a').toUpperCase() : ''
        }
      },
      descriptionForm: {
        description: this.reviewEditRuleData.desc ? this.reviewEditRuleData.desc : '',
        notes: this.reviewEditRuleData.note ? this.reviewEditRuleData.note : '',
        changeRequestId: this.reviewEditRuleData.changeRequestId ? this.reviewEditRuleData.changeRequestId : ''
      },
    });
    this.employeeAccounts = this.employeeAccounts.concat(this.reviewEditRuleData.employeeAccounts);
    this.employeeGroups = this.employeeGroups.concat(this.reviewEditRuleData.employeeGroups);
    this.empIds = this.reviewEditRuleData.empIds;
    this.subAccntNumbers = this.reviewEditRuleData.subAccntNumbers;
    if (this.reviewEditRuleData.mode === 'Override') {
      this.changeJustificationOpt();
    } else {
      this.reviewEditRuleForm.removeControl('justification');
      this.reviewEditRuleForm.removeControl('otherInput');
    }
  }

  /* Handled Cancel Scenario */
  canDeactivate(event, observer) {
    /* eslint-disable-next-line max-len */
    this.confirmMessage.message = this.confirmMessage.message ? this.confirmMessage.message : this.emtrConst.rulesConstant.confirmationMessage.menuClickMsg;
    if (this.isClicked) {
      this.localObserver = observer;
      return this.confirmationMessage.showConfirmMsg(this.confirmMessage, observer);
    } else {
      return true;
    }
  }

  /* Override is selected showing Justification */
  changeJustificationOpt() {
    if (this.reviewEditRuleForm.get('justification').value !== 'Other') {
      this.reviewEditRuleForm.get('otherInput').disable();
    }
    this.reviewEditRuleForm.get('justification').valueChanges.subscribe((value) => {
      if (value === 'Other') {
        this.reviewEditRuleForm.get('otherInput').enable();
      } else {
        this.reviewEditRuleForm.get('otherInput').reset();
        this.reviewEditRuleForm.get('otherInput').disable();
      }
    });
  }

  editRuleNameDescriptionNotes() {
    this.changeJustificationOpt();
  }
  /* get the emitted assigned & unassigned ordIds from the child component */
  getSelectedClientData(e) {
    this.assignOrgIds = e.selectedOrgIDArr;
    this.unassignOrgIds = e.unAssignOrgIdsArray;
    this.subAccntNumbers = [];
    this.employeeAccounts = e.selectedArr;
    this.subAccntNumbers = e.subAccntNumbers;
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1 && this.employeeAccounts && this.employeeAccounts.length > 0) {
      this.unassignedEmpGroup = this.employeeGroups[index].id ? [this.employeeGroups[index].id] : [];
      this.employeeGroups = [];
    }
    setTimeout(() => {
      this.sharedMasterGrp.getDatafromParent(this.employeeAccounts);
    }, 10);
  }

  displayErrorDate(errorMsg) {
    this.dateError = errorMsg;

    /*To show date error on top of the step wizard*/
    if (this.dateError) {
      /*To remove the previously displayed date error from errorMsg and replace it with new error msg*/
      if (this.dateErrorMsg) {
        if (this.errorMsg.indexOf(this.dateErrorMsg) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.dateErrorMsg), 1);
        }
      }
      this.dateErrorMsg = this.dateError;
      if (this.errorMsg.indexOf(this.dateError) === -1) {
        this.errorMsg.push(this.dateError);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.dateErrorMsg) {
        if (this.errorMsg.indexOf(this.dateErrorMsg) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.dateErrorMsg), 1);
        }
      }
    }

    /*Hide the error block when errorMsg is empty*/
    if (this.errorMsg.length === 0) {
      this.ruleService.showSchError.emit(false);
    }

    this.ruleService.schErrorMsg.emit(this.errorMsg);
  }

  /* Row is selected in Employee Group/Employee setting flag*/
  employeeRowSelected(data) {
    this.isRowSelected = true;
  }

  getDataEmpGrp(e) {
    this.employeeGroups = e.selectedArr;
    this.shouldDisabledEmployeesOrParticipants = this.employeeGroups && (this.employeeGroups.length !== 0);
    if (this.shouldDisabledEmployeesOrParticipants) {
      this.activeIndex = 0;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMessage);
        this.showSelectionMessage = true;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledGroupsOrPlans && !this.shouldDisabledEmployeesOrParticipants) {
    this.ruleService.toastMsg.emit('');
    this.showSelectionMessage = false;
  }
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1) {
      this.employeeAccounts = [];
    }
    if (this.employeeGroups && this.employeeGroups.length > 0) {
      this.employeeAccounts = [];
      this.subAccntNumbers = [];
      this.empIds = [];
    }
    this.sharedEmpAcc.getDatafromParent(e.selectedArr, '', '');
  }

  /* Activating Rule */
  activateRule() {
    if (!this.dateError) {
      this.reviewEditRuleData.activate = true;
      this.updateRuleMessage();
      this.employeeAccounts.forEach(emp => {
        const subAct = this.subAccntNumbers.findIndex(acct => acct.empId === emp.empId && emp.subAcctNum === acct.subAcctNum);
        const empId = this.empIds.includes(emp.empId);
        if (emp.parent && !empId) {
          console.log('In If Block  : ')
          this.empIds.push(emp.empId);
          const removeAcct = this.subAccntNumbers;
          console.log('removeAcct : ', removeAcct)
          removeAcct.forEach(acctVal => {
            if (acctVal.empId) {
              const index = this.subAccntNumbers.findIndex(acct => acct.empId && this.empIds.includes(acct.empId));
              console.log('In If Block  - index : ', index)
              index !== -1 ? this.subAccntNumbers.splice(index, 1) : '';
            }
          });
        } else if (!empId && subAct === -1) {
          console.log('In Else Block : ')
          this.subAccntNumbers.push({empId: emp.empId || '', subAcctNum: emp.subAcctNum});
        }
      });
      this.saveRule('save');
    }
  }

  getSubAccnts(e) {
    this.modifiedSubAccnts = e;
  }

  getDataEmpAcc(e) {
    this.employeeAccounts = e;
    // this.employeeAccounts = e.selectedArr;
    // this.empIds = e.empIds;
    // this.subAccntNumbers = e.subAccntNumbers;
    this.shouldDisabledGroupsOrPlans = this.employeeAccounts && (this.employeeAccounts.length !== 0);
    if (this.shouldDisabledGroupsOrPlans) {
      this.activeIndex = 1;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMessage);
        this.showSelectionMessage = true;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledGroupsOrPlans && !this.shouldDisabledEmployeesOrParticipants) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1 && this.employeeAccounts && this.employeeAccounts.length > 0) {
      this.employeeGroups = [];
    }
    if (this.employeeAccounts && this.employeeAccounts.length > 0) {
      this.employeeGroups = [];
    }
    this.sharedEmpGrp.getDatafromParent(this.employeeAccounts);
  }

  /* Updating the FormObj before Save */
  getFormValues() {
    const formObj = this.reviewEditRuleForm.value;
    /* eslint-disable-next-line max-len */
    this.reviewEditRuleData.startDt = (formObj.calendarForm.dateFrom.date) ? (moment.tz(moment(new Date(moment(formObj.calendarForm.dateFrom.date).format('YYYY/MM/DD') + ' ' + formObj.calendarForm.dateFrom.time + ' ' + formObj.calendarForm.dateFrom.timeMeridian).getTime()).format('YYYY MM DD HH:mm:ss.SSS'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS')) : '';
    /* eslint-disable-next-line max-len */
    this.reviewEditRuleData.endDt = (formObj.calendarForm.dateTo) ? moment.tz(moment(new Date(moment(formObj.calendarForm.dateTo.date).format('YYYY/MM/DD') + ' ' + formObj.calendarForm.dateTo.time + ' ' + formObj.calendarForm.dateTo.timeMeridian).getTime()).format('YYYY MM DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS') : '';

    formObj.restrictBuys = (formObj.restrictBuys && formObj.restrictBuys === true) ? 'Y' : 'N';
    this.reviewEditRuleData.name = this.reviewEditRuleForm.get('reviewRuleName').value.trim();
    this.reviewEditRuleData.desc = this.reviewEditRuleForm.get('descriptionForm').get('description').value;
    this.reviewEditRuleData.note = this.reviewEditRuleForm.get('descriptionForm').get('notes').value;
    this.reviewEditRuleData.changeRequestId = this.reviewEditRuleForm.get('descriptionForm').get('changeRequestId').value;
    if (this.reviewEditRuleData.mode === 'Override') {
      this.reviewEditRuleData.justification = this.reviewEditRuleForm.get('justification').value;
      this.reviewEditRuleData.otherInput = this.reviewEditRuleForm.get('otherInput').value;
    }
    this.reviewEditRuleData.employeeGroups = this.employeeGroups;
    this.reviewEditRuleData.employeeAccounts = this.employeeAccounts;
    this.reviewEditRuleData.empIds = this.empIds;
    this.reviewEditRuleData.subAccntNumbers = ((this.modifiedSubAccnts && this.modifiedSubAccnts.length > 0) ? this.modifiedSubAccnts : this.subAccntNumbers);
  }

  setTime(form) {
    const date = form.get('date').value;
    const time = form.get('time').value;
    const timeMeridian = form.get('timeMeridian').value;
    let hours = time.split(':')[0];
    const mins = time.split(':')[1];
    if (timeMeridian === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    const newDate = new Date(date);
    newDate.setHours(parseInt(hours, 10));
    newDate.setMinutes(parseInt(mins, 10));
    return newDate;
  }

  getInitialCount(e) {
    this.getInitialData = e;
    this.sharedEmpAcc.getDatafromParent(this.getInitialData, 'count', '');
  }

  /* Updating Message to corresponding selection Override/Recurrence/Restriction */
  updateRuleMessage() {
    if (this.reviewEditRuleData.rulesCategory === 'RESTRICT_LST_ENG' && this.reviewEditRuleData.override !== 'Y') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.restriction;
    } else if (this.reviewEditRuleData.rulesCategory === 'WATCH_LST_ENG') {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.watchList;
    } else {
      this.ruleType = this.emtrConst.rulesConstant.ruleGlobalConst.override;
    }
    this.message = '<strong>' + this.reviewEditRuleForm.get('reviewRuleName').value.trim() + '</strong>' + ' - ' + this.ruleType;
  }

  /* Saving the Rule */
  saveRule(inputData) {
    this.modifiedSubAccnts = (this.modifiedSubAccnts === undefined) ? [] : this.modifiedSubAccnts;
    this.modifiedSubAccnts = (this.modifiedSubAccnts && this.modifiedSubAccnts.length === 0) ? this.emtrService.setModifiedRecords(this.modifiedSubAccnts, this.subAccntNumbers, [...this.reviewEditRuleData.employeeAccounts]) : this.modifiedSubAccnts;
    this.getFormValues();
    this.isClicked = false;
    const currentDate = new Date(moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'));
    const currentTime = currentDate.getTime() > this.setTime(this.reviewEditRuleForm.get('calendarForm').get('dateFrom')).getTime();
    const fromDate = this.reviewEditRuleForm.get('calendarForm').get('dateFrom').value;
    const DateCheck = (moment(currentDate).format('YYYY/MM/DD') === moment(fromDate.date).format('YYYY/MM/DD'));
    if (currentTime && DateCheck) {
      /* eslint-disable-next-line max-len */
      this.reviewEditRuleData.startDt = (fromDate.date) ? (moment.tz(moment(new Date(moment(fromDate.date).format('YYYY/MM/DD') + ' ' + moment(currentDate).add(1, 'minutes').format('hh:mm A') + ' ' + fromDate.timeMeridian).getTime()).format('YYYY MM DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS')) : '';
    }
    const reviewDataCopy: any = {...this.reviewEditRuleData};
    if (this.reviewEditRuleData.mode === 'Recurrence') {
      reviewDataCopy.recurrence = true;
    } else {
      reviewDataCopy.recurrence = false;
    }

    const subAcc = [];
    this.employeeAccounts.forEach(accntNo => {
      subAcc.push({ empdId: '', subAcctNum: accntNo.subAcctNum });
    });
    reviewDataCopy.ruleAssignInfo = {
      assignEmpIds: this.reviewEditRuleData.empIds,
      employeeGroups: this.reviewEditRuleData.employeeGroups,
      assignSubAcctNumbers: this.reviewEditRuleData.global && (this.reviewEditRuleData.global.type === 'GLOBAL' || this.reviewEditRuleData.global.type === 'SEMI_GLOBAL') ? subAcc : this.reviewEditRuleData.subAccntNumbers,
      assignBU: (this.employeeGroups.length > 0 && this.employeeGroups[0].name === this.emtrConst.ruleSteps.step3.allClientInfo.clientName) ? ['RBS'] : [],
      id: this.reviewEditRuleData.id,
      orgId: this.reviewEditRuleData.orgId
    };
    delete reviewDataCopy.employeeAccounts;
    delete reviewDataCopy.empIds;
    delete reviewDataCopy.employeeGroups;
    delete reviewDataCopy.planId;
    delete reviewDataCopy.ruleSubCategory;

    /* Removing restrictShortSells in request Payload if ruleType is FundFamily */
    if (reviewDataCopy.ruleType === this.emtrConst.rulesConstant.autoComplete.FNDISUTxt) {
      delete reviewDataCopy.rstrctShortSell;
    }
    reviewDataCopy.assignBU = (this.employeeGroups.length > 0 && this.employeeGroups[0].name === this.emtrConst.ruleSteps.step3.allClientInfo.clientName) ? ['RBS'] : [];
    if (reviewDataCopy.global) {
      if (reviewDataCopy.assignBU.length > 0) {
        reviewDataCopy.global.type = 'GLOBAL';
      } else {
        reviewDataCopy.global.type = 'SEMI_GLOBAL';
        reviewDataCopy.subAccntNumbers = [];
        this.employeeAccounts.forEach(accntNo => {
          reviewDataCopy.subAccntNumbers.push({ empdId: '', subAcctNum: accntNo.subAcctNum });
        });
      }
    }
    /* Calling Recurring for Recurrence else calling copy for override/restriction */
    if (this.reviewEditRuleData.mode === 'Recurrence') {
      this.ruleService.saveEditRuleRecurring(reviewDataCopy).subscribe((res) => {
        this.toastMessages();
        this.router.navigate(['/ems/emtr/rules/rules-list'], {relativeTo: this.r});
      });
    } else {
      reviewDataCopy.action = 'COPY';
      this.ruleService.saveEditRuleCopy(reviewDataCopy).subscribe((res) => {
        this.toastMessages();
        this.router.navigate(['/ems/emtr/rules/rules-list'], {relativeTo: this.r});
      });
    }
  }

  /* Clickling Previous Button Scenario */
  goToPrevious() {
    this.isClicked = false;
    this.getFormValues();
    if (this.ruleNameError === false) {
      this.ruleService.showSchError.emit(false);
    }
    this.sharedService.clearValidationFields();
    this.router.navigate(['../step1'], {relativeTo: this.r});
  }

  navigateTo(path) {
    this.router.navigate([path], {relativeTo: this.r});
  }

  cancelCreateRule() {
    this.confirmMessage.message = this.emtrConst.rulesConstant.confirmationMessage.cancelBtnClickMsg;
    this.router.navigate(['/ems/emtr/rules/rules-list']);
  }

  getEmpGrpRecords(param) {
    this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, param.filterObj).subscribe((response) => {
      if (this.reviewEditRuleData.global && this.reviewEditRuleData.globalRule) {
        if (response.data.number === 0 && (param.filterObj.groupName === '' || this.emtrConst.ruleSteps.step3.allClientInfo.k4ClientName.toUpperCase().includes(param.filterObj.groupName.toUpperCase())) && (param.filterObj.groupDescription === '')
         && (param.filterObj.lastUpdatedFromDate === '')) {
          response.data.content.unshift({ name: 'Business Unit', type: 'ORG' });
        }
        this.showActionBtn = true;
      }
      this.employees = this.masterGroups = response.data.content;
      if (this.employees.length > 0) {
        if (this.tableCountData.empCount === 0) {
          this.tableCountData.empCount = this.employees[0].noOfEmployees;
          this.tableCountData.subAccCount = this.employees[0].noOfSubAccounts;
          const companyGroupEmpData = this.tableCountData.empCount;
          const companyGroupAccData = this.tableCountData.subAccCount;
          const emitData = {companyGroupEmp: companyGroupEmpData, companyGroupAcc: companyGroupAccData};
          if (emitData.companyGroupAcc !== undefined && emitData.companyGroupEmp !== undefined) {
            this.getInitialCount(emitData);
          }
          if (this.reviewEditRuleData.employeeGroups) {
            const empGrps = Object.keys(this.reviewEditRuleData.employeeGroups);
            empGrps.map((key) => {
              const value = this.reviewEditRuleData.employeeGroups[key];
              this.tableCountData.empSelected += value.noOfEmployees;
              this.tableCountData.subAccSelected += value.noOfSubAccounts;
            });
            const emitDataVal = {
              selectedArr: this.reviewEditRuleData.employeeGroups,
              empSelected: this.tableCountData.empSelected,
              subAccSelected: this.tableCountData.subAccSelected,
              empCount: this.tableCountData.empCount,
              subAccCount: this.tableCountData.subAccCount
            };
            if (emitDataVal.selectedArr.length > 0) {
              if (this.showActionBtn) {
                this.updatedMasterGrpData(emitDataVal);
              } else {
                this.getDataEmpGrp(emitDataVal);
              }
            }
          }
        }
        this.tableCountData.totalRecords = response.data.totalElements;
        this.tableCountData.paginationCount = response.data.totalElements;
        return this.employees;
      } else {
        this.masterGroups = [];
        this.employees = [];
        this.tableCountData = {};
        return this.employees;
      }
    });
  }

  paginateEmployeeList($event) {
    this.getEmpGrpRecords($event);
  }

  toastMessages() {
    const currentDate = new Date(moment().tz('America/New_York').format('YYYY-MM-DDTHH:mm:ss'));
    const fromDate = this.reviewEditRuleForm.get('calendarForm').get('dateFrom').value;
    const formattedDate = (fromDate.date) ? (moment.tz(moment(new Date(moment(fromDate.date).format('YYYY/MM/DD') + ' ' + fromDate.time + ' ' + fromDate.timeMeridian).getTime()).format('YYYY MM DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSS', 'America/New_York').format('YYYY-MM-DDTHH:mm:ss.SSS')) : '';
    if (this.reviewEditRuleData.activate) {
      if (moment(currentDate).isBefore(moment(formattedDate))) {
        const newDate = moment(formattedDate).format('MM/DD/YYYY @ hh:mm A');
        this.landingPageMsg = this.message + this.emtrConst.rulesConstant.ruleMessages.ruleWasSuccessfullySavedAndHasBeenScheduledToStart + '<strong>' + newDate + '</strong>';
      } else {
        this.landingPageMsg = this.message + this.emtrConst.rulesConstant.ruleMessages.ruleWasSuccessfullySavedAndActivated;
      }
    }
    this.ruleService.sendMessage(this.landingPageMsg);
  }

  onChanges(): void {
    this.reviewEditRuleForm.get('reviewRuleName').valueChanges.subscribe((ruleNameValue) => {
      /*To show invalied rule name error on top of the step wizard*/
      const regExp = new RegExp('^[a-zA-Z0-9+_()\[\\],.\\- ]*$');
      if (ruleNameValue && !regExp.test(ruleNameValue)) {
        if (this.errorMsg.indexOf(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter) === -1) {
          this.errorMsg.push(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter);
        }
        this.ruleNameError = true;
        this.ruleService.showSchError.emit(true);
      } else {
        this.ruleNameError = false;
        if (this.errorMsg.indexOf(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter) !== -1) {
          this.errorMsg.splice(this.errorMsg.indexOf(this.emtrConst.rulesConstant.ruleNameMsg.invalidCharacter), 1);
        }
      }

      /*Hide the error block when errorMsg is empty*/
      if (this.errorMsg.length === 0) {
        this.ruleService.showSchError.emit(false);
      }

      this.ruleService.schErrorMsg.emit(this.errorMsg);
    });
  }

  calendarValidation(value) {
    if (value.dateLabel === this.emtrConst.rulesConstant.startDate) {
      this.isInValidFromDate = value.isInValidDate;
      this.isPastFromDate = value.isPastDate;
    } else if (value.dateLabel === this.emtrConst.rulesConstant.endDate) {
      this.isInValidToDate = value.isInValidDate;
      this.isPastToDate = value.isPastDate;
    }

    /*To show 'Please enter a valid date MM/DD/YYYY' error msg*/
    if (this.isInValidFromDate || this.isInValidToDate) {
      if (this.errorMsg.indexOf(this.emtrConst.inValidDateMessage) === -1) {
        this.errorMsg.push(this.emtrConst.inValidDateMessage);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.errorMsg.indexOf(this.emtrConst.inValidDateMessage) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.emtrConst.inValidDateMessage), 1);
      }
    }

    /*To show 'From Date should be Current or Future Date' error msg*/
    if (this.isPastFromDate) {
      if (this.errorMsg.indexOf(this.emtrConst.pastFromDateMessage) === -1) {
        this.errorMsg.push(this.emtrConst.pastFromDateMessage);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.errorMsg.indexOf(this.emtrConst.pastFromDateMessage) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.emtrConst.pastFromDateMessage), 1);
      }
    }

    /*To show 'To Date should be Current or Future Date' error msg*/
    if (this.isPastToDate) {
      if (this.errorMsg.indexOf(this.emtrConst.pastToDateMessage) === -1) {
        this.errorMsg.push(this.emtrConst.pastToDateMessage);
      }
      this.ruleService.showSchError.emit(true);
    } else {
      if (this.errorMsg.indexOf(this.emtrConst.pastToDateMessage) !== -1) {
        this.errorMsg.splice(this.errorMsg.indexOf(this.emtrConst.pastToDateMessage), 1);
      }
    }

    /*Hide the error block when errorMsg is empty*/
    if (this.errorMsg.length === 0) {
      this.ruleService.showSchError.emit(false);
    }

    this.ruleService.schErrorMsg.emit(this.errorMsg);
  }

  ngOnDestroy(): void {
    this.sharedService.clearValidationFields();
    this.ruleService.showRequestIdError.emit(false);
    this.ruleService.toastMsg.emit('');
    this.ruleService.toastType.emit('');
    this.sharedService.groupType = '';
  }
  updatedMasterGrpData(data) {
    this.masterGrpData(data.selectedArr);
    this.unassignedEmpGroup = data.unassignedEmpGroup && data.unassignedEmpGroup[0] !== undefined ? data.unassignedEmpGroup : [];
    const index = this.employeeGroups.map(x => x.type).indexOf('ORG');
    if (index > -1) {
      this.buSelected = true;
      this.assignOrgIds = [];
      this.employeeAccounts = [];
      this.reviewEditRuleData.empAccounts = [];
    }
    if (this.clientSelection) {
      this.clientSelection.getDatafromParent(data.selectedArr, '', '');
    }
    const prevMasterAcc: any = (this.selectedMasterGrp) ? [...this.selectedMasterGrp] : null;
    if (prevMasterAcc !== null) {
      if ( prevMasterAcc.length === this.employeeGroups.length ) {
      for (const key in this.employeeGroups) {
        if (this.employeeGroups[key].id === prevMasterAcc[key].id) {
          this.selectedMasterAcc = false;
        } else {
          this.selectedMasterAcc = true;
          this.buSelected = false;
        }
      }
    }
    } else {
      this.selectedMasterAcc = true;
    }

    if (this.buSelected) {
      this.selectedMasterAcc = true;
      this.buSelected = false;
    }

  }
  masterGrpData(event) {
    this.selectedMasterGrp = event;
    this.employeeGroups = event;
    this.shouldDisabledMasterAccounts = this.employeeGroups && (this.employeeGroups.length !== 0);
    if (this.shouldDisabledMasterAccounts) {
      this.activeIndex = 0;
      if (!this.showSelectionMessage) {
        this.ruleService.toastType.emit('warn');
        this.ruleService.toastMsg.emit(this.selectionWarningMesgForMGP);
        this.showSelectionMessage = true;
        this.sharedService.showRulesDraftSaveSuccessMsg = false;
      }
    } else if (this.showSelectionMessage && !this.shouldDisabledMasterGroups && !this.shouldDisabledMasterAccounts) {
      this.ruleService.toastMsg.emit('');
      this.showSelectionMessage = false;
    }
  }

  onChangeTab(event) {
    this.sharedService.resetFilterForm.emit({ tabChange: true, tabName: event.originalEvent.target.innerText });
  }
}
