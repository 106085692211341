<div class="sdps-navigation--horizontal" role="application">
  <nav class="sdps-navigation--horizontal__levelThree" role="navigation" aria-label="primary">
    <ul class="sdps-navigation--horizontal__list">
      <ng-container *ngFor="let item of menuList;">
        <li class="sdps-navigation--horizontal__item" routerLinkActive="sdps-is-active" [ngClass]="!item.showLink ? 'demo-border-bottom':''">
          <a class="sdps-navigation--horizontal__link" *ngIf="item.showLink && this.sharedService.checkAccess(item.accessName)" >
            <button [id]="item.routerLink" class="buttons navigation-button" [routerLink]="item.routerLink"
                  [innerHTML]="item.menuName" (click)="sharedService.loadDataTrackValue(item.routerLink)"></button>
          </a>
          <a class="sdps-navigation--horizontal__link" *ngIf="!item.showLink && this.sharedService.checkAccess(item.accessName)" [class.ui-disable-nav-tab]="!item.showLink ? true: ''"
            [innerHTML]="item.menuName" [title]="shellConstant.accessDeniedTooltipMessage"></a>
        </li>
      </ng-container>
    </ul>
  </nav>
</div>
