<ems-confirm-message #confirmationMessage></ems-confirm-message>

<div class="heading-font sdps-p-bottom_small"
     [innerHTML]="getSharedConstant.DBS.step1IndicatorName"></div>

<div class="step-border-box sdps-p-around_large select-rule-type">
  <form [formGroup]="createInstrumentGrpForm">
    <div class="sdps-grid-container sdps-wrap">
      <div class="sdps-row">
        <!-- Instrument group Name -->
        <div class="rule-type-textbox sdps-p-around_medium col-3"
             [ngClass]="{'invalidField': ((createInstrumentGrpForm.get('groupName').value && (createInstrumentGrpForm.get('groupName').invalid || groupNameExists)))}">
          <span class="p-float-label">
            <input type="text" name="groupName" [maxlength]="100" formControlName="groupName"
                   #focusName emsAlphaNumericSpecialCharacterValidation emsCharacterLimit
                   [charMaxLimit]="commonConstants.charLimitValues.hundredMaxLimit"
                   (emsCharacterLimitCheck)="showGroupNameCharLimit = $event" pInputText/>
            <label>{{getSharedConstant.DBS.name}}
              <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
            </label>
          </span>
        </div>

        <!-- Business Unit dropdown -->
        <div class="col-6 sdps-p-left_xx-large sdps-flex">
          <p class="sdps-p-top_medium sdps-p-right_x-small">
            <span [innerHTML]="getConstant.instrumentGroups.instrumentGrpStep1.businessUnit"></span>
            <span class="sdps-text-market-red" [innerHTML]="commonConstants.asterix"></span>
          </p>
          <div class="sdps-p-top_x-small timePickerForm col-4">
            <p-dropdown [options]="businessUnitData" placeholder=" " [disabled]="businessUnitData.length === 1 || formData.mode === 'EDIT'"
                        class="timePickerForm editableTimepicker business-unit-dropdown step1-bu" optionLabel="name"
                        formControlName="businessUnit">
            </p-dropdown>
          </div>
        </div>
      </div>
      <p class="col-3 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
         *ngIf="createInstrumentGrpForm.get('groupName').value && createInstrumentGrpForm.get('groupName').value.trim().length > showGroupNameCharLimit">{{getConstant.rulesConstant.characterLimit}}
        <span class="character-limit">{{createInstrumentGrpForm.get('groupName').value.trim().length}}
          /{{commonConstants.charLimitValues.hundredMaxLimit}}</span>
      </p>

      <div class="sdps-row sdps-p-top_large">
        <div class="rule-type-textbox sdps-p-around_medium col-6">
          <span class="p-float-label">
            <textarea class="sdps-p-vertical_x-small group-desc" rows="5" maxlength="300" formControlName="description"
                      name="description" emsCharacterLimit (emsCharacterLimitCheck)="showGroupDescCharLimit = $event"
                      [charMaxLimit]="commonConstants.charLimitValues.threeHundredMaxLimit" pInputTextarea></textarea>
            <label>{{getSharedConstant.DBS.description}}</label>
          </span>
        </div>
      </div>
      <p class="col-6 sdps-text-right sdps-p-around_none sdps-m-around_none sdps-text-small"
         *ngIf="createInstrumentGrpForm.get('description').value && createInstrumentGrpForm.get('description').value.length > showGroupDescCharLimit">{{getConstant.rulesConstant.characterLimit}}
        <span class="character-limit">{{createInstrumentGrpForm.get('description').value.length}}
          /{{commonConstants.charLimitValues.threeHundredMaxLimit}}</span>
      </p>
    </div>
  </form>
</div>

<ems-step-wizard-buttons [disableBtn]="createInstrumentGrpForm?(createInstrumentGrpForm.invalid || groupNameExists || (createInstrumentGrpForm.get('groupName').value && createInstrumentGrpForm.get('groupName').value.trim() === '')):''"
                         [showCancelBtn]=true [showContinueBtn]="true" (closeBtn)="cancelCreateInstrumentGroup()"
                         (goToNext)="goToNextStep()"></ems-step-wizard-buttons>
