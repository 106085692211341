import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService, FormDataService } from '@ems/shared';
import {Router} from '@angular/router';

@Component({
  selector: 'tr-rules',
  templateUrl: './rules.component.html'
})
export class RulesComponent implements OnInit, OnDestroy {

  constructor(public sharedService: SharedService, public router: Router, private formDataService: FormDataService) {
    this.sharedService.clearErrorMessage();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    /* Clearing sessionStorage value for other routes for State management */
    if (this.router.routerState.snapshot.url.indexOf('rules') === -1) {
      this.sharedService.clearLocalStorage();
      this.formDataService.resetForm();
    }
  }
}
