import {Component, OnInit, Injector, OnDestroy, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShellConstant } from '@shell_components/constants/shellConstants';
import { Dropdown } from 'primeng/dropdown';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { commonConstants } from '@shell_components/constants/commonConstants';
import { ReportsService } from '@core_services/report/reports.service';
import { AppService } from '@core_services/app.service';
import { PresetReportsComponent } from '@shell_components/reports/preset-reports/preset-reports.component';
import { AddlinkService } from '@ems/addlink';
import { Subject, Subscription } from 'rxjs';
import {ManageEmpGrpService, SharedConstant, SharedService} from '@ems/shared';
import {entitlementConst} from '@shell_components/constants/entitlementConstants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EmtrPagination, EmtrPaginationValues } from 'projects/ems/emtr/src/public-api';

@Component({
  selector: 'app-employee-group-association',
  templateUrl: './employee-group-association.component.html'
})
export class EmployeeGroupAssociationComponent implements OnInit, OnDestroy {
  showEmpIdCharLimit: boolean;
  showTextboxCharLimit: boolean;
  allGroupsInfo = ShellConstant.reports.allGroupsInfo;
  empGroupDropDownOpts = [];
  getShellConstant = ShellConstant;
  reportDetails: UntypedFormGroup;
  getCommConst = commonConstants;
  fromDate: any;
  toDate: any;
  parent: PresetReportsComponent;
  snapshotSelected: any = true;
  runReportFor: string;
  passReportName: string;
  reportGroupName: any;
  empGroupReport: string;
  orgID: any;
  private currentOrgSubscription: Subscription;
  reportRuleName: any;
  disableRunButton: boolean;
  errorMsg: any;
  dateError: any;
  endDateLessThanStartDate = '';
  showClientMsg: any;
  reportName: any;
  sharedConstant = SharedConstant;
  businessUnit: any;
  batchCitiOrg: any;
  dateRangeConstarint = '';
  searchInputUpdate = new Subject<any>();
  pageObject: EmtrPagination = EmtrPaginationValues;
  initialLoad = true;
  dropdownInputValue: any;
  @ViewChild('dropdown') groupDropdown: Dropdown;

  constructor(public addlinkService: AddlinkService, private appService: AppService, public fb: UntypedFormBuilder, public reportService: ReportsService,
              private injector: Injector, private activatedRoute: ActivatedRoute, public sharedService: SharedService, public manageEmpGrpService:ManageEmpGrpService) {
    this.parent = this.injector.get(PresetReportsComponent);
    this.reportName = this.reportService.getReportName();
  }

  ngOnInit() {
    this.reportDetails = this.fb.group({
      dateTo: this.fb.group({
        date: ['', Validators.required],
      }),
      dateFrom: this.fb.group({
        date: ['']
      }),
      accountHolderLastName: '',
      employeeID: '',
      employeeAccount: '',
      employeeLastName: '',
      empGroupName: '',
      tradeSymbol: '',
      cusipSymbol: '',
      selectedButtonType: ''
    });
    this.batchCitiOrg = this.sharedService.applicationInfo.data.batchCitiOrg;
    this.currentOrgSubscription = this.addlinkService.clientInformation.subscribe(clientInfo => {
      if (clientInfo !== null) {
        this.orgID = clientInfo && clientInfo.orgId;
        this.businessUnit = clientInfo && clientInfo.businessUnit;
        this.appService.setCurrentOrg(this.orgID);
        this.reportService.orgId = this.orgID;
        this.pageObject.pageNo = 0;
        this.pageObject.pageSize = 15;
        this.pageObject.sortField = ['name,asc'];
        const param = {
          paramObj: this.pageObject,
          groupName: ''
        };
        if (this.orgID !== 0) {
          this.getEmployeeGroupList(param);
          /*Freeze the global client selector when the report is selected*/
          this.reportService.freezeGlobalClientSelector(this.reportDetails);
        }
      }
    });
    this.reportDetails.get('selectedButtonType').setValue(this.getShellConstant.reports.RulesDetails.snapShot);
    this.reportDetails.get('selectedButtonType').updateValueAndValidity();
    this.reportService.makeFormDirty(this.reportDetails, this.parent);
    this.onChanges();
    this.reportService.reportsArray.subscribe((data: any) => {
      if (data !== null) {
        data.map((res) => {
          if (res.name === this.reportName) {
            res.accessType === 'CLIENT' ? this.orgID > 0 ? this.showClientMsg = false : this.showClientMsg = true : this.showClientMsg = false;
          }
        });
      }
    });

    this.searchInputUpdate
    .pipe(debounceTime(500))
    .pipe(distinctUntilChanged())
    .subscribe((results) => {
      const param = {
        paramObj: this.pageObject,
        groupName: results ? results : ''
      };
      if (this.orgID !== undefined && this.orgID !== 0) {
        this.getEmployeeGroupList(param);
      }
    });
  }

  clearSearch(dropdown: Dropdown) {
    if (!this.reportRuleName && !this.reportGroupName) {
      dropdown.resetFilter();
    }
  }

  getEmployeeGroupList(param) {
    const filterObj = {orgId : this.orgID, groupName: param.groupName, active: true};
    if ((this.reportService.businessUnit !== this.getShellConstant.rbsBUText && this.reportService.selectedReport === this.getShellConstant.reports.presetReports.reportNames.employeeGrpAssociationTxt) ||
      (this.reportService.businessUnit === this.getShellConstant.rbsBUText && this.reportService.selectedReport !== this.getShellConstant.reports.presetReports.reportNames.employeeGrpAssociationTxt) &&
      this.sharedService.checkEntitlements(entitlementConst.navUUID.emtrAccess.ruleSummary.uuid, '', '')) {
      this.manageEmpGrpService.getEmployeeGrpOrPlanList(param.paramObj, filterObj).subscribe((value: any) => {
        this.empGroupDropDownOpts = [];

        if (this.reportService.businessUnit === this.getShellConstant.rbsBUText) {
          this.empGroupDropDownOpts.unshift({label: this.allGroupsInfo.namePlan, value: this.allGroupsInfo.namePlan});
        } else {
          this.empGroupDropDownOpts.unshift({label: this.allGroupsInfo.name, value: this.allGroupsInfo.name});
        }
        value.data.content.forEach(empGroupName => {
          const data = {label: empGroupName.name, value: empGroupName.name};
          this.empGroupDropDownOpts.push(data);
        });
        this.editSnapshotHistory();
      });
    } else {
      this.cancelReport();
    }
  }

  editSnapshotHistory(reset?) {
    if (reset) {
      this.initialLoad = true;
      this.dropdownInputValue = '';
      this.reportDetails.get('empGroupName').reset();
      this.groupDropdown.value = '';
      this.getEmployeeGroupList({paramObj: this.pageObject, groupName: ''});
      return;
    }
    this.reportDetails.get('selectedButtonType').valueChanges.subscribe(value => {
      if (value === this.getShellConstant.reports.RulesDetails.snapShot) {
        this.reportDetails.get('employeeID').reset();
        this.reportDetails.get('employeeAccount').reset();
        this.reportDetails.get('employeeLastName').reset();
        this.reportDetails.get('accountHolderLastName').reset();
        this.snapshotSelected = true;
        this.dateError = false;
        this.endDateLessThanStartDate = '';
        this.parent.throwDateError('');
        this.parent.clearDateErrors();
        this.reportDetails.get('empGroupName').setValidators([Validators.required]);
      } else {
        this.snapshotSelected = false;
        this.reportDetails.get('empGroupName').setValidators(null);
      }
      this.reportDetails.get('empGroupName').updateValueAndValidity();
    });
    /*********
     * added the condition as the client may or may not have the company group.
     * Client may not have company group because it was created earlier and it is bad data issue.
     * The company group is the 2 object in the array list, so arr[1] will select the company group by default in the dropdown
     ********/
    const empGropName = this.reportDetails.get('empGroupName').value;
    if(empGropName) {
      const empIdx = this.empGroupDropDownOpts.findIndex(ele => ele.value === empGropName);
      if (empIdx === -1) {
        this.empGroupDropDownOpts.push({label: empGropName, value: empGropName});
      }
    }
    if ((this.empGroupDropDownOpts.length > 1 && (this.initialLoad))) {
      this.initialLoad = false;
      this.reportDetails.get('empGroupName').setValue(this.empGroupDropDownOpts[1].value);
      this.groupDropdown.value = this.empGroupDropDownOpts[1].value;
    } else {
      this.groupDropdown.value = this.reportDetails.get('empGroupName').value;
    }
  }

  groupNameUpdated(e) {
    this.reportDetails.get('empGroupName').setValue(e);
    this.reportDetails.markAsDirty();
    this.reportDetails.updateValueAndValidity();
  }

  setDate(event) {
    if (!this.snapshotSelected) {
      this.fromDate = event.fromDate;
      this.toDate = event.toDate;
      this.dateError = event.dateError;
    }
  }
  throwDateRangeError(event) {
    this.dateRangeConstarint = event;
    this.parent.throwDateRangeError(event);
  }

  calendarValidation(event) {
    this.parent.calendarValidation(event);
  }

  throwDateError(event) {
    if (!this.snapshotSelected) {
      this.endDateLessThanStartDate = event;
      this.parent.throwDateError(event);
    }
  }

  runReports() {
    const localInput = {
      employeeId: this.reportDetails.get('employeeID').value ? this.reportDetails.get('employeeID').value.trim() : '',
      accountNumber: this.reportDetails.get('employeeAccount').value ? this.reportDetails.get('employeeAccount').value.trim() : '',
      employeeLastName: this.reportDetails.get('employeeLastName').value ? this.reportDetails.get('employeeLastName').value.trim().toUpperCase() : '',
      groupName: (this.reportDetails.get('empGroupName').value) ? this.reportDetails.get('empGroupName').value : '',
      endDate: this.snapshotSelected ? '' : encodeURIComponent(this.reportService.formatDate(this.toDate)),
      startDate: this.snapshotSelected ? '' : encodeURIComponent(this.reportService.formatDate(this.fromDate)),
      accountHolderLastName: this.reportDetails.get('accountHolderLastName').value ? this.reportDetails.get('accountHolderLastName').value.trim().toUpperCase() : '',
      orgId: this.reportService.orgId,
      clientName: this.reportService.clientName,
      clientId: this.reportService.clientId
    };
    if (this.snapshotSelected) {
      this.runReportFor = this.getShellConstant.reports.sendReportAPI.snapshotAPI;
      this.empGroupReport = this.getShellConstant.reports.sendReportAPI.empGroupTxt;
      this.passReportName = this.getShellConstant.reports.presetReports.reportNames.employeeGrpAssociationSnapshotReportName;
      this.reportService.selectedSnapshotReport = true;
    } else {
      this.runReportFor = this.getShellConstant.reports.sendReportAPI.empGroup;
      this.passReportName = this.getShellConstant.reports.presetReports.reportNames.employeeGrpAssociationReportName;
    }
    const empGroup = this.snapshotSelected ? 'empgroup' : '';
    this.reportService.executeReports(this.parent, localInput, this.reportService.selectedReport, this.getShellConstant.reports.presetReports.reportNames.employeeGrpAssociationTxt, this.runReportFor,
      this.passReportName, this.appService, empGroup);
  }

  changeInEmpGrpName() {
    if (this.reportDetails.get('empGroupName').value === 'All Groups' || this.reportDetails.get('empGroupName').value === 'All Plans') {
      this.validateFields(this.reportDetails.get('empGroupName').value);
    } else {
      this.clearErrorMsgAllGroups();
    }
  }
  onChanges() {
    this.reportDetails.get('empGroupName').valueChanges.subscribe(empGroupNamevalue => {
      this.disableRunButton = false;
      if (empGroupNamevalue === 'All Groups' || empGroupNamevalue === 'All Plans') {
        this.validateFields(empGroupNamevalue);
      } else {
        this.clearErrorMsgAllGroups();
      }
    });
    this.reportDetails.get('employeeLastName').valueChanges.subscribe(empGroupLastNamevalue => {
      if (empGroupLastNamevalue && this.reportDetails.get('employeeLastName').invalid) {
        this.reportService.errorInEmpLastName = true;
      } else {
        this.reportService.errorInEmpLastName = false;
        this.changeInEmpGrpName();
      }
    });

    this.reportDetails.get('accountHolderLastName').valueChanges.subscribe(accountHolderLastNamevalue => {
      if (accountHolderLastNamevalue && this.reportDetails.get('accountHolderLastName').invalid) {
        this.reportService.errorInAccntHolderLastName = true;
      } else {
        this.reportService.errorInAccntHolderLastName = false;
        this.changeInEmpGrpName();
      }
    });
    const that = this;
    this.reportService.formErrorMessage(this.reportDetails, this.parent, that, 'employeeID', this.getShellConstant.reports.presetReports.employeeIdErrorMsg);
    this.reportService.formCharLimitErrorMessage(this.reportDetails, this.parent, 'employeeAccount', 8, this.getShellConstant.manageClients.clientDetailsPage.errorMsg.empAcctNumShouldContain8Digits);
    this.reportService.formErrorMessage(this.reportDetails, this.parent, that, 'employeeAccount', this.businessUnit ? this.sharedConstant[this.businessUnit].empOrParticipantAccountErrMsg : this.getShellConstant.reports.presetReports.empAccountErrMsg);

  }

  validateFields(empGroupNamevalue) {
    if (this.reportDetails.get('employeeID').value || this.reportDetails.get('employeeAccount').value || this.reportDetails.get('accountHolderLastName').value || this.reportDetails.get('employeeLastName').value) {
      this.disableRunButton = false;
      this.clearErrorMsgAllGroups();
    } else {
      if ((empGroupNamevalue === 'All Groups' || empGroupNamevalue === 'All Plans') && this.parent.schErrorMsg.indexOf(this.sharedConstant[this.businessUnit].employeeErrorMsgForAllGroup) === -1) {
        this.disableRunButton = true;
        this.parent.schErrorMsg.push(this.sharedConstant[this.businessUnit].employeeErrorMsgForAllGroup);
      } else {
        this.disableRunButton = false;
      }
    }
  }

  clearErrorMsgAllGroups() {
    if (this.parent.schErrorMsg.indexOf(this.sharedConstant[this.businessUnit].employeeErrorMsgForAllGroup) !== -1) {
      this.parent.schErrorMsg.splice(this.parent.schErrorMsg.indexOf(this.sharedConstant[this.businessUnit].employeeErrorMsgForAllGroup), 1);
    }
  }

  stopDisabledAllGroupsClick(event, label) {
    if ((label === this.allGroupsInfo.name || label === this.allGroupsInfo.namePlan) && this.snapshotSelected) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  cancelReport() {
    this.reportService.cancelReport(this.reportDetails, this.parent);
  }

  searchedText(e, data) {
    this.reportService.makeFormDirty(this.reportDetails, parent);
    if (data === 'groupName') {
      this.reportGroupName = e.target.value;
      this.searchInputUpdate.next(this.reportGroupName);
    } else {
      this.reportRuleName = e.target.value;
    }
  }

  ngOnDestroy(): void {
    if (this.currentOrgSubscription) {
      this.currentOrgSubscription.unsubscribe();
    }
    if (!this.snapshotSelected) {
      this.parent.clearErrorMsg();
      this.reportService.errorInEmpLastName = false;
      this.parent.reportFormIsDirty = false;
    }
  }
}
