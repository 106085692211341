<div class="step-indicator" [style.width]="stepObj.length*20+'%'">
  <ul *ngIf="stepObj.length">
    <li *ngFor="let step of stepObj;index as i" [style.width]="stepwidth+'%'" class="stepname">
      <div [ngClass]="step.valid?'active':''">
        <button routerLink="step{{i+1}}" routerLinkActive="active" [disabled]="true">
          {{i+1}}
        </button>
        <span>{{step.name}}</span>
      </div>
    </li>
  </ul>
</div>
<router-outlet></router-outlet>
