import {Directive, ElementRef } from '@angular/core';
import {Validator, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[emsEmailPatterncheck]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: EmailPatterncheckDirective, multi: true}
  ]
})
export class EmailPatterncheckDirective implements Validator {
  newExp: any;
  constructor(public el: ElementRef) { }

  validate() {
    this.newExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const elementVal = this.el.nativeElement.value;
    if (this.newExp.test(elementVal)) {
      return null;
    } else {
      return {
          emailValidationErrors: false
      };
    }
  }

}
