<div class="sdps-navigation--horizontal" role="application">
  <nav aria-label="Expanded Summary panel with five nested items" class="sdps-navigation--horizontal__levelFour subNavigation" id="unique-secondaryNav">
    <ul aria-label="Open an Summary steps" class="sdps-navigation--horizontal__nav" role="group" >
      <ng-container *ngFor="let nav of adminMenuSubMenu">
        <li class="sdps-navigation--horizontal__item" role="group" routerLinkActive="sdps-is-active" [ngClass]="!nav.showLink ? 'demo-border-bottom':''">
          <a class="sdps-navigation--horizontal__link" *ngIf="nav.showLink && this.sharedService.checkAccess(nav.accessName)">
            <button class="buttons sub-navigation-button" [routerLink]="nav.route" [ngClass]="!nav.showLink ? 'demo-border-bottom':''"
                    [innerHTML]="nav.name" (click)="sharedService.loadDataTrackValue(nav.route)"></button>
          </a>
          <a class="sdps-navigation--horizontal__link" *ngIf="!nav.showLink && this.sharedService.checkAccess(nav.accessName)" [class.ui-disable-nav-tab]="!nav.showLink ? true: ''"
             [innerHTML]="nav.name" [title]="shellConstant.accessDeniedTooltipMessage"></a>
        </li>
      </ng-container>
    </ul>
  </nav>
  <div class="content-area sdps-p-around_large">

    <router-outlet></router-outlet>

  </div>
</div>
