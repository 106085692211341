<div [formGroup]="calendarForm" class="display-flex">
<div class="col-3 sdps-p-left_none">
    <div formGroupName="dateFrom">
      <div class="sdps-p-bottom_x-small">
        <strong [innerHTML]="getShellConstant.reports.presetReports.from"></strong>
        <span class="sdps-text-market-red" [innerHTML]="getCommConst.asterix"></span>
      </div>
      <div class="sdps-m-around_none sdps-p-right_medium">
        <tr-datepicker (calendarValidationEvent)="calendarValidationEvent($event)" (calendarValidationEventOnChange)="validateDateErrors($event)" [showErr]="dateErrCssFromDate" [dateLabel]="'From Date'" [minDate]="minDate"
          [maxDateValue]="maxDate" [dateForm]="calendarForm.get('dateFrom')" class="sdps-p-around_none" [readonlyInput]="false" [disabled]="disabled"></tr-datepicker>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div>
    <div class="sdps-p-bottom_x-small">
      <strong [innerHTML]="getShellConstant.reports.presetReports.to"></strong>
      <span class="sdps-text-market-red" [innerHTML]="getCommConst.asterix"></span>
    </div>
    <div formGroupName="dateTo" class="sdps-m-around_none">
      <tr-datepicker (calendarValidationEvent)="calendarValidationEvent($event)" (calendarValidationEventOnChange)="validateDateErrors($event)" [showErr]="dateErrCss || dateRangeErrCss" [dateLabel]="'To Date'" [dateForm]="calendarForm.get('dateTo')"
        [minDate]="minDate" [maxDateValue]="maxDate" class="sdps-p-around_none" [readonlyInput]="false" [disabled]="disabled"></tr-datepicker>
    </div>
    </div>
  </div>
</div>
